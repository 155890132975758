import { useEffect, useMemo } from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import OrderLineItemPriceBreakdown from "components/LineItemPriceBreakdown/OrderLineItemPriceBreakdown";
import useOrderActions from "components/Orders/useOrderActions";
import OrderView from "components/Orders/OrderView/OrderView";
import useOrderData from "../useOrderData";
import LoadingLinearProgress from "components/Common/LoadingLinearProgress";
import NotFound from "components/Layout/NotFound";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import useOnlineAckToken from "helpers/hooks/useOnlineAckToken";

const OrderViewMain: React.FC<any> = () => {

    const query = new URLSearchParams(useLocation().search);
    const oKeyString = query.get("oKey") ?? undefined;
    const onlineAckToken = useOnlineAckToken();
    const orderActions = useOrderActions();
    const userInfo = useUserInfo();
    const { isLoading, onlineAckOKey } = useOrderData();

    const oKey = useMemo(() => {
        if (userInfo.HasRole(RoleEnum.OnlineAck)) {
            return onlineAckOKey ?? 0;
        }
        else {
            return oKeyString ? parseInt(oKeyString) : 0;
        }
    }, [oKeyString, userInfo, onlineAckOKey]);

    // If the online ack user is logged in and token provided in the URL, find the matching oKey from the token and load that order
    useEffect(() => {
        if (userInfo.HasRole(RoleEnum.OnlineAck) && onlineAckToken !== undefined) {
            orderActions.LoadOnlineAckOkeyAsync(onlineAckToken);
            orderActions.LoadOtherUnacknowledgedOrdersAsync(onlineAckToken);
        }
    }, [userInfo, onlineAckToken, orderActions]);

    useEffect(() => {
        if (oKey) {
            orderActions.LoadOrderAsync(oKey);
        }
    }, [oKey, orderActions]);

    if (!oKey && onlineAckToken === undefined) {
        return <NotFound />
    }

    if (isLoading) {
        return <LoadingLinearProgress />
    }

    return <Routes>
        <Route path="" element={<OrderView oKey={oKey} />} />
        <Route path="pricebreakdown" element={<OrderLineItemPriceBreakdown />} />
        <Route path="*" element={<NotFound />} />
    </Routes>

};

export default OrderViewMain;