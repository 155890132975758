import isNumeric from "fast-isnumeric";
import React, { useState, useCallback, useMemo } from "react";
import { TextField, IconButton, Checkbox, Tooltip, Autocomplete } from "@mui/material";
import { StyledRow, StyledCell } from "./RowStyles"
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import { useTranslations } from "@fenetech/translations";
import useIsMobile from "helpers/hooks/useIsMobile";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { ISimpleListItem, IPriceTableOptionGridRow } from "helpers/interfaces";
import { PricingMethodEnum } from "helpers/enums";
import SetupAPI from "components/Setup/SetupAPI"
import HelperFunctions from "components/Setup/PriceTables/HelperFunctions";
import ErrorAdornment from "components/Common/ErrorAdornment";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";

interface IProps {
    tableID: string,
    schedules: ISimpleListItem[],
    option: IPriceTableOptionGridRow,
    index: number,
    usesMultiplier: boolean,
    tableType: PricingMethodEnum,
    partNo: string,
    partNoSuffix: string,
    fillDownHandler: (index: number, scheduleID: number, discount: string, fixedPrice: boolean) => void,
    cf: ICurrencyFormatter,
    lnf: ILocaleNumberFormatter
}

const OptionsGridRow = ({tableID, schedules, option, index, usesMultiplier, tableType, partNo, partNoSuffix, fillDownHandler, cf, lnf}: IProps) => {
    const tm = useTranslations();
    const isMobile = useIsMobile();
    const messageBox = useMessageBox();

    const [scheduleValue, setScheduleValue] = useState<number>(option.wcPriceScheduleID);
    const [discountValue, setDiscountValue] = useState<number | null>(option.discount ?? option.fixedPrice);
    const [displayDiscountValue, setDisplayDiscountValue] = useState<string>(HelperFunctions.FormatDiscountValue(option.discount, option.fixedPrice, usesMultiplier, tableType, cf, lnf));
    const [errorText, setErrorText] = useState<string>("");
    const [override, setOverride] = useState<boolean>(option.override);

    const handleDiscountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayDiscountValue(e.target.value);
    }, []);

    const handleDiscountBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        let value: string = e.target.value;
        if (value === (discountValue === null ? "" : lnf.Format(discountValue))) {
            return;
        }
        if (value === "") {
            setDiscountValue(null);
            SetupAPI.UpdateOptionPricingItemWithValue(parseInt(tableID), partNo, partNoSuffix, option.code, null, false);
        } else {
            let strippedValue = cf.Parse(value);
            if (isNumeric(strippedValue)) {
                if (!cf.IsCurrencyFormat(value)) {
                    let numericValue = strippedValue;
                    let displayValueToShow = lnf.Format(numericValue);
                    let errorText = "";
                    if (usesMultiplier) {
                        errorText = HelperFunctions.ValidateMultiplier(numericValue, tableType, tm);
                        if (errorText !== "") {
                            // Error
                            setErrorText(errorText);
                            return;
                        }
                        numericValue = HelperFunctions.ConvertMultiplierToPercent(numericValue, tableType);
                    }
                    errorText = HelperFunctions.ValidatePercent(numericValue, tableType, tm);
                    if (errorText !== "") {
                        // Error
                        setErrorText(errorText);
                        return;
                    }
                    // Valid and not a Fixed Price
                    setScheduleValue(0);
                    setDiscountValue(numericValue);
                    setDisplayDiscountValue(displayValueToShow);
                    SetupAPI.UpdateOptionPricingItemWithValue(parseInt(tableID), partNo, partNoSuffix, option.code, numericValue, false);
                } else {
                    // Fixed Price
                    setScheduleValue(0);
                    setDiscountValue(strippedValue);
                    setDisplayDiscountValue(cf.Format(strippedValue));
                    SetupAPI.UpdateOptionPricingItemWithValue(parseInt(tableID), partNo, partNoSuffix, option.code, strippedValue, true);
                }
            } else {
                // NaN error
                setErrorText(tm.Get("Invalid Value"));
                return;
            }
        }
        setErrorText("");
    }, [cf, lnf, tm, discountValue, usesMultiplier, tableID, tableType, partNo, partNoSuffix, option.code]);

    const handleScheduleChange = useCallback((data: ISimpleListItem) => {
        // Set the Schedule
        setDiscountValue(null);
        setDisplayDiscountValue("");
        setScheduleValue(data.id);
        setErrorText("");
        SetupAPI.UpdatePricingItemWithSchedule(parseInt(tableID), partNo, partNoSuffix, option.code, data.id);
    }, [tableID, partNo, partNoSuffix, option.code]);

    const handleOverrideChange = useCallback((e: any) => {
        let checked = e.target.checked;
        if (checked) {
            // Creating new override, insert original values as default point
            setOverride(true);
            if (discountValue !== null) {
                let value = discountValue;
                if (usesMultiplier) {
                    value = HelperFunctions.ConvertMultiplierToPercent(value, tableType);
                }
                SetupAPI.UpdateOptionPricingItemWithValue(parseInt(tableID), partNo, partNoSuffix, option.code, value, cf.IsCurrencyFormat(displayDiscountValue));
            } else {
                SetupAPI.UpdatePricingItemWithSchedule(parseInt(tableID), partNo, partNoSuffix, option.code, scheduleValue);
            }
        } else {
            // Removing override, clear out values and requery just this row
            SetupAPI.UpdatePricingItemWithSchedule(parseInt(tableID), partNo, partNoSuffix, option.code, 0).then(() => {
                SetupAPI.RequeryDefaultPriceForOption(parseInt(tableID), option.code).then(newData => {
                    setScheduleValue(newData.wcPriceScheduleID);
                    setDiscountValue(newData.discount ?? newData.fixedPrice);
                    setDisplayDiscountValue(HelperFunctions.FormatDiscountValue(newData.discount, newData.fixedPrice, usesMultiplier, tableType, cf, lnf));
                    setErrorText("");
                    setOverride(false);
                });
            });
        }
    }, [tableID, partNo, partNoSuffix, option.code, displayDiscountValue, discountValue, scheduleValue, tableType, usesMultiplier, cf, lnf]);

    const promptToFillDown = useCallback(() => {
        messageBox.Show({
            message: tm.Get("Are you sure you would like to fill down the value?"), 
            title: tm.Get("Fill Down"),
            yesNoPrompt: true,
            callback: (result) => {
                if (result) fillDownHandler(index, scheduleValue, discountValue?.toString() ?? "", cf.IsCurrencyFormat(displayDiscountValue));
            }
        });
    }, [messageBox, tm, cf, discountValue, displayDiscountValue, fillDownHandler, index, scheduleValue]);

    const scheduleList = useMemo(() => {
        return [{id: 0, description: tm.Get("{None}")}].concat(schedules);
    }, [schedules, tm]);

    const schedule = useMemo(() => {
        return scheduleList.find((s) => s.id === scheduleValue) ?? scheduleList[0];
    }, [scheduleList, scheduleValue]);

    return <>
        <StyledRow>
            {partNo !== "" && 
                <StyledCell><Checkbox checked={override} onChange={handleOverrideChange}/></StyledCell>
            }
            {isMobile && <>
                <StyledCell>{option.code} - {option.description}</StyledCell>
            </>}
            {!isMobile && <>
                <StyledCell>{option.code}</StyledCell>
                <StyledCell>{option.description}</StyledCell>
            </>}
            <StyledCell>
                <Autocomplete
                    sx={{marginY: 1}}
                    autoComplete
                    autoHighlight
                    autoSelect
                    selectOnFocus
                    disableClearable
                    handleHomeEndKeys
                    options={scheduleList}
                    fullWidth
                    size="small"
                    getOptionLabel={(option : ISimpleListItem) => option.description}
                    renderInput={(params) => <TextField
                        variant="outlined"
                        {...params}
                    />}
                    value={schedule}
                    onChange={(_, data) => handleScheduleChange(data)}
                />
            </StyledCell>
            <StyledCell>
                <TextField size="small" value={displayDiscountValue} fullWidth disabled={partNo !== "" && !override}
                    error={errorText !== ""}
                    onChange={handleDiscountChange}
                    onBlur={handleDiscountBlur}
                    variant="outlined"
                    InputProps={{
                        inputProps: {style: { textAlign: "right" }},
                        endAdornment: errorText !== "" ? 
                            <ErrorAdornment validationError={errorText}/> : null
                    }}
                />
            </StyledCell>
            <StyledCell style={{ textAlign: "right" }}>
                <Tooltip title={tm.Get("Fill Down")}>
                    <IconButton onClick={promptToFillDown} tabIndex={-1}>
                        <ArrowCircleDownIcon fontSize="small" color="primary"/>
                    </IconButton>
                </Tooltip>
            </StyledCell>
        </StyledRow>
    </>
}

export default OptionsGridRow;