import React, { useState } from "react";
import QuotesAPI from "components/Quotes/QuotesAPI";
import { ICategoryListItem } from "helpers/interfaces";


export function useCategoryList(mfgCustomerID?: string, mfgSiteID?: string): { categoryList: ICategoryListItem[], isLoading: boolean } {

    const [categories, setCategories] = useState<{ mfgCustomerID: string, mfgSiteID: string, list: ICategoryListItem[] }>({ mfgCustomerID: "", mfgSiteID: "", list: [] });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const isExpired = false;

    const loadCategoriesAsync = async (mfgCustomerID: string, mfgSiteID: string) => {
        setIsLoading(true);
        const response = await QuotesAPI.GetCategoryList(mfgCustomerID, mfgSiteID);
        const categories = response.Result;
        if (response.IsOk()) {
            setCategories({ mfgCustomerID: mfgCustomerID, mfgSiteID: mfgSiteID, list: categories });
        }
        setIsLoading(false);
    }

    React.useEffect(() => {
        if (mfgCustomerID && mfgSiteID) {
            if (isExpired || categories.mfgCustomerID !== mfgCustomerID || categories.mfgSiteID !== mfgSiteID) {
                loadCategoriesAsync(mfgCustomerID, mfgSiteID);
            }
        }

    }, [mfgCustomerID, mfgSiteID, categories, isExpired]);

    return { categoryList: categories.list, isLoading: isLoading };

};

export default useCategoryList