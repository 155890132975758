import React, { } from "react";
import { Paper } from "@mui/material";
import { TreeView } from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { IFolder } from "helpers/interfaces";
import CustomTreeItem from "components/Common/CustomTreeItem";

interface IProps {
    rootFolder?: IFolder,
    selectedFolder?: string,
    setSelectedFolder: (folder: string) => void,
}

const FolderTree: React.FC<IProps> = ({ rootFolder, selectedFolder, setSelectedFolder }: IProps) => {

    const renderTree = (folder: IFolder) => (
        <CustomTreeItem key={getNodeID(folder)} nodeId={getNodeID(folder)} label={folder.displayName}>
            {Array.isArray(folder.folders)
                ? folder.folders.map((node) => renderTree(node))
                : null}
        </CustomTreeItem>
    );

    const getNodeID = (folder: IFolder) => {
        return `N${folder.pathName}`
    }

    const getPath = (nodeID: string) => {
        if (nodeID.startsWith(`N`)) {
            return nodeID.substring(1);
        }
        return nodeID;
    }

    const onNodeSelect = ((event: React.SyntheticEvent, nodeIds: Array<string> | string) => {
        if (!Array.isArray(nodeIds)) {
            const nodeId = nodeIds;
            setSelectedFolder(getPath(nodeId));
        }
    });

    const getChildNodeIds = (folder: IFolder | undefined): string[] => {

        const nodeIds: string[] = [];

        if (folder) {
            nodeIds.push(getNodeID(folder));

            folder.folders?.forEach(element => {
                nodeIds.push(...getChildNodeIds(element));
            });
        }

        return nodeIds;
    }

    const getDefaultExpandedNodeIds = () => {
        return getChildNodeIds(rootFolder);
    };

    const getDefaultSelectedNodeId = () => {
        if (rootFolder) {
            return getNodeID(rootFolder);
        }
        return "";
    };

    return <>
        <Paper sx={{ display: "flex", width: "100%" }}>
            {rootFolder &&
                <TreeView
                    sx={{ width: "100%" }}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={getDefaultExpandedNodeIds()}
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultSelected={getDefaultSelectedNodeId()}
                    onNodeSelect={onNodeSelect}
                >
                    {rootFolder && renderTree(rootFolder)}
                </TreeView>
            }
        </Paper>
    </>;
};

export default FolderTree;
