import React from 'react';

import { IPricingMethod } from 'helpers/interfaces';
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function usePricingMethods(): IPricingMethod[] | undefined {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const pricingMethods = selectionValues.pricingMethods;

    React.useEffect(() => {

        if (!pricingMethods) {
            actions.LoadPricingMethodsAsync();
        }

    }, [actions, pricingMethods]);

    return pricingMethods;

};

export default usePricingMethods;
