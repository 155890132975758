import React, { useEffect, useState, useCallback } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Container, Box } from "@mui/material";
import Collapse from '@mui/material/Collapse';

import ShapeAPI from "components/OptionsWizard/ShapeAPI";
import { IShapeDisplayInfo } from "models/IShapeDisplayInfo";
import ShapeDisplay from "./ShapeDisplay";
import Constants from "helpers/constants";
import ShapeFilters from "./ShapeFilters";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";

const ShapeLibrary: React.FC<any> = () => {

    const [shapeLibrary, setShapeLibrary] = useState<IShapeDisplayInfo[]>([]);
    const [displayShapeLibrary, setDisplayShapeLibrary] = useState<IShapeDisplayInfo[]>([]);

    const [searchFilter, setSearchFilter] = useState<string>("");
    const [straightSidesFilter, setStraightSidesFilter] = useState<string>("");
    const [curvedSidesFilter, setCurvedSidesFilter] = useState<string>("");

    const [page, setPage] = useState<number>(0);
    const [resultsPerPage, setResultsPerPage] = useState<number>(20);
    const [totalResults, setTotalResults] = useState<number>(0);

    useEffectOnLoad(() => {
        ShapeAPI.GetLibrary().then((sl) => {
            setShapeLibrary(sl);
        });
    });


    useEffect(() => {
        let newDisplayShapes: IShapeDisplayInfo[] = [];
        shapeLibrary.forEach((s) => {
            if ((s.shapeCodeAndDescription.toUpperCase().indexOf(searchFilter.toUpperCase()) >= 0) &&
                (straightSidesFilter === "" || s.straightSides.toString() === straightSidesFilter) &&
                (curvedSidesFilter === "" || s.curvedSides.toString() === curvedSidesFilter)) {
                newDisplayShapes.push(s);
            }
        });

        var same = newDisplayShapes.length === displayShapeLibrary.length;
        if (same) {
            newDisplayShapes.forEach((s, i) => { same = (same && s.shapeNumber === displayShapeLibrary[i].shapeNumber) })
        }

        if (!same) {
            setPage(0);
            setTotalResults(newDisplayShapes.length);
            setDisplayShapeLibrary([]);
            const timer = setTimeout(() => {
                setDisplayShapeLibrary(newDisplayShapes);
            }, Constants.TransitionDelays.Collapse);
            return (() => clearTimeout(timer));
        }
    }, [shapeLibrary, searchFilter, straightSidesFilter, curvedSidesFilter]);

    const handleSearchBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.target.value !== searchFilter) {
            setSearchFilter(e.target.value);
        }
    }, [searchFilter]);

    const handleStraightSidesBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.target.value !== straightSidesFilter) {
            setStraightSidesFilter(e.target.value);
        }
    }, [straightSidesFilter]);

    const handleCurvedSidesBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.target.value !== curvedSidesFilter) {
            setCurvedSidesFilter(e.target.value);
        }
    }, [curvedSidesFilter]);

    const handleChangeRowsPerPage = useCallback((e) => {
        setResultsPerPage(e.target.value);
        setPage(0);
    }, []);

    return <>
        <Container maxWidth="xl">
            <Box p={1} gap={1}>
                <ShapeFilters
                    displayShapeLibrary={displayShapeLibrary}
                    page={page}
                    resultsPerPage={resultsPerPage}
                    totalResults={totalResults}
                    handleSearchBlur={handleSearchBlur}
                    handleStraightSidesBlur={handleStraightSidesBlur}
                    handleCurvedSidesBlur={handleCurvedSidesBlur}
                    setPage={setPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TransitionGroup style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {displayShapeLibrary.slice(page * resultsPerPage, (page + 1) * resultsPerPage).map((s) => {
                        return <Collapse key={s.shapeNumber} style={{ display: "inline-block" }}>
                            <ShapeDisplay key={s.shapeNumber} shape={s} />
                        </Collapse>
                    })}
                </TransitionGroup>
            </Box>
        </Container>
    </>

};

export default ShapeLibrary;