
export enum ResponsePayloadTypeEnum {
    None = 0x0,
    UserPreferences = 0x1 << 0,
    EngineeringUnits = 0x1 << 1,
    PartPreferences = 0x1 << 2,
    LineItemInfo = 0x1 << 3,
    OptionStructure = 0x1 << 4,
    Validation = 0x1 << 5,
    WebDesigner = 0x1 << 6,
    Translations = 0x1 << 7,
    SequencedWizardInfo = 0x1 << 8,
    SysInfo = 0x1 << 9,
    PriceInfo = 0x1 << 10,
    DisplayViewSettings = 0x1 << 12,
    WizardMode = 0x1 << 13
}

export enum DesignerToolsEnum {
    SideSplit = 0x1 << 0,
    StackSplit = 0x1 << 1,
    DeleteSection = 0x1 << 2,
    ResetDesigner = 0x1 << 3,
    PinSection = 0x1 << 4,
    UnPinSection = 0x1 << 5,
    MultiSelect = 0x1 << 6,
    ODSLIProperties = 0x1 << 7
}

export enum OptionSequenceTypeEnum {
    Question = 0,
    Code = 1
}

export enum GroupDisplayTypeEnum {
    Tabs = 1,
    Expander = 2
}

export enum ViewTypeEnum {
    Inside = 1,
    Outside = 2,
    Flat = 4,
    Isometric = 8,
    Both = 16
}

export enum PartValidationTypeEnum {
    Exception = 0,
    Info = 1,
    Block = 2,
}

export enum AltWizActionTypeEnum {
    MainLineItemOptions = 0,
    DesignerSizing = 1,
    SectionSelectPart = 2,
    SectionSizing = 3,
    SectionOptions = 4
}

export enum MapAreaShapeTypeEnum {
    Rect = 0,
    Circle = 1,
    Poly = 2
}


export enum SubLineItemTypeEnum {
    Section = 1,
    Split = 2,
    Group = 4
}

export enum DisplacementDirectionEnum {
    None = 0,
    Left = 1,
    Right = 2,
    Top = 4,
    Bottom = 8,
    Both = 16,
    Opening = 32
}
