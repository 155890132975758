import { useMemo } from "react";
import { TableRow, TableCell, styled } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

import useOrderData from "components/Orders/useOrderData";

import { ILineItemGridProps } from "components/Orders/OrderView/LineItems/LineItemGrid";
import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";

interface IProps {
    lineItemGridProps: ILineItemGridProps;
    columns: LineItemColumn[];
}

const LineItemFooter: React.FC<IProps> = ({ lineItemGridProps, columns }: IProps) => {

    const { order, permissions } = useOrderData();

    const StyledFooterCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    }));

    const columnProps = useMemo((): IOrderLevelProps | undefined => {
        if (order && permissions) {
            return { lineItemGridProps: lineItemGridProps, order: order, permissions: permissions }
        }
        return undefined;
    }, [lineItemGridProps, order, permissions]);

    return <>
        <TableRow>
            {columns.map(lic => (
                <StyledFooterCell key={lic.columnName} rowSpan={lic.rowSpan} align={lic.align}>
                    {columnProps ? lic.FooterContent(columnProps) : ""}
                </StyledFooterCell>
            ))
            }
        </TableRow>
    </>;
}


export default LineItemFooter;