import WebResponse from "helpers/WebResponse";
import React, { useRef } from "react";

interface FileDownloadProps {
    preDownload: () => void;
    postDownload: () => void;
    onError: () => void;
}

interface DownloadProps {
    apiCall: () => Promise<WebResponse<Blob>>,
}

interface FileDownloadInfo {
    download: (downloadProps: DownloadProps) => void;
    ref: React.MutableRefObject<HTMLAnchorElement | null>;
}

export default function useFileDownload({ preDownload, postDownload, onError }: FileDownloadProps): FileDownloadInfo {

    const ref = useRef<HTMLAnchorElement | null>(null);

    const download = async ({ apiCall }: DownloadProps) => {
        try {
            preDownload();
            const wr = await apiCall();
            const url = URL.createObjectURL(wr.Result);
            if (ref.current) {
                ref.current.href = url;
                ref.current.download = wr.FileName;
                ref.current.click();
            }
            postDownload();
            URL.revokeObjectURL(url);
        } catch (error) {
            onError();
        }
    };

    return { download, ref };

};