import * as React from "react";

import NonTiledOptionValue from "./NonTiledOptionValue";
import QuestionHeader from "./QuestionHeader";
import NonTiledLockedOption from "./NonTiledLockedOption";


import { IQuestion, ICode, IDropDownCode } from "../interfaces";
import WizardHelper from "../WizardHelper";
import { useTranslations } from "@fenetech/translations";
import { Autocomplete, Box, Grid, GridSize, TextField } from "@mui/material";
import APIHelper from "helpers/APIHelper";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useIsMobile from "helpers/hooks/useIsMobile";
import OptionGroupBox from "components/OptionsWizard/OptionsWizard/OptionGroupBox";
import useWizardInteractions from "../useWizardInteractions";
import useWizardState from "../WebDesigner/useWizardState";

interface IOWQuestionProps {
    question: IQuestion;
    readOnlyMode: boolean;
}

const NonTiledSingleSelectQuestion: React.FC<IOWQuestionProps> = (props: IOWQuestionProps) => {

    const tm = useTranslations();
    const appInfo = useApplicationInfo();
    const isMobile = useIsMobile();
    const wizardInteractions = useWizardInteractions();
    const wizardState = useWizardState();

    const noneCode: IDropDownCode = React.useMemo(() => {
        return {
            code: "",
            description: tm.Get("{None}"),
            imageExists: false,
        }
    }, [tm]);

    const requiredCode: IDropDownCode = React.useMemo(() => {
        return {
            code: "",
            description: tm.Get("{Required}"),
            imageExists: false,
        }
    }, [tm]);

    const handleOptionChange = (event: React.SyntheticEvent<Element, Event>, value: IDropDownCode) => {

        //Grab the newly selected code
        let c: ICode | null = props.question.codes.find((tc: ICode) => tc.code === value.code) ?? null;

        //Update component state to reflect the newly selected code
        let optionCode: string = c ? c.code : "";
        let optionValue: string = c ? c.value : "";

        wizardInteractions.SetFocusedCode(c);
        //Submit to Web Service to commit
        wizardInteractions.SelectCodeValueAsync(props.question.sequence, optionCode, optionValue);

    }

    const handleCodeFocus = () => {
        let c: ICode | null = getSelectedCode(props.question.codes);
        if (c)
            wizardInteractions.SetFocusedCode(c);
    }

    const handleCodeBlur = () => {
        wizardInteractions.SetFocusedCode(null);
    }

    const getSelectedCode = React.useCallback((codes: Array<ICode>) => {
        let selectedCodes: Array<ICode> = codes.filter((c) => c.selected);

        if (selectedCodes && selectedCodes.length === 1) {
            return selectedCodes[0];
        } else {
            return null;
        }
    }, []);

    const q = React.useMemo(() => {
        return props.question;
    }, [props.question]);

    const selectedCodeObj = React.useMemo(() => {
        return getSelectedCode(q.codes);
    }, [q.codes, getSelectedCode]);

    let substitutePartEnabled: boolean = false;
    if (selectedCodeObj) {
        substitutePartEnabled = selectedCodeObj.substitutePartEnableWizard;
    }

    const codeOptions = React.useMemo<IDropDownCode[]>(() => {

        let filteredCodes: IDropDownCode[] = q.codes.filter(c => c.visible);

        if (q.required && WizardHelper.SelectedCodes(q).length === 0) {
            filteredCodes = ([requiredCode]).concat(filteredCodes);
        }

        if (!q.required) {
            filteredCodes = ([noneCode]).concat(filteredCodes);
        }

        return filteredCodes;

    }, [q, noneCode, requiredCode])


    const selectedCode = React.useMemo<IDropDownCode>(() => {

        let selectedCode = codeOptions.find(c => c.selected);

        if (selectedCode) {
            return selectedCode;
        } else {
            selectedCode = codeOptions.find(c => !c.hasOwnProperty("Selected"));

            if (selectedCode) {
                return selectedCode;
            } else {
                throw Error("Could not find selected code");
            }
        }

    }, [codeOptions]);

    const hasUserInput = React.useMemo<boolean>(() => {
        if (selectedCodeObj) {
            return selectedCodeObj.userInput;
        }
        return false;
    }, [selectedCodeObj]);

    const hasConfigurator = React.useMemo<boolean>(() => {
        if (selectedCodeObj) {
            return selectedCodeObj.config !== null;
        }
        return false;
    }, [selectedCodeObj]);

    const renderOptionValue = () => {
        if (selectedCodeObj) {
            return <NonTiledOptionValue
                code={selectedCodeObj}
                withinTiled={false}
                key={selectedCodeObj.value}
                locked={q.locked && !substitutePartEnabled}
                readOnlyMode={props.readOnlyMode} />;
        }
        return <></>;
    }

    const renderQuestion = () => {
        return <Autocomplete
            autoSelect
            size="small"
            disableClearable={true}
            disablePortal
            disabled={props.readOnlyMode || q.locked}
            handleHomeEndKeys
            options={codeOptions}
            onBlur={handleCodeBlur}
            onFocus={handleCodeFocus}
            getOptionLabel={(c: IDropDownCode) => {
                //This is used to format the selected value ( values not in the dropdown)
                return WizardHelper.GetOptionCodeDescription(c, appInfo.parameters.hideOptionCodes);
            }}
            renderOption={(props, c: IDropDownCode) => {
                let imageSource: string = APIHelper.GetBaseURL() + `api/images/OptionImage?optionCode=${c?.code ?? ""}&thumbnail=true`;
                let label: string = WizardHelper.GetOptionCodeDescription(c, appInfo.parameters.hideOptionCodes);

                return <Box component="li"
                    {...props}
                >
                    {c.imageExists &&
                        <img loading="lazy"
                            width="82"
                            src={imageSource} />}
                    {<Box ml={1}>{label}</Box>}
                </Box>;
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={q.description}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <QuestionHeader question={q} showImageSelection={true} readOnlyMode={props.readOnlyMode} />
                    }}
                    inputProps={{
                        ...params.inputProps,
                        inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                    }}
                />
            )}
            onChange={handleOptionChange}
            value={selectedCode} />;
    }

    const renderEnabledCell = () => {
        var questionXsValue: boolean | GridSize | undefined;
        var valueXsValue: boolean | GridSize | undefined;

        if (hasUserInput) {
            questionXsValue = 6;
            valueXsValue = 6;
        } else if (hasConfigurator) {
            questionXsValue = true;
            valueXsValue = "auto";
        } else {
            questionXsValue = 12;
        }
        
        return <Grid container direction={(isMobile && hasUserInput) ? "column" : "row"} spacing={1}>
            <Grid item xs={questionXsValue}>
                {renderQuestion()}
            </Grid>

            {(hasUserInput || hasConfigurator)  && 
                <Grid item xs={valueXsValue}>
                    {renderOptionValue()}
                </Grid>    
            }
        </Grid>;
    }

    if (q.locked && !substitutePartEnabled) {
        if (!selectedCodeObj) {
            //The question is locked with nothing selected, hide the question
            // TODO: This still reserves space in the stack (a larger gap than the rest)
            return null
        } else {
            //The question is locked with something selected
            return <OptionGroupBox id={"OGB_NTSSQ"} label={q.description}
                startAdornment={<QuestionHeader question={q} showImageSelection={false} readOnlyMode={props.readOnlyMode} />}
            >
                <NonTiledLockedOption code={selectedCodeObj} />
            </OptionGroupBox>
        }
    } else {
        return renderEnabledCell();

    }
}

export default NonTiledSingleSelectQuestion;
