import APIHelper from 'helpers/APIHelper';
import { AuthorizationTypeEnum } from 'helpers/enums';
import WebResponse from 'helpers/WebResponse';

export default class API {

    /* FUTURE: SSO
        public static async SSOTokenAsync(): Promise<IBearerToken> {
    
            //At this point, the serven has already authenticated and created a session cookie. 
            // We will hit this special token endpoint that requires 
            const baseurl = APIHelper.GetBaseURL();
            const query: string = `${baseurl}/SSOToken`;
    
            // const wr = APIHelper.GetAsyncWithoutToken<IBearerToken>(query);
            let details = {
                grant_type: "sso"
            }
    
            const wr = await APIHelper.GetUrlEncodedAsync<IBearerToken>(query, details, false);
    
    
            return wr.Result;
    
        }
    */

    public static async SignInAsync(username: string, password: string, authType: AuthorizationTypeEnum): Promise<WebResponse<any>> {

        const query: string = `api/auth/SignIn`;

        const details = {
            userName: username,
            password: password,
            authType: (authType === AuthorizationTypeEnum.CSR ? "csr" : "standard"),
        };

        const response = await APIHelper.GetAsync(query, details, undefined, false);

        return response;
    }

    public static async SignInWithRemoteIDAsync(userName: string, remoteID: string): Promise<WebResponse<any>> {

        const query: string = `api/auth/SignInWithRemoteID`;

        const details = {
            userName: userName,
            remoteID: remoteID,
        };

        const response = await APIHelper.GetAsync(query, details, undefined, false);

        return response;
    }

    public static async SignInWithOnlineAckTokenAsync(token: string): Promise<WebResponse<any>> {
        const query = `api/auth/SignInWithOnlineAckToken`;

        const details = {
            token: token,
        }

        const response = await APIHelper.GetAsync(query, details, undefined, false);

        return response;
    }

}