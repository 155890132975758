import React, { useState, useCallback } from "react"
import { Dialog, DialogContent, DialogActions, TextField, Stack } from "@mui/material"
import { useTranslations } from "@fenetech/translations";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import OKCancelControl from "components/Common/OKCancelControl";
import ErrorAdornment from "components/Common/ErrorAdornment";

interface IProps {
    oldName: string;
    open: boolean;
    subHeaderText: string;
    close: () => void;
    handleRename: (name: string) => Promise<boolean>;
}

const TermsShipViaRename: React.FC<IProps> = ({ oldName, open, subHeaderText, close, handleRename }) => {
    const tm = useTranslations();

    const [newName, setNewName] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(e.target.value);
    }, []);

    const validate = useCallback((name: string) => {
        if (name.trim() === "") {
            return tm.Get(`${subHeaderText} cannot be blank.`)
        }
        return "";
    }, [subHeaderText, tm]);

    const handleNameBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setErrorText(validate(e.target.value));
    }, [validate]);

    const trySubmit = useCallback((name: string) => {
        let error = validate(name);
        if (error === "") {
            handleRename(name);
        } else {
            setErrorText(error);
        }
    }, [handleRename, validate]);

    return <>
        <Dialog fullWidth
            onClose={close}
            open={open}
        >
            <DialogTitleWithClose onClose={close}>
                {tm.Get(`Edit ${subHeaderText} Record`)}
            </DialogTitleWithClose>
            <DialogContent>
                <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={2}>
                    <TextField size="small" label={tm.Get("Old Name")} value={oldName} fullWidth disabled sx={{ marginTop: 2 }} />
                    <TextField size="small" label={tm.Get("New Name")} value={newName} onChange={handleNameChange} onBlur={handleNameBlur} fullWidth
                        error={errorText !== ""}
                        InputProps={{
                            endAdornment: errorText !== "" ?
                                <ErrorAdornment validationError={errorText} /> : null
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <OKCancelControl okCommand={() => trySubmit(newName.trim())} cancelCommand={close} />
            </DialogActions>
        </Dialog>
    </>
}

export default TermsShipViaRename;