import API from "./OptionsAPI";
import { ICode, IQuestion, IPostResultItem, ISequencedWizardInfo, IDisplayViewSettings, ILineItemInfo, ILineItemPriceInfo, IPartPreferences, ISysInfo, IUserPreferences, IValidationInfo, IWebDesigner } from "./interfaces";
import { useTranslations, ITranslationManager } from "@fenetech/translations";
import { IItemPropertiesData } from "components/OptionsWizard/ItemProperties/ItemPropertiesContext";
import { IODOpeningProperties } from "components/OptionsWizard/ItemProperties/ODOpeningProperties";
import { IODSLIProperties } from "components/OptionsWizard/ItemProperties/ODSLIProperties";
import { IWizardState, WizardActions, WizardModeEnum } from "./WizardContext";
import useWizardState, { useWizardStateActions } from "./WebDesigner/useWizardState";
import { ResponsePayloadTypeEnum } from "./enum";
import OptionsAPI from "./OptionsAPI";
import WizardHelper from "./WizardHelper";
import { useMemo } from "react";

export class WizardInteractions {

    #currentState: IWizardState;
    #wizardActions: WizardActions;
    #tm: ITranslationManager;

    constructor(currentState: IWizardState, wizardActions: WizardActions, tm: ITranslationManager) {
        this.#currentState = currentState;
        this.#wizardActions = wizardActions;
        this.#tm = tm;
    }

    private SetWizardLoading() {
        this.#wizardActions.MergeState({ loading: true });
    }

    private HandleReceivedState(value: Array<IPostResultItem> | null, incrementWizardStateKey: boolean): void {

        if (value) {

            let newState: Partial<IWizardState> = {};

            let receivedPricing: boolean = false;

            let viTempDisabler: IValidationInfo

            if (this.#currentState.validations) {
                //If there is an existing validation, leave it there but disable sli tabs and wait
                viTempDisabler = { ...this.#currentState.validations, waiting: true };
            } else {
                viTempDisabler = {
                    canSave: false,
                    requiredOptionSubLineItems: [],
                    requiredPartSubLineItems: [],
                    blockingValidationSubLineItems: [],
                    validationEligible: false,
                    messages: [],
                    waiting: true
                };
            }

            for (let pr of value) {
                switch (pr.type) {
                    case ResponsePayloadTypeEnum.OptionStructure:
                        newState.options = (pr.value as Array<IQuestion | ICode>);
                        const groups = WizardHelper.ConvertStructureToGroups(newState.options, this.#tm);
                        if (groups)
                            newState.groups = groups;
                        break;

                    case ResponsePayloadTypeEnum.LineItemInfo:
                        newState.itemInfo = (pr.value as ILineItemInfo);
                        break;
                    case ResponsePayloadTypeEnum.PartPreferences:
                        newState.partPreferences = (pr.value as IPartPreferences);
                        break;
                    case ResponsePayloadTypeEnum.UserPreferences:
                        newState.userPreferences = (pr.value as IUserPreferences);
                        break;
                    case ResponsePayloadTypeEnum.Validation:
                        newState.validations = (pr.value as IValidationInfo);
                        if (newState.validations) { newState.validations.waiting = false; }
                        break;
                    case ResponsePayloadTypeEnum.WebDesigner:
                        newState.webDesigner = (pr.value as IWebDesigner);
                        break;
                    case ResponsePayloadTypeEnum.SequencedWizardInfo:
                        newState.sequencedWizardInfo = (pr.value as ISequencedWizardInfo);
                        break;
                    case ResponsePayloadTypeEnum.SysInfo:
                        newState.sysInfo = (pr.value as ISysInfo);
                        WizardHelper.setSysInfo(newState.sysInfo);
                        break;
                    case ResponsePayloadTypeEnum.PriceInfo:
                        if (pr.value === null) {
                            newState.prices = null;
                        } else {
                            newState.prices = (pr.value as ILineItemPriceInfo)
                            newState.prices.waiting = false;
                        }

                        receivedPricing = true;
                        break;
                    case ResponsePayloadTypeEnum.DisplayViewSettings:
                        newState.displayViewSettings = (pr.value as IDisplayViewSettings);
                        break;
                    case ResponsePayloadTypeEnum.WizardMode:
                        if (pr.value) {
                            newState.wizardMode = (pr.value as WizardModeEnum);
                        }
                }
            }

            let validationsNeedReQueried: boolean = false;

            if (!newState.validations) {
                //We only need to requery this type of thing if options changed.  If we receive only receive (ex.) pricing
                // then we wouldn't want to disable everything until we receive new validation.
                if (newState.options) {
                    newState.validations = viTempDisabler;
                    validationsNeedReQueried = true;
                }
            }

            if (newState.options && !receivedPricing) {
                newState.prices = this.#currentState.prices;
                if (newState.prices) {
                    newState.prices.waiting = true;
                }
            }

            if (this.#currentState.objectView === null) {
                if (newState.webDesigner) {
                    newState.objectView = newState.webDesigner.defaultView;
                } else if (newState.partPreferences) {
                    newState.objectView = newState.partPreferences.defaultView;
                }
            }
            else if (newState.webDesigner && (newState.webDesigner.availableViews & this.#currentState.objectView.valueOf()) === 0) {
                //If we received new state, and the current view does not match an available view, reset it to webDesignerDefault
                newState.objectView = newState.webDesigner.defaultView;
            }

            newState.loading = false;

            if (this.#currentState.wizardStateKey && incrementWizardStateKey)
                newState.wizardStateKey = this.#currentState.wizardStateKey + 1;

            let requiredInfo: ResponsePayloadTypeEnum = ResponsePayloadTypeEnum.None;

            if (newState.options) {
                /*When options change, we need to requery validations and item info because it may have changed.  
                 * If they were not returned as part of the response each one will happen async and will force a re-render when complete */

                if (!receivedPricing) {
                    requiredInfo = requiredInfo | ResponsePayloadTypeEnum.PriceInfo;
                }

                if (!newState.itemInfo)
                    requiredInfo = requiredInfo | ResponsePayloadTypeEnum.LineItemInfo;

                if (validationsNeedReQueried)
                    requiredInfo = requiredInfo | ResponsePayloadTypeEnum.Validation;
            }

            if (requiredInfo) {
                OptionsAPI.QueryOptionsWizardInitialStateAsync(requiredInfo).then((pr: Array<IPostResultItem> | null) => this.HandleReceivedState(pr, false));
                this.#wizardActions.MergeState({ ...newState, loading: true });
            } else {
                //Set the state
                this.#wizardActions.MergeState(newState);
            }

        } else {
            //If we did not need any new state, we still must reset the loading flag.
            this.#wizardActions.MergeState({ loading: false });
        }
    }

    public InitialLoad(): void {
        let requiredInfo: ResponsePayloadTypeEnum = ResponsePayloadTypeEnum.OptionStructure |
            ResponsePayloadTypeEnum.LineItemInfo |
            ResponsePayloadTypeEnum.PartPreferences |
            ResponsePayloadTypeEnum.Translations |
            ResponsePayloadTypeEnum.UserPreferences |
            ResponsePayloadTypeEnum.Validation |
            ResponsePayloadTypeEnum.SysInfo |
            ResponsePayloadTypeEnum.EngineeringUnits |
            ResponsePayloadTypeEnum.WebDesigner |
            ResponsePayloadTypeEnum.PriceInfo |
            ResponsePayloadTypeEnum.SequencedWizardInfo;

        API.QueryOptionsWizardInitialStateAsync(requiredInfo).then((value: Array<IPostResultItem> | null) => {
            this.HandleReceivedState(value, false);
        });
    }

    public async RequeryFullStateAsync(): Promise<any> {
        const state = await OptionsAPI.QueryOptionsWizardInitialStateAsync();
        this.HandleReceivedState(state, true);
    }

    public SetFocusedCode(code: ICode | null) {
        this.#wizardActions.MergeState({ focusedCode: code });
    }

    public async SelectDesignerItemAsync(sublineitem: number) {
        this.SetWizardLoading();
        const state = await API.SelectDesignerSectionAsync(sublineitem);
        this.HandleReceivedState(state, true);
    }

    public async ChangeItemPropertiesAsync(params: IItemPropertiesData): Promise<any> {
        this.SetWizardLoading();
        const state = await API.ChangeItemProperties(params);
        this.HandleReceivedState(state, true);
    }

    public async ChangeODOpeningPropertiesAsync(params: IODOpeningProperties): Promise<any> {
        this.SetWizardLoading();
        const state = await API.ChangeODOpeningProperties(params);
        this.HandleReceivedState(state, true);
    }

    public async ChangeODSLIPropertiesAsync(params: IODSLIProperties): Promise<string | null> {
        this.SetWizardLoading();
        const { success, result } = await API.ChangeODSLIProperties(params);
        if (success) {
            this.HandleReceivedState(result as IPostResultItem[], true);
            return null;
        } else {
            //reset loading flag
            this.HandleReceivedState(null, false);
            return result as string;
        }
    }

    public async ChangeDesignerPartAsync(partNo: string, partNoSuffix: string) {
        this.SetWizardLoading();
        const state = await API.ChangeDesignerPart(partNo, partNoSuffix);
        this.HandleReceivedState(state, true);
    }

    public async ChangeSectionSizingAsync(width: number, height: number, callSize: string | null) {
        this.SetWizardLoading();
        const state = await API.ChangeSectionSizing(width, height, callSize);
        this.HandleReceivedState(state, true);
    }

    public async SelectCodeAsync(sequence: number, code: string) {
        this.SetWizardLoading();
        const state = await API.SubmitCodeSelect(sequence, code);
        this.HandleReceivedState(state, false);
    }

    public async SelectCodeValueAsync(sequence: number, code: string, value: string) {
        this.SetWizardLoading();
        const state = await API.SubmitCodeValueSelect(sequence, code, value);
        this.HandleReceivedState(state, false);
    }

    public async SelectShapeCodeAsync(shapeNum: number) {
        this.SetWizardLoading();
        const state = await API.SubmitShapeCodeSelect(shapeNum);
        this.HandleReceivedState(state, false);
    }

    public async SelectShapeCodeWithParamsAsync() {
        this.SetWizardLoading();
        const s = await API.SubmitShapeCodeWithParamsSelect();
        this.HandleReceivedState(s, false);
    }

    public async RemoveCodeAsync(sequence: number, code: string) {
        this.SetWizardLoading();
        const s = await API.RemoveCode(sequence, code);
        this.HandleReceivedState(s, false);
    }

    public async RunExpressionsAsync(sequence: number, code: string) {
        this.SetWizardLoading();
        const s = await API.RunExpressions(sequence, code);
        this.HandleReceivedState(s, false);
    }

    public async CommitCurrentActionAsync(): Promise<IPostResultItem[] | null> {
        const state = await OptionsAPI.CommitCurrentAction();
        if (state) {
            this.HandleReceivedState(state, true);
        }
        return state;
    }

    public async SelectPartOrOptionsInMultiSelect(doParts: boolean, sublineitems: Array<number>): Promise<any> {
        const state = await API.HandleMultiSelectPartOrOptionsClickAsync(doParts, sublineitems);

        if (state) {
            this.HandleReceivedState(state, true);
            this.#wizardActions.SwitchToSequencedWizard();
        }

        return state;
    }

}

export default function useWizardInteractions() {

    const currentState = useWizardState();
    const wizardActions = useWizardStateActions();
    const tm = useTranslations();

    const wizardInteractions = useMemo(() => {
        return new WizardInteractions(currentState, wizardActions, tm);
    }, [currentState, wizardActions, tm]);

    return wizardInteractions;

}
