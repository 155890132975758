import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import LineItemSize from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemSize";
import { ILineItem } from "helpers/interfaces";

export default class LineItemSizeColumn extends LineItemColumn {

    public constructor(columnName: string, headerText: string, width: string) {
        super(columnName, headerText, width);
        this.align = "left";
    }

    public CellContent(props: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {

        return <LineItemSize
            lineItem={lineItem}
            engineeringUnitSetID={props.quote.engineeringUnitSetID}
            variant="body2"
        />
    }

    public FooterContent(quoteProps: IQuoteLevelProps): JSX.Element {
        return <></>;
    }
}
