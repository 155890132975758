import React from 'react';
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { customerCommandsColumn, CustomerNameColumn } from "./CustomerColumns";
import { useNavigate } from "react-router-dom";
import { IWebCustomerSummary } from "models/IWebCustomer";
import { useTranslations } from "@fenetech/translations";
import useUserInfo from "helpers/context/User/useUserInfo";
import { AlertColor,  useTheme } from "@mui/material";
import { RoleEnum } from "helpers/enums";
import useIsMobile from "helpers/hooks/useIsMobile";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";
import CustomerAPI from "./CustomerAPI";
import useWait from "helpers/context/Page/useWait";
import CustomersGridMobile from "./CustomersGridMobile";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

interface IProps {
    getCustomerSummaryList: () => void,
    setAlert: (alert: [text: string, alertType: AlertColor] | null) => void,
    filteredCustomerList: IWebCustomerSummary[] | null,
    isMfgSiteVisible: boolean
}

const CustomersGrid: React.FC<IProps> = (props: IProps) => {

    const navigate = useNavigate();
    const tm = useTranslations();
    const user = useUserInfo();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const wait = useWait();
    const selectionValuesActions = useSelectionValuesActions();
    const rowsPerPage = useRowsPerPage("Customer");

    const [isPosting, setIsPosting] = React.useState<boolean>(false);

    React.useEffect(() => {
        wait.Show(isPosting);
    }, [isPosting, wait])

    function clearCache() {
        selectionValuesActions.ClearCustomersCache();
    }

    const viewCustomerHandler = (customerID: string) => {
        const url = `view/${encodeURIComponent(customerID)}`;
        navigate(url);
    }

    const editCustomerHandler = (customerID: string) => {
        const url = `edit/${encodeURIComponent(customerID)}`;
        navigate(url);
    }

    const deleteCustomerHandler = async (customerID: string) => {
        setIsPosting(true);
            try {
                let result = user.HasRole(RoleEnum.Contractor) ?
                    await CustomerAPI.DeleteWebContractorCustomer(customerID, user.parentCustomerID) :
                    await CustomerAPI.DeleteWebCustomer(customerID);
                if (result.message) {
                    const translated = tm.Get(result);
                    props.setAlert([translated, "error"]);
                } else {
                    clearCache();
                    props.getCustomerSummaryList();
                    props.setAlert([tm.Get("Deleted"), "success"]);
                }
            } finally {
                setIsPosting(false);
            }
    }

    const generator = new DataGridColumnGenerator(tm, props.filteredCustomerList, theme, isMobile);
    generator.AddColumn(CustomerNameColumn("name", "Name", props.isMfgSiteVisible));
    //Only include site ID when Dealers have multiSite
    if (props.isMfgSiteVisible) {
        generator.TryAddTextColumn("parentSiteName", "Mfg Site", {hideInMobile: true});
    }
    generator.TryAddTextColumn("billAddress1", "Address", {hideInMobile: true});
    generator.TryAddTextColumn("billCity", "City", {hideInMobile: true});
    generator.TryAddTextColumn("billState", "State", {hideInMobile: true});
    generator.TryAddTextColumn("billZipCode", "Zip Code", {hideInMobile: true});
    generator.TryAddTextColumn("salesPersonName", "Salesperson", {hideInMobile: true});
    generator.AddColumn(customerCommandsColumn(editCustomerHandler, deleteCustomerHandler));

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);
    const getRowId: GridRowIdGetter = (row: GridRowModel) => row["customerID"] + row["siteID"];

    if (isMobile) {
        return <CustomersGridMobile
            filteredCustomerList={props.filteredCustomerList}
            isMfgSiteVisible={props.isMfgSiteVisible}
            viewCustomerHandler={viewCustomerHandler}
            editCustomerHandler={editCustomerHandler}
            deleteCustomerHandler={deleteCustomerHandler}
        />
    } else {
        return <CustomDataGridPro
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
            getRowId={getRowId}
            columns={generator.GetColumns()}
            rows={props.filteredCustomerList}
        />
    }
}

export default CustomersGrid;