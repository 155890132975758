import React from 'react';
import { TableCell, TableHead, TableRow, styled } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';

import { useTranslations } from "@fenetech/translations";

const TaxHeader: React.FC = () => {

    const tm = useTranslations();

    const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
    }));

    return <>
        <TableHead>
            <TableRow sx={{ height: "0px" }}>
                <StyledHeaderCell>
                    {tm.Get("Taxes")}
                </StyledHeaderCell>
                <StyledHeaderCell sx={{ width: "140px" }} align="right">

                </StyledHeaderCell>
            </TableRow>
        </TableHead>
    </>;
}

export default TaxHeader;