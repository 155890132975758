import { useRef, useState } from "react";
import { Download } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { useTranslations } from "@fenetech/translations";

import { IQuote } from "helpers/interfaces";
import DownloadMenu from "components/Quotes/QuoteEntry/Header/DownloadMenu";

interface IProps {
    quote: IQuote,
}

function DownloadReport({ quote }: IProps) {

    const tm = useTranslations();
    const [downloadMenuOpen, setDownloadMenuOpen] = useState<boolean>(false);
    const downloadAnchorRef = useRef<HTMLButtonElement>(null);

    return <>

        <Tooltip title={tm.Get("Download")}>
            <IconButton aria-label="download" sx={{ padding: 0 }} ref={downloadAnchorRef} onClick={() => setDownloadMenuOpen(true)}>
                <Download color="primary" />
            </IconButton>
        </Tooltip>

        <DownloadMenu
            quote={quote}
            isMenuOpen={downloadMenuOpen}
            closeMenu={() => setDownloadMenuOpen(false)}
            anchorRef={downloadAnchorRef.current}
        />

    </>;
};

export default DownloadReport;
