import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";
import { useTranslations } from "@fenetech/translations";
import React from 'react';

interface IProps {
    expanded: boolean,
    onExpandedChange: (event: React.SyntheticEvent, expanded: boolean) => void,
    readOnly: boolean
}

const CustomerBilling: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    return <>
        <Accordion expanded={props.expanded} onChange={props.onExpandedChange} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("Billing Address")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="customer.billingInfo.address1"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="customer.billingInfo.address1"
                            inputProps={{ maxLength: Constants.MaxLength.Address1 }}
                            label={tm.Get("Address 1")}
                            placeholder={tm.Get("Address 1")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="customer.billingInfo.address2"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="customer.billingInfo.address2"
                            inputProps={{ maxLength: Constants.MaxLength.Address2 }}
                            label={tm.Get("Address 2")}
                            placeholder={tm.Get("Address 2")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="customer.billingInfo.city"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="customer.billingInfo.city"
                            inputProps={{ maxLength: Constants.MaxLength.City }}
                            label={tm.Get("City")}
                            placeholder={tm.Get("City")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextFieldForm
                            fieldName="customer.billingInfo.state"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="customer.billingInfo.state"
                            inputProps={{ maxLength: Constants.MaxLength.State }}
                            label={tm.Get("State")}
                            placeholder={tm.Get("State")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextFieldForm
                            fieldName="customer.billingInfo.zipCode"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="customer.billingInfo.zipCode"
                            inputProps={{ maxLength: Constants.MaxLength.ZipCode }}
                            label={tm.Get("Zip Code")}
                            placeholder={tm.Get("Zip Code")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="customer.billingInfo.phoneNumber"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="customer.billingInfo.phoneNumber"
                            inputProps={{ maxLength: Constants.MaxLength.Phone }}
                            label={tm.Get("Phone")}
                            placeholder={tm.Get("Phone")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="customer.billingInfo.faxNumber"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="customer.billingInfo.faxNumber"
                            inputProps={{ maxLength: Constants.MaxLength.Fax }}
                            label={tm.Get("Fax")}
                            placeholder={tm.Get("Fax")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldForm
                            fieldName="customer.billingInfo.emailAddress"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="customer.billingInfo.emailAddress"
                            inputProps={{ maxLength: Constants.MaxLength.EmailAddress }}
                            label={tm.Get("Email")}
                            placeholder={tm.Get("Email")}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default CustomerBilling;