import { Button } from "@mui/material";
import { ThemeColorEnum } from "helpers/enums";
import { ITranslationManager } from "@fenetech/translations";
import * as React from "react";

import { ViewTypeEnum } from "../enum";
import { IDisplayViewSettings } from "../interfaces";

interface IViewButton {
    view: ViewTypeEnum;
    displayViewSettings: IDisplayViewSettings;
    currentView: ViewTypeEnum;
    availableViews: ViewTypeEnum;
    clickHandler: (view: ViewTypeEnum) => void;
    allowBoth: boolean;
    tm:ITranslationManager;
}

export default class ObjectViewButton extends React.PureComponent<IViewButton, any> {
    render(): React.ReactNode {
        let view: ViewTypeEnum = this.props.view;
        let insideEnabled: boolean = ((this.props.availableViews & ViewTypeEnum.Inside) === ViewTypeEnum.Inside);
        let outsideEnabled: boolean = ((this.props.availableViews & ViewTypeEnum.Outside) === ViewTypeEnum.Outside);
        let flatEnabled: boolean = ((this.props.availableViews & ViewTypeEnum.Flat) === ViewTypeEnum.Flat);
        let isoEnabled: boolean = ((this.props.availableViews & ViewTypeEnum.Isometric) === ViewTypeEnum.Isometric);
        let showButtons: boolean = ((insideEnabled ? 1 : 0) + (outsideEnabled ? 1 : 0) + (flatEnabled ? 1 : 0) + (isoEnabled ? 1 : 0)) > 1;
        
        const buttonValue: string = (view === ViewTypeEnum.Inside || view === ViewTypeEnum.Outside || view === ViewTypeEnum.Both ? 
            this.props.displayViewSettings[view].description : 
            this.props.tm.Get(ViewTypeEnum[view]));


        if (showButtons && ((this.props.availableViews & view) === view) && (view !== ViewTypeEnum.Both || this.props.allowBoth)) {
            return <Button 
                variant={view === this.props.currentView ? "contained" : "outlined"}
                key={view}
                color={ThemeColorEnum.Secondary}
                onClick={this.handleViewButtonClick}
            >{buttonValue}</Button>
        } else {
            return null;
        }
    }

    handleViewButtonClick = (e: React.MouseEvent) => {
        this.props.clickHandler(this.props.view);
    }
}
