import { useMemo } from "react";
import { TableRow, Tooltip } from "@mui/material"

import useQuoteData from "components/Quotes/useQuoteData";
import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import LineItemOptions from "components/Quotes/QuoteEntry/LineItems/Rows/LineItemOptions";

import { ILineItem } from "helpers/interfaces";
import { ILineItemGridProps } from "components/Quotes/QuoteEntry/LineItems/LineItemGrid";
import { StyledCell, StyledCommentCell } from "components/Quotes/QuoteEntry/LineItems/Rows/RowStyles";

interface IProps {
    lineItemGridProps: ILineItemGridProps,
    columns: LineItemColumn[];
    lineItem: ILineItem,
}

const LineItem: React.FC<IProps> = ({ lineItemGridProps, columns, lineItem }: IProps) => {

    const { quote, permissions, lock } = useQuoteData();

    const columnProps = useMemo((): IQuoteLevelProps | undefined => {
        if (quote && permissions && lock) {
            return { lineItemGridProps: lineItemGridProps, quote: quote, permissions: permissions, lock: lock }
        }
        return undefined;
    }, [lineItemGridProps, quote, permissions, lock]);

    return <>
        <TableRow>
            {columns.map(lic => (
                <StyledCell key={lic.columnName} rowSpan={lic.rowSpan} align={lic.align}>
                    {columnProps ? lic.CellContent(columnProps, lineItem) : ""}
                </StyledCell>
            ))
            }
        </TableRow>

        {/* Options */}
        <LineItemOptions
            columns={columns}
            lineItem={lineItem}
        />

        {/* Comment (if it exists) */}
        <TableRow>
            {lineItem.comment ?
                <Tooltip title={lineItem.comment}>
                    <StyledCommentCell colSpan={columns.length - 1}>
                        {lineItem.comment}
                    </StyledCommentCell>
                </Tooltip>
                :
                <></>
            }
        </TableRow>
    </>;
}


export default LineItem;