import { useState } from "react";

import { Alert, IconButton, Snackbar, SnackbarCloseReason, Tooltip } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import useWait from "helpers/context/Page/useWait";
import useReports, { IReportSelection } from "helpers/context/SelectionValues/useReports";
import useFileDownload from "helpers/hooks/useFileDownload";

import { IQuote } from "helpers/interfaces";
import ReportSelectionDialog from "components/Reports/ReportSelectionDialog";
import { Print } from "@mui/icons-material";

interface IProps {
    quote: IQuote,
}

function PrintReport({ quote }: IProps) {

    const tm = useTranslations();
    const wait = useWait();

    const [isReportSelectionVisible, setIsReportSelectionVisible] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string | undefined>(undefined);

    const { reportSelections, getReportDownloadAPI } = useReports("web", "quote");

    const handlePrintError = () => {
        wait.Show(false);
        setAlertText(tm.Get("An unknown error has occurred."));
    };

    const fileDownload = useFileDownload({
        preDownload: () => wait.Show(true),
        postDownload: () => wait.Show(false),
        onError: () => handlePrintError(),
    });

    const printClick = () => {
        setIsReportSelectionVisible(true);
    }

    const onReportSelected = (selectedReport: IReportSelection): void => {
        setIsReportSelectionVisible(false);
        if (quote.oKey) {
            fileDownload.download({ apiCall: () => getReportDownloadAPI(quote.oKey, selectedReport.reportID, "PDF") });
        }
    }


    const onAlertClose = (event: React.SyntheticEvent<Element, Event>) => {
        setAlertText(undefined);
    }

    const onAlertCloseSnackbar = (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway')
            return;

        setAlertText(undefined);
    }

    return <>

        <Tooltip title={tm.Get("Print")}>
            <IconButton aria-label="print" sx={{ padding: 0 }} onClick={() => printClick()}>
                <Print color="primary"/>
            </IconButton>
        </Tooltip>

        <ReportSelectionDialog
            title={tm.Get("Print")}
            dialogVisible={isReportSelectionVisible}
            reports={reportSelections}
            onSubmit={onReportSelected}
            onCancel={() => setIsReportSelectionVisible(false)}
        />

        <Snackbar open={alertText ? true : false} autoHideDuration={5000} onClose={onAlertCloseSnackbar} >
            <Alert onClose={onAlertClose} severity='error' variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alertText}</Alert>
        </Snackbar>

        {/* Hidden link to host file downloads */}
        <a hidden ref={fileDownload.ref} />
    </>;
};

export default PrintReport;
