import { useEffect } from 'react';
import { IDialogBox } from 'helpers/context/Page/PageContext';
import usePageActions from "./usePageActions";

/*
    Use this hook to show a message overlay
*/

export interface IShowMessageBox {
    Show: (args: Partial<IDialogBox>) => void;
    Hide: () => void;
}

export default function useMessageBox(): IShowMessageBox {

    const pageActions = usePageActions();

    const showMessageBox: IShowMessageBox = {
        Show: (args: Partial<IDialogBox>) => {
            pageActions.SetDialog({ message: args.message ?? "", title: args.title ?? "", visible: true, yesNoPrompt: args.yesNoPrompt ?? false, callback: args.callback, imageSource: args.imageSource ?? "" });
        },
        Hide: () => {
            pageActions.HideDialog();
        }
    };

    useEffect(() => {
        return () => {
            pageActions.SetDialog(undefined);
        };
    }, [pageActions]);

    return showMessageBox;

}
