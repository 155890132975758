import { ITranslationManager } from "@fenetech/translations";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import { PricingMethodEnum } from "helpers/enums";
import Constants from "helpers/constants";
import Format from "helpers/fv.format";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";

export default class HelperFunctions {
    public static FormatDiscountValue(discountValue: number | null, fixedPrice: number | null, usesMultiplier: boolean, tableType: PricingMethodEnum, cf: ICurrencyFormatter, lnf: ILocaleNumberFormatter) {
        if (discountValue !== null) {
            if (usesMultiplier) {
                return lnf.Format(Format.ConvertPercentToMultiplier(discountValue, tableType));
            } else {
                return lnf.Format(discountValue);
            }
        } else if (fixedPrice !== null) {
            return cf.Format(fixedPrice);
        } else {
            return "";
        }
    }
    
    public static ValidateMultiplier(multiplier: number, tableType: PricingMethodEnum, tm: ITranslationManager) {
        if (tableType === PricingMethodEnum.Markup) {
            if ((multiplier > Constants.Max.MarkupMultiplier) || (multiplier) < Constants.Min.MarkupMultiplier) {
                // Out of range error
                return tm.GetWithParams("Must be between {0} and {1}", Constants.Max.MarkupMultiplier.toString(), Constants.Min.MarkupMultiplier.toString());
            }
        } else {
            if ((multiplier > Constants.Max.DiscountMultiplier) || (multiplier) < Constants.Min.DiscountMultiplier) {
                // Out of range error
                return tm.GetWithParams("Must be between {0} and {1}", Constants.Max.DiscountMultiplier.toString(), Constants.Min.DiscountMultiplier.toString());
            }
        }
        return "";
    }
    
    public static ValidatePercent(percent: number, tableType: PricingMethodEnum, tm: ITranslationManager) {
        if (tableType === PricingMethodEnum.Markup) {
            if ((percent > Constants.Max.MarkupPercent) || (percent) < Constants.Min.MarkupPercent) {
                // Out of range error
                return tm.GetWithParams("Must be between {0} and {1}", Constants.Max.MarkupPercent.toString(), Constants.Min.MarkupPercent.toString());
            }
        } else if (tableType === PricingMethodEnum.Margin) {
            if ((percent > Constants.Max.MarginPercent) || (percent) < Constants.Min.MarginPercent) {
                // Out of range error
                return tm.GetWithParams("Must be between {0} and {1}", Constants.Max.MarginPercent.toString(), Constants.Min.MarginPercent.toString());
            }
        } else {
            if ((percent > Constants.Max.DiscountPercent) || (percent) < Constants.Min.DiscountPercent) {
                // Out of range error
                return tm.GetWithParams("Must be between {0} and {1}", Constants.Max.DiscountPercent.toString(), Constants.Min.DiscountPercent.toString());
            }
        }
        return "";
    }
    
    public static ConvertMultiplierToPercent(multiplier: number, tableType: PricingMethodEnum) {
        if (tableType === PricingMethodEnum.Markup) {
            //convert to markup percent
            return (multiplier - 1) * 100;
        } else {
            //convert to discount percent
            return (1 - multiplier) * 100;
        }
    }
}