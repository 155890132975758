
import { CompanyPermissionEnum, RoleEnum, SecurityLevelEnum, UserPermissionTypes } from "../helpers/enums";
import { IUserInfo } from "../helpers/interfaces";

export default class ApiUser implements IUserInfo {

    public mfgCustomerID: string;
    public parentCustomerID: string;
    public userID: string;
    public employeeID: number;
    public fullName: string;
    public roles: RoleEnum[];
    public permissions: UserPermissionTypes;
    public companyPermissions: CompanyPermissionEnum;
    public securityLevel: number;
    public locationCurrencyCulture: string;
    public isMultiSite: boolean;
    public isAccessedExternal: boolean;

    constructor(userInfo: IUserInfo) {
        this.employeeID = userInfo.employeeID;
        this.fullName = userInfo.fullName;
        this.mfgCustomerID = userInfo.mfgCustomerID;
        this.parentCustomerID = userInfo.parentCustomerID;
        this.roles = userInfo.roles;
        this.userID = userInfo.userID;
        this.permissions = userInfo.permissions;
        this.companyPermissions = userInfo.companyPermissions;
        this.securityLevel = userInfo.securityLevel;
        this.locationCurrencyCulture = userInfo.locationCurrencyCulture;
        this.isMultiSite = userInfo.isMultiSite;
        this.isAccessedExternal = userInfo.isAccessedExternal;
    }

    public DisplayUserName(): string {
        if ( this.fullName){
            return this.fullName;
        } else {
            return this.userID;
        }
    }

    public HasRole(role: RoleEnum): boolean {
        return this.roles && this.roles.indexOf(role) >= 0;
    }

    public HasPermission(permission: UserPermissionTypes): boolean {

        if (this.HasRole(RoleEnum.Contractor)) {
            switch (permission) {
                case UserPermissionTypes.UploadQuotes:
                case UserPermissionTypes.UploadQuotesWithExceptions:
                case UserPermissionTypes.ApproveWebHolds:
                    return false;
            }
        } else {
            switch (permission) {
                case UserPermissionTypes.SubmitQuotes:
                    return false;

            }
        }

        if (this.securityLevel === SecurityLevelEnum.Administrator) {
            return true;
        }

        return (this.permissions & permission) > 0;

    }

    public HasCompanyPermission(permission: CompanyPermissionEnum): boolean {

        if (this.HasRole(RoleEnum.Contractor)) {
            switch (permission) {
                case CompanyPermissionEnum.UploadQuotes:
                case CompanyPermissionEnum.UploadQuotesWithExceptions:
                    return false;
            }
        } 

        return (this.companyPermissions & permission) > 0;

    }


}

