import { IOfflineSettings } from "helpers/interfaces";

export default class OfflineSettings implements IOfflineSettings {

    public constructor(info: IOfflineSettings) {
        this.offlineMainText = info.offlineMainText;
        this.offlineSupportText = info.offlineSupportText;
        this.offlineMailToSubject = info.offlineMailToSubject;
        this.offlineMailToRecipients = info.offlineMailToRecipients;
    }

    offlineMainText: string;
    offlineSupportText: string;
    offlineMailToSubject: string;
    offlineMailToRecipients: string;
}
