import useBrowserBackedMap, { useBrowserBackedMapAsync } from "helpers/hooks/useBrowserBackedMap";
import { IPartCallSizePresets } from "helpers/interfaces";
import PartsAPI from "helpers/PartsAPI";

const sessionKey = "FV_PART_CALLSIZE_CACHE";

const blankCallSizes = {
    callSizes: [],
    locked: false
};

const getPartCallSizesFetchAsync = async (partNo?: string, partNoSuffix?: string) => {

    if (partNo && partNoSuffix) {
        return await PartsAPI.GetPartCallSizes(partNo, partNoSuffix);
    }
    else {
        return blankCallSizes;
    }
}

export default function usePartCallSizes(partNo?: string, partNoSuffix?: string): IPartCallSizePresets {

    const data = useBrowserBackedMap<IPartCallSizePresets>(sessionKey, getPartCallSizesFetchAsync, partNo, partNoSuffix);

    return data ?? blankCallSizes;
}

export function usePartCallSizesRepo() : (partNo: string, partNoSuffix: string) => Promise<IPartCallSizePresets> {
    return useBrowserBackedMapAsync<IPartCallSizePresets>(sessionKey, getPartCallSizesFetchAsync);
}
