import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import { useFormContext, useController } from 'react-hook-form';
import { useTranslations } from '@fenetech/translations';
import useMeasurementTypes from 'helpers/context/SelectionValues/useMeaurementTypes';

import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    permissions?: IQuotePermissions,
    [x: string] : any  // this should always be the last prop, to support any other props that will be passed along down the component chain
}


function SelectMeasurementType ({ permissions, ...rest }: IProps)
{

    const measurementTypes = useMeasurementTypes();
    const tm = useTranslations();
    
    const name = "engineeringUnitSetID"
    const { control } = useFormContext();
    const { field, formState: { errors } } = useController({
           name: name,
           control: control,
           rules: { required: {value: true, message: tm.Get("Required")} },
        });

    return <>
        {measurementTypes && measurementTypes.length > 1 && 
            <FormControl
                variant="outlined"
                error={errors[name] ? true : false}
                {...rest}
                >
                <InputLabel>{tm.Get("Measurement Type")}</InputLabel>
                <Select
                    id="measurementType"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    value={field.value}
                    disabled={!permissions?.measurementType ?? true}
                    label={tm.Get("Measurement Type")}
                >
                    {measurementTypes.map((mt) =>
                        <MenuItem key={mt.setID} value={mt.setID}>{tm.Get(mt.description)}</MenuItem>
                    )}
                </Select>
                <FormHelperText>{errors[name]?.message}</FormHelperText>
            </FormControl>    
        }
    
    </>

}

export default SelectMeasurementType;