import { useEffect } from 'react';

import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useTerms(mfgCustomerID: string) {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const terms = selectionValues.terms;

    const isExpired = false;

    const cacheKey = `${mfgCustomerID ?? ""}`;

    useEffect(() => {

        if (mfgCustomerID) {
            if (!terms.has(cacheKey) || isExpired) {
                actions.LoadTermsAsync(mfgCustomerID, cacheKey);
            }
        }

    }, [actions, mfgCustomerID, terms, isExpired, cacheKey]);

    return terms.get(cacheKey);
}

export default useTerms;

