
import "./helpers/polyfills/string.format";

import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import APIHelper from "helpers/APIHelper";
import ClientConfig from "ClientConfig";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticationContextProvider from "helpers/context/Authentication/AuthenticationContext";
import { initializeIcons } from '@uifabric/icons';
import { ErrorContextProvider } from "components/Errors/ErrorContext";
import ErrorPage from "components/Errors/ErrorPage";
import { NavigationBlockerContextProvider } from "helpers/context/Application/NavigationBlockerContext";

const baseUrl: HTMLBaseElement = document.getElementsByTagName("base")[0];

let apiRootPath = baseUrl.href;

if (ClientConfig.apiRootPath) apiRootPath = ClientConfig.apiRootPath;

APIHelper.SetBaseURL(apiRootPath);


LicenseInfo.setLicenseKey(
  'a300cbb2861b79cf4fe858fb17b176a5T1JERVI6Mjk4MjYsRVhQSVJZPTE2NjM5NzYyMTAwMDAsS0VZVkVSU0lPTj0x',
);

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons();

const base = baseUrl.getAttribute("href") ?? "";

const mainContainer = document.querySelector("#root");
const mainRoot = createRoot(mainContainer!);


mainRoot.render(
  <StyledEngineProvider injectFirst>
    {/* <React.StrictMode> React 18 Strict mode is more strict.  FUTURE: Fix stuff up to make it work in strict mode */}

    <AuthenticationContextProvider>
      <BrowserRouter basename={base}>
        <NavigationBlockerContextProvider>
          <ErrorContextProvider>
            <Routes>
              <Route path="errorPage" element={<ErrorPage />} />
              <Route path="*" element={<App />} />
            </Routes>
          </ErrorContextProvider>
        </NavigationBlockerContextProvider>
      </BrowserRouter>
    </AuthenticationContextProvider>

    {/* </React.StrictMode> */}
  </StyledEngineProvider >
);

// If you want to register a service worker, uncomment the following line. 
// https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

