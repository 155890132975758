import { Edit } from "@mui/icons-material";
import { GridActionsCellItem, GridColumnHeaderParams, GridRenderCellParams, GridEnrichedColDef, GridRowParams } from "@mui/x-data-grid-pro";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { useTranslations } from "@fenetech/translations";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import React from "react";
import { Typography } from "@mui/material";

type EditPriceActionHandler = (okey: number) => void;

interface ICommandProps {
    params: GridRowParams;
    actionHandler: EditPriceActionHandler
}

const EditCommand: React.FC<ICommandProps> = ({ params, actionHandler }: ICommandProps) => {
    const tm = useTranslations();
    const optionIndex: number = parseInt(params.row.index as string);

    const handlerShowDialog = React.useCallback(() => {
        actionHandler(optionIndex);
    }, [actionHandler, optionIndex]);

    return <><GridActionsCellItem
        icon={<Edit />}
        color="primary"
        label={tm.Get("Edit Option Price")}
        onClick={handlerShowDialog}
    />
    </>
};

const commandsColumn = (editHandler: EditPriceActionHandler, fieldName: string) => {

    const minWidth = 46;

    const col = {
        field: fieldName,
        type: "actions",
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                &npsp;
            </div>
        },
        minWidth: minWidth,
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {
            const actions: Array<JSX.Element> = [];
            actions.push(<EditCommand params={params} actionHandler={editHandler} />);
            return actions;
        },
    } as GridEnrichedColDef;

    return col;
}

const textColumn = (fieldName: string, title: string, isMobile: boolean, hideable?: boolean) => {

    const col = {
      field: fieldName,
      minWidth: isMobile ? 90 : 130,
      headerName: title,
      hideable: hideable,
      filterable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="body2">{params.value}</Typography>;
      },
    } as GridEnrichedColDef

    return col
}

const priceColumn = (fieldName: string, title: string, isMobile: boolean, cf: ICurrencyFormatter) => {
    
    const col = {
      field: fieldName,
      minWidth: isMobile ? 90 : 130,
      headerName: title,
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row["priceOverride"]) {
            return (params.value !== undefined && <Typography variant="body2"><b>{cf.Format(params.value)}</b></Typography>)
        } else {
            return (params.value !== undefined && <Typography variant="body2">{cf.Format(params.value)}</Typography>)
        }
      },
    } as GridEnrichedColDef

    return col
}

export function AddColumns(generator: DataGridColumnGenerator, isMobile: boolean, editEnabled: boolean, editHandler: EditPriceActionHandler, cf: ICurrencyFormatter): void {
        const appInfo = useApplicationInfo();
        const tm = useTranslations();
        generator.AddColumn(textColumn("group", tm.Get("Question"), isMobile, false));
        if (!appInfo.parameters.hideOptionCodes) {
            generator.AddColumn(textColumn("code", tm.Get("Code"), isMobile));
        }
        generator.AddColumn(textColumn("description", tm.Get("Description"), isMobile));
        generator.AddColumn(textColumn("value", tm.Get("Value"), isMobile));
        generator.AddColumn(priceColumn("price", tm.Get("Price"), isMobile, cf));
        if (editEnabled) {
            generator.AddColumn(commandsColumn(editHandler, tm.Get("Edit Option Price")));
        }
}
