import { useEffect, useState } from "react";
import { TableRow, TableCell, Tooltip, Skeleton, styled, alpha } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

import OrdersAPI from "components/Orders/OrdersAPI";
import useOrderData from "components/Orders/useOrderData";
import LineItemColumn from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";

import { IOrderLineItem } from "helpers/interfaces";
import Constants from "helpers/constants";

interface IProps {
    columns: LineItemColumn[];
    lineItem: IOrderLineItem,
}

const LineItemComment: React.FC<IProps> = ({ columns, lineItem }: IProps) => {

    const { order } = useOrderData();
    const [optionsString, setOptionsString] = useState<string | undefined>(undefined);
    const [optionsToolTip, setOptionsToolTip] = useState<string | undefined>(undefined);

    const StyledOptionsCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
        backgroundColor: alpha(theme.palette.secondary.light, 0.1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),        
        },
    }));        
    

    useEffect(() => {
        if (order?.oKey && lineItem?.odKey)
        {
            OrdersAPI.GetLineItemOptionsString(order.oKey, lineItem.odKey, false).then((os) => {
                setOptionsString(os);
            });
            OrdersAPI.GetLineItemOptionsString(order.oKey, lineItem.odKey, true).then((os) => {
                setOptionsToolTip(os);
            });            

        }


    }, [order, lineItem]);

    return <>
        <TableRow>
            <Tooltip title={optionsToolTip ?? ""}>
                <StyledOptionsCell colSpan={columns.length - 1}>
                    {(optionsString === undefined || optionsToolTip === undefined) ? 
                        <Skeleton variant="text" width="100%" /> 
                    : 
                        optionsString ? optionsString : Constants.Strings.ZeroWidthSpace 
                    }
                </StyledOptionsCell>
            </Tooltip>
        </TableRow>
    </>;
}


export default LineItemComment;