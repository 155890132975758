import { Chip, Stack } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import React from 'react';

interface IProps {
    isInactive: boolean,
    isCustomerInactive: boolean,
    isMfgInactive: boolean,
    isMfgCreatedEmployee: boolean,
    mfgCreatedText: string | null
}

const UserStatusChips: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    return <Stack direction="row" spacing={1}>
        {props.isInactive && <Chip label={tm.Get("User Inactive")} color="error" />}
        {props.isCustomerInactive && <Chip label={tm.Get("Customer Inactive")} color="error" />}
        {props.isMfgInactive && <Chip label={tm.Get("Mfg Inactive")} color="error" />}
        {props.isMfgCreatedEmployee && props.mfgCreatedText && <Chip label={props.mfgCreatedText} color="info" />}
    </Stack>;
}

export default UserStatusChips;