import { useState, useEffect, useCallback } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslations } from '@fenetech/translations';
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";
import Constants from "helpers/constants";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import { TextField } from "@mui/material";
import LabeledText from "components/Common/LabeledText";

interface IProps {
    fieldName: string,
    readOnly: boolean,
    lnf: ILocaleNumberFormatter,
    [x: string]: any    // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function TaxFieldForm({ fieldName, readOnly, lnf, label, ...rest }: IProps) {
    const { setValue, watch } = useFormContext();
    const formValue = watch(fieldName);
    const tm = useTranslations();
    const [displayTax, setDisplayTax] = useState<string>(lnf.Format(formValue));
    const [validationError, setValidationError] = useState<string>("");

    useEffectOnLoad(() => {
        const initialValue = watch(fieldName);
        setValue(fieldName, lnf.Format(initialValue));
    });
    
    useEffect(() => {
        if (readOnly) {
            setValidationError("");
        }
        // If the form value is set (or reset) to an unformatted value, format it now
        setDisplayTax(lnf.Format(formValue));
    }, [lnf, formValue, readOnly]);

    const validateValue = useCallback((value: string): string => {
        if (value) {
            const floatValue = lnf.Parse(value.toString());

            if (isNaN(floatValue)) {
                const message = tm.Get("Must be a number.");
                return message;
            } else {
                let minValue: number = Constants.Min.TaxRate;
                let maxValue: number = Constants.Max.TaxRate;
                if (floatValue > maxValue || floatValue < minValue) {
                    const message = tm.GetWithParams("{0} must be between {1} and {2}.", label ?? "value", minValue.toString(), maxValue.toString());
                    return message;
                }
            }
        }
        return "";
    }, [label, lnf, tm]);

    const handleOnChange = useCallback((e: any) => {
        setDisplayTax(e.target.value)
    }, []);

    const handleOnBlur = useCallback((e: any) => {
        const error = validateValue(displayTax);
        setValidationError(error);
        if (error === "") {
            if (displayTax  === "") {
                setValue(fieldName, "");
            } else {
                let numericValue = lnf.Parse(displayTax);
                setValue(fieldName, numericValue);
                setDisplayTax(lnf.Format(numericValue));
            }
        }
    }, [validateValue, setValue, lnf, displayTax, fieldName]);

    if (readOnly) {
        return <LabeledText
            label={label}
            text={`${displayTax} %`}
            showSkeletonWhenEmpty={true}
        />
    }

    return <>
        <TextField
            variant="standard"
            id={fieldName}
            value={displayTax}
            label={label}
            placeholder={label}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={validationError !== ""}
            helperText={validationError}
            {...rest}
        />
    </>
}

export default TaxFieldForm;