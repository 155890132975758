import { Checkbox, Select, MenuItem, TextField, FormControlLabel, FormControl, InputLabel } from "@mui/material";
import Constants from "helpers/constants";
import { ControlTypeEnum } from "helpers/enums";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import { useFormContext } from "react-hook-form";
import { IPreference } from "../../helpers/interfaces";

interface IProps {
    preference: IPreference
}

const EditPreference: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const { register, reset, formState: { errors } } = useFormContext();

    useEffectOnLoad(() => {
        reset();
    });

    switch (props.preference.type) {
        case ControlTypeEnum.Checkbox: {
            return <FormControlLabel
                control={<Checkbox
                    {...register(props.preference.key.toString())}
                    defaultChecked={props.preference.value === '1'}
                />}
                label={tm.Get(props.preference.description)}
            />
        }
        case ControlTypeEnum.Textbox: {
            let check = {
                validate: {
                    isNotEmpty: (value: string) => new RegExp("^(?!\\s*$).+").test(value) || tm.GetWithParams("{0} cannot be blank.", tm.Get("Preference")),
                }
            };
            let validation = register(props.preference.key.toString(), check);
            return <TextField
                {...validation}
                variant="standard"
                defaultValue={props.preference.value}
                inputProps={{ maxLength: Constants.MaxLength.PreferenceValue }}
                error={errors[props.preference.description]}
                helperText={errors[props.preference.description]?.value}
                label={tm.Get(props.preference.description)}
            />;
        }
        case ControlTypeEnum.Combobox: {
            return (
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>{props.preference.description}</InputLabel>
                    <Select {...register(props.preference.key.toString())} defaultValue={props.preference.value} label={props.preference.description} variant="outlined">
                        {props.preference.comboBoxListItems.map(item => (
                            <MenuItem key={item.itemKey} value={item.itemKey}>{tm.Get(item.description)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        }
    }
}

export default EditPreference;