import React, { useMemo } from 'react';
import { Dialog } from "@mui/material";

import Surcharges, { ISurchargeInfo } from "components/Quotes/QuoteEntry/Surcharges/Surcharges";

import useIsMobile from "helpers/hooks/useIsMobile";

import { ILineItem } from "helpers/interfaces";
import { ISurchargeDetail } from "components/Quotes/QuoteEntry/Surcharges/SurchargeDetail";

interface IProps {
    lineItems: ILineItem[];
    dialogVisible: boolean;
    isReadOnly: boolean;
    viewCost: boolean;
    onCancel: () => void;
    onSubmit: (originalState: ISurchargeInfo, newState: ISurchargeInfo) => void;
}

const SurchargeDialog: React.FC<IProps> = ({ lineItems, dialogVisible, isReadOnly, viewCost, onCancel, onSubmit }: IProps) => {

    const isMobile = useIsMobile();

    const initialState: ISurchargeInfo = useMemo(() => {

        const details: ISurchargeDetail[] = [];

        lineItems?.filter(li => li.isSurchargePart === true).forEach((li, index) => {
            details.push({
                index: index,
                odKey: li.odKey,
                formattedPart: li.formattedPart,
                partNo: li.partNo,
                partNoSuffix: li.partNoSuffix,
                unitPrice: li.unitPrice,
                cost: li.cost,
                priceOverride: li.priceOverride,
                priceChanged: false,
            });
        }
        );

        return {
            details: details,
        };
    }, [lineItems]);

    return <>
        <Dialog
            open={dialogVisible}
            onClose={onCancel}
            fullWidth={isMobile}
            maxWidth={isMobile ? false : undefined}
        >
            <Surcharges
                initialState={initialState}
                isReadOnly={isReadOnly}
                viewCost={viewCost}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </Dialog>
    </>;

}

export default SurchargeDialog;