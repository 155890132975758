import React, { PropsWithChildren } from 'react';
import { Accordion, Typography, AccordionDetails, Box } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";

interface IProps {
    headerText: string|JSX.Element;
    defaultExpanded:boolean;
}

const OWExpanderAccordian: React.FC<PropsWithChildren<IProps>> = (props: PropsWithChildren<IProps>) => {

    const [expanded, setExpanded] = React.useState<boolean>(props.defaultExpanded);

    const onExpandedChange = React.useCallback<((event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void)>((e, exp) => {
        setExpanded(exp);
    }, [setExpanded]);


    return <Accordion expanded={expanded} onChange={onExpandedChange} disableGutters >
        <AccordionSummarySlim>
            {/* Component is a span because the default <p> could not nest other controls beneath it. */}
            <Typography component="span">
                {props.headerText}
            </Typography>
        </AccordionSummarySlim>
        <AccordionDetails sx={{ padding: 0 }}>
            <Box display="flex" flexDirection="column" p={1} gap={1} >
                {props.children}
            </Box>
        </AccordionDetails>
    </Accordion>;
}

export default OWExpanderAccordian;