import { Stack, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import LabeledText from "components/Common/LabeledText";

import { useTranslations } from "@fenetech/translations";
import { ITransLogDiff } from "helpers/interfaces";
import Format from "helpers/fv.format";

interface IProps {
    diffs: ITransLogDiff[],
    user: string,
    date: Date
}

const TransLogDetails: React.FC<IProps> = ({ diffs, user, date }: IProps) => {

    const tm = useTranslations();

    const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
    }));

    return <>
        <Stack direction="column" gap={1}>
            <LabeledText label={tm.Get("User")} text={user} />
            <LabeledText label={tm.Get("Date")} text={Format.FormatDateTime(date)} />
            {!diffs || diffs.length === 0 ?
                (
                    <Typography>No changes were found in the transaction records</Typography>
                )
                :
                (
                    <TableContainer>
                        <Table sx={{ minWidth: "500px", width: "100%", tableLayout: "auto" }} size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledHeaderCell>
                                        {tm.Get("Column")}
                                    </StyledHeaderCell>
                                    <StyledHeaderCell>
                                        {tm.Get("Old Value")}
                                    </StyledHeaderCell>
                                    <StyledHeaderCell>
                                        {tm.Get("New Value")}
                                    </StyledHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {diffs.map((diff) => (
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{diff.columnName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{diff.oldValue}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{diff.newValue}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
        </Stack>
    </>
};

export default TransLogDetails;

