import { useMemo } from "react";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { useTranslations } from "@fenetech/translations";
import useAuthHandler from "helpers/context/Authentication/useAuthHandler";

interface IProps {
    orderNumber: string;
    token: string;
}

function OtherOnlineAckOrderLink({ orderNumber, token }: IProps) {

    const tm = useTranslations();
    const authHandler = useAuthHandler();

    const orderLink = useMemo((): { label: string, url: string } => {

        let label = "";
        let url = "";
        if (token && orderNumber) {
            label = orderNumber;
            url = `/Orders?OnlineAckToken=${token}`;
        }

        return { label, url };

    }, [orderNumber, token]);

    return <>
        <Link component={RouterLink} to={orderLink.url} onClick={async () => await authHandler.SignOutAsync()} >
            {orderLink.label}
        </Link>
    </>;

}

export default OtherOnlineAckOrderLink;