import * as React from "react";
import { ICode } from "../interfaces";

interface ICodeProps {
    code: ICode
}

const NonTiledLockedOption: React.FC<ICodeProps> = (props: ICodeProps) => {

    const c: ICode = props.code;
    const desc: string = c ? c.codeDescriptionAndValue : "";
    return <span>{desc}</span>;
}

export default NonTiledLockedOption;