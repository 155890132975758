import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import AutocompleteForm from "components/Common/AutocompleteForm";
import SelectForm from "components/Common/SelectForm";
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";
import usePricingMethods from "helpers/context/SelectionValues/usePricingMethods";
import useSalespersons from "helpers/context/SelectionValues/useSalespersons";
import useTerms from "helpers/context/SelectionValues/useTerms";
import { PricingMethodEnum } from "helpers/enums";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import CustomerPricingCategories from "./CustomerPricingCategories";
import TaxFieldForm from "./TaxFieldForm";

interface IProps {
    expanded: boolean,
    onExpandedChange: (event: React.SyntheticEvent, expanded: boolean) => void,
    readOnly: boolean,
    parentCustomerID: string,
    parentSiteID: string | undefined,
    pricingMethod: PricingMethodEnum,
    isPricingMethodPercentBased: boolean
}

const CustomerFinancial: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4 });
    const pricingMethods = usePricingMethods();
    const salespersons = useSalespersons(props.parentCustomerID, props.parentSiteID ?? props.parentCustomerID);
    const terms = useTerms(props.parentCustomerID);

    return <>
        <Accordion expanded={props.expanded} onChange={props.onExpandedChange} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("Financial")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    <Grid item xs={12}>
                        <SelectForm
                            fieldName="customer.pricingMethod"
                            dataSource={pricingMethods}
                            keyField="id"
                            valueField="id"
                            displayField="description"
                            translateDisplayField={true}
                            label={tm.Get("Pricing Method")}
                            readOnly={props.readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomerPricingCategories
                            pricingMethod={props.pricingMethod}
                            isPricingMethodPercentBased={props.isPricingMethodPercentBased}
                            readOnly={props.readOnly}
                            parentCustomerID={props.parentCustomerID}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AutocompleteForm
                            fieldName="customer.terms"
                            dataSource={terms}
                            valueField="description"
                            defaultValue=""
                            displayField="description"
                            translateDisplayField={false}
                            label={tm.Get("Terms")}
                            readOnly={props.readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AutocompleteForm
                            fieldName="customer.salesPersonID"
                            dataSource={salespersons}
                            valueField="employeeID"
                            defaultValue={0}
                            displayField="fullName"
                            translateDisplayField={false}
                            label={tm.Get("Salesperson")}
                            readOnly={props.readOnly}
                        />
                    </Grid>
                    {[...Array(5)].map((e, i) => {
                        return <React.Fragment key={i}>
                            <Grid item xs={12} sm={6}>
                                <TaxFieldForm
                                    fieldName={`customer.taxPercent${i > 0 ? i.toString() : ""}`}
                                    readOnly={props.readOnly}
                                    lnf={lnf}
                                    label={tm.Get(`Tax ${i > 0 ? i.toString() : ""}`.trim())}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextFieldForm
                                    fieldName={`customer.taxTitle${i > 0 ? i.toString() : ""}`}
                                    readOnly={props.readOnly}
                                    variant="standard"
                                    id={`customer.taxTitle${i > 0 ? i.toString() : ""}`}
                                    inputProps={{ maxLength: Constants.MaxLength.CustomerTaxTitles }}
                                    label={i > 0 ? tm.Get(`Tax Title ${i.toString()}`) : tm.Get("Tax Title")}
                                    placeholder={i > 0 ? tm.Get(`Tax Title ${i.toString()}`) : tm.Get("Tax Title")}
                                    fullWidth
                                />
                            </Grid>
                        </React.Fragment>
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default CustomerFinancial;