import * as React from "react";
import { ISubLineItemInfo, IKeyValuePair } from "../interfaces";
import { useTranslations } from "@fenetech/translations";
import { Grid, Typography } from "@mui/material";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { useMemo } from "react";
import OptionGroupBox from "../OptionsWizard/OptionGroupBox";

interface IProps {
    includeGridItem?: boolean;
}

const DesignerItemProperties: React.FC<IProps> = (props: IProps) => {

    const wizardState = useWizardState();
    const format = useFormatHelper();

    const currentSLI = useMemo(() => {
        if (wizardState.webDesigner && wizardState.webDesigner.sectionList) {
            const cs = wizardState.webDesigner.currentSection;
            return wizardState.webDesigner.sectionList?.find((sli: ISubLineItemInfo) => sli.sli === cs);
        }
        return null;
    }, [wizardState.webDesigner]);

    const tm = useTranslations();

    const renderOptionGroupBox = useMemo(() => {
        if (currentSLI && currentSLI.itemProperties) {
            return <OptionGroupBox id="DESIGNER_PROPS" label="">
                <Grid container direction="column" alignItems="stretch" spacing={1}>
                    {currentSLI.itemProperties.map((p: IKeyValuePair) => <Grid item container direction="row" spacing={1} key={p.key}>
                        <Grid item xs="auto"><Typography>{p.key && tm.Get(p.key) + ":"}</Typography></Grid>
                        <Grid item xs> <Typography>{p.value}</Typography></Grid>
                    </Grid>
                    )}
                </Grid>
            </OptionGroupBox>
        }
        else {
            return <></>;
        }

    }, [currentSLI, tm]);

    if (!wizardState.itemInfo || !format || !currentSLI || !currentSLI.itemProperties) {
        return null;
    } else if (props.includeGridItem) {
        return <Grid item>
            {renderOptionGroupBox}
        </Grid>;
    } else {
        return renderOptionGroupBox;
    }
}

export default DesignerItemProperties;
