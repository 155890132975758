import React, { PropsWithChildren } from 'react';
import Waiting from "./Waiting";

interface IProps {
    loading?: boolean;
}

const LoadingOverlay: React.FC<PropsWithChildren<IProps>> = (props: PropsWithChildren<IProps>) => {

    return <Waiting waiting={props.loading ?? false} imageSize={46} minSpacing={true} >
        {props.children}
    </Waiting>
}

export default LoadingOverlay;