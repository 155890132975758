import { useEffect } from 'react';
import usePageActions from "./usePageActions";

/*
    Use this hook to set the action content (any JSX eleemnt)
*/

export default function useFooterContent(element: JSX.Element): void {

    const pageActions = usePageActions();

    useEffect(() => {
        pageActions.SetFooterContent(element);
    }, [pageActions, element]);

    useEffect(() => {

        return () => {
            pageActions.SetFooterContent(null);
        };
    }, [pageActions]);

}

