import React, { useEffect, useState } from "react";
import { Link, Breadcrumbs, Typography } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import useIsMobile from "helpers/hooks/useIsMobile";
import { IProductNavigatorNode, IPartKey } from "helpers/interfaces";

interface IProps {
    partList: IPartKey[] | null,
    nodeMap: Map<string, IProductNavigatorNode>,
    finalNode: IProductNavigatorNode | null,
    onCancel: () => void,
    setFinalNode: (node: IProductNavigatorNode | null) => void,
}

function ProductNavigatorBreadCrumbs({partList, nodeMap, finalNode, onCancel, setFinalNode}: IProps) {
    const isMobile = useIsMobile();
    const tm = useTranslations();
    
    const [breadCrumbsPath, setBreadCrumbsPath] = useState<IProductNavigatorNode[]>([]);

    useEffect(() => {
        var n: any = finalNode;
        var path = Array<IProductNavigatorNode>();
        while (n !== null) {
            path.push(n);
            if (n.parentNodeID !== "") {
                n = nodeMap.get(n.parentNodeID);
            } else {
                n = null;
            }
        }
        path.reverse();
        setBreadCrumbsPath(path);
    }, [nodeMap, finalNode])

    return <>
        <Breadcrumbs maxItems={isMobile ? 2 : 10} separator=">" aria-label="breadcrumb">
            <Link component="button" underline="hover" onClick={onCancel}>
                <Typography>{partList === null ? tm.Get("Quote") : tm.Get("Quote Item")}</Typography>
            </Link>
            <Link component="button" underline="hover" onClick={() => setFinalNode(null)}>
                <Typography fontWeight={finalNode === null ? 700 : 500}>{tm.Get("Product Navigator")}</Typography>
            </Link>
            {breadCrumbsPath.map((n) => {
                return <Link key={n.nodeID} component="button" underline="hover" onClick={() => setFinalNode(n)}>
                    <Typography fontWeight={finalNode?.nodeID === n.nodeID ? 700 : 500}>{n.title}</Typography>
                </Link>
            })}
        </Breadcrumbs>
    </>
}

export default ProductNavigatorBreadCrumbs