import { Paper, Box, Typography, Grid, Skeleton } from "@mui/material";
import { Business } from "@mui/icons-material";

import useMfgCustomer from "helpers/context/SelectionValues/useMfgCustomer";
import useQuoteData from "components/Quotes/useQuoteData";

function MfgCustomerCard( )
{
    const { quote } = useQuoteData();
    const mfgCustomer = useMfgCustomer(quote?.mfgCustomerID ?? "", quote?.mfgSiteID ?? "");

    function formatMfgCustomerName() : string {
        let formattedName = mfgCustomer?.name ?? "";

        if (mfgCustomer?.siteName && mfgCustomer?.siteName !== mfgCustomer?.name)
        {
            formattedName += `[${mfgCustomer.siteName}]`;
        }

        return formattedName;
    }

    return <>
    <Paper sx={{display: "flex"}}>
        <Box sx={{ width: 300, margin: 1}} display="flex" flexDirection="row" >
            <Grid container direction="row">
                <Grid item mr={1} display="flex" alignItems="center">
                    <Business color="primary"/>
                </Grid>
                <Grid item xs display="flex" alignItems="center">
                    {(!mfgCustomer) && 
                        <Box display="flex" width="100%">
                            <Skeleton width="100%" />
                        </Box>
                    }
                    {(mfgCustomer) &&
                        <Typography variant="subtitle1">
                            {formatMfgCustomerName()}
                        </Typography>
                    }
                </Grid>
            </Grid>
        </Box>
    </Paper>
    </>

}


export default MfgCustomerCard;