import { PricingMethodEnum } from "helpers/enums";
import APIHelper from "../../helpers/APIHelper";
import { IPriceTablePartGridRow, IPriceTableOptionGridRow, IPriceTablesModel, IShipVia, IShipViaRename, ITerms, ITermsRename, IPartListItem, IPartKey, IPriceTableCommandResults, ISimpleListItemStringKey, IPriceSchedule, IPriceScheduleItem, IBaseContent } from "../../helpers/interfaces";

export default class SetupAPI {

    public static async GetShipVia(parentCustomerID: string = ""): Promise<IShipVia[]> {

        const query = parentCustomerID === "" ? "api/shipvia" : `api/shipvia/${parentCustomerID}`;

        const response = await APIHelper.GetAsync<IShipVia[]>(query);
        return response.Result;
    }

    public static async InsertShipVia(shipVia: IShipVia): Promise<IBaseContent> {
        const response = await APIHelper.PostAsync<IBaseContent>('api/shipvia', {}, shipVia, false);
        return response.Result;
    }

    public static async RenameShipVia(shipVia: IShipViaRename): Promise<IBaseContent> {
        const response = await APIHelper.PostAsync<IBaseContent>('api/shipvia/update', {}, shipVia, false);
        return response.Result;
    }

    public static async DeleteShipVia(shipVia: IShipVia): Promise<IBaseContent> {

        const response = await APIHelper.DeleteAsync<IBaseContent>('api/shipvia', {}, shipVia, false);
        return response.Result;
    }

    public static async GetTerms(parentCustomerID: string = ""): Promise<ITerms[]> {

        const query = parentCustomerID === "" ? "api/terms" : `api/terms/${parentCustomerID}`;

        const response = await APIHelper.GetAsync<ITerms[]>(query);
        return response.Result;
    }

    public static async InsertTerms(terms: ITerms): Promise<IBaseContent> {

        const response = await APIHelper.PostAsync<IBaseContent>('api/terms', {}, terms, false);
        return response.Result;
    }

    public static async RenameTerms(terms: ITermsRename): Promise<IBaseContent> {

        const response = await APIHelper.PostAsync<IBaseContent>('api/terms/update', {}, terms, false);
        return response.Result;
    }

    public static async DeleteTerms(terms: ITerms): Promise<IBaseContent> {

        const response = await APIHelper.DeleteAsync<IBaseContent>('api/terms', {}, terms, false);
        return response.Result;
    }

    public static async QueryPricingTablesInit(): Promise<IPriceTablesModel> {
        const query = `api/pricetables/init`;
        const response = await APIHelper.GetAsync<IPriceTablesModel>(query);
        return response.Result;
    }

    public static async QueryPricingTables(categoryID: number): Promise<IPriceTablesModel> {
        const query = `api/pricetables/${categoryID}`;
        const response = await APIHelper.GetAsync<IPriceTablesModel>(query);
        return response.Result;
    }

    public static async QueryParts(discountTableID: number): Promise<IPartListItem[]> {
        const query = `api/pricetables/parts/${discountTableID}`;
        const response = await APIHelper.GetAsync<IPartListItem[]>(query);
        return response.Result;
    }

    public static async QueryPartsGrid(discountTableID: number): Promise<IPriceTablePartGridRow[]> {
        const query = `api/pricetables/partsGrid/${discountTableID}`;
        const response = await APIHelper.GetAsync<IPriceTablePartGridRow[]>(query);
        return response.Result;
    }

    public static async QueryOptionsGrid(discountTableID: number, optionGroup: string): Promise<IPriceTableOptionGridRow[]> {
        const query = `api/pricetables/optionsGrid/${discountTableID}`;

        const params = { optionGroup };

        const response = await APIHelper.GetAsync<IPriceTableOptionGridRow[]>(query, params);
        return response.Result;
    }

    public static async QueryOptionsGridForPart(discountTableID: number, partNo: string, partNoSuffix: string, optionGroup: string): Promise<IPriceTableOptionGridRow[]> {
        const query = `api/pricetables/optionsGridForPart/${discountTableID}`;

        const params = { partNo, partNoSuffix, optionGroup };

        const response = await APIHelper.GetAsync<IPriceTableOptionGridRow[]>(query, params);
        return response.Result;
    }

    public static async QueryOptionGroupsForPart(partNo: string, partNoSuffix: string): Promise<ISimpleListItemStringKey[]> {
        const query = `api/pricetables/optionGroups`;

        const params = { partNo, partNoSuffix };

        const response = await APIHelper.GetAsync<ISimpleListItemStringKey[]>(query, params);
        return response.Result;
    }

    public static async UpdatePricingItemWithSchedule(discountTableID: number, partNo: string, partNoSuffix: string, optionCode: string, priceScheduleID: number): Promise<void> {
        const query = `api/pricetables/schedule`;

        const params = { 
            discountTableID,
            partNo, 
            partNoSuffix, 
            optionCode,
            priceScheduleID
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async UpdatePartPricingItemWithValue(discountTableID: number, partNo: string, partNoSuffix: string, optionCode: string, discount: number | null, fixedPrice: boolean, checked: boolean): Promise<void> {
        const query = `api/pricetables/value/part`;

        const params = { 
            discountTableID,
            partNo, 
            partNoSuffix, 
            optionCode,
            discount,
            fixedPrice,
            checked
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async UpdateOptionPricingItemWithValue(discountTableID: number, partNo: string, partNoSuffix: string, optionCode: string, discount: number | null, fixedPrice: boolean): Promise<void> {
        const query = `api/pricetables/value/option`;

        const params = { 
            discountTableID,
            partNo, 
            partNoSuffix, 
            optionCode,
            discount,
            fixedPrice
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async RequeryDefaultPriceForOption(discountTableID: number, optionCode: string): Promise<IPriceTableOptionGridRow> {
        const query = `api/pricetables/${discountTableID}/${optionCode}`;

        const response = await APIHelper.GetAsync<IPriceTableOptionGridRow>(query);
        return response.Result;
    }

    public static async FillDownPricingItemPartWithSchedule(discountTableID: number, parts: IPartKey[], priceScheduleID: number): Promise<void> {
        const query = `api/pricetables/schedule/part/filldown`;

        const params = { 
            discountTableID,
            parts,
            priceScheduleID
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async FillDownPricingItemOptionWithSchedule(discountTableID: number, partNo: string, partNoSuffix: string, optionCodes: string[], optionGroup: string, priceScheduleID: number): Promise<void> {
        const query = `api/pricetables/schedule/option/filldown`;

        const params = { 
            discountTableID,
            partNo, 
            partNoSuffix, 
            optionCodes,
            optionGroup,
            priceScheduleID
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async FillDownPricingItemPartWithValue(discountTableID: number, parts: IPartKey[], discount: number | null, fixedPrice: boolean, checked: boolean): Promise<void> {
        const query = `api/pricetables/value/part/filldown`;

        const params = { 
            discountTableID,
            parts, 
            discount,
            fixedPrice,
            checked
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async FillDownPricingItemOptionWithValue(discountTableID: number, partNo: string, partNoSuffix: string, optionCodes: string[], optionGroup: string, discount: number | null, fixedPrice: boolean): Promise<void> {
        const query = `api/pricetables/value/option/filldown`;

        const params = { 
            discountTableID,
            partNo, 
            partNoSuffix,
            optionCodes,
            optionGroup,
            discount,
            fixedPrice
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async AddPriceTable(name: string, categoryID: number, tableType: PricingMethodEnum): Promise<IPriceTableCommandResults> {
        const query = `api/pricetables/addTable`;

        const params = { 
            name,
            categoryID, 
            tableType
        };

        const response = await APIHelper.PostAsync<IPriceTableCommandResults>(query, null, params);
        return response.Result;
    }

    public static async EditPriceTable(tableID: number, newName: string): Promise<string> {
        const query = `api/pricetables/editTable`;

        const params = { 
            tableID,
            newName
        };

        const response = await APIHelper.PostAsync<string>(query, null, params);
        return response.Result;
    }

    public static async DeletePriceTable(tableID: number): Promise<string> {
        const query = `api/pricetables/deleteTable`;

        const response = await APIHelper.DeleteAsync<string>(query, null, tableID);
        return response.Result;
    }

    public static async CopyPriceTableNew(sourceTable: number, newName: string): Promise<IPriceTableCommandResults> {
        const query = `api/pricetables/copyTable/new`;
        
        const params = { 
            sourceTable,
            newName
        };

        const response = await APIHelper.PostAsync<IPriceTableCommandResults>(query, null, params);
        return response.Result;
    }

    public static async CopyPriceTableExisting(sourceTable: number, destTable: number): Promise<void> {
        const query = `api/pricetables/copyTable/existing`;
        
        const params = { 
            sourceTable,
            destTable
        };

        await APIHelper.PostAsync<void>(query, null, params);
    }

    public static async GetPriceSchedules(): Promise<IPriceSchedule[]> {
        const query = `api/priceschedules`;

        const response = await APIHelper.GetAsync<IPriceSchedule[]>(query);
        return response.Result;
    }

    public static async GetPriceScheduleItems(priceScheduleID: number): Promise<IPriceScheduleItem[]> {
        const query = `api/priceschedules/${priceScheduleID}`;

        const response = await APIHelper.GetAsync<IPriceScheduleItem[]>(query);
        return response.Result;
    }

    public static async SetPriceScheduleAdders(priceScheduleID: number, price: number, increment: number): Promise<void> {
        const query = `api/priceschedules/${priceScheduleID}/adders`;

        const params = { 
            price,
            increment
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async PriceScheduleAddUI(priceScheduleID: number, price: number, increment: number): Promise<void> {
        const query = `api/priceschedules/${priceScheduleID}/ui`;

        const params = { 
            price,
            increment
        };

        await APIHelper.PostAsync<void>(query, null, params);
    }

    public static async PriceScheduleEditUI(priceScheduleID: number, price: number, increment: number): Promise<void> {
        const query = `api/priceschedules/${priceScheduleID}/ui`;

        const params = { 
            price,
            increment
        };

        await APIHelper.PutAsync<void>(query, null, params);
    }

    public static async PriceScheduleDeleteUI(priceScheduleID: number, increment: number): Promise<void> {
        const query = `api/priceschedules/${priceScheduleID}/ui`;

        await APIHelper.DeleteAsync<void>(query, null, increment);
    }

    public static async PriceScheduleAdd(name: string): Promise<IPriceTableCommandResults> {
        const query = `api/priceschedules`;

        const response = await APIHelper.PostAsync<IPriceTableCommandResults>(query, null, name);
        return response.Result;
    }

    public static async PriceScheduleEditName(priceScheduleID: number, name: string): Promise<string> {
        const query = `api/priceschedules/${priceScheduleID}`;

        const response = await APIHelper.PutAsync<string>(query, null, name);
        return response.Result;
    }

    public static async PriceScheduleDelete(priceScheduleID: number): Promise<string> {
        const query = `api/priceschedules/${priceScheduleID}`;

        const response = await APIHelper.DeleteAsync<string>(query);
        return response.Result;
    }
}