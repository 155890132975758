import * as React from "react";
import { CSSProperties } from "react";

interface IProps {
    src: string | undefined;
    renderAsDiv: boolean;
    imageStyle?: CSSProperties;
    onLoad?: (e: React.SyntheticEvent<HTMLImageElement>) => void;
}

/*
 * This component encapsulates an image can optionally be rendered as a div in order to change the scaling behavior.
 * 
 * 1) renderAsDiv=false: The image is rendered as an <img> that will overflow the parent container by nature (unless parent has absolute coordinates) 
 * 2) renderAsDiv=true: The image is rendered as a <div> using the backgroundImage css prop. This prevents overflow and scales the image up or down (matching the aspect ratio)
 *
 * The whole reason for renderAsDiv=true is to get the image to scale properly in mobile view, where the header and footer are sticky and auto grow based on their own contents
 * letting the "article" div grow to take up the remaining space.  We don't want to show an image with a scrollbar in this view. We want it to scale up/down to fit.
 * At the time of writing there was no other way to solve this, apart from moving all of the image rending to canvas, but then that code is in charge of scaling.
 */

const ScalableImage = React.forwardRef((props: IProps, ref) => {

    if (props.renderAsDiv) {
        return <div
            ref={ref as any}
            onLoad={props.onLoad}
            draggable={false}
            style={{
                ...props.imageStyle,
                backgroundImage: `url(${props.src})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center"
            }} />;
    }
    else {
        return <img
            ref={ref as any}
            onLoad={props.onLoad}
            draggable={false}
            style={props.imageStyle}
            src={props.src}
            alt="Display Object" />;
    }
});

export default ScalableImage;
