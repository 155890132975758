import { IGlobalItemChangeInfo } from "./IGlobalItemChangeInfo";
import { ISelectItem } from "./ISelectItem";

export interface IGlobalItem {
    part : string,
    customerRef : string,
    group : string,
    odKey : number, 
    lineItem : number,
    subLineItem : number,
    changeInfo : IGlobalItemChangeInfo,
    userInput : boolean,
    selectItems : ISelectItem[],
    changeType : GlobalChangeTypeEnum,
    visible : boolean,
    haltingItem : boolean,
}

export enum GlobalChangeTypeEnum {
    Added = 1,
    Changed,
    Removed,
}