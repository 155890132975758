import { Typography } from "@mui/material";

import { IGlobalOptions } from "components/GlobalOptions/GlobalOptionsContext";
import { IGlobalItem } from "models/GlobalOptions/IGlobalItem";
import ResultColumn from "components/GlobalOptions/Results/Columns/ResultColumn";

export default class TextResultColumn extends ResultColumn {

    getText: (globalItem: IGlobalItem) => string;

    public constructor(columnName: string, headerText: string, width: string, align: "inherit" | "left" | "center" | "right" | "justify" | undefined, getText: (globalItem: IGlobalItem) => string) {
        super(columnName, headerText, width);
        this.align = align;
        this.getText = getText;
    }

    public CellContent(globalOptions: IGlobalOptions, globalItem: IGlobalItem, previousItem?: IGlobalItem): JSX.Element {

        if (previousItem && globalItem.lineItem === previousItem.lineItem && globalItem.subLineItem === previousItem.subLineItem) {
            const previousItemText = this.getText(previousItem);
            const itemText = this.getText(globalItem);
            if (previousItemText === itemText) {
                return <></>;
            }
        }

        function getColor() {
            if (globalOptions.isGlobalItemSelected(globalItem)) {
                return "text.primary";
            }
            else {
                return "text.disabled";
            }
        }

        return <>
            <Typography color={getColor()}>
                {this.getText(globalItem)}
            </Typography>
        </>;
    }

}
