import { useContext } from "react";

import ItemPropertiesContext, { IItemPropertiesData, IItemPropertiesBuffer } from "components/OptionsWizard/ItemProperties/ItemPropertiesContext";

export function useItemPropertiesData(): IItemPropertiesData {
    return useContext(ItemPropertiesContext).currentState;
};

export function useItemPropertiesLoaded(): boolean {
    return useContext(ItemPropertiesContext).loaded;
};

export function useItemPropertiesBuffer(): IItemPropertiesBuffer {
    return useContext(ItemPropertiesContext).currentBufferState;
};

export function useItemPropertiesOriginalState(): IItemPropertiesData {
    return useContext(ItemPropertiesContext).previousState;
};

export default useItemPropertiesData;
