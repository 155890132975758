
import APIHelper from "../../helpers/APIHelper";
import { ICompanyUpdate, ICompanyInfoWithLogo, IBaseContent } from "../../helpers/interfaces";

export default class CompanyAPI {

    public static async GetCompanyInformation(mfgCustomerID: string, mfgSiteID: string): Promise<ICompanyInfoWithLogo> {
        const url: string = 'api/Company';
        const params = {
            mfgCustomerID: mfgCustomerID,
            mfgSiteID: mfgSiteID
        }
        const response = await APIHelper.GetAsync<ICompanyInfoWithLogo>(url, params);
        return response.Result;
    }

    public static async GetContractorCompanyInformation(parentCustomerID: string): Promise<ICompanyInfoWithLogo> {
        const url: string = 'api/Company/Contractor';
        const params = {
            parentCustomerID: parentCustomerID
        }
        const response = await APIHelper.GetAsync<ICompanyInfoWithLogo>(url, params);
        return response.Result;
    }

    public static async UpdateCompanyInformation(update: ICompanyUpdate, logoFile: string | null, clearLogo: boolean): Promise<IBaseContent> {
        const url: string = 'api/Company/';
        const params = {
            clearLogo: clearLogo
        }
        const updateWithLogo = {companyUpdate: update, companyLogoBase64: logoFile}
        const body = updateWithLogo;
        const response = await APIHelper.PutAsync<IBaseContent>(url, params, body);
        return response.Result;
    }

    public static async UpdateContractorCompanyInformation(update: ICompanyUpdate, logoFile: string | null, clearLogo: boolean): Promise<IBaseContent> {
        const url: string = 'api/Company/Contractor';
        const params = {
            clearLogo: clearLogo
        }
        const updateWithLogo = {companyUpdate: update, companyLogoBase64: logoFile}
        const body = updateWithLogo;
        const response = await APIHelper.PutAsync<IBaseContent>(url, params, body);
        return response.Result;
    }
}