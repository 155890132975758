import { DispatchActionsBase } from "components/Common/DispatchActionsBase";
import React, { } from "react";
import { NavigateFunction, NavigateOptions, To, useNavigate } from "react-router-dom";

export interface IErrorData {
    errorMessage: string,
}

export enum ErrorActionTypeEnum {
    SetMessage,
}

export type ErrorActionType =
    { type: ErrorActionTypeEnum.SetMessage, value: string };

const ErrorReducer: React.Reducer<IErrorData, ErrorActionType> = (data: IErrorData, action) => {

    switch (action.type) {
        case ErrorActionTypeEnum.SetMessage:
            return { ...data, errorMessage: action.value };
    }
};

const initialState: IErrorData = {
    errorMessage: ""
};

export class ErrorActions extends DispatchActionsBase<ErrorActionType> {

    #navigate: NavigateFunction;

    constructor(dispatcher: React.Dispatch<ErrorActionType>, navigate: NavigateFunction) {
        super(dispatcher);
        this.#navigate = navigate;
    }

    private navigate(to: To, options?: NavigateOptions) {
        this.#navigate(to, options)
    }

    public async NavigateToErrorPage(message: string) {
        this.dispatch({ type: ErrorActionTypeEnum.SetMessage, value: message });
        this.navigate("/errorPage");
    }

}

export const ErrorContextDispatch = React.createContext<ErrorActions | undefined>(undefined);
export const ErrorContext = React.createContext<IErrorData>(initialState);

export const ErrorContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(ErrorReducer, initialState);
    const navigate = useNavigate();

    const actions = React.useMemo<ErrorActions>(() => {
        return new ErrorActions(dispatch, navigate);
    }, [dispatch, navigate]);

    return <ErrorContext.Provider value={state}>
        <ErrorContextDispatch.Provider value={actions}>
            {props.children}
        </ErrorContextDispatch.Provider>
    </ErrorContext.Provider>
}

export default ErrorContext;
