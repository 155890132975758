import { Box, Grid, Paper, Stack, Typography } from "@mui/material"
import { useTranslations } from "@fenetech/translations";

import { ILineItem, ILineItemQuantityChange, IQuote, IQuotePermissions } from "helpers/interfaces";
import LineItemImage from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemImage";
import LineItemPart from "components/Quotes/QuoteEntry/LineItems/Fields//LineItemPart";
import LineItemSize from "components/Quotes/QuoteEntry/LineItems/Fields//LineItemSize";
import LineItemCommands, { ILineItemCommandEvents } from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemCommands";
import { ILineItemGridProps } from "components/Quotes/QuoteEntry/LineItems/LineItemGrid";
import LineItemUnitPrice from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemUnitPrice";
import LineItemTotal from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemTotal";
import LineItemExceptionIcon from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemExceptionIcon";
import LineItemQuantityDialog from "components/Quotes/QuoteEntry/LineItems/LineItemQuantityDialog";
import { useState, useCallback } from "react";
import useQuoteActions from "components/Quotes/useQuoteActions";
import useWait from "helpers/context/Page/useWait";

interface IProps {
    quote: IQuote,
    permissions: IQuotePermissions,
    lineItem: ILineItem,
    lineItemGridProps: ILineItemGridProps,
    isReadOnly: boolean,
    onImageClick: (imageSource: string) => void,
    onExceptionClick: (lineItemn: ILineItem) => void,
    commandEvents: ILineItemCommandEvents,
}

const LineItemCard: React.FC<IProps> = ({ quote, permissions, lineItem, lineItemGridProps, isReadOnly, onImageClick, onExceptionClick, commandEvents }: IProps) => {

    const tm = useTranslations();

    const { priceVisible } = lineItemGridProps;

    const [lineItemQtyVisible, setLineItemQtyVisible] = useState<boolean>(false);
    const quoteActions = useQuoteActions();
    const wait = useWait();

    const onQtyClick = () => {
        setLineItemQtyVisible(true);
    };

    const updateLineItemQty = useCallback(async (lineItem: ILineItem, newQuantity: number) => {
        setLineItemQtyVisible(false);

        if (quote?.oKey && lineItem.odKey) {
            wait.Show(true);
            const lineItemQuantityChanges: ILineItemQuantityChange[] = [];
            lineItemQuantityChanges.push({ newQuantity: newQuantity, odKey: lineItem.odKey });

            await quoteActions.UpdateLineItemQuantitiesAsync(quote.oKey, lineItemQuantityChanges);
            await quoteActions.LoadQuoteAsync(quote.oKey);
            wait.Show(false);
        }
    }, [quote, quoteActions, wait]);    

    return <>

        <Paper>
            <Box m={1} display="flex">
                <Grid container display="flex" flexDirection="column">
                    <Grid item container xs display="flex" flexDirection="row">
                        <Grid item xs="auto">
                            <Box width="150px" height="150px">
                                <LineItemImage
                                    oKey={quote.oKey}
                                    lineItem={lineItem}
                                    imageAccessKey={permissions.imageAccessKey}
                                    isOrder={false}
                                    onClick={onImageClick}
                                    showMediumSize={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs container display="flex" flexDirection="column">
                            <Grid item>
                                <Stack ml={1} direction="column" display="flex">
                                    <Grid container display="flex" direction="row">
                                        <Grid item xs>
                                            <LineItemPart
                                                oKey={quote.oKey}
                                                includeItemNumber={true}
                                                isReadOnly={isReadOnly}
                                                variant="h6"
                                                lineItem={lineItem}
                                            />
                                        </Grid>
                                        {lineItem.hasExceptions &&
                                            <Box display="flex" alignSelf="flex-end">
                                                <LineItemExceptionIcon
                                                    lineItem={lineItem}
                                                    validationExceptionsOnly={false}
                                                    onClick={onExceptionClick}
                                                />
                                            </Box>
                                        }
                                    </Grid>
                                    <LineItemSize
                                        lineItem={lineItem}
                                        engineeringUnitSetID={quote.engineeringUnitSetID}
                                        variant="body1"
                                    />
                                    {priceVisible ?
                                        <>
                                            <Box display="flex" alignSelf="flex-end">
                                                <LineItemUnitPrice
                                                    lineItem={lineItem}
                                                    variant="body1"
                                                    showQuantity={true}
                                                    showSqFtPrice={false}
                                                    onQtyClick={!isReadOnly ? onQtyClick : undefined}
                                                    isOrder={false}
                                                />
                                            </Box>
                                            <Box display="flex" alignSelf="flex-end">
                                                <LineItemTotal
                                                    lineItem={lineItem}
                                                    variant="body1"
                                                />
                                            </Box>
                                        </>
                                        :
                                        <>
                                            <Typography>
                                                {tm.Get("Qty:")} {lineItem.quantity.toString()}
                                            </Typography>
                                        </>
                                    }
                                </Stack>
                            </Grid>
                            {!isReadOnly &&
                                <Grid item alignSelf="flex-end" marginTop="auto">
                                    <LineItemCommands
                                        lineItem={lineItem}
                                        lineItemGridProps={lineItemGridProps}
                                        events={commandEvents}
                                    />
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                    {lineItem.comment &&
                        <Grid item xs="auto">
                            <Typography textAlign="left">
                                {lineItem.comment}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Paper>

        {lineItem &&
            <LineItemQuantityDialog
                dialogVisible={lineItemQtyVisible}
                lineItem={lineItem}
                onCancel={() => setLineItemQtyVisible(false)}
                onSubmit={updateLineItemQty}
            />
        }

    </>;
}

export default LineItemCard;
