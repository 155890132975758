import React from 'react';
import { Grid, TextField, Typography, Checkbox } from "@mui/material";

import { IShapeEditorInfo } from "helpers/interfaces";
import { useTranslations } from "@fenetech/translations";

interface IProps {
    shapeEditor: IShapeEditorInfo,
    loading: boolean,
    displayShapeParamValues: string[],
    handleCheckboxChange: (i: number, value: boolean) => void,
    handleTextboxChange: (i: number, value: string) => void,
    handleTextboxBlur: (i: number, value: string) => void,
}

const ShapeEditorParams: React.FC<any> = (props: IProps) => {
    const tm = useTranslations();

    return <Grid container direction={"row"} alignItems="center" maxHeight={props.loading ? 35 : (props.shapeEditor.shapeParameters.length * 50) + 35} spacing={1} columns={2} >
        <Grid item xs={1} height={35}>
            <Typography fontWeight="bold">{tm.Get("Parameter")}</Typography>
        </Grid>
        <Grid item xs={1} height={35}>
            <Typography fontWeight="bold">{tm.Get("Value")}</Typography>
        </Grid>
        {!props.loading && props.shapeEditor.shapeParameters.map((sp, i) => {
            return <React.Fragment key={i}>
                <Grid item xs={1} height={50} display="flex" alignItems="center">
                    <Typography>{sp.name}:</Typography>
                </Grid>
                <Grid item xs={1} height={50}>
                    {sp.booleanValueType ? 
                        <Checkbox checked={(sp.value !== null) && (sp.value > 0)} onChange={(e) => props.handleCheckboxChange(i, e.target.checked)} />
                        :
                        <TextField size="small" value={props.displayShapeParamValues[i]} onBlur={(e) => props.handleTextboxBlur(i, e.target.value)} onChange={(e) => props.handleTextboxChange(i, e.target.value)} />
                    }
                </Grid>
            </React.Fragment>
        })}
    </Grid>

};

export default ShapeEditorParams;