import { useState } from "react";
import { InputAdornment } from "@mui/material";

import { useTranslations } from "@fenetech/translations";

import DuplicatePOCheck from "components/Quotes/DuplicatePOCheck/DuplicatePOCheck";
import TextFieldForm from "components/Common/TextFieldForm";

import Constants from "helpers/constants";
import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    oKey?: number,
    customerGUID?: string,
    permissions?: IQuotePermissions,
    [x: string]: any    // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function SelectPONumber({ oKey, customerGUID, permissions, ...rest }: IProps) {
    const tm = useTranslations();
    const [poNumberToCheck, setPONumberToCheck] = useState<string | undefined>(undefined);

    function handleOnChange(e: any) {
        //when text changes, clear the warning and any progress icon
        if (poNumberToCheck) {
            setPONumberToCheck(undefined);
        }
    }

    function handleOnBlur(e: any) {
        //when focus leaves, kick off the process that checks for PO
        setPONumberToCheck(e.target.value);
    }

    return <>
        <TextFieldForm
            variant="standard"
            id="poNumber"
            readOnly={false}
            fieldName="poNumber"
            autoComplete="off"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <DuplicatePOCheck oKey={oKey} customerGUID={customerGUID} poNumberToCheck={poNumberToCheck} />
                    </InputAdornment>
                )
            }}
            inputProps={{ maxLength: Constants.MaxLength.PONumber }}
            label={tm.Get("PO Number")}
            placeholder={tm.Get("PO Number")}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={!permissions?.poNumber ?? true}
            {...rest}
        />
    </>

}

export default SelectPONumber;
