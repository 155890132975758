
import { GroupDisplayTypeEnum, ViewTypeEnum } from "components/OptionsWizard/enum";
import { IPartPreferences, ILineItemInfo, IWebDesigner, IDisplayViewSettings, ISysInfo, IUserPreferences, ICode, ILineItemPriceInfo, IQuestion, IValidationInfo, ISequencedWizardInfo } from "components/OptionsWizard/interfaces";
import { IQuestionGroup } from "components/OptionsWizard/models";
import { useTranslations, ITranslationManager } from "@fenetech/translations";
import React from "react";
import OptionsAPI from "./OptionsAPI";

export enum WizardModeEnum {
    Standard = 1,
    SequencedWizard = 2,
    MultiSelect = 3
}

export enum WizardViewEnum {
    Wizard = 0,
    ProductNavigator = 1,
    ShapeLibrary = 2,
    ShapeEditor = 3
}

export enum WizardActionEnum {
    SetWizardMode,
    MergeState,
    SetWizardView,
    SetShapeEditorCanSave,
    SetCameFromMultiSelect,
    SetObjectView,
}

export type WizardActionType =
    { action: WizardActionEnum.SetWizardMode, value: WizardModeEnum } |
    { action: WizardActionEnum.MergeState, value: Partial<IWizardState> } |
    { action: WizardActionEnum.SetWizardView, value: WizardViewEnum } |
    { action: WizardActionEnum.SetShapeEditorCanSave, value: boolean } |
    { action: WizardActionEnum.SetCameFromMultiSelect, value: boolean } |
    { action: WizardActionEnum.SetObjectView, value: ViewTypeEnum }


export interface IWizardState {
    wizardMode: WizardModeEnum;
    wizardView: WizardViewEnum;
    shapeEditorCanSave: boolean;
    cameFromMultiSelect: boolean;

    loading: boolean;
    partPreferences: IPartPreferences | null;
    itemInfo: ILineItemInfo | null;
    webDesigner: IWebDesigner | null;
    displayViewSettings: IDisplayViewSettings | null;
    objectView: ViewTypeEnum | null;
    sysInfo: ISysInfo | null;
    wizardStateKey: number;

    userPreferences: IUserPreferences;
    options: Array<ICode | IQuestion> | null;
    groups: Array<IQuestionGroup>;
    validations: IValidationInfo | null;
    focusedCode: ICode | null;
    prices: ILineItemPriceInfo | null;
    sequencedWizardInfo: ISequencedWizardInfo | null;
}

const initialState: IWizardState = {
    wizardMode: WizardModeEnum.Standard,
    wizardView: WizardViewEnum.Wizard,
    shapeEditorCanSave: false,
    cameFromMultiSelect: false,

    loading: true,
    partPreferences: null,
    itemInfo: null,
    webDesigner: null,
    displayViewSettings: null,
    objectView: null,
    sysInfo: null,
    wizardStateKey: 0,

    userPreferences: { showTiled: true, groupDisplayType: GroupDisplayTypeEnum.Expander, enableMobileKeyboard: false },
    options: null,
    groups: [],
    validations: null,
    focusedCode: null,
    prices: null,
    sequencedWizardInfo: null,
}
export type nullish = null | undefined;

const WizardReducer: React.Reducer<IWizardState, WizardActionType> = (wizInfo: IWizardState, action) => {

    switch (action.action) {
        case WizardActionEnum.SetWizardMode:
            return { ...wizInfo, wizardMode: action.value };
        case WizardActionEnum.MergeState:
            return { ...wizInfo, ...action.value };
        case WizardActionEnum.SetWizardView:
            return { ...wizInfo, wizardView: action.value };
        case WizardActionEnum.SetShapeEditorCanSave:
            return { ...wizInfo, shapeEditorCanSave: action.value };
        case WizardActionEnum.SetCameFromMultiSelect:
            return { ...wizInfo, cameFromMultiSelect: action.value };
        case WizardActionEnum.SetObjectView:
            return { ...wizInfo, objectView: action.value }
    }

    return wizInfo;
};

export class WizardActions {

    #dispatcher: React.Dispatch<WizardActionType>;
    #tm: ITranslationManager;

    constructor(dispatcher: React.Dispatch<WizardActionType>, tm: ITranslationManager) {
        this.#dispatcher = dispatcher;
        this.#tm = tm;
    }

    public SwitchToStandard() {
        this.#dispatcher({ action: WizardActionEnum.SetWizardMode, value: WizardModeEnum.Standard });
    }

    public SwitchToMode(value: WizardModeEnum) {
        this.#dispatcher({ action: WizardActionEnum.SetWizardMode, value });
    }

    public SwitchToMultiSelect() {
        this.#dispatcher({ action: WizardActionEnum.SetWizardMode, value: WizardModeEnum.MultiSelect });

    }

    public SwitchToSequencedWizard() {
        this.#dispatcher({ action: WizardActionEnum.SetWizardMode, value: WizardModeEnum.SequencedWizard });
    }

    public SwitchToView(value: WizardViewEnum) {
        this.#dispatcher({ action: WizardActionEnum.SetWizardView, value });
    }

    public SetShapeEditorCanSave(value: boolean) {
        this.#dispatcher({ action: WizardActionEnum.SetShapeEditorCanSave, value });
    }

    public SetCameFromMultiSelect(value: boolean) {
        this.#dispatcher({ action: WizardActionEnum.SetCameFromMultiSelect, value });
    }

    public async SetObjectViewAsync(value: ViewTypeEnum, odKey: number) {
        await OptionsAPI.SetDefaultOptionsWizardDisplayView(value, odKey);
        this.#dispatcher({ action: WizardActionEnum.SetObjectView, value });
    }

    public MergeState(newState: Partial<IWizardState>) {
        this.#dispatcher({ action: WizardActionEnum.MergeState, value: newState });
    }

    public ClearState() {
        this.#dispatcher( { action: WizardActionEnum.MergeState, value: initialState });
    }

}


/*Create context for state and reducer dispatch*/
export const WizardDispatchContext = React.createContext<WizardActions | null>(null);
export const WizardContext = React.createContext<IWizardState>(initialState);


/*Create provider*/
export const WizardContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const tm = useTranslations();
    const [state, dispatch] = React.useReducer(WizardReducer, initialState);
    const actions = React.useMemo<WizardActions>(() => {
        return new WizardActions(dispatch, tm);
    }, [dispatch, tm]);

    return <WizardContext.Provider value={state}>
        <WizardDispatchContext.Provider value={actions}>
            {props.children}
        </WizardDispatchContext.Provider>
    </WizardContext.Provider>
}

export default WizardContext;
