export enum ResponseTypeEnum {
    Ok,
    BadRequest,
    ServerError,
    Redirect, //301
    Found, //302
    UnAuthorized,
    Unknown,
    Forbidden,
    ServiceUnavailable
}

export default class WebResponse<T> {

    public Result!: T;
    public Message!: string;
    public AuthRequired!: boolean;
    public BadRequest: boolean;
    private responseType: ResponseTypeEnum;

    public Location!:string;
    public FileName!: string;

    constructor(responseType: ResponseTypeEnum) {
        this.responseType = responseType;
        this.BadRequest = (responseType === ResponseTypeEnum.BadRequest);
    }

    public GetResponseType(): ResponseTypeEnum { 
        return this.responseType; 
    }

    public IsOk = () => this.responseType === ResponseTypeEnum.Ok
}
