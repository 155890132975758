import LabeledText from "components/Common/LabeledText";
import { ControlTypeEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import { IPreference } from "../../helpers/interfaces";

interface IProps {
    preference: IPreference
}

const PreferenceInfo: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    switch (props.preference.type) {
        case ControlTypeEnum.Checkbox: {
            return <LabeledText
                label={tm.Get(props.preference.description)}
                text=""
                isChecked={props.preference.value === '1'}
            />
        }
        case ControlTypeEnum.Textbox: {
            return <LabeledText
                label={tm.Get(props.preference.description)}
                text={props.preference.value}
            />
        }
        case ControlTypeEnum.Combobox: {

            const preference = props.preference.comboBoxListItems.find(item => item.itemKey === parseInt(props.preference.value));
            const text = preference ? tm.Get(preference.description) : "";

            return <LabeledText
                label={tm.Get(props.preference.description)}
                text={text}
            />
        }
    }
}

export default PreferenceInfo;