import { Box } from "@mui/material";
import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import LineItemCommands, { ILineItemCommandEvents } from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemCommands";
import { ILineItem } from "helpers/interfaces";

export default class LineItemCommandsColumn extends LineItemColumn {

    events: ILineItemCommandEvents;

    public constructor(columnName: string, headerText: string, width: string, events: ILineItemCommandEvents) {
        super(columnName, headerText, width);
        this.events = events;
    }

    public CellContent({ lineItemGridProps, permissions }: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {

        return <>
            <Box pl={1}>
                <LineItemCommands
                    lineItem={lineItem}
                    lineItemGridProps={lineItemGridProps}
                    events={this.events}
                />
            </Box>
        </>;
    }

    public FooterContent(quoteProps: IQuoteLevelProps): JSX.Element {
        return <></>;
    }
}
