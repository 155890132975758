import * as React from "react";

import { ISubLineItemInfo, ISubLineItemPartInfo, ILineItemInfo } from "../interfaces";
import { useTranslations } from "@fenetech/translations";
import { FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Explore } from "@mui/icons-material";
import { WizardModeEnum, WizardViewEnum } from "components/OptionsWizard/WizardContext";
import { useWizardStateActions } from "./useWizardState";
import useWizardInteractions from "../useWizardInteractions";


interface IProps {
    sliInfo: ISubLineItemInfo;
    itemInfo: ILineItemInfo;
    mode: WizardModeEnum;
}

function getPartKeyString(partNo: string, partNoSuffix: string) {
    if (!partNo) { partNo = ""; }
    if (!partNoSuffix) { partNoSuffix = ""; }
    return partNo + String.fromCharCode(30) + partNoSuffix;
}

const PartSelector: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const wizardActions = useWizardStateActions();
    const wizardInteractions = useWizardInteractions();

    const handleProductNavigator = (e: React.MouseEvent<any>) => {
        wizardActions?.SwitchToView(WizardViewEnum.ProductNavigator);
    }

    const handlePartChange = (e: SelectChangeEvent<string>) => {
        let newValue = e.target.value;
        let pnpns: Array<string> = newValue.split(String.fromCharCode(30));

        let partNo: string = pnpns[0];
        let partNoSuffix: string = pnpns[1];

        wizardInteractions.ChangeDesignerPartAsync(partNo, partNoSuffix);
    }

    const getPartKey = (pi: ISubLineItemPartInfo) => {
        return getPartKeyString(pi.partNo, pi.partNoSuffix);
    }

    let ii: ISubLineItemInfo = props.sliInfo;

    if (ii && ii.availableParts && ii.availableParts.length > 0) {

        let selectedPartKey: string = getPartKeyString(ii.partNo, ii.partNoSuffix);
        let nonePartKey: string = getPartKeyString("", "");
        let partSelected: boolean = selectedPartKey !== nonePartKey;
        let disableSelection: boolean = ii.availableParts.length === 1 && partSelected


        /*We have to use sx to set the color, because the color attritute only supports main/primary/warning/etc */
        return <FormControl fullWidth sx={{ color: "inherit" }}>
            <InputLabel sx={{ color: "inherit" }}  >{tm.Get("Part")}</InputLabel>

            <Select
                disabled={disableSelection}
                sx={{ color: "inherit" }}
                value={selectedPartKey}
                label={tm.Get("Part")}
                fullWidth
                startAdornment={ii.availableParts.length > 1 &&
                    <IconButton sx={{ color: "inherit" }} onClick={handleProductNavigator} >
                        <Explore />
                    </IconButton>
                }
                onChange={handlePartChange}
            >
                {ii.requiresPart && <MenuItem key={"KREQ"} value={nonePartKey}  >{tm.Get("{Required}")}</MenuItem>}
                {!ii.requiresPart && <MenuItem key={"KNONE"} value={nonePartKey}>{tm.Get("{None}")}</MenuItem>}
                {ii.availableParts.map((pi: ISubLineItemPartInfo) =>
                    <MenuItem key={getPartKey(pi)} value={getPartKey(pi)}>{pi.part}</MenuItem>
                )}

            </Select>
        </FormControl>

    } else
        return null;
}

export default PartSelector;