import * as React from "react";

import { IQuestion, ICode } from "../interfaces";
import OptionCodeImage from "./OptionCodeImage";

import { PhotoSizeSelectActual } from "@mui/icons-material";
import { IconButton, Container, Dialog, DialogTitle, Grid } from "@mui/material";

interface IOptionImagePickerProps {
    question: IQuestion;
}

const OptionImagePicker: React.FC<IOptionImagePickerProps> = (props: IOptionImagePickerProps) => {

    const [state, setState] = React.useState<{ IsPopupOpen: boolean }>({ IsPopupOpen: false });

    const togglePopup = (openParam: boolean) => {
        setState({ IsPopupOpen: openParam });
    };

    const openPopup = () => {
        togglePopup(true);
    };

    const closePopup = () => {
        togglePopup(false);
    };

    const q: IQuestion = props.question;

    return <Container disableGutters>
        <IconButton size="small" onClick={openPopup}>
            <PhotoSizeSelectActual fontSize="inherit" />
        </IconButton>

        <Dialog
            open={state.IsPopupOpen}
            onClose={closePopup}
        >
            <DialogTitle>{q.question}</DialogTitle>

            <Grid container sx={{ flexWrap: "wrap", padding: "4px" }} >
                {q.codes && q.codes.filter((c: ICode) => c.visible).map((c: ICode) =>
                    <OptionCodeImage code={c} key={c.code} closePopup={closePopup} />
                )}
            </Grid>
        </Dialog>

    </Container>;

}

export default OptionImagePicker;