import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useApplicationInfo from 'helpers/context/Application/useApplicationInfo';
import { useTranslations } from '@fenetech/translations';
import * as React from 'react';
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

export enum OrderDataTypeEnum {
  Orders = 1,
  Pickups = 2,
  RMAs = 3
}

interface IProps {

}

const SelectOrderType: React.FC<IProps> = (props: IProps) => {

  const tm = useTranslations();
  const appInfo = useApplicationInfo();
  const { dashboardOrderType } = useSelectionValuesData();
  const selectionValuesActions = useSelectionValuesActions();

  const handleChange = (e: SelectChangeEvent<OrderDataTypeEnum>) => {
    const type = e.target.value as unknown as OrderDataTypeEnum;
    selectionValuesActions.SetDashboardOrderType(type);
  };

  return <>
    <Select
      value={dashboardOrderType}
      onChange={handleChange}
    >

      <MenuItem value={OrderDataTypeEnum.Orders}>{tm.Get("Orders")}</MenuItem>
      {appInfo.rmaUnlocked && <MenuItem value={OrderDataTypeEnum.Pickups}>{tm.Get("Pickups")}</MenuItem>}
      {appInfo.rmaUnlocked && <MenuItem value={OrderDataTypeEnum.RMAs}>{tm.Get("RMAs")}</MenuItem>}

    </Select>

  </>

};

export default SelectOrderType;