import React from 'react';
import usePageActions from "./usePageActions";
/*
    Use this hook to set the wait overlay
*/

export interface IShowWait {
    Show: (value: boolean) => void;
}


export default function useWait(): IShowWait {

    const pageActions = usePageActions();

    const showWait: IShowWait = {
        Show: (value: boolean) => {
            pageActions.SetWaitVisible(value);
        }
    };

    React.useEffect(() => {
        return () => {
            pageActions.SetWaitVisible(false);
        };
    }, [pageActions]);

    return showWait;

}
