import { Info, ReportGmailerrorred, Warning } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import * as React from "react";

import { PartValidationTypeEnum } from "../enum";
import { IValidationInfoItem } from "../interfaces";

const ItemValidationItem: React.FC<{ vi: IValidationInfoItem }> = (props: { vi: IValidationInfoItem }) => {

    const validationIcon = () => {
        switch (props.vi.type) {
            case PartValidationTypeEnum.Info:
                return <Info color="secondary" />
            case PartValidationTypeEnum.Block:
                return <ReportGmailerrorred color="error" />
            case PartValidationTypeEnum.Exception:
                return <Warning color="warning" />
        }
    };

    return <Stack direction="row" spacing={1} alignItems="center">
        {validationIcon()}
        <Typography textAlign={"left"} >
            {props.vi.message}
        </Typography>
    </Stack>;

}

export default ItemValidationItem;