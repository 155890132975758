import { Error } from "@mui/icons-material";
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { nullish } from "components/OptionsWizard/WizardContext";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import { ThemeColorEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";
import React from 'react';

interface IProps {
    hasExceptions: boolean,
    quoteContact: string|nullish,
    customerInactive: boolean,
    parentCustomerInactive: boolean,
}

const OrderBlockReasonAction: React.FC<IProps> = ({ hasExceptions, quoteContact, customerInactive, parentCustomerInactive }: IProps) => {

    const tm = useTranslations();
    const appInfo = useApplicationInfo();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleOpen = React.useCallback(() => setIsOpen(true), [setIsOpen]);
    const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);

    const requireQuoteContact: boolean = (appInfo !== null && appInfo.parameters.requireQuoteContact);
    const hasBlock = (hasExceptions || customerInactive || parentCustomerInactive || (requireQuoteContact && !quoteContact))

    if (hasBlock) {

        return <>

            <Tooltip title={tm.Get("Click icon to display message")}>
                <IconButton color={ThemeColorEnum.Primary} onClick={handleOpen} size="small">
                    <Error fontSize="small" />
                </IconButton>
            </Tooltip>

            <Dialog
                open={isOpen}
                onClose={handleClose}
            >
                <DialogTitle>{tm.Get("Exceptions")}</DialogTitle>
                
                <DialogContent>

                    {requireQuoteContact && !quoteContact && <>
                        <DialogContentText>
                            {tm.Get("Quote Contact is required")}
                        </DialogContentText>
                    </>}

                    {customerInactive && <>
                        <DialogContentText>
                            {tm.Get("This customer is inactive")}
                        </DialogContentText>
                    </>}

                    {parentCustomerInactive && <>
                        <DialogContentText>
                            {tm.Get("This site is inactive")}
                        </DialogContentText>
                    </>}

                    {hasExceptions && <>
                        <DialogContentText>
                            {tm.Get("Quote has line item exceptions")}
                        </DialogContentText>
                    </>}

                </DialogContent>

            </Dialog>

        </>;

    } else {
        return <></>;
    }

}

export default OrderBlockReasonAction;