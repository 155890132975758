import * as React from "react";

import QuestionHeader from "./QuestionHeader";
import NonTiledSingleOption from "./NonTiledSingleOption";

import { IQuestion, ICode } from "../interfaces";
import { Stack, Grid } from "@mui/material";
import OptionGroupBox from "components/OptionsWizard/OptionsWizard/OptionGroupBox";

interface IQuestionProps {
    question: IQuestion,
    readOnlyMode: boolean
}


const NonTiledSingleOptionQuestion: React.FC<IQuestionProps> = (props: IQuestionProps) => {

    const q: IQuestion = props.question;
    const c: ICode = q.codes[0];

    if (q.locked && !c.selected) {
        //The question is locked with nothing selected, hide the question
        return null
    } else {
        return <Stack direction="row" alignItems="center" >
            <Grid item xs>
                <OptionGroupBox label={q.description}
                    id="NTSOQ_GROUP"
                    disabled={q.locked || props.readOnlyMode}
                    startAdornment={<QuestionHeader question={q} showImageSelection={true} readOnlyMode={props.readOnlyMode} />}
                >
                    <NonTiledSingleOption code={c} locked={q.locked} isWithinMultiSelect={false} readOnlyMode={props.readOnlyMode} />
                </OptionGroupBox>
            </Grid>
        </Stack>
    }
}

export default NonTiledSingleOptionQuestion;

