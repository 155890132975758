import React, { useEffect, useState } from "react";

import { Container, Grid } from "@mui/material";

import FolderTree from "components/Documents/FolderTree";
import FileList from "components/Documents/FileList";

import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { useTranslations } from "@fenetech/translations";
import { IFolder } from "helpers/interfaces";
import DocumentsAPI from "./DocumentsAPI";

const Documents: React.FC<any> = (props) => {

    const tm = useTranslations();
    const [selectedFolder, setSelectedFolder] = useState<string | undefined>(undefined);
    const [folderHeirarchy, setFolderHeirarchy] = useState<IFolder | undefined>(undefined);

    useWindowTitle(tm.Get("Documents"));

    useEffect(() => {
        if (!folderHeirarchy) {
            DocumentsAPI.QueryFolders().then((folder) => {
                folder.displayName = tm.Get(folder.displayName);
                setFolderHeirarchy(folder);
                setSelectedFolder("");
            });
        }
    }, [folderHeirarchy, tm]);

    return <>
        <Container>
            <Grid container marginTop={1} gap={1} display={"flex"} flexDirection={"row"}>
                <Grid item xs={12} md={3} display="flex" height="auto" width="100%">
                    <FolderTree rootFolder={folderHeirarchy} selectedFolder={selectedFolder} setSelectedFolder={setSelectedFolder} />
                </Grid>
                <Grid item xs>
                    <FileList selectedFolder={selectedFolder} />
                </Grid>
            </Grid>
        </Container>
    </>;
};

export default Documents;
