import React, { useCallback, useEffect, useState } from "react";

import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import QuotesAPI from "components/Quotes/QuotesAPI";
import useAuthInfo from "helpers/context/Authentication/useAuthInfo";
import useQuoteData from "./useQuoteData";
import useQuoteActions from "./useQuoteActions";

export const QuoteLockContext = React.createContext<number | undefined>(undefined);

export const QuoteLockContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [lockedOkey, setLockedOkey] = useState<number | undefined>(undefined);
    const appInfo = useApplicationInfo();
    const { quote } = useQuoteData();
    const quoteActions = useQuoteActions();
    const authInfo = useAuthInfo();

    const unlockQuote = useCallback((oKey: number) => {
        // Checking the token in case the unmount happened due to a Sign Out (which will delete all quote locks)
        if (oKey && authInfo.signedIn && !authInfo.timedOut) {
            QuotesAPI.DeleteQuoteLock(oKey, appInfo);
        }
    }, [authInfo, appInfo]);

    // If the quote in context is different from the one that is locked, unlock it
    useEffect(() => {
        if (quote?.oKey && lockedOkey && lockedOkey !== quote.oKey) {
            unlockQuote(lockedOkey);
        }
    }, [quote, lockedOkey, unlockQuote]);

    useEffect(() => {
        if (quote?.oKey) {
            setLockedOkey(quote.oKey);
        }
    }, [quote]);

    // Unlock the quote in context when unmounting
    useEffect(() => {
        return () => {
            if (lockedOkey) {
                unlockQuote(lockedOkey);
            }
        };
    }, [lockedOkey, unlockQuote, quoteActions]);

    return <QuoteLockContext.Provider value={lockedOkey}>
        {props.children}
    </QuoteLockContext.Provider>
}

export default QuoteLockContext;
