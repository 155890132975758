import { Alert, Container, Snackbar, Button, AlertColor } from "@mui/material";
import { IActionButton } from "helpers/context/Page/PageContext";
import { ThemeColorEnum } from "../../helpers/enums";
import useWait from "helpers/context/Page/useWait";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import React from 'react';
import { IPreferenceGroup, IPreferenceUpdate } from "../../helpers/interfaces";
import PreferenceGrouping from "./PreferenceGrouping";
import PreferencesAPI from "./PreferencesAPI";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { FormProvider, useForm } from "react-hook-form";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useFooterContent from "helpers/context/Page/useFooterContent";
import { LocalCacheHelper } from "helpers/LocalCacheHelper";
import { useTranslations, TranslationActionsAPIContext } from "@fenetech/translations";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import isNumeric from "fast-isnumeric";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";

const Preferences: React.FC = () => {

    const tm = useTranslations();
    const wait = useWait();
    const actionButtons = useActionButtons();
    const formMethods = useForm({ mode: 'onChange' });
    const { handleSubmit } = formMethods;
    const messageBox = useMessageBox();
    const navBlockerActions = useNavigationBlockerActions();

    const [preferences, setPreferences] = React.useState<IPreferenceGroup[] | null>(null);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<[text: string, alertType: AlertColor] | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);

    useNavigationBlocker(isEditMode && formMethods.formState.isDirty);
    useWindowTitle(isEditMode ? tm.GetWithParams("Edit {0}", tm.Get("Preferences")) : tm.Get("Preferences"));

    const onSubmit = async (formData: any, e: any) => {
        let preferenceUpdates: IPreferenceUpdate[] = [];
        Object.keys(formData).forEach(key => {
            if (isNumeric(key)) {
                let value: string = formData[key];
                if (typeof value === "boolean") value = value ? "1" : "0";
                let preferenceUpdate: IPreferenceUpdate = { key: Number(key), value: value };
                preferenceUpdates.push(preferenceUpdate);
            }
        });

        try {
            setIsPosting(true);
            await PreferencesAPI.UpdatePreferences(preferenceUpdates);
            await getPreferences();
            setIsEditMode(false);
            setAlert([tm.Get("Changes have been saved."), "success"]);
        } finally {
            setIsPosting(false);
            actionButtons.Remove(1);
        }
    }

    const onError = (errors: { [x: string]: any }, e: any) => {
        console.log(errors, e);
        let messages: any[] = [];
        for (const key in errors) {
            messages.push(errors[key].message);
        }
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const getPreferences = async () => {
        setIsLoading(true);
        try {
            let result = await PreferencesAPI.GetPreferences();
            if (result) {
                setPreferences(result);
            } else {
                setAlert([tm.Get("An unknown error has occurred."), "error"]);
            }
        } finally {
            setIsLoading(false);
        }
    }

    useEffectOnLoad(() => {
        getPreferences();
    });

    React.useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait]);

    React.useEffect(() => {
        actionButtons.SetBackButton(0, "/dashboard");
        if (!isEditMode) {
            const editButton: IActionButton = {
                text: tm.Get("Edit"),
                color: ThemeColorEnum.Secondary,
                disabled: (preferences === null || isLoading || isPosting),
                onClick: (() => setIsEditMode(true))
            };
            actionButtons.Set(1, editButton);
        }
        else {
            const saveButton: IActionButton = {
                text: tm.Get("Save"),
                color: ThemeColorEnum.Secondary,
                disabled: (isLoading || isPosting) ? true : false,
                type: "submit",
                form: "preferencesForm",
                onClick: () => navBlockerActions.Unblock()
            };
            const cancelButton: IActionButton = {
                text: tm.Get("Cancel"),
                color: ThemeColorEnum.Primary,
                disabled: (isLoading || isPosting) ? true : false,
                onClick: () => {
                    setIsEditMode(false);
                    actionButtons.Remove(1);
                    navBlockerActions.Unblock();
                }
            };
            actionButtons.Set(1, saveButton);
            actionButtons.Set(2, cancelButton);
        }
    }, [preferences, isEditMode, isLoading, isPosting, navBlockerActions, actionButtons, tm]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert(null);
    }

    const translationActions: any = React.useContext(TranslationActionsAPIContext)

    const clearCache = () => {
        LocalCacheHelper.ClearLocalCache();
        translationActions.ResetContext();
    }

    useFooterContent(
        <Container sx={{ margin: 1 }} >
            <Button variant="contained" color={ThemeColorEnum.Secondary} onClick={clearCache} >{tm.Get("Clear Cache")}</Button>
        </Container>
    );

    return <>
        <Snackbar open={alert !== null} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert ? alert[1] : "info"} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert ? alert[0] : ""}</Alert>
        </Snackbar>

        <Container>
            <FormProvider {...formMethods}>
                <form id="preferencesForm" onSubmit={handleSubmit(onSubmit, onError)}>
                    {preferences && preferences.map((group) => (
                        <PreferenceGrouping groupName={group.description} key={group.groupKey} isEditMode={isEditMode} preferences={group.preferences} />
                    ))}
                </form>
            </FormProvider>
        </Container>
    </>;
}

export default Preferences;
