import React, { ReactElement, ReactNode } from "react";
import NotchedOutline from "@mui/material/OutlinedInput/NotchedOutline";
import InputLabel from "@mui/material/InputLabel";

import { Grid, Stack, Theme, useTheme} from "@mui/material";


const contentWrapper = (theme) => {
  return {
    padding: theme.spacing(.75),
    position: "relative",
    "&:hover": {
      "& $notchedOutline": {
        borderColor: theme.palette.text.primary,
      }
    },
    '@media (hover: none)': {
      [`&:hover`]: {
        "& $notchedOutline": {
          borderColor: theme.palette.text.primary,
        }
      },
    },
  }
}

const content = (theme) => {
  return {
    paddingTop: theme.spacing(.3125),
    paddingRight: theme.spacing(.5),
    paddingBottom: theme.spacing(.3125),
    paddingLeft: theme.spacing(.5),
  }
};

const inputLabel = {
  position: "absolute",
  left: 0,
  top: 0,
}

const GetNotchedOutlineStyle = (theme: Theme, disabled: boolean | undefined) => {

  //https://github.com/mui-org/material-ui/blob/master/packages/mui-material/src/OutlinedInput/OutlinedInput.js
  const borderColor = theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)';

  const notchedOutline = {
    borderRadius: theme.shape.borderRadius,
    borderColor: borderColor,
  };

  const notchedOutlineDisabled = {
    borderRadius: theme.shape.borderRadius,
    borderColor: borderColor,
  };

  return (disabled ? notchedOutlineDisabled : notchedOutline) as React.CSSProperties;

}

interface Props {
  id: string;
  label: string;
  children: ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
}

export default function OptionGroupBox({ id, label, children, startAdornment, endAdornment, disabled }: Props): ReactElement {


  const theme = useTheme();


  const labelRef = React.useRef(null);


  return (

    <Stack direction="row" sx={{ position: "relative" }}>

      <InputLabel
        ref={labelRef}
        htmlFor={id}
        variant="outlined"
        sx={inputLabel}
        disabled={disabled}
        shrink
      >
        {label}
      </InputLabel>

      <Grid item container direction="row" alignItems="center" xs sx={contentWrapper}      >

        {startAdornment &&
          <Grid item xs="auto" >
            {startAdornment}
          </Grid>
        }
        <Grid item container xs id={id} sx={content}        >

          <Grid item xs>
            {children}
          </Grid>

          <NotchedOutline style={GetNotchedOutlineStyle(theme, disabled)} notched label={label} />

        </Grid>

        {endAdornment &&
          <Grid item xs="auto" justifyContent="center" mx={2}  >
            {endAdornment}
          </Grid>
        }

      </Grid>

    </Stack>
  );
}


