import { Accordion, AccordionDetails, Typography, Grid } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";

interface IProps {
    expanded: boolean,
    onExpandedChange: (event: React.SyntheticEvent, expanded: boolean) => void,
    readOnly: boolean
}

const CustomerUserDefined: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    return <>
        <Accordion expanded={props.expanded} onChange={props.onExpandedChange} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("User Defined")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    {[...Array(20)].map((e, i) => {
                        return <Grid item xs={12} sm={6} key={i}>
                            <TextFieldForm
                                fieldName={`customer.userDef${i + 1}`}
                                readOnly={props.readOnly}
                                variant="standard"
                                id={`customer.userDef${i + 1}`}
                                inputProps={{ maxLength: Constants.MaxLength.UserDefined }}
                                label={tm.GetWithParams("User Defined {0}", (i + 1).toString())}
                                placeholder={tm.GetWithParams("User Defined {0}", (i + 1).toString())}
                                fullWidth
                            />
                        </Grid>
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default CustomerUserDefined;