
import { useEffect, useState } from "react";
import { Paper, Stack, Typography } from "@mui/material";

import useOrderData from "components/Orders/useOrderData";
import { useTranslations } from "@fenetech/translations";
import OtherOnlineAckOrderLink from "components/Orders/OrderView/Footer/OtherOnlineAckOrderLink";

function OtherOnlineAckOrders() {
    const { otherUnackowledgedOrders } = useOrderData();
    const [orderToken, setOrderToken] = useState<string | undefined>(undefined);
    const tm = useTranslations();

    useEffect(() => {
        if (orderToken === undefined && otherUnackowledgedOrders && otherUnackowledgedOrders.length > 0) {
            setOrderToken(otherUnackowledgedOrders[0].token);
        }
    }, [otherUnackowledgedOrders, orderToken])

    return <>
        {otherUnackowledgedOrders && otherUnackowledgedOrders.length > 0 &&
            <Paper sx={{ display: "flex", width: "100%" }}>
                <Stack direction="column" spacing={1} margin={1} alignItems="flex-start">
                    <Typography>{tm.Get("Additional Unacknowledged Orders:")}</Typography>
                    {otherUnackowledgedOrders.map((o) =>
                        <OtherOnlineAckOrderLink key={o.token} orderNumber={o.orderNumber} token={o.token} />
                    )}
                </Stack>
            </Paper>
        }
    </>

}

export default OtherOnlineAckOrders;