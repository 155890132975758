import { GridLocaleText } from "@mui/x-data-grid";
import { useTranslations } from "@fenetech/translations";

export const useDataGridLocale = (): GridLocaleText => {

    const tm = useTranslations();

    //All fields are defined for DataGridLocalText props, however only those used in Web Sauce have translations to avoid unnecessary entries in TM.
    return {
        // Root
        noRowsLabel: tm.Get("No data available"),
        noResultsOverlayLabel: tm.Get('No results were found.'),
        errorOverlayDefaultLabel: tm.Get('An unknown error has occurred.'),

        // Density selector toolbar button text
        toolbarDensity: 'Density',
        toolbarDensityLabel: 'Density',
        toolbarDensityCompact: 'Compact',
        toolbarDensityStandard: 'Standard',
        toolbarDensityComfortable: 'Comfortable',

        // Columns selector toolbar button text
        toolbarColumns: 'Columns',
        toolbarColumnsLabel: 'Select columns',

        // Filters toolbar button text
        toolbarFilters: 'Filters',
        toolbarFiltersLabel: 'Show filters',
        toolbarFiltersTooltipHide: 'Hide filters',
        toolbarFiltersTooltipShow: 'Show filters',
        toolbarFiltersTooltipActive: (count) => count !== 1 ? `${count} active filters` : `${count} active filter`,

        // Quick filter toolbar field
        toolbarQuickFilterPlaceholder: 'Search…',
        toolbarQuickFilterLabel: 'Search',
        toolbarQuickFilterDeleteIconLabel: 'Clear',

        // Export selector toolbar button text
        toolbarExport: 'Export',
        toolbarExportLabel: 'Export',
        toolbarExportCSV: 'Download as CSV',
        toolbarExportPrint: 'Print',
        toolbarExportExcel: 'Download as Excel',

        // Columns panel text
        columnsPanelTextFieldLabel: 'Find column',
        columnsPanelTextFieldPlaceholder: 'Column title',
        columnsPanelDragIconLabel: 'Reorder column',
        columnsPanelShowAllButton: 'Show all',
        columnsPanelHideAllButton: 'Hide all',

        // Filter panel text
        filterPanelAddFilter: tm.GetWithParams('Add {0}', "Filter"),
        filterPanelDeleteIconLabel: tm.Get('Delete'),
        filterPanelLinkOperator: 'Logic operator',
        filterPanelOperators: tm.Get('Operator'),
        filterPanelOperatorAnd: tm.Get('And'),
        filterPanelOperatorOr: tm.Get('Or'),
        filterPanelColumns: tm.Get('Column'),
        filterPanelInputLabel: tm.Get('Value'),
        filterPanelInputPlaceholder: tm.Get('Value'),

        // Filter operators text
        filterOperatorContains: tm.Get("Contains"),
        filterOperatorEquals: tm.Get("Equals"),
        filterOperatorStartsWith: tm.Get("Starts with"),
        filterOperatorEndsWith: tm.Get("Ends with"),
        filterOperatorIs: 'is',
        filterOperatorNot: 'is not',
        filterOperatorAfter: 'is after',
        filterOperatorOnOrAfter: 'is on or after',
        filterOperatorBefore: 'is before',
        filterOperatorOnOrBefore: 'is on or before',
        filterOperatorIsEmpty: tm.Get('Is Empty'),
        filterOperatorIsNotEmpty: tm.Get('Is Not Empty'),
        filterOperatorIsAnyOf: tm.Get('Is Any Of'),

        // Filter values text
        filterValueAny: 'any',
        filterValueTrue: 'true',
        filterValueFalse: 'false',

        // Column menu text
        columnMenuLabel: tm.Get('Menu'),
        columnMenuShowColumns: 'Show columns',
        columnMenuFilter: tm.Get('Filter'),
        columnMenuHideColumn: 'Hide',
        columnMenuUnsort: tm.Get('Remove Sort'),
        columnMenuSortAsc: tm.Get('Sort Ascending'),
        columnMenuSortDesc: tm.Get('Sort Descending'),

        // Column header text
        columnHeaderFiltersTooltipActive: (count) => tm.Get("Filters"),
        columnHeaderFiltersLabel: 'Show filters',
        columnHeaderSortIconLabel: tm.Get('Sort'),

        // Rows selected footer text
        footerRowSelected: (count) => tm.GetWithParams("{0} row(s) selected.", count.toString()),

        // Total row amount footer text
        footerTotalRows: 'Total Rows:',

        // Total visible row amount footer text
        footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

        // Checkbox selection text
        checkboxSelectionHeaderName: 'Checkbox selection',
        checkboxSelectionSelectAllRows: 'Select all rows',
        checkboxSelectionUnselectAllRows: 'Unselect all rows',
        checkboxSelectionSelectRow: 'Select row',
        checkboxSelectionUnselectRow: 'Unselect row',

        // Boolean cell text
        booleanCellTrueLabel: 'yes',
        booleanCellFalseLabel: 'no',

        // Actions cell more text
        actionsCellMore: 'more',

        // Column pinning text
        pinToLeft: 'Pin to left',
        pinToRight: 'Pin to right',
        unpin: 'Unpin',

        // Tree Data
        treeDataGroupingHeaderName: 'Group',
        treeDataExpand: 'see children',
        treeDataCollapse: 'hide children',

        // Grouping columns
        groupingColumnHeaderName: 'Group',
        groupColumn: (name) => `Group by ${name}`,
        unGroupColumn: (name) => `Stop grouping by ${name}`,

        // Master/detail
        detailPanelToggle: 'Detail panel toggle',
        expandDetailPanel: 'Expand',
        collapseDetailPanel: 'Collapse',

        // Used core components translation keys
        MuiTablePagination: {
            labelRowsPerPage: tm.Get("Rows Per Page"),
            labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} ${tm.Get("of")} ${count}`
        },

        // Row reordering text
        rowReorderingHeaderName: 'Row reordering',

        // Aggregation
        aggregationMenuItemHeader: 'Aggregation',
        aggregationFunctionLabelSum: 'sum',
        aggregationFunctionLabelAvg: 'avg',
        aggregationFunctionLabelMin: 'min',
        aggregationFunctionLabelMax: 'max',
        aggregationFunctionLabelSize: 'size',
    }
}