import OfflineSettings from "../../../models/OfflineSettings"
import React from "react";
import API from "../../API";

export default function useOfflineSettings(): OfflineSettings | null {

    const [offlineSettings, setOfflineSettings] = React.useState<OfflineSettings | null>(null);

    React.useEffect(() => {
        if (offlineSettings === null) {
            API.GetOfflineSettings().then(o => {
                setOfflineSettings(new OfflineSettings(o));
            }).catch(() => {
                setOfflineSettings(null);
            });
        }
    }, [offlineSettings]);

    return offlineSettings;
}
