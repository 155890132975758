import { styled, TableRow, TableCell, alpha } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';

export const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
}));

export const StyledCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
}));

export const StyledRow = styled(TableRow)(({ theme }) => ({
    [`&:nth-of-type(even)`]: {
        backgroundColor: alpha(theme.palette.secondary.light, 0.1),
    }
}));