
import React from 'react';

import QuoteClassificationMethods from 'helpers/context/SelectionValues/QuoteClassifications';
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useQuoteClassifications(): QuoteClassificationMethods | undefined {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const classifications = selectionValues.quoteClassifications;

    React.useEffect(() => {

        if (!classifications) {
            actions.LoadQuoteClassificationsAsync();
        }

    }, [actions, classifications]);

    return classifications;

};

export default useQuoteClassifications;