import React, { useRef, useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, Grid, TextField } from "@mui/material";

import { ILineItem } from "helpers/interfaces";
import OKCancelControl from "components/Common/OKCancelControl";

interface IProps {
    lineItem: ILineItem;
    onCancel: () => void;
    onSubmit: (lineItem: ILineItem, newQuantity: number) => void;
}

const LineItemQuantity: React.FC<IProps> = ({ lineItem, onCancel, onSubmit }: IProps) => {

    const qtyInput = useRef<HTMLInputElement>(null);

    const [qty, setQty] = useState<number>(lineItem.quantity);

    useEffect(() => {
        if (qtyInput?.current) {
            qtyInput.current.focus();
            qtyInput.current.select();
        }
    }, [qtyInput]);

    const handleQtyChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newQty = parseInt(e.target.value);
        if (isNaN(newQty)) {
            newQty = 0;
        }
        setQty(newQty);
    };

    const handleQtyBlur = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newQty = parseInt(e.target.value);
        if (isNaN(newQty) || newQty < 1)
            newQty = 1;

        setQty(newQty);
    };

    if (!lineItem) {
        return <></>;
    }

    return <>
        <DialogContent>
            <Box display="flex" flexDirection="column" mt={1}>
                <Grid container direction="column" rowSpacing={1} justifyItems="stretch">
                    <TextField onFocus={e => e.target.select()} inputRef={qtyInput} value={qty} onChange={handleQtyChange} onBlur={handleQtyBlur} fullWidth
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                </Grid>
            </Box>
        </DialogContent>
        <DialogActions>
            <OKCancelControl okCommand={() => { onSubmit(lineItem, qty) }} cancelCommand={onCancel} />
        </DialogActions>
    </>;

}

export default LineItemQuantity;