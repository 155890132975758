import { ILineItem } from "helpers/interfaces";
import LineItemPart from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemPart";
import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";

export default class LineItemPartColumn extends LineItemColumn {

    isReadOnly: boolean;

    public constructor(columnName: string, headerText: string, width: string, isReadOnly: boolean) {
        super(columnName, headerText, width);
        this.align="left";
        this.isReadOnly = isReadOnly;
    }

    public CellContent(props: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {

        return <LineItemPart
            oKey={props.quote.oKey}
            isReadOnly={this.isReadOnly}
            lineItem={lineItem}
            includeItemNumber={false}
            variant="body2"
        />;
    }

    public FooterContent(quoteProps: IQuoteLevelProps): JSX.Element {
        return <></>;
    }
}
