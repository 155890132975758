import React from 'react';
import { Grid, TextField } from "@mui/material";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { useTranslations } from "@fenetech/translations";
import { SecurityLevelEnum } from "../../helpers/enums";
import { IProfileInfo } from "models/IProfileInfo";
import { useFormContext } from "react-hook-form";
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";
import LabeledText from "components/Common/LabeledText";
import BoxWithHeader from "components/Common/BoxWithHeader";

interface IProps {
    profileInfo: IProfileInfo | null,
    isEditMode: boolean
}

const ProfileInfo: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const { formState: { errors } } = useFormContext();
    useWindowTitle(props.isEditMode ? tm.GetWithParams("Edit {0}", tm.Get("Profile")) : tm.Get("Profile"));

    //Username and Authentication are static since they cannot be changed from this screen
    return <BoxWithHeader caption={tm.Get("Profile")}>
        <Grid container padding={1} spacing={2}>
            <Grid item xs={12} sm={6}>
                {props.isEditMode ?
                    <TextField
                        value={props.profileInfo?.userName ?? ""}
                        label={tm.Get("User Name")}
                        variant="standard"
                        disabled={true}
                        fullWidth
                    /> :
                    <LabeledText
                        label={tm.Get("User Name")}
                        text={props.profileInfo?.userName ?? ""}
                        showSkeletonWhenEmpty={true}
                    />
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                {props.isEditMode ?
                    <TextField
                        value={props.profileInfo?.userName ?? ""}
                        label={tm.Get("Authentication Type")}
                        variant="standard"
                        disabled={true}
                        fullWidth
                    /> :
                    <LabeledText
                        label={tm.Get("Authentication Type")}
                        text={props.profileInfo ? (props.profileInfo.authenticationType === SecurityLevelEnum.Administrator ? tm.Get("Administrator") : tm.Get("Standard")) : ""}
                        showSkeletonWhenEmpty={true}
                    />
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFieldForm
                    fieldName="firstName"
                    readOnly={!props.isEditMode}
                    requiredRule={{ value: true, message: tm.GetWithParams("{0} Required", tm.Get("First Name")) }}
                    validateValue={{
                        isNotEmpty: (value: string) => new RegExp("^(?!\\s*$).+").test(value) || tm.GetWithParams("{0} cannot be blank.", tm.Get("First Name"))
                    }}
                    error={errors.firstName?.message ? true : false}
                    helperText={errors.firstName?.message}
                    variant="standard"
                    id="profile.userName"
                    inputProps={{ maxLength: Constants.MaxLength.FirstName }}
                    label={tm.Get("First Name")}
                    placeholder={tm.Get("First Name")}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFieldForm
                    fieldName="lastName"
                    readOnly={!props.isEditMode}
                    requiredRule={{ value: true, message: tm.GetWithParams("{0} Required", tm.Get("Last Name")) }}
                    validateValue={{
                        isNotEmpty: (value: string) => new RegExp("^(?!\\s*$).+").test(value) || tm.GetWithParams("{0} cannot be blank.", tm.Get("Last Name"))
                    }}
                    error={errors.lastName?.message ? true : false}
                    helperText={errors.lastName?.message}
                    variant="standard"
                    id="profile.lastName"
                    inputProps={{ maxLength: Constants.MaxLength.LastName }}
                    label={tm.Get("Last Name")}
                    placeholder={tm.Get("Last Name")}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldForm
                    fieldName="emailAddress"
                    readOnly={!props.isEditMode}
                    requiredRule={{ value: true, message: tm.GetWithParams("{0} Required", tm.Get("Email Address")) }}
                    validateValue={{
                        isNotEmpty: (value: string) => new RegExp("^(?!\\s*$).+").test(value) || tm.GetWithParams("{0} cannot be blank.", tm.Get("Email Address"))
                    }}
                    error={errors.emailAddress?.message ? true : false}
                    helperText={errors.emailAddress?.message}
                    variant="standard"
                    id="profile.emailAddress"
                    inputProps={{ maxLength: Constants.MaxLength.EmailAddress }}
                    label={tm.Get("Email Address")}
                    placeholder={tm.Get("Email Address")}
                    fullWidth
                />
            </Grid>
        </Grid>
    </BoxWithHeader>
}

export default ProfileInfo;