
import React from 'react';
import { IMeasurementType } from 'helpers/interfaces';
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useMeasurementTypes(): IMeasurementType[] | undefined {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const measurementTypes = selectionValues.measurementTypes;

    React.useEffect(() => {

        if (!measurementTypes) {
            actions.LoadMeasurementTypesAsync();
        }

    }, [actions, measurementTypes]);

    return measurementTypes;

};

export default useMeasurementTypes;
