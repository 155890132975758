import React, { useState, useEffect, useCallback } from "react";
import { Container, Box, Grid, TextField, MenuItem, useTheme } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";

import useIsMobile from "helpers/hooks/useIsMobile";
import { useTranslations } from "@fenetech/translations";
import { generateOrderHistoryColumns } from "./OrderHistoryColumns";
import { IOrderHistoryItem } from "models/IOrderHistory";
import HistoryAPI from "./HistoryAPI";
import useUserInfo from "helpers/context/User/useUserInfo";
import { UserPermissionTypes } from "helpers/enums";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useWait from "helpers/context/Page/useWait";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import OrderHistoryControls from "./OrderHistoryControls";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

const OrderHistory = () => {

    const isMobile = useIsMobile();
    const theme = useTheme();
    const tm = useTranslations();
    const user = useUserInfo();
    const userCanViewCost = user.HasPermission(UserPermissionTypes.ViewCost);
    const cf = useCurrencyFormatter();
    const wait = useWait();
    const rowsPerPage = useRowsPerPage("OrderHistory");

    const today = DateTime.now();
    const lastMonth = today.minus({ months: 1 });

    const [startDate, setStartDate] = useState<DateTime>(lastMonth);
    const [endDate, setEndDate] = useState<DateTime>(today);
    const [gridView, setGridView] = useState<string>("o");
    const [openOrders, setOpenOrders] = useState<IOrderHistoryItem[]>([]);
    const [completeOrders, setCompleteOrders] = useState<IOrderHistoryItem[]>([]);

    const [startDateError, setStartDateError] = useState<boolean>(false);
    const [endDateError, setEndDateError] = useState<boolean>(false);
    const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

    useWindowTitle(tm.Get("Order History"));

    useEffect(() => {
        if (!startDate || !endDate || !startDate.isValid || startDate.year < 1753 || startDate.year > 9999 || startDate.startOf("day") > endDate.startOf("day")) {
            setStartDateError(true);
        } else {
            setStartDateError(false);
        }
        if (!startDate || !endDate || !endDate.isValid || endDate.year < 1753 || endDate.year > 9999 || startDate.startOf("day") > endDate.startOf("day")) {
            setEndDateError(true);
        } else {
            setEndDateError(false);
        }
    }, [startDate, endDate]);

    const handleGridViewChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setGridView(e.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        if (!startDateError && !endDateError) {
            wait.Show(true);
            HistoryAPI.QueryOrderHistory(startDate, endDate).then(oh => {
                setOpenOrders(oh.openOrders);
                setCompleteOrders(oh.completeOrders);
                setSubmittedOnce(true);
                wait.Show(false);
            });
        }
    }, [startDate, endDate, startDateError, endDateError, wait]);

    const getRowId: GridRowIdGetter = (row: GridRowModel) => row["oKey"];
    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Container maxWidth="xl">
            <Box p={1} gap={1} mt={1}>
                <Grid container direction="column" rowSpacing={3} justifyItems="stretch">
                    <Grid item>
                        <OrderHistoryControls
                            startDate={startDate}
                            endDate={endDate}
                            startDateError={startDateError}
                            endDateError={endDateError}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            handleSubmit={handleSubmit}
                        />
                    </Grid>
                    <Grid item>
                        {submittedOnce && 
                            <TextField value={gridView} onChange={handleGridViewChange} select sx={{width: isMobile ? 1 : (1 / 3)}}>
                                <MenuItem value="o">{tm.Get("Open Orders")}</MenuItem>
                                <MenuItem value="c">{tm.Get("Complete Orders")}</MenuItem>
                            </TextField>
                        }
                    </Grid>

                    <Grid item>
                        {gridView === "o" && submittedOnce && 
                            <CustomDataGridPro
                                onPageSizeChange={onPageSizeChange}
                                pageSize={rowsPerPage.pageSize}
                                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                                getRowId={getRowId}
                                columns={generateOrderHistoryColumns(tm, openOrders, theme, isMobile, cf, userCanViewCost, user)}
                                rows={openOrders}
                            />
                        }

                        {gridView === "c" && submittedOnce && 
                            <CustomDataGridPro
                                onPageSizeChange={onPageSizeChange}
                                pageSize={rowsPerPage.pageSize}
                                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                                getRowId={getRowId}
                                columns={generateOrderHistoryColumns(tm, completeOrders, theme, isMobile, cf, userCanViewCost, user)}
                                rows={completeOrders}
                            />
                        }
                    </Grid>
                            
                </Grid>
            </Box>
        </Container>
    </>
}

export default OrderHistory;