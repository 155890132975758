import React from "react";
import { Button } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

interface IProps {
    moreText: string,
    lessText: string,
    viewMore: boolean,
    setViewMore: React.Dispatch<React.SetStateAction<boolean>>,
}

function MoreLessButton({ moreText, lessText, viewMore, setViewMore }: IProps) {

    return <>
        {!viewMore &&
            <Button variant="text" aria-label="more" endIcon={<ExpandMore />} onClick={() => { setViewMore(true) }} >
                {moreText}
            </Button>
        }
        {viewMore &&
            <Button variant="text" aria-label="less" endIcon={<ExpandLess />} onClick={() => { setViewMore(false) }}>
                {lessText}
            </Button>
        }
    </>;

}

export default MoreLessButton;