import * as React from "react";

import { ICode } from "../interfaces";
import APIHelper from "helpers/APIHelper";
import { Stack, Grid } from "@mui/material";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import useWizardInteractions from "../useWizardInteractions";
import WizardHelper from "../WizardHelper";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";

interface ICodeProps {
    code: ICode;
    locked: boolean;
    readOnlyMode: boolean;
}

const TiledCode: React.FC<ICodeProps> = (props: ICodeProps) => {

    const appInfo = useApplicationInfo();
    const wizardInteractions = useWizardInteractions();

    const handleCodeImageClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        const c: ICode = props.code;

        if (!c.locked && !props.readOnlyMode) {
            //Need to ignore this if the question is locked since you cannot disable 'onClick' in <div>
            if (c.selected)
                wizardInteractions.RemoveCodeAsync(c.sequence, c.code);
            else
                wizardInteractions.SelectCodeAsync(c.sequence, c.code);
        }
    }

    const handleCodeFocus = () => {
        wizardInteractions.SetFocusedCode(props.code);
    }

    const handleCodeBlur = () => {
        wizardInteractions.SetFocusedCode(null);
    }

    const c: ICode = props.code;

    const imageSource: string = APIHelper.GetBaseURL() + `api/images/OptionImage?optionCode=${c.code}&thumbnail=true`;
    
    const backgroundStyle = c.selected ? {
        backgroundColor: "primary.light",
        borderRadius: 2,
        border: "solid",
        borderColor: "transparent",
        color: "primary.contrastText"
    } : {}

    return <Grid flexDirection="column" alignItems="center" display="inline-flex" p={.5} m={.5} textAlign="center"
        onClick={handleCodeImageClick}
        key={c.sequence + "_" + c.code}
        width={(theme) => theme.spacing(18)}
        minHeight={(theme) => theme.spacing(18)}
        onFocus={handleCodeFocus}
        onBlur={handleCodeBlur}
        sx={{
            cursor: (props.locked || props.readOnlyMode) ? "default" : "pointer",
            ...backgroundStyle
        }}
    >
        <div>
            {c.imageExists ?
                <input type="image"
                    width="82"
                    height="82"
                    src={imageSource}
                    disabled={props.locked || props.readOnlyMode}
                />
                :
                <Stack sx={{ height: "88px", display: "flex", lineHeight: "normal" }} justifyContent="center" alignItems="center" >
                    <InsertPhotoIcon fontSize="large" />
                </Stack>
            }
        </div>
        <div onClick={handleCodeImageClick} style={{ wordWrap: "break-word", cursor: (props.locked || props.readOnlyMode) ? "default" : "pointer" }} >
            {WizardHelper.GetOptionCodeDescription(c, appInfo.parameters.hideOptionCodes)}
        </div>

    </Grid>;


}

export default TiledCode;
