import React from 'react';
import { TableCell, TableHead, TableRow, styled } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';

import { useTranslations } from "@fenetech/translations";

interface IProps {
    viewCost: boolean;
}

const SurchargeHeader: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
    }));

    return <>
        <TableHead>
            <TableRow>
                <StyledHeaderCell sx={{ minWidth: "150px" }}>
                    {tm.Get("Surcharge")}
                </StyledHeaderCell>
                {props.viewCost &&
                    <StyledHeaderCell sx={{ width: "150px", minWidth: "150px" }} align="right">
                        {tm.Get("Cost")}
                    </StyledHeaderCell>
                }
                <StyledHeaderCell sx={{ width: "150px", minWidth: "150px" }} align="right">
                    {tm.Get("Price")}
                </StyledHeaderCell>
            </TableRow>
        </TableHead>
    </>;
}

export default SurchargeHeader;