
import APIHelper from "../../helpers/APIHelper";
import { IPreferenceGroup, IPreferenceUpdate } from "../../helpers/interfaces";

export default class PreferencesAPI {

    public static async GetPreferences(): Promise<IPreferenceGroup[]> {
        let query = "api/preferences/preferences";

        const response = await APIHelper.GetAsync<IPreferenceGroup[]>(query);
        return response.Result;
    }

    public static async UpdatePreferences(preferences: IPreferenceUpdate[]): Promise<void> {
        let query = "api/preferences/preferences";

        await APIHelper.PostAsync<boolean>(query, {}, preferences);
    }
}