import APIHelper from "helpers/APIHelper";
import { IAdvertisement, IDashboardQuote, IDashboardOrder, IDashboardPickup, IDashboardRMA } from "helpers/interfaces";

export default class DashboardAPI {


    public static QueryOpenOrders(): Promise<IDashboardOrder[]> {
        return APIHelper.GetAsync<IDashboardOrder[]>('api/orders/list').then(wr => {
            return wr.Result;
        });
    }

    public static QueryOpenPickups(): Promise<IDashboardPickup[]> {
        return APIHelper.GetAsync<IDashboardPickup[]>('api/pickups/list').then(wr => {
            return wr.Result;
        });
    }

    public static QueryOpenRMAs(): Promise<IDashboardRMA[]> {
        return APIHelper.GetAsync<IDashboardRMA[]>('api/rmas/list').then(wr => {
            return wr.Result;
        });
    }

    public static QueryCompanyQuotes(): Promise<IDashboardQuote[]> {
        return APIHelper.GetAsync<IDashboardQuote[]>('api/quotes/list').then(wr => {
            return wr.Result;
        });
    }

    public static QueryCustomerQuotes(): Promise<IDashboardQuote[]> {
        return APIHelper.GetAsync<IDashboardQuote[]>('api/quotes/list', { customer: true }).then(wr => {
            return wr.Result;
        });
    }

    public static QuerySubmittedQuotes(): Promise<IDashboardQuote[]> {
        return APIHelper.GetAsync<IDashboardQuote[]>('api/quotes/list', { submitted: true }).then(wr => {
            return wr.Result;
        });
    }

    public static QueryAdvertisements(): Promise<IAdvertisement[]> {
        return APIHelper.GetAsync<IAdvertisement[]>('api/advertisements').then(wr => {
            return wr.Result;
        })
    }
};





