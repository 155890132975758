import { Box, Typography } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";

const Footer: React.FC<any> = (props: any) => {
    const year = new Date().getFullYear();

    const isMobile = useIsMobile();
    if (isMobile) {
        return <Box mb={1} />
    }
    else {
        return <Box textAlign="center" width="100%">
            <Typography width="100%" variant="caption">
                ©{year} <strong>FeneTech</strong> | www.fenetech.com
            </Typography>
        </Box>
    }


};

export default Footer;
