import { useMemo } from "react";
import { Link, Stack, Typography } from "@mui/material";

import useApplicationInfo from "helpers/context/Application/useApplicationInfo";

import { useTranslations } from "@fenetech/translations";
import useQuoteData from "components/Quotes/useQuoteData";

interface IProps {
    variant: any,
    onSurchargeClick: () => void,
    onTaxClick: () => void,
}

const QuoteTotalLabels: React.FC<IProps> = ({ variant, onSurchargeClick, onTaxClick }: IProps) => {

    const tm = useTranslations();
    const appInfo = useApplicationInfo();
    const { lineItems } = useQuoteData();

    // Prevent any of the links in this section from acting as "submit"
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    const surchargesExist = useMemo(() => {
        const surchargeCount = lineItems?.filter(li => li.isSurchargePart).length ?? 0;
        return surchargeCount > 0;
    }, [lineItems]);

    return <>
        <Stack direction="column" onClick={preventDefault}>
            {appInfo.surchargesConfigured &&
                surchargesExist ?
                <Link component="button" textAlign="right" underline="hover" onClick={onSurchargeClick} >
                    <Typography variant={variant} fontWeight="bold" noWrap >
                        {tm.Get("Surcharge:")}
                    </Typography>
                </Link>
                :
                <Typography variant={variant} textAlign="right" fontWeight="bold" noWrap >
                    {tm.Get("Surcharge:")}
                </Typography>
            }
            <Typography variant={variant} textAlign="right" fontWeight="bold" noWrap >
                {tm.Get("Subtotal:")}
            </Typography>
            <Link component="button" textAlign="right" underline="hover" onClick={onTaxClick} >
                <Typography variant={variant} fontWeight="bold" noWrap >
                    {tm.Get("Tax:")}
                </Typography>
            </Link>
            <Typography variant={variant} textAlign="right" fontWeight="bold" noWrap >
                {tm.Get("Total:")}
            </Typography>
        </Stack>
    </>;

};

export default QuoteTotalLabels;