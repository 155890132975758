
import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";
import LineItemTotal from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemTotal";
import OrderTotals from "components/Orders/OrderView/LineItems/Fields/OrderTotals";

import { IOrderLineItem } from "helpers/interfaces";

export default class LineItemTotalsColumn extends LineItemColumn {

    public constructor(columnName: string, headerText: string, width: string) {
        super(columnName, headerText, width);
        this.align = "right";
    }

    public CellContent(props: IOrderLevelProps, lineItem: IOrderLineItem): JSX.Element {
        return <LineItemTotal
            lineItem={lineItem}
            variant="body2"
        />
    }

    public FooterContent(props: IOrderLevelProps): JSX.Element {

        return <OrderTotals
            variant="body2"
        />;

    }
}

