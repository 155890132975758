import React, { useMemo } from "react";

import useQuoteData from "components/Quotes/useQuoteData";
import { GlobalOptionsContextProvider } from "components/GlobalOptions/GlobalOptionsContext";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { useTranslations } from "@fenetech/translations";
import GlobalOptionsWizard from "components/GlobalOptions/GlobalOptionsWizard";

const GlobalOptionsWizardMain: React.FC<any> = (props) => {

    const { quote, permissions } = useQuoteData();
    const tm = useTranslations();
    const title = useMemo(() => {
        if (quote) {
            return (`${tm.Get("Global Option Changes")} - #${quote.orderNumber ?? ""}`);
        }
        return "";
    }, [quote, tm]);

    useWindowTitle(title);

    if (!quote || !permissions || !permissions.editQuote) {
        return null;
    }

    return <>
        <GlobalOptionsContextProvider quote={quote}>
            <GlobalOptionsWizard quote={quote} />
        </GlobalOptionsContextProvider>
    </>;
};

export default GlobalOptionsWizardMain;
