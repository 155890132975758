import PageContext from 'helpers/context/Page/PageContext';
import { Backdrop, CircularProgress } from "@mui/material";

function WaitOverlay () {

return <>
    <PageContext.Consumer>
    {pageInfo => (
        <Backdrop
            open={pageInfo.waitVisible}
            onClick={() => {}}
            style={{transitionDelay:".75s", zIndex:99999}}
        >     
        <CircularProgress color="inherit" />
        </Backdrop>
        )
    }   
    </PageContext.Consumer>
</>

}

export default WaitOverlay;