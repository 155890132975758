import * as React from "react";

import QuestionHeader from "./QuestionHeader";
import TiledOption from "./TiledOption";
import TiledOptionValueList from "./TiledOptionValueList";
import { IQuestion, ICode } from "../interfaces";
import { Grid, Stack } from "@mui/material";
import WizardHelper from "components/OptionsWizard/WizardHelper";
import NonTiledOptionValue from "components/OptionsWizard/OptionsWizard/NonTiledOptionValue";
import OptionGroupBox from "./OptionGroupBox";

interface IQuestionProps {
    question: IQuestion;
    readOnlyMode: boolean;
}

const TiledQuestion: React.FC<IQuestionProps> = (props: IQuestionProps) => {

    const q: IQuestion = props.question;
    const c: ICode[] = q.codes

    const selectedQuestions: boolean = c.some((c: ICode) => c.selected)

    if (q.locked && !selectedQuestions) {
        //Theres is locked question with nothing selected, hide question
        return null

    } else {

        const selectedCodes = c.filter(c => c.selected && (c.userInput || c.config) && !WizardHelper.CodeHasValueList(c));

        return <OptionGroupBox
            label={q.description}
            id="TQ_GROUP"
            disabled={q.locked || props.readOnlyMode}
        >
            <Stack direction="row">
                <QuestionHeader question={q} showImageSelection={false} readOnlyMode={props.readOnlyMode} />
                <Grid container spacing={1}  >
                    <Grid item container >
                        {q.codes.filter((c: ICode) => c.visible).map((c: ICode) =>
                            <TiledOption locked={q.locked} code={c} key={c.sequence + "_" + c.code} readOnlyMode={props.readOnlyMode} />
                        )}
                    </Grid>

                    {selectedCodes.length > 0 &&
                        <Grid item container>
                            {selectedCodes.map(c => {
                                /*Using a key here, will force the component to be recreated when value is updated from the API due to RunExpressions.  This is required to refresh its internal input state variable */ 
                                return <NonTiledOptionValue key={c.value} code={c} withinTiled={false} locked={q.locked && !c.substitutePartEnableWizard} readOnlyMode={props.readOnlyMode} />
                            })}
                        </Grid>
                    }

                    <Grid item container>
                        {q.codes.filter((c: ICode) => c.visible).map((c: ICode) =>
                            <TiledOptionValueList locked={q.locked && !c.substitutePartEnableWizard} code={c} key={"VL" + c.sequence + "_" + c.code} readOnlyMode={props.readOnlyMode} />
                        )}
                    </Grid>
                </Grid>
            </Stack>

        </OptionGroupBox>

    }
}

export default TiledQuestion;
