import { GridColumns, GridComparatorFn, GridRenderCellParams, gridStringOrNumberComparator, gridDateComparator, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { ITranslationManager } from "@fenetech/translations";
import { IApplicationInfo, IDashboardOrder } from "helpers/interfaces";
import { Theme, Typography } from "@mui/material";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { IRecordWithReqDate } from "models/IDashboardOrder";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import ApiUser from "models/ApiUser";
import Format from "helpers/fv.format";

export const generateOrderColumns = (tm: ITranslationManager, data: IDashboardOrder[] | null, theme: Theme, application: IApplicationInfo, isMobile: boolean, cf: ICurrencyFormatter, user: ApiUser) => {

    if (!data || data.length === 0) return [] as GridColumns;

    let reqDateColumnHeader: string = tm.Get("Est Delivery Date");
    if (application && application.parameters.customDeliveryDateLabel) {
        reqDateColumnHeader = application.parameters.customDeliveryDateLabel;
    }

    const generator = new DataGridColumnGenerator(tm, data, theme, isMobile, cf);

    generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Order", "/orders", { hideable: false });
    generator.TryAddDateColumn("orderDate", "Date", { hideInMobile: true });
    generator.TryAddTextColumn("poNumber", "PO Number", { hideInMobile: false });
    generator.TryAddTextColumn("customerRef", "Customer Ref", { hideInMobile: false });
    generator.TryAddTotalPriceColumn({ hideInMobile: true });

    generator.AddColumn({
        field: "reqDate",
        headerName: reqDateColumnHeader,
        minWidth: generator.GetMinWidth(),
        flex: 1,
        hide: isMobile,
        sortComparator: reqDateSortComparator,
        valueGetter: (params: GridValueGetterParams) => ({
            reqDate: params.row.reqDate,
            reqDateString: params.row.reqDateString
        }),
        renderCell: (params: GridRenderCellParams<any, IRecordWithReqDate>) => {
            const reqDate = params.row.reqDate;
            const reqDateString = params.row.reqDateString;

            if (reqDate) {
                return <>
                    <Typography variant="body2">
                        {Format.FormatDate(reqDate as string)}
                    </Typography>
                </>;
            } else {
                return <>
                    <Typography variant="body2">
                        {reqDateString}
                    </Typography>
                </>;
            }
        }
    });

    generator.TryAddDateColumn("targetShipDate", "Target Ship Date", { hideInMobile: true });

    generator.TryAddStatusColumn();
    if (user.isMultiSite) {
        generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", { hideInMobile: true });
    }

    return generator.GetColumns();
}

const reqDateSortComparator: GridComparatorFn = (v1, v2, param1, param2) => {
    if (v1.reqDate && v2.reqDate) {
        return gridDateComparator(v1.reqDate, v2.reqDate, param1, param2);
    }

    if (v1.reqDate) {
        return -1; // Req dates come before req date strings
    }

    if (v2.reqDate) {
        return 1
    }

    return gridStringOrNumberComparator(v1.reqDateString, v2.reqDateString, param1, param2);
}