import { useEffect } from 'react';

import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useMfgSecondaryShipTos(mfgCustomerID: string, mfgSiteID: string) {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const mfgSecondaryShipTos = selectionValues.mfgSecondaryShipTos;

    const isExpired = false;

    const cacheKey = `${mfgCustomerID ?? ""}~|~${mfgSiteID ?? ""}`;

    useEffect(() => {

        if (mfgCustomerID && mfgSiteID) {
            if (!mfgSecondaryShipTos.has(cacheKey) || isExpired) {
                actions.LoadMfgSecondaryShipTosAsync(mfgCustomerID, mfgSiteID, cacheKey);
            }
        }

    }, [actions, mfgCustomerID, mfgSiteID, mfgSecondaryShipTos, isExpired, cacheKey]);

    return mfgSecondaryShipTos.get(cacheKey);
}

export default useMfgSecondaryShipTos;

