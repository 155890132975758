import { ClassificationTypeEnum, OrderStatusEnum } from 'helpers/enums';
import { IQuoteClassification } from 'models/IQuote';

export default class QuoteClassificationMethods {

    public constructor(classifications: IQuoteClassification[]) {
        this.classifications = classifications;
    }

    classifications: IQuoteClassification[];

    public GetQuoteClassification(classificationID : number | undefined | null) : IQuoteClassification | undefined {
        if (classificationID)
        {
            return this.classifications.find((qs) => qs.classificationID === classificationID);
        }
        
        return undefined; 
    }

    public GetQuoteClassificationsBeforeUpload()
    {
        return this.classifications.filter((c) => c.classificationType === ClassificationTypeEnum.AvailableBeforeUpload || c.classificationType === ClassificationTypeEnum.OnHoldBeforeUpload);
    }

    public GetQuoteClassificationsAfterUpload()
    {
        return this.classifications.filter((c) => c.classificationType === ClassificationTypeEnum.AfterUpload);
    }

    public GetQuoteClassificationDescription(classificationID : number | null, status : OrderStatusEnum) : string {
        let classification = this.GetQuoteClassification(classificationID)

        if (classification !== undefined && this.ClassificationMatchesStatus(classification, status))
        {
            return classification.description ?? "";
        }

        switch (status)
        {
            case OrderStatusEnum.Available:
                return "Available";
            case OrderStatusEnum.OnHold:
                return "On Hold";
            case OrderStatusEnum.Uploaded:
                return "Uploaded";
            default:
                return "";
        }
        
    }
    
    public ClassificationMatchesStatus(classification: IQuoteClassification, status: OrderStatusEnum) : boolean {
        if (classification.classificationType === ClassificationTypeEnum.AfterUpload)
        {
            return status === OrderStatusEnum.Uploaded;
        }
        else
        {
            return status !== OrderStatusEnum.Uploaded;
        }
    }

}