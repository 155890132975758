import React from "react"
import { Typography, Grid } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import useOfflineSettings from "helpers/context/Offline/useOfflineSettings";
import Header from "../Layout/TopHeader";
import Footer from "../Layout/Footer";

interface IProps {
    signedIn: boolean;
    checkOffline: () => void;
}

const OfflineMessage: React.FC<any> = ({ signedIn, checkOffline }: IProps) => {

    const offlineSettings = useOfflineSettings();
    const [disabledTime, setDisabledTime] = React.useState<number>(0);

    const link = React.useMemo(() => {
        if (offlineSettings && offlineSettings.offlineMailToRecipients !== "") {
            if (offlineSettings.offlineMailToSubject !== "") {
                return "mailto:" + offlineSettings.offlineMailToRecipients + "?subject=" + offlineSettings.offlineMailToSubject;
            } else {
                return "mailto:" + offlineSettings.offlineMailToRecipients;
            }
        }
        return "";
    }, [offlineSettings]);

    const handleCheck = React.useCallback(() => {
        setDisabledTime(3);
        checkOffline();
    }, [checkOffline]);

    React.useEffect(() => {
        if (disabledTime <= 0) return;

        const intervalId = setInterval(() => {
            setDisabledTime(disabledTime - 1);
        }, 1000)

        return () => clearInterval(intervalId);
    }, [disabledTime]);

    return <>
        <Header /> 
        <div className="article">
            <Grid container justifyContent="center" padding="10px" >
                <Grid item justifyContent="center" alignItems='stretch'>
                    <Grid item margin={1}>
                        <Typography variant="h3" textAlign="center">{offlineSettings?.offlineMainText}</Typography>
                    </Grid>

                    <Grid item margin={1}>
                        {link !== "" ?
                            <Typography textAlign="center"><a href={link}>{offlineSettings?.offlineSupportText}</a></Typography>
                            :
                            <Typography textAlign="center">{offlineSettings?.offlineSupportText}</Typography>
                        }
                    </Grid>

                    <Grid item margin={1} display="flex" justifyContent="center">
                        <LoadingButton loading={disabledTime !== 0} variant="contained" onClick={handleCheck}>Check if Online</LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        <div className="footer">
            <Footer />
        </div>
    </>
}

export default OfflineMessage;