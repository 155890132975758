import { useMemo } from "react"
import { useNavigate } from "react-router-dom";
import { Paper, Box, Typography, Grid, IconButton, Skeleton, Stack } from "@mui/material";
import { Person, Edit } from "@mui/icons-material";

import WebCustomerNotes from "components/WebCustomerNotes/WebCustomerNotes";
import useWebCustomer from "helpers/context/SelectionValues/useWebCustomer";
import useQuoteData from "components/Quotes/useQuoteData";
import CustomerInactiveIcon from "components/Customers/CustomerInactiveIcon";
import QuoteNavigation from "components/Quotes/QuoteNavigation";

function WebCustomerCard() {
    const { quote, permissions, lock } = useQuoteData();
    const webCustomer = useWebCustomer(quote?.customerID ?? "");
    const navigate = useNavigate();

    const canEditWebCustomer = useMemo(() => {
        if (permissions && lock) {
            return permissions.classification && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    const customerTextColor = useMemo(() => {
        if (webCustomer?.isInactive) {
            return "error";
        }
        return undefined;
    }, [webCustomer]);

    return <>
        <Paper sx={{ display: "flex" }}>
            <Box sx={{ width: 300, margin: 1 }} display="flex" flexDirection="row">
                <Grid container direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        {(!webCustomer) &&
                            <Box display="flex" width="100%">
                                <Skeleton width="100%" />
                            </Box>
                        }
                        {(webCustomer) &&
                            <Stack direction="row" alignItems={"center"}>
                                <Typography variant="subtitle1" color={customerTextColor}>
                                    {webCustomer?.name ?? ""}
                                </Typography>
                                {webCustomer.isInactive &&
                                    <CustomerInactiveIcon name={webCustomer.name} />
                                }
                            </Stack>
                        }
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                        <WebCustomerNotes webCustomerID={webCustomer?.customerID} />
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                        {canEditWebCustomer &&
                            <IconButton onClick={() => navigate(QuoteNavigation.QuoteEditURL(quote?.oKey ?? 0, "info"))}>
                                <Edit />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    </>

}


export default WebCustomerCard;