import React from "react";

export function useEffectOnLoad(effect:React.EffectCallback) {

    const performedEffect = React.useRef(false);

    React.useEffect(() => {

        if (performedEffect.current === false) {
            performedEffect.current = true;
            effect();
        }
    }, [])

}
