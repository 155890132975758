import APIHelper from 'helpers/APIHelper';
import WebResponse from 'helpers/WebResponse';

export default class LogoutAPI {

    public static async LogoutAsync(): Promise<WebResponse<string>> {

        const response = await APIHelper.GetAsync<string>(`api/auth/Logout`, undefined, undefined, false);

        return response;

    }
}
