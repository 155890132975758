import * as React from 'react';

import type { } from '@mui/x-data-grid-pro/themeAugmentation';
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from '@mui/x-data-grid-pro';
import { useTheme } from '@mui/material';

import { useTranslations } from '@fenetech/translations';
import { useDashboardPickupData } from './useDashboardPickupData';
import { generatePickupColumns } from "./PickupsGridColumns";
import useIsMobile from 'helpers/hooks/useIsMobile';
import { AddStatusColumnData } from 'components/Common/DataGridColumnGenerator';
import useUserInfo from "helpers/context/User/useUserInfo";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

const PickupsGrid: React.FC<any> = (props: any) => {

    const tm = useTranslations();
    const theme = useTheme();
    const rawData = useDashboardPickupData();
    const isMobile = useIsMobile();
    const user = useUserInfo();
    const rowsPerPage = useRowsPerPage("DashboardPickup");

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        return row.oKey;
    }

    const data = React.useMemo(() => {
        if (rawData) {
            return rawData.map((o) => AddStatusColumnData(o, "", theme, tm));
        } else {
            return null;
        }
    }, [rawData, theme, tm]);

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
          <CustomDataGridPro
              rows={data}
              columns={generatePickupColumns(tm, data, theme, isMobile, user)}
              getRowId={getRowId}
              onPageSizeChange={onPageSizeChange}
              pageSize={rowsPerPage.pageSize}
              rowsPerPageOptions={rowsPerPage.pageSizeOptions}
          />
    </>;
};

export default PickupsGrid;
