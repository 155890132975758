import useStateWithLocalStorage from "./useStateWithLocalStorage";

export interface IRowsPerPage {
    pageSize: number
    setPageSize: (rows: number) => void;
    pageSizeOptions: number[]
}

const defaultRowsPerPage: number = 10;
const pageSizeOptions: number[] = [10, 15, 25, 50, 100, 500];

export default function useRowsPerPage(tableName: string): IRowsPerPage {

    const [options, setOptions] = useStateWithLocalStorage("RowsPerPage");
    
    const getCurrentOptionsObject = () => {
        const currentOptionsString = localStorage.getItem("RowsPerPage");
        const currentOptionObject = currentOptionsString ? JSON.parse(currentOptionsString) : {};
        return currentOptionObject;
    }
    
    const initialOptionsObject: any = options ? JSON.parse(options) : {}
    const pageSize: number = initialOptionsObject[tableName] ? parseInt(initialOptionsObject[tableName]) : defaultRowsPerPage;

    return {
        pageSize: pageSize,
        setPageSize: (value: number) => {
            //Need to re-check local storage in case entry updated from different hook
            setOptions(JSON.stringify({...getCurrentOptionsObject(), [tableName]: value}))
        },
        pageSizeOptions: pageSizeOptions
    }
}