import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from "@mui/material";
import OKCancelControl from "components/Common/OKCancelControl";
import OptionsAPI, { ISelectItem, ISplitParts } from "components/OptionsWizard/OptionsAPI";
import useIsMobile from "helpers/hooks/useIsMobile";
import { useTranslations } from "@fenetech/translations";
import React, { ChangeEvent } from 'react';

interface IProps {
    dialogVisible: boolean;
    onCancel: () => void;
    onSubmit: (qty: number, part: string, sideBySide: boolean) => void;
    sideBySide: boolean;
}

const SplitSectionsDialog: React.FC<IProps> = ({ dialogVisible, onCancel, onSubmit, sideBySide }: IProps) => {

    const isMobile = useIsMobile();
    const tm = useTranslations();

    const [splitParts, setSplitParts] = React.useState<ISplitParts | null>(null);

    React.useEffect(() => {
        if (dialogVisible) {
            OptionsAPI.GetSplitParts(sideBySide).then(s => {
                setSplitParts(s);
            });
        }
    }, [sideBySide, dialogVisible]);

    if (splitParts && dialogVisible) {

        return <Dialog
            open={dialogVisible}
            onClose={onCancel}
            fullScreen={isMobile}
        >
            <DialogTitle>
                {sideBySide ? tm.Get("Side By Side Split") : tm.Get("Stack Split")}
            </DialogTitle>
            <SplitSections
                sideBySide={sideBySide}
                partList={splitParts.parts}
                defaultPart={splitParts.default}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </Dialog>;
    } else return null;
}

interface ISSProps {
    sideBySide: boolean;
    partList: ISelectItem[];
    defaultPart: string;
    onCancel: () => void;
    onSubmit: (qty: number, part: string, sideBySide: boolean) => void;
}


const SplitSections: React.FC<ISSProps> = ({ sideBySide, partList, defaultPart, onCancel, onSubmit }: ISSProps) => {

    const [sectionCount, setSectionCount] = React.useState<number>(2);
    const [selectedPartKey, setSelectedPartKey] = React.useState<string>(defaultPart);
    const tm = useTranslations();

    React.useEffect(() => {
        setSelectedPartKey(defaultPart);
        setSectionCount(2);
    }, [defaultPart, sideBySide])

    const handleSectionCountChange = React.useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newSectionCount = parseInt(e.target.value);
        if (isNaN(newSectionCount)) {
            setSectionCount(0);
        } else {
            setSectionCount(newSectionCount);
        }
    }, [])

    const handlePartKeyChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPartKey(e.target.value);
    }, []);

    const HandleSubmitClick = React.useCallback(() => {
        onSubmit(sectionCount, selectedPartKey, sideBySide);
    }, [onSubmit, sectionCount, selectedPartKey, sideBySide]);

    return <>
        <DialogContent>
            <Box display="flex" flexDirection="column" mt={1}>
                <Grid container direction="column" rowSpacing={1} justifyItems="stretch">
                    <Grid item xs>
                        <TextField
                            fullWidth
                            value={sectionCount}
                            onChange={handleSectionCountChange}
                            label={tm.Get("Evenly Into")}
                            InputProps={{
                                // startAdornment: 
                                endAdornment: <Typography>{tm.Get("Sections")}</Typography>,
                                inputProps: { inputMode: "numeric", pattern: "[0-9]*" }
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <TextField
                            fullWidth
                            value={selectedPartKey}
                            select
                            onChange={handlePartKeyChange}
                            label={tm.Get("Split Part")}
                        >
                            {partList?.map(pl =>
                                <MenuItem key={pl.value} value={pl.value}>{pl.text === "{None}" ? tm.Get("{None}") : pl.text}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>
        <DialogActions>
            <OKCancelControl okCommand={HandleSubmitClick} cancelCommand={onCancel} />
        </DialogActions>
    </>
};

export default SplitSectionsDialog;