import { Flag } from "@mui/icons-material";
import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { IQuestionGroup } from "components/OptionsWizard/models";
import OWQuestions from "components/OptionsWizard/OptionsWizard/OWQuestions";
import { IOWTabsProps } from "components/OptionsWizard/OptionsWizard/OWTabs";
import WizardHelper from "components/OptionsWizard/WizardHelper";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import ItemPropertiesSelector from "../ItemProperties/ItemPropertiesSelector";

const OWTabsMobile: React.FC<IOWTabsProps> = (props: IOWTabsProps) => {

    const SIZE_STRING: string = "TAB_SIZE"
    const tm = useTranslations();
    const [currentValue, setCurrentValue] = React.useState<string>(props.showItemProperties ? SIZE_STRING : props.questionGroups[0].groupName);

    const handleChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setCurrentValue(event.target.value);
    };

    const currentGroup = React.useMemo(() => {
        return props.questionGroups.find(qg => qg.groupName === currentValue);
    }, [currentValue, props.questionGroups]);

    return <>
        <Grid container direction="column" spacing={1} alignItems="stretch" >
            <Grid item pb={1}>

                <Select
                    label={tm.Get("Question Group")}
                    value={currentValue}
                    onChange={handleChange}
                    variant="standard"
                    fullWidth
                >
                    {(props.showItemProperties && props.itemInfo) &&
                        <MenuItem key={SIZE_STRING} value={SIZE_STRING} >
                            {tm.Get("ITEM")}
                        </MenuItem>
                    }

                    {props.questionGroups.map((qg: IQuestionGroup, index) =>
                        <MenuItem key={`MNU_${index}_${qg.groupName}`} value={qg.groupName} >
                            <Grid container>
                                {(WizardHelper.RecursivelyIsThereARequiredQuestionOrCode(qg.questions)) && <Grid item xs="auto"><Flag sx={{ width: "16px", height: "16px" }} color="warning" /></Grid>}
                                {<Grid item xs>{qg.groupName}</Grid>}
                            </Grid>
                        </MenuItem>
                    )}

                </Select>
            </Grid>

            <Grid item>
                {(props.showItemProperties && currentValue === SIZE_STRING) ?
                    <ItemPropertiesSelector />
                    : currentGroup &&
                    <OWQuestions
                        preferences={props.preferences}
                        questionGroup={currentGroup}
                        readOnlyMode={props.readOnlyMode}
                    />
                }
            </Grid>

        </Grid>

    </>;
}
export default OWTabsMobile;