import { useMemo } from 'react';
import { IconButton } from '@mui/material'
import { SpeakerNotes } from '@mui/icons-material';

import { useTranslations } from '@fenetech/translations';
import useMessageBox from 'helpers/context/Page/useMessageBox';
import useWebCustomerNotes from 'components/WebCustomerNotes/useWebCustomerNotes'


interface IProps {
    webCustomerID?: string
}

function WebCustomerNotes({ webCustomerID }: IProps) {

    const messageBox = useMessageBox();
    const tm = useTranslations();
    const webCustomerNotes = useWebCustomerNotes(webCustomerID);

    const isNotesIconVisible = useMemo(() => {
        return (webCustomerID && webCustomerNotes);
    }, [webCustomerID, webCustomerNotes]);    

    return <>
        {isNotesIconVisible && 
            <IconButton 
                onClick={() => {messageBox.Show({message: webCustomerNotes, title: tm.Get("Customer Notes")})}}
                >
                <SpeakerNotes />
            </IconButton>
        }   
    </>;

}

export default WebCustomerNotes;