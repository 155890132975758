import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import { ILineItem } from "helpers/interfaces";

export default class LineItemTextColumn extends LineItemColumn {

    getText: (lineItem: ILineItem) => string;

    public constructor(columnName: string, headerText: string, width: string, align: "inherit" | "left" | "center" | "right" | "justify" | undefined, getText: (lineItem: ILineItem) => string) {
        super(columnName, headerText, width);
        this.align = align;
        this.getText = getText;
    }
    public CellContent(quoteProps: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {

        return <>
            {this.getText(lineItem)}
        </>;
    }

    public FooterContent(quoteProps: IQuoteLevelProps): JSX.Element {
        return <></>;
    }
}
