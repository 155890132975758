
import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import LineItemTotal from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemTotal";
import QuoteTotals from "components/Quotes/QuoteEntry/LineItems/Fields/QuoteTotals";

import { ILineItem } from "helpers/interfaces";

export default class LineItemTotalsColumn extends LineItemColumn {

    public constructor(columnName: string, headerText: string, width: string) {
        super(columnName, headerText, width);
        this.align = "right";
    }

    public CellContent(quoteProps: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {
        return <LineItemTotal
            lineItem={lineItem}
            variant="body2"
        />
    }

    public FooterContent({ lineItemGridProps }: IQuoteLevelProps): JSX.Element {

        return <QuoteTotals
            variant="body2"
        />;

    }
}

