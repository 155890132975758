import { useMemo } from "react";
import { Stack } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import { ViewTypeEnum } from "../enum";
import { IDisplayViewSettings } from "../interfaces";
import ObjectViewButton from "./ObjectViewButton";
import useWizardState, { useWizardStateActions } from "../WebDesigner/useWizardState";

interface IProps {
}

const ObjectViewSelector: React.FC<IProps> = () => {

    const tm = useTranslations();

    const state = useWizardState();
    const wizardActions = useWizardStateActions();
    const currentView = state.objectView!;
    const displayViewSettings = state.displayViewSettings!;
    const webDesigner = state.webDesigner;
    const partPreferences = state.partPreferences;
    const odKey = state.itemInfo?.odkey;

    const availableViews = useMemo(() => {
        if (webDesigner) {
            return webDesigner.availableViews;
        } else if (partPreferences) {
            return partPreferences.availableViews;
        }
    }, [webDesigner, partPreferences]);

    const showTop = useMemo(() => {
        if (webDesigner) {
            return false;
        } else if (partPreferences) {
            return partPreferences.showTop;
        }
    }, [webDesigner, partPreferences]);

    const showCross = useMemo(() => {
        if (webDesigner) {
            return false;
        } else if (partPreferences) {
            return partPreferences.showCross;
        }
    }, [webDesigner, partPreferences]);

    const allowBoth = useMemo(() => {
        if (availableViews) {
            let allow: boolean = ((availableViews & ViewTypeEnum.Both) === ViewTypeEnum.Both);

            if (showTop || showCross) {
                allow = false;
            }

            if (webDesigner) {
                allow = false;
            }
            return allow;
        }
        return false;
    }, [webDesigner, availableViews, showTop, showCross]);

    function handleClick(view: ViewTypeEnum): void {
        if (availableViews && odKey !== undefined && view !== currentView) {
            if ((availableViews & view) === view) {
                wizardActions.SetObjectViewAsync(view, odKey);
            }
        }
    }

    if (!availableViews) {
        return <></>;
    }

    return <>
        <Stack direction="row" justifyContent="center" spacing={2}>
            {(currentView === ViewTypeEnum.Inside || currentView === ViewTypeEnum.Both) &&
                <span>{displayViewSettings[ViewTypeEnum.Inside].drawingCaption}</span>
            }

            {(currentView === ViewTypeEnum.Outside || currentView === ViewTypeEnum.Both) &&
                <span>{displayViewSettings[ViewTypeEnum.Outside].drawingCaption}</span>
            }
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={1}>
            {Object.keys(ViewTypeEnum).filter((k) => typeof ViewTypeEnum[k as any] === "number").map((k: string) =>
                <ObjectViewButton
                    key={k}
                    view={ViewTypeEnum[k as unknown as number] as unknown as ViewTypeEnum}
                    displayViewSettings={displayViewSettings as IDisplayViewSettings}
                    currentView={currentView}
                    availableViews={availableViews}
                    clickHandler={handleClick}
                    allowBoth={allowBoth}
                    tm={tm}
                />
            )}
        </Stack>
    </>;
}


export default ObjectViewSelector;