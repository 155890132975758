import React, { ReactElement } from "react";
import { Stack, Link } from "@mui/material";
import { useAdvertisements } from "./useAdvertisements";
import { IAdvertisement } from "../../../helpers/interfaces";
import useIsMobile from "helpers/hooks/useIsMobile";



const AdRotator: React.FC = () => {

    const ads: IAdvertisement[] | undefined = useAdvertisements();
    const isMobile = useIsMobile();

    const [currentAdID, setCurrentAdID] = React.useState<number | null>(null);

    React.useEffect(() => {

        if (ads && ads.length > 0) {

            if (!currentAdID) {
                let id = tryParseInt(sessionStorage.getItem("last_ad"))
                if (id) {
                    id = getNextAdID(ads, id);
                } else {
                    id = ads[0].adID;
                }

                sessionStorage.setItem("last_ad", id.toString());
                setCurrentAdID(id);
            }

        }

    }, [ads, currentAdID]);

    const currentAd = React.useMemo(() => {

        if (ads && currentAdID) {
            const ad = ads.find(a => a.adID === currentAdID);
            if (ad) {
                return ad;
            }
        }

        return null;

    }, [currentAdID, ads]);

    let AdImageTag: ReactElement;

    if (currentAd) {

        if (isMobile) {
            AdImageTag = < img src={currentAd.imageUrl} width={"95%"} alt={currentAd.alternateText} />;
        } else {
            AdImageTag = <img src={currentAd.imageUrl} width={currentAd.width} height={currentAd.height} alt={currentAd.alternateText} />
        }

        return <Stack alignItems="center" p={1} >
            {currentAd && <>
                {currentAd.navigateUrl &&
                    <Link href={currentAd.navigateUrl} title={currentAd.alternateText} >
                        {AdImageTag}
                    </Link>
                }
                {!currentAd.navigateUrl && <>
                    {AdImageTag}
                </>}

            </>}
        </Stack>

    } else {
        return <></>;
    }

};



const getNextAdID = (ads: IAdvertisement[], currentAdID: number): number => {

    const currentAd = ads.find(a => a.adID === currentAdID);

    let idx: number = 0;

    if (currentAd) {
        //get next index or roll back to zero
        idx = ads.indexOf(currentAd);
        if (idx >= 0) {
            idx++;
            if (idx >= ads.length) {
                idx = 0;
            }
        }
    }

    return ads[idx].adID;

}

const tryParseInt = (x: string | null): number | null => {
    if (x) {
        return parseInt(x);
    } else {
        return null;
    }
}

export default AdRotator;

