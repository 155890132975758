
export const ResizeBase64Image = (imageBase64: string, newWidth: number, newHeight: number): Promise<string> => {
    return new Promise((res, rej) => {
        try {
            let image = document.createElement("img");
            let canvas = document.createElement("canvas");
            image.src = imageBase64;
            
            image.onload = () => {
                let width = image.width;
                let height = image.height;

                if (width > newWidth) {
                    if (width > newWidth) {
                        height = height * (newWidth / width);
                        width = newWidth
                    }
                } else {
                    if (height > newHeight) {
                        width = width * (newHeight / height);
                        height = newHeight;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                let context = canvas.getContext("2d");

                context?.drawImage(image, 0, 0, width, height);
                res(canvas.toDataURL());
            }
        } catch (e) {
            rej(e);
        }
    })
}