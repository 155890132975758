import { useMemo } from "react";
import { Accordion, AccordionDetails, Grid, IconButton, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import SelectMeasurementType from "components/Quotes/SelectFields/SelectMeasurementType";
import SelectQuoteStatus from "components/Quotes/SelectFields/SelectQuoteStatus";
import SelectPONumber from "components/Quotes/SelectFields/SelectPONumber";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim"
import TextFieldForm from "components/Common/TextFieldForm"

import { useTranslations } from "@fenetech/translations";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";

import Constants from "helpers/constants";
import { IQuotePermissions } from "helpers/interfaces";
import useWebCustomer from "helpers/context/SelectionValues/useWebCustomer";
import WebCustomerNotes from "components/WebCustomerNotes/WebCustomerNotes";
import LabeledText from "components/Common/LabeledText";
import { Edit } from "@mui/icons-material";

interface IProps {
    webCustomerGUID: string,
    webCustomerID: string,
    oKey: number,
    isUploaded: boolean,
    expanded: boolean,
    permissions?: IQuotePermissions,
    originalClassificationID: number | null,
    onExpandedChange: (event: React.SyntheticEvent, expanded: boolean) => void,
    showWebCustomer?: boolean,
    canEditWebCustomer?: boolean,
    onEditWebCustomerClick?: () => void,
}

function QuoteInfo({ webCustomerGUID, webCustomerID, oKey, expanded, isUploaded, permissions, originalClassificationID, onExpandedChange,
    showWebCustomer, canEditWebCustomer, onEditWebCustomerClick }: IProps) {

    const tm = useTranslations();
    const { formState: { errors } } = useFormContext();
    const appInfo = useApplicationInfo();
    const webCustomer = useWebCustomer(webCustomerID ?? "");

    const isQuoteContactRequired = useMemo(() => {
        return appInfo?.parameters.requireQuoteContact;
    }, [appInfo]);

    return <>
        <Accordion expanded={expanded} onChange={onExpandedChange} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("Quote")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    {showWebCustomer &&
                        <Grid item xs={12}>
                            <Grid container direction="row" alignItems={"center"}>
                                <Grid item mr={1}>
                                    {/* Web Customer */}
                                    <LabeledText
                                        label={tm.Get("Customer")}
                                        text={webCustomer?.name} />
                                </Grid>
                                <Grid item>
                                    <WebCustomerNotes webCustomerID={webCustomer?.customerID} />
                                </Grid>
                                <Grid item display="flex" alignItems="center">
                                    {(canEditWebCustomer && onEditWebCustomerClick) &&
                                        <IconButton onClick={() => { onEditWebCustomerClick() }}>
                                            <Edit />
                                        </IconButton>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        {/* Measurement Type */}
                        <SelectMeasurementType
                            permissions={permissions}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* Status */}
                        <SelectQuoteStatus
                            isUploaded={isUploaded}
                            permissions={permissions}
                            originalClassificationID={originalClassificationID}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* PO Number */}
                        <SelectPONumber
                            oKey={oKey}
                            customerGUID={webCustomerGUID}
                            permissions={permissions}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* Customer Ref */}
                        <TextFieldForm
                            id="customerRef"
                            fieldName="customerRef"
                            readOnly={false}
                            variant="standard"
                            inputProps={{ maxLength: Constants.MaxLength.CustomerRef }}
                            label={tm.Get("Customer Ref")}
                            placeholder={tm.Get("Customer Ref")}
                            disabled={!permissions?.customerRef ?? true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* Quote Contact */}
                        <TextFieldForm
                            id="quoteContact"
                            fieldName="orderContact"
                            readOnly={false}
                            requiredRule={{ value: isQuoteContactRequired, message: tm.Get("Quote Contact is required") }}
                            variant="standard"
                            inputProps={{ maxLength: Constants.MaxLength.QuoteContact }}
                            label={tm.Get("Quote Contact")}
                            placeholder={tm.Get("Quote Contact")}
                            error={errors.orderContact?.message ? true : false}
                            helperText={errors.orderContact?.message}
                            disabled={!permissions?.quoteContact ?? true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* Quote Comment */}
                        <TextFieldForm
                            id="quoteComment"
                            fieldName="orderComment"
                            readOnly={false}
                            inputProps={{ maxLength: Constants.MaxLength.QuoteComment }}
                            label={tm.Get("Quote Comment")}
                            placeholder={tm.Get("Quote Comment")}
                            multiline
                            minRows={2} maxRows={5}
                            disabled={!permissions?.quoteComment ?? true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* Notes */}
                        <TextFieldForm
                            id="notes"
                            fieldName="orderNotes"
                            readOnly={false}
                            /* no maximum length */
                            label={tm.Get("Notes")}
                            placeholder={tm.Get("Notes")}
                            multiline
                            minRows={2} maxRows={5}
                            disabled={!permissions?.notes ?? true}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>

}


export default QuoteInfo;