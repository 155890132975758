import { IPermission, IUserInfoFull } from "helpers/interfaces";
import { FormProvider, useForm } from "react-hook-form";
import React from 'react';
import { FindAllNestedProps } from "helpers/objects";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { useTranslations } from "@fenetech/translations";
import { LoginAuthenticationTypeEnum, LoginTypeEnum, RoleEnum, SecurityLevelEnum, ThemeColorEnum } from "helpers/enums";
import UserGeneral from "./Form/UserGeneral";
import PermissionSelect from "./Form/PermissionSelect";
import { Alert, AlertColor, Container, Snackbar, Stack } from "@mui/material";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { IActionButton } from "helpers/context/Page/PageContext";
import useUserInfo from "helpers/context/User/useUserInfo";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import { IUserInsert } from "models/IUser";
import { BlankUser } from "./Form/BlankUser";
import { useNavigate, useParams } from "react-router-dom";
import useUserData from "./useUserSetupData";
import UserAPI from "components/User/UserAPI";
import useWait from "helpers/context/Page/useWait";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";
import useWindowTitle from "helpers/context/Title/useWindowTitle";

const AddUser: React.FC = () => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<[text: string, alertType: AlertColor] | null>(null);
    const [userDefaults, setUserDefaults] = React.useState<IUserInfoFull>(BlankUser);

    const userInfoWithFormFields = React.useCallback((userDefaults: IUserInfoFull) => {
        return {
            ...userDefaults.user,
            password: "",
            permissions: userDefaults.user.securityLevel === SecurityLevelEnum.Administrator ?
                //If administrator, DB returns all zeroes but we want to show every permission enabled
                userDefaults.permissions.map(permission => { return { ...permission, enabled: true } }) :
                userDefaults.permissions,
            siteIDs: userDefaults.assignedSites?.map(mfgSite => mfgSite.siteID),
            isAllSites: userDefaults.loginType === LoginTypeEnum.AllSites
        }
    }, []);
    const formMethods = useForm({ mode: "onChange", defaultValues: userInfoWithFormFields(userDefaults) });

    const { handleSubmit, watch, reset } = formMethods;
    const tm = useTranslations();
    const messageBox = useMessageBox();
    const actionButtons = useActionButtons();
    const user = useUserInfo();
    const wait = useWait();
    const userData = useUserData();
    const navigate = useNavigate();
    const navBlockerActions = useNavigationBlockerActions();
    useWindowTitle(tm.GetWithParams("Add {0}", tm.Get("User")));

    useNavigationBlocker(formMethods.formState.isDirty);

    const securityLevel = watch("securityLevel");
    const loginAuthenticationType = watch("authenticationType");

    const { contractorCustomerGUID } = useParams();
    if (!contractorCustomerGUID && userData.isDealerCreatingAdminContractor) navigate(-1);

    React.useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    React.useEffect(() => {
        const saveButton: IActionButton = {
            text: tm.Get("Save"),
            color: ThemeColorEnum.Secondary,
            disabled: (isLoading || isPosting),
            type: "submit",
            form: "add-user",
            onClick: (() => navBlockerActions.Unblock())
        };
        const cancelButton: IActionButton = {
            text: tm.Get("Cancel"),
            color: ThemeColorEnum.Primary,
            disabled: (isLoading || isPosting),
            onClick: () => {
                navigate(-1);
                navBlockerActions.Unblock();
            }
        };
        actionButtons.Set(0, saveButton);
        actionButtons.Set(1, cancelButton);
    }, [isLoading, isPosting, navBlockerActions, actionButtons, navigate, tm]);

    useEffectOnLoad(() => {
        const getDefaults = async () => {
            try {
                setIsLoading(true);
                //If dealer creating an admin contractor, should have passed the customerGUID in URL
                if (userData.isDealerCreatingAdminContractor && !contractorCustomerGUID) return;

                let result = userData.isDealerCreatingAdminContractor ?
                    await UserAPI.GetDefaultDealerCreatedContractorInfoFull(contractorCustomerGUID!) :
                    await UserAPI.GetDefaultUserInfoFull();
                if (result) {
                    setUserDefaults(result);
                    reset(userInfoWithFormFields(result));
                } else {
                    setAlert([tm.Get("An unknown error has occurred."), "error"]);
                }
            } finally {
                setIsLoading(false);
            }
        }
        getDefaults();
    })

    const getPermissionValue = (permissions: IPermission[]) => permissions.reduce<number>((sum, permission) => { return permission.enabled ? sum + permission.id : sum }, 0)

    const onSubmit = async (formData: any, e: any) => {
        const insertUser: IUserInsert = {
            firstName: formData["firstName"].trim(),
            lastName: formData["lastName"].trim(),
            emailAddress: formData["emailAddress"].trim(),
            userName: formData["userName"].trim(),
            password: formData["password"],
            inactive: formData["inactive"],
            mfgCreatedEmployee: formData["mfgCreatedEmployee"],
            customerID: formData["customerID"],
            authenticationType: formData["authenticationType"],
            securityLevel: formData["securityLevel"],
            permissions: getPermissionValue(formData["permissions"]),
            siteIDs: formData["isAllSites"] ? [] : formData["siteIDs"] //If all sites, database expects empty list
        }

        setIsPosting(true);
        try {
            let result = await UserAPI.InsertUser(insertUser, (userData.isDealerCreatingAdminContractor || user.HasRole(RoleEnum.Contractor)));
            if (!result) {
                setAlert([tm.Get("An unknown error has occurred."), "error"]);
                return;
            } else if (result.baseContent.message) {
                const translated = tm.Get(result.baseContent);
                setAlert([translated, "error"]);
            } else {
                navigate(-1);
            }
        } finally {
            setIsPosting(false);
        }
    };

    const onError = (errors: { [x: string]: any }, e: any) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");
        console.log(formattedMessage);

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert(null);
    }

    return <>
        <Snackbar open={alert !== null} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert ? alert[1] : "info"} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert ? alert[0] : ""}</Alert>
        </Snackbar>
        <Container sx={{marginTop: 1}}>
            <FormProvider {...formMethods}>
                <form id="add-user" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Stack direction="column" gap={1}>
                        <UserGeneral
                            windowTitle={tm.GetWithParams("Add {0}", tm.Get("User"))}
                            mfgSites={[...userDefaults.availableSites ?? [], ...userDefaults.assignedSites ?? []]}
                            mfgSitesVisible={!user.HasRole(RoleEnum.Contractor) && !userData.isDealerCreatingAdminContractor}
                            isEditMode={true}
                            isAllSites={userDefaults.loginType === LoginTypeEnum.AllSites}
                            allSitesVisible={userDefaults.canSelectAllSites}
                            securityLevelDisabled={userData.isDealerCreatingAdminContractor}
                            loginAuthenticationVisible={!user.HasRole(RoleEnum.Contractor) && !userData.isDealerCreatingAdminContractor}
                            userNameRequired={true}
                            passwordRequired={loginAuthenticationType === LoginAuthenticationTypeEnum.Standard}
                            firstNameDisabled={loginAuthenticationType === LoginAuthenticationTypeEnum.SingleSignOn}
                            lastNameDisabled={loginAuthenticationType === LoginAuthenticationTypeEnum.SingleSignOn}
                            emailDisabled={loginAuthenticationType === LoginAuthenticationTypeEnum.SingleSignOn}
                            inactiveDisabled={false}
                            customerInactiveVisible={userData.isDealerCreatingAdminContractor}
                            mfgInactiveVisible={user.HasRole(RoleEnum.Dealer)}
                            mfgCreatedVisible={false}
                            mfgCreatedText={""}
                        />

                        <PermissionSelect
                            readOnly={false}
                            disabled={securityLevel === SecurityLevelEnum.Administrator || userDefaults.user.mfgCreatedEmployee}
                        />
                    </Stack>
                </form>
            </FormProvider>
        </Container>
    </>;
}

export default AddUser;