import { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Autocomplete, TextField, CircularProgress, Typography } from '@mui/material'

import WebCustomerNotes from 'components/WebCustomerNotes/WebCustomerNotes';

import { useTranslations } from '@fenetech/translations';
import useUserInfo from 'helpers/context/User/useUserInfo'
import useMfgCustomersAndSites from 'helpers/context/SelectionValues/useMfgCustomersAndSites';
import useWebCustomers from 'helpers/context/SelectionValues/useWebCustomers';

import { IMfgCustomer, IWebCustomer, IQuotePermissions } from "helpers/interfaces";

interface IProps {
    isContractorQuote: boolean,
    contractorCustomerID: string,
    setWebCustomer: (webCustomerID: string, webCustomerGUID: string) => void,
    setMfgCustomerID: (mfgCustomerID: string) => void,
    setMfgSiteID: (mfgSiteID: string) => void
    defaultMfgCustomer?: { customerID: string, siteID: string },
    defaultWebCustomerID?: string,
    permissions?: IQuotePermissions,
    showSelectionRequired?: boolean,
}

function SelectWebCustomer({ isContractorQuote, contractorCustomerID, setWebCustomer, setMfgCustomerID, setMfgSiteID, defaultMfgCustomer, defaultWebCustomerID, permissions, showSelectionRequired }: IProps) {

    //#region State variables
    const [selectedMfgCustomer, setSelectedMfgCustomer] = useState<IMfgCustomer | null>(null);
    const [selectedMfgSite, setSelectedMfgSite] = useState<IMfgCustomer | null>(null);
    const [selectedWebCustomer, setSelectedWebCustomer] = useState<IWebCustomer | null>(null);
    //#endregion

    //#region Hooks
    const tm = useTranslations();
    const userInfo = useUserInfo();
    const mfgCustomerSites = useMfgCustomersAndSites();
    const parentCustomerID = useMemo(() => {
        return isContractorQuote ? contractorCustomerID : selectedMfgSite?.customerID;
    }, [isContractorQuote, contractorCustomerID, selectedMfgSite]);

    const parentSiteID = useMemo(() => {
        return isContractorQuote ? contractorCustomerID : selectedMfgSite?.siteID;
    }, [isContractorQuote, contractorCustomerID, selectedMfgSite]);
    const webCustomers = useWebCustomers(isContractorQuote, parentCustomerID, parentSiteID);
    //#endregion

    //#region Memoized fields

    const mfgCustomers = useMemo(() => {
        if (mfgCustomerSites) {
            return mfgCustomerSites.GetDistinctMfgCustomers();
        }
        return undefined;

    }, [mfgCustomerSites]);

    const mfgSites = useMemo(() => {
        if (mfgCustomerSites && selectedMfgCustomer) {
            return mfgCustomerSites.GetMfgSitesFromMfgCustomerID(selectedMfgCustomer.customerID);
        }
        return undefined;
    }, [mfgCustomerSites, selectedMfgCustomer]);

    const isMfgCustomerVisible = useMemo(() => {
        return (!isContractorQuote && mfgCustomers && !userInfo.mfgCustomerID && mfgCustomers.length !== 1);
    }, [mfgCustomers, userInfo, isContractorQuote]);

    const isMfgSiteVisible = useMemo(() => {
        return (!isContractorQuote && mfgSites && mfgSites.length !== 1);
    }, [mfgSites, isContractorQuote]);

    const isWebCustomerVisible = useMemo(() => {
        return (isContractorQuote || (selectedMfgCustomer && selectedMfgSite));
    }, [selectedMfgCustomer, selectedMfgSite, isContractorQuote]);

    const mfgCustomerSiteCount = useMemo(() => {
        return mfgCustomerSites?.mfgCustomersAndSites.length;
    }, [mfgCustomerSites]);

    const mfgCustomerHelperText = useMemo(() => {
        if (showSelectionRequired && !selectedMfgCustomer) {
            return tm.Get("Selection Required");
        }
        return "";
    }, [showSelectionRequired, selectedMfgCustomer, tm]);

    const mfgSiteHelperText = useMemo(() => {
        if (showSelectionRequired && !selectedMfgSite) {
            return tm.Get("Selection Required");
        }
        return "";
    }, [showSelectionRequired, selectedMfgSite, tm]);

    const webCustomerHelperText = useMemo(() => {
        if (showSelectionRequired && !selectedWebCustomer) {
            return tm.Get("Selection Required");
        }
        return "";
    }, [showSelectionRequired, selectedWebCustomer, tm]);


    //#endregion

    //#region Effects

    /* Auto select mfg customer */
    useEffect(() => {
        if (mfgCustomers && defaultMfgCustomer) {
            let mfgCustomer = mfgCustomers.find((m) => m.customerID === defaultMfgCustomer.customerID);
            setSelectedMfgCustomer(mfgCustomer ?? null);
        }
        else if (mfgCustomers && userInfo.mfgCustomerID) {
            let mfgCustomer = mfgCustomers.find((m) => m.customerID === userInfo.mfgCustomerID);
            setSelectedMfgCustomer(mfgCustomer ?? null);
        }
        else if (mfgCustomers && mfgCustomers.length === 1) {
            setSelectedMfgCustomer(mfgCustomers[0]);
        }
        else {
            setSelectedMfgCustomer(null);
        }
    }, [userInfo, mfgCustomers, defaultMfgCustomer]);

    /* Auto select mfg site */
    useEffect(() => {
        if (mfgSites && defaultMfgCustomer && selectedMfgCustomer && selectedMfgCustomer.customerID === defaultMfgCustomer.customerID) {
            let mfgSite = mfgSites.find((m) => m.siteID === defaultMfgCustomer.siteID);
            setSelectedMfgSite(mfgSite ?? null);
        }
        else if (selectedMfgCustomer && mfgSites && mfgSites.length === 1) {
            setSelectedMfgSite(mfgSites[0]);
        }
        else {
            setSelectedMfgSite(null);
        }
    }, [selectedMfgCustomer, mfgSites, defaultMfgCustomer]);

    /* Auto select web customer */
    useEffect(() => {
        if (webCustomers && defaultWebCustomerID && defaultMfgCustomer && selectedMfgSite && selectedMfgSite.siteID === defaultMfgCustomer?.siteID) {
            let webCustomer = webCustomers.find((m) => m.customerID === defaultWebCustomerID);
            setSelectedWebCustomer(webCustomer ?? null);
        }
        else if (selectedMfgCustomer && selectedMfgSite && webCustomers && webCustomers.length === 1) {
            setSelectedWebCustomer(webCustomers[0]);
        }
        else {
            setSelectedWebCustomer(null);
        }
    }, [selectedMfgCustomer, selectedMfgSite, webCustomers, defaultWebCustomerID, defaultMfgCustomer]);

    /* MfgCustomerID prop setter */
    useEffect(() => {
        setMfgCustomerID(selectedMfgCustomer?.customerID ?? "");
    }, [setMfgCustomerID, selectedMfgCustomer]);

    /* MfgSiteID prop setter */
    useEffect(() => {
        setMfgSiteID(selectedMfgSite?.siteID ?? "");
    }, [setMfgSiteID, selectedMfgSite]);

    /* WebCustomer prop setter */
    useEffect(() => {
        setWebCustomer(selectedWebCustomer?.customerID ?? "", selectedWebCustomer?.customerGUID ?? "");
    }, [setWebCustomer, selectedWebCustomer]);

    //#endregion

    if (mfgCustomerSiteCount === 0) {
        // this can happen if the mfg customer is made inactive
        return <Typography display="flex" justifyContent="center">
            {tm.GetWithParams("{0} not found", tm.Get("Mfg Customer"))}
        </Typography>
    }

    if (!(isMfgCustomerVisible || isMfgSiteVisible || isWebCustomerVisible)) {
        return (
            <Autocomplete
                id="customerLoading"
                disabled
                options={[""]}
                renderInput={(params) => (
                    <TextField
                        variant="outlined"
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    <CircularProgress color="inherit" size={20} />
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        )
    }

    return (
        <Box display="flex" flexDirection="column" gap={1} sx={{marginTop: 1}}>
            {isMfgCustomerVisible &&
                <Autocomplete
                    id="mfgCustomer"
                    autoComplete
                    autoSelect
                    autoHighlight
                    selectOnFocus
                    handleHomeEndKeys
                    options={mfgCustomers ?? []}
                    disabled={!permissions?.parentCustomer ?? true}
                    getOptionLabel={(option) => (option?.name || "")}
                    isOptionEqualToValue={(option, value) => (option?.name) === (value?.name)}
                    renderInput={(params) => (
                        <TextField
                            variant="outlined"
                            helperText={mfgCustomerHelperText}
                            error={mfgCustomerHelperText ? true : false}
                            label={tm.Get("Mfg Customer")}
                            {...params}
                        />
                    )}
                    value={selectedMfgCustomer}
                    onChange={(_, value) => { setSelectedMfgCustomer(value); }}
                />
            }
            {isMfgSiteVisible &&
                <Autocomplete
                    id="mfgSite"
                    autoComplete
                    autoSelect
                    autoHighlight
                    selectOnFocus
                    handleHomeEndKeys
                    options={mfgSites ?? []}
                    disabled={!permissions?.parentSite ?? true}
                    getOptionLabel={(option) => (option?.siteName || "")}
                    isOptionEqualToValue={(option, value) => (option?.siteName) === (value?.siteName)}
                    renderInput={(params) => (
                        <TextField
                            variant="outlined"
                            helperText={mfgSiteHelperText}
                            error={mfgSiteHelperText ? true : false}
                            label={tm.Get("Mfg Site")}
                            {...params}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.customerGUID}>
                            {option?.siteName || ""}
                        </li>
                    )}
                    value={selectedMfgSite}
                    onChange={(_, value) => { setSelectedMfgSite(value); }}
                />
            }
            {isWebCustomerVisible &&
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Autocomplete
                            id="webcustomer"
                            autoComplete
                            autoSelect
                            autoHighlight
                            selectOnFocus
                            handleHomeEndKeys
                            options={webCustomers ?? []}
                            disabled={webCustomers === undefined || (!permissions?.webCustomer ?? true)}
                            getOptionLabel={(option) => (option?.name || "")}
                            isOptionEqualToValue={(option, value) => (option?.name) === (value?.name)}
                            renderInput={(params) => (
                                <TextField
                                    variant="outlined"
                                    {...params}
                                    label={tm.Get("Customer")}
                                    helperText={webCustomerHelperText}
                                    error={webCustomerHelperText ? true : false}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {(webCustomers === undefined) && <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.customerGUID}>
                                    {option?.name || ""}
                                </li>
                            )}
                            value={selectedWebCustomer}
                            onChange={(_, value) => { setSelectedWebCustomer(value); }}
                        />
                    </Grid>
                    <Grid item>
                        <WebCustomerNotes webCustomerID={selectedWebCustomer?.customerID} />
                    </Grid>
                </Grid>
            }
        </Box>
    );
}

export default SelectWebCustomer;