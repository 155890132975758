import { useMemo } from "react";
import { TableRow, TableCell, styled } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

import useQuoteData from "components/Quotes/useQuoteData";

import { ILineItemGridProps } from "components/Quotes/QuoteEntry/LineItems/LineItemGrid";
import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";

interface IProps {
    lineItemGridProps: ILineItemGridProps;
    columns: LineItemColumn[];
}

const LineItemFooter: React.FC<IProps> = ({ lineItemGridProps, columns }: IProps) => {

    const { quote, permissions, lock } = useQuoteData();

    const StyledFooterCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        },
    }));    

    const columnProps = useMemo(() : IQuoteLevelProps|undefined => {
        if (quote && permissions && lock)
        {
            return {lineItemGridProps: lineItemGridProps, quote: quote, permissions: permissions, lock: lock}
        }
        return undefined;
    }, [lineItemGridProps, quote, permissions, lock]);

    return <>
        <TableRow>
            {columns.map(lic => (
                <StyledFooterCell key={lic.columnName} rowSpan={lic.rowSpan} align={lic.align}>
                    {columnProps ? lic.FooterContent(columnProps) : ""}
                </StyledFooterCell>
                ))
            }
        </TableRow>
    </>;
}


export default LineItemFooter;