import { IUserInsert } from "models/IUser";
import APIHelper from "../../helpers/APIHelper";
import { IBaseContent, IBaseContentWithPayload, IProfileInfo, IProfileUpdate, IUserInfoFull, IUserSummary, IUserUpdate } from "../../helpers/interfaces";

export default class UserAPI {

    public static async GetUsers(employeeID: number): Promise<IUserSummary[]> {
        const query = "api/user/userlist";
        const params = {
            employeeID: employeeID
        }

        const response = await APIHelper.GetAsync<IUserSummary[]>(query, params);
        return response.Result;
    }

    public static async GetContractorUsers(customerID: string, siteID: string, parentCustomerID: string): Promise<IUserSummary[]> {
        const query = "api/user/Contractors/userlist";
        const params = {
            customerID: customerID,
            siteID: siteID,
            parentCustomerID: parentCustomerID
        }

        const response = await APIHelper.GetAsync<IUserSummary[]>(query, params);
        return response.Result;
    }

    public static async GetUserInfoFull(employeeID: string): Promise<IUserInfoFull> {
        const query = "api/user/InfoFull";
        const params = {
            employeeID: employeeID
        };

        const response = await APIHelper.GetAsync<IUserInfoFull>(query, params);
        return response.Result;
    }

    public static async GetDefaultUserInfoFull(): Promise<IUserInfoFull> {
        const query = "api/user/DefaultInfoFull";

        const response = await APIHelper.GetAsync<IUserInfoFull>(query);
        return response.Result;
    }

    public static async GetDefaultDealerCreatedContractorInfoFull(contractorCustomerGUID: string): Promise<IUserInfoFull> {
        const query = "api/user/DefaultDealerCreatedContractorInfoFull";
        const params = {
            contractorCustomerGUID: contractorCustomerGUID
        };

        const response = await APIHelper.GetAsync<IUserInfoFull>(query, params);
        return response.Result;
    }

    public static async InsertUser(user: IUserInsert, creatingContractor: boolean): Promise<IBaseContentWithPayload<number>> {
        const query = "api/user";
        const params = {
            creatingContractor: creatingContractor
        }
        const body = user;

        const response = await APIHelper.PostAsync<IBaseContentWithPayload<number>>(query, params, body);
        return response.Result;
    }

    public static async UpdateUser(userUpdate: IUserUpdate): Promise<IBaseContent> {
        const query = "api/user";
        const body = userUpdate;

        const response = await APIHelper.PutAsync<IBaseContent>(query, null, body);
        return response.Result;
    }

    public static async DeleteUser(employeeID: string): Promise<IBaseContent> {
        const query = "api/user/";
        const params = {
            employeeID: employeeID
        }

        const response = await APIHelper.DeleteAsync<IBaseContent>(query, params);
        return response.Result;
    }

    public static async GetProfileInfo(): Promise<IProfileInfo> {
        const query = "/api/user/profile";

        const response = await APIHelper.GetAsync<IProfileInfo>(query);
        return response.Result;
    }

    public static async UpdateProfileInfo(profileInfo: IProfileUpdate): Promise<void> {
        const query = "/api/user/profile";
        await APIHelper.PostAsync(query, {}, profileInfo);
    }

    public static async UpdateUserPassword(employeeID: number, password: string): Promise<IBaseContent> {
        const query = "api/user/password";
        const params = {
            employeeID: employeeID,
            password: password
        }

        const response = await APIHelper.PutAsync<IBaseContent>(query, params);
        return response.Result;
    }
}