import React, { FormEventHandler } from "react";

export type SubmitHandler = (form: HTMLFormElement) => Promise<any>;

export interface ILoadingFormState {
    internalSubmitHandler: FormEventHandler<HTMLFormElement>;
    submitting: boolean;
}

export default function useLoadingForm(userHandler: SubmitHandler):ILoadingFormState {

    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const internalSubmitHandler: FormEventHandler<HTMLFormElement> = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        //Prevent double submits
        if (submitting)
            return;

        setSubmitting(true);

        userHandler(e.currentTarget).finally(() => {
            //Remove submit flag once user handler finishes
            setSubmitting(false);
        });

        return false;

    };

    return {submitting, internalSubmitHandler};

};


