
import { TabList, TabListProps } from "@mui/lab";

const style =
{
    '.MuiTabs-root':
    {
        color: "secondary.contrastText"
    },
    bgcolor: "background.default",
};

export default function CustomTabList(props: React.PropsWithChildren<TabListProps>) {
    return <TabList
        {...props}
        sx={{ ...style, ...props.sx }}
    >
        {props.children}
    </TabList>
}