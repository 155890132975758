import { useMemo } from "react";
import { TableRow, TableCell, Tooltip, styled, alpha } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

import useOrderData from "components/Orders/useOrderData";
import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";
import LineItemOptions from "components/Orders/OrderView/LineItems/Rows/LineItemOptions";

import { IOrderLineItem } from "helpers/interfaces";
import { ILineItemGridProps } from "components/Orders/OrderView/LineItems/LineItemGrid";

interface IProps {
    lineItemGridProps: ILineItemGridProps,
    columns: LineItemColumn[];
    lineItem: IOrderLineItem,
}

const LineItem: React.FC<IProps> = ({ lineItemGridProps, columns, lineItem }: IProps) => {

    const { order, permissions } = useOrderData();

    const StyledCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    }));

    const StyledCommentCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            backgroundColor: alpha(theme.palette.secondary.light, 0.1),
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    }));

    const columnProps = useMemo((): IOrderLevelProps | undefined => {
        if (order && permissions) {
            return { lineItemGridProps: lineItemGridProps, order: order, permissions: permissions}
        }
        return undefined;
    }, [lineItemGridProps, order, permissions]);

    return <>
        <TableRow>
            {columns.map(lic => (
                <StyledCell key={lic.columnName} rowSpan={lic.rowSpan} align={lic.align}>
                    {columnProps ? lic.CellContent(columnProps, lineItem) : ""}
                </StyledCell>
            ))
            }
        </TableRow>

        {/* Options */}
        <LineItemOptions
            columns={columns}
            lineItem={lineItem}
        />

        {/* Comment (if it exists) */}
        <TableRow>
            {lineItem.comment ?
                <Tooltip title={lineItem.comment}>
                    <StyledCommentCell colSpan={columns.length - 1}>
                        {lineItem.comment}
                    </StyledCommentCell>
                </Tooltip>
                :
                <></>
            }
        </TableRow>
    </>;
}


export default LineItem;