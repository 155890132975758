import React, {  } from 'react';
import { Dialog, DialogTitle } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import useIsMobile from "helpers/hooks/useIsMobile";
import { ILineItem } from "helpers/interfaces";
import LineItemQuantity from "./LineItemQuantity";


interface IProps {
    dialogVisible: boolean;
    lineItem: ILineItem;
    onCancel: () => void;
    onSubmit: (lineItem: ILineItem, newQuantity: number) => void;
}

const LineItemQuantityDialog: React.FC<IProps> = ({ dialogVisible, lineItem, onCancel, onSubmit }: IProps) => {

    const tm = useTranslations();
    const isMobile = useIsMobile();

    if (!lineItem) {
        return <></>;
    }

    return <>
        <Dialog
            open={dialogVisible}
            onClose={onCancel}
            fullWidth={isMobile}
            maxWidth={isMobile ? "xs" : undefined}
        >
            <DialogTitle>{tm.Get("Quantity")}</DialogTitle>
            <LineItemQuantity
                lineItem={lineItem}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
        </Dialog>
    </>;

}

export default LineItemQuantityDialog;