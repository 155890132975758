import { Box, CircularProgress, Container, ContainerTypeMap, Fade, styled } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import React from 'react';

interface IProps extends DefaultComponentProps<ContainerTypeMap<{}, 'div'>> {
    waiting: boolean;
}

const LoadingOverlayContainer: React.FC<IProps> = (props: IProps) => {

    const { waiting, ...containerProps } = props;

    const OverlayContainer = styled(Container)(({ theme }) => ({

        position: "absolute",
        display: "block",
        top: -2,
        left: -2,
        bottom: -2,
        right: -2,
        zIndex: 99999,
        border: "2px solid transparent",
        borderRadius: 6,
        // borderColor: "red",

    }));


    const ColorOverlay = styled(Container)(({ theme }) => ({
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        opacity: .2,
        borderRadius: 6,
    }));

    return <Container
        sx={{ position: "relative" }}
        {...containerProps}
    >
        {/* {props.waiting && <> */}
        <Fade in={waiting}
            // timeout={250}
            style={{ transitionDelay: ".75s" }}
        >
            <OverlayContainer disableGutters maxWidth="xl">
                <ColorOverlay disableGutters maxWidth="xl" />
                <Box display="flex" height="100%" justifyContent="center" alignItems="center" >
                    <CircularProgress />
                </Box>
            </OverlayContainer>
        </Fade>
        {/* </>} */}
        {props.children}
    </Container>;
}

export default LoadingOverlayContainer;