import APIHelper from "helpers/APIHelper";
import { IGlobalOptionsSelections, IGlobalOptionsResults } from "helpers/interfaces";

export interface ILineItemSimple {
    orderNumber: string,
    lineItem: number,
    partNo: string,
    partNoSuffix: string,
}

export default class GlobalOptionsAPI {

    public static async InitAsync(oKey: number): Promise<void> {

        let url = "api/GlobalOptions/Init";

        const params = { oKey };

        await APIHelper.GetAsync<void>(url, params);
    }

    public static async GetSelectionsAsync(): Promise<IGlobalOptionsSelections> {

        let url = "api/GlobalOptions/Selections";

        const res = await APIHelper.GetAsync<IGlobalOptionsSelections>(url);
        return res.Result;
    }

    public static async PostSelectQuestion(question: string): Promise<IGlobalOptionsSelections> {

        let url = "api/GlobalOptions/SelectQuestion";

        const params = { question };

        const res = await APIHelper.PostAsync<IGlobalOptionsSelections>(url, params);
        return res.Result;
    }

    public static async PostSelectOldOption(optionCode: string): Promise<IGlobalOptionsSelections> {

        let url = "api/GlobalOptions/SelectOldOption";

        const params = { optionCode };

        const res = await APIHelper.PostAsync<IGlobalOptionsSelections>(url, params);
        return res.Result;
    }

    public static async PostSelectOldOptionValue(optionValue: string): Promise<IGlobalOptionsSelections> {

        let url = "api/GlobalOptions/SelectOldOptionValue";

        const params = { optionValue };

        const res = await APIHelper.PostAsync<IGlobalOptionsSelections>(url, params);
        return res.Result;
    }

    public static async PostSelectNewOption(optionCode: string): Promise<IGlobalOptionsSelections> {

        let url = "api/GlobalOptions/SelectNewOption";

        const params = { optionCode };

        const res = await APIHelper.PostAsync<IGlobalOptionsSelections>(url, params);
        return res.Result;
    }

    public static async PostSelectNewOptionValue(optionValue: string): Promise<IGlobalOptionsSelections> {

        let url = "api/GlobalOptions/SelectNewOptionValue";

        const params = { optionValue };

        const res = await APIHelper.PostAsync<IGlobalOptionsSelections>(url, params);
        return res.Result;
    }

    public static async PostCommitSelections(): Promise<IGlobalOptionsResults> {

        let url = "api/GlobalOptions/CommitSelections";

        const res = await APIHelper.PostAsync<IGlobalOptionsResults>(url);
        return res.Result;
    }

    public static async PostIgnoreLineItem(lineItem: number, ignore: boolean): Promise<IGlobalOptionsResults> {

        let url = "api/GlobalOptions/IgnoreLineItem";

        const params = { lineItem, ignore };

        const res = await APIHelper.PostAsync<IGlobalOptionsResults>(url, params);
        return res.Result;
    }

    public static async PostChangeOptionValue(odKey: number, sequence: number, newOption: string, newValue: string): Promise<IGlobalOptionsResults> {

        let url = "api/GlobalOptions/ChangeOptionValue";

        const params = { odKey, sequence, newOption, newValue };

        const res = await APIHelper.PostAsync<IGlobalOptionsResults>(url, params);
        return res.Result;
    }

    public static async PostSave(oKey: number): Promise<void> {

        let url = "api/GlobalOptions/Save";

        const params = { oKey };

        await APIHelper.PostAsync<void>(url, params);
    }    


}



