import React, { } from "react";

import QuotesAPI from "components/Quotes/QuotesAPI";
import { IQuote } from "helpers/interfaces";
import { DispatchActionsBase } from "components/Common/DispatchActionsBase";


export interface IEditQuoteData {
    quoteDefaults?: IQuote,
}

export enum EditQuoteActionTypeEnum {
    LoadQuoteDefaults,
}

export type EditQuoteActionType =
    { type: EditQuoteActionTypeEnum.LoadQuoteDefaults, value: IQuote };

const EditQuoteReducer: React.Reducer<IEditQuoteData, EditQuoteActionType> = (data: IEditQuoteData, action) => {

    switch (action.type) {
        case EditQuoteActionTypeEnum.LoadQuoteDefaults:
            return { ...data, quoteDefaults: action.value };
    }
};

const initialState: IEditQuoteData = {
    quoteDefaults: undefined
};

export class EditQuoteActions extends DispatchActionsBase<EditQuoteActionType> {

    public async LoadQuoteDefaultsAsync(webCustomerGUID: string): Promise<void> {
        const response = await QuotesAPI.GetQuoteDefaults(webCustomerGUID);
        const quoteDefaults = response.Result;

        if (response.IsOk()) {
            this.dispatch({ type: EditQuoteActionTypeEnum.LoadQuoteDefaults, value: quoteDefaults });
        }
    }

    public async UpdateQuoteHeaderAsync(oKey: number, quoteHeader: IQuote): Promise<void> {
        await QuotesAPI.PutQuoteHeader(oKey, quoteHeader);
    }

}

export const EditQuoteContextDispatch = React.createContext<EditQuoteActions | undefined>(undefined);
export const EditQuoteContext = React.createContext<IEditQuoteData>(initialState);

export const EditQuoteContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(EditQuoteReducer, initialState);

    const actions = React.useMemo<EditQuoteActions>(() => {
        return new EditQuoteActions(dispatch);
    }, [dispatch]);

    return <EditQuoteContext.Provider value={state}>
        <EditQuoteContextDispatch.Provider value={actions}>
            {props.children}
        </EditQuoteContextDispatch.Provider>
    </EditQuoteContext.Provider>
}

export default EditQuoteContext;
