import React, { useMemo } from 'react';
import { Dialog, DialogContent, Stack, Typography, useTheme } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import useIsMobile from "helpers/hooks/useIsMobile";

import { IProdStatusUnitRejectHistory } from "helpers/interfaces";
import useOrderData from "../useOrderData";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import Format from "helpers/fv.format";
import { DateTime } from "luxon";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

interface IProps {
    dialogVisible: boolean;
    onClose: () => void;
}

interface IProdStatusUnitRejectHistoryRow extends IProdStatusUnitRejectHistory {
    formattedPart: string,
}

const ProdStatusUnitRejectHistory: React.FC<IProps> = ({ dialogVisible, onClose }: IProps) => {

    const tm = useTranslations();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("ProdStatusUnitRejectHistory");
    const { order, prodStatusUnitRejectHistory } = useOrderData();

    const UnitRejectHistoryRows = useMemo(() => {
        const details: IProdStatusUnitRejectHistoryRow[] = [];

        if (order && prodStatusUnitRejectHistory?.unitRejectHistory) {
            prodStatusUnitRejectHistory.unitRejectHistory.forEach((uh) => {
                const props: IProdStatusUnitRejectHistoryRow = {
                    ...uh,
                    formattedPart: Format.FormatPartDescription(uh.partNo, uh.partNoSuffix, uh.description),
                };
                details.push(props);
            });

            return details;

        } else {
            return null;
        }

    }, [prodStatusUnitRejectHistory?.unitRejectHistory, order]);

    const generator = useMemo(() => {
        const columns = new DataGridColumnGenerator(tm, UnitRejectHistoryRows, theme, isMobile);
        columns.TryAddDateTimeColumn("dateTime", "Date/Time", DateTime.DATETIME_SHORT_WITH_SECONDS, { flex: 2 });
        columns.TryAddTextColumn("stationID", "Station", { flex: 1 });
        columns.TryAddTextColumn("rejectDescription", "Reject Reason", { flex: 1 });
        columns.TryAddTextColumn("formattedPart", "Part", { flex: 4, hideInMobile: true });
        return columns;
    }, [tm, UnitRejectHistoryRows, theme, isMobile]);

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        const history = row as IProdStatusUnitRejectHistory;
        return `${history.stationID}-${history.dateTime}-${history.rejectDescription}`;
    };

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Dialog
            open={dialogVisible}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogContent sx={{ padding: 1 }}>
                <Stack direction="column" gap={1}>
                    <Typography variant="h5">
                        {tm.Get("Reject Status")}
                    </Typography>
                    <Typography>
                        {tm.Get("Schedule:")} {prodStatusUnitRejectHistory?.schedule.schedID} - {prodStatusUnitRejectHistory?.schedule.description} {tm.Get("Unit:")} {prodStatusUnitRejectHistory?.unit.unitID}
                    </Typography>
                    {prodStatusUnitRejectHistory &&
                        <CustomDataGridPro
                            getRowId={getRowId}
                            columns={generator.GetColumns()}
                            rows={UnitRejectHistoryRows}
                            onPageSizeChange={onPageSizeChange}
                            pageSize={rowsPerPage.pageSize}
                            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                        />
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    </>;

}

export default ProdStatusUnitRejectHistory;