import { IGlobalOptions } from "components/GlobalOptions/GlobalOptionsContext";

import { IGlobalItem } from "models/GlobalOptions/IGlobalItem";

export default abstract class ResultColumn {

    columnName: string;
    headerText: string;
    width: string;
    align?: "inherit" | "left" | "center" | "right" | "justify";

    public constructor(columnName: string, headerText: string, width: string) {
        this.columnName = columnName;
        this.headerText = headerText;
        this.width = width;
    }

    public abstract CellContent(globalOptions: IGlobalOptions, globalItem: IGlobalItem, previousItem?: IGlobalItem): JSX.Element

}


