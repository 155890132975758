import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useOrderData from "components/Orders/useOrderData";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import useQuoteData from "components/Quotes/useQuoteData";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";

import { ILineItem } from "models/IQuote";
import { useMemo } from "react";
import OrderNavigation from "components/Orders/OrderNavigation";
import useOnlineAckToken from "helpers/hooks/useOnlineAckToken";

interface IProps {
    lineItem: ILineItem,
    variant: any,
    showQuantity: boolean,
    showSqFtPrice: boolean,
    onQtyClick?: (lineItem: ILineItem) => void,
    isOrder: boolean;
}

const LineItemUnitPrice: React.FC<IProps> = ({ lineItem, variant, showQuantity, showSqFtPrice, onQtyClick, isOrder }: IProps) => {

    const { quote } = useQuoteData();
    const { order } = useOrderData();
    const onlineAckToken = useOnlineAckToken();

    const currencyFormatter = useCurrencyFormatter(isOrder ? order?.currencyCulture : quote?.currencyCulture);

    const getPriceText = (lineItem: ILineItem): string => {
        return currencyFormatter.Format(lineItem.unitPrice);
    }

    const getSqFtPriceText = (lineItem: ILineItem): string => {
        return currencyFormatter.Format(lineItem.sqFtPrice);
    }

    const isPriceBold = useMemo(() => {
        return lineItem.priceOverride;
    }, [lineItem]);

    const priceBreakdownURL = useMemo(() => {
        if (isOrder) {
            return OrderNavigation.LineItemPriceBreakdownURL(order?.oKey ?? 0, lineItem.odKey, onlineAckToken)
        }
        else {
            return QuoteNavigation.LineItemPriceBreakdownURL(quote?.oKey ?? 0, lineItem.odKey)
        }
    }, [isOrder, lineItem.odKey, order, quote, onlineAckToken]);

    return <>
        {showQuantity && onQtyClick &&
            <Link component="button" underline="hover" onClick={(event: React.SyntheticEvent) => { event.preventDefault(); onQtyClick(lineItem); }}
                variant={variant} sx={{mr: 1}} >
                {`${lineItem.quantity} x`}
            </Link>
        }
        {showQuantity && !onQtyClick &&
            <Typography variant={variant} mr={1}>
                {`${lineItem.quantity} x`}
            </Typography>
        }
        {showSqFtPrice ?
            <Typography variant={variant} fontWeight={isPriceBold ? "bold" : ""} >
                {getSqFtPriceText(lineItem)}
            </Typography>
            :
            <Link component={RouterLink} to={priceBreakdownURL} underline="hover"
                variant={variant} textAlign="right" fontWeight={isPriceBold ? "bold" : ""} >
                {getPriceText(lineItem)}
            </Link>
        }
    </>;

};

export default LineItemUnitPrice;