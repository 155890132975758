import { InputAdornment, Tooltip } from "@mui/material";
import { Error } from "@mui/icons-material";

const ErrorAdornment = (props: { validationError: string, position?: "start" | "end" }) => {
    return <>
        <Tooltip title={props.validationError}>
            <InputAdornment position={props.position ?? "end"}>
                <Error color="error" />
            </InputAdornment>
        </Tooltip>
    </>
}

export default ErrorAdornment;