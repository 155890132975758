import React from "react";
import { UserContext } from 'helpers/context/User/UserContext';

export const UserContextValueAssurance: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    // Children of this component will not be rendered until the application context value is defined
    return <>
        <UserContext.Consumer>
            {apiUser => apiUser && props.children}
        </UserContext.Consumer>
    </>;
    
}

export default UserContextValueAssurance;