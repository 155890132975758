import { Grid } from "@mui/material";
import usePageData from "helpers/context/Page/usePageData";
import React from 'react';

interface IProps {

}

const ActionFooter: React.FC<IProps> = (props: IProps) => {


    const pageInfo = usePageData();

    const containerRef = React.useRef<HTMLDivElement>(null);

    if (!pageInfo.footerContent)
        return null;

    return <Grid container direction="row" justifyContent="stretch"
         ref={containerRef} sx={(theme) => {
        return {
            background: theme.palette.background.paper
        }
    }}>
        <Grid item width="100%">
            {pageInfo.footerContent}
        </Grid>
    </Grid>;
}

export default ActionFooter;


