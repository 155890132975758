import React from "react"
import { Paper, Box, Grid } from "@mui/material";

import LabeledText from "components/Common/LabeledText";
import { useTranslations } from "@fenetech/translations";
import useEmployee from "helpers/context/SelectionValues/useEmployee";
import useQuoteData from "components/Quotes/useQuoteData";

import Format from "helpers/fv.format";


interface IProps {

}

const FooterCard: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const { quote } = useQuoteData();
    const enteredBy = useEmployee(quote?.enteredBy);

    return <>
        <Paper>
            <Box sx={{ margin: 1 }}>
                <Grid container>
                    <Grid item xs={4}>
                        <LabeledText label={tm.Get("Entered By")} text={enteredBy?.fullName} isLoading={enteredBy === undefined} />
                    </Grid>
                    <Grid item xs={4}>
                        <LabeledText label={tm.Get("Date")} text={Format.FormatDate(quote?.orderDate)} />
                    </Grid>
                    <Grid item xs={4}>
                        <LabeledText label={tm.Get("Expires")} text={Format.FormatDate(quote?.expirationDate)} />
                    </Grid>
                </Grid>
            </Box>
        </Paper>

    </>

};


export default FooterCard;