import React, { useState, useCallback } from "react";
import { TextField, Stack, IconButton, Tooltip, Autocomplete } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import useIsMobile from "helpers/hooks/useIsMobile";
import { useTranslations } from "@fenetech/translations";
import AddSchedule from "./AddSchedule";
import EditSchedule from "./EditSchedule";
import { IPriceSchedule } from "helpers/interfaces";
import useMessageBox from "helpers/context/Page/useMessageBox";

interface IProps {
    schedules: IPriceSchedule[],
    selectedSchedule: IPriceSchedule | null,
    handleScheduleChange: (schedule: IPriceSchedule) => void,
    addHandler: (name: string) => void,
    editHandler: (name: string) => void,
    deleteHandler: () => void,
}

const PricingSchedulesControls = ({schedules, selectedSchedule, handleScheduleChange, addHandler, editHandler, deleteHandler} : IProps) => {

    const isMobile = useIsMobile();
    const tm = useTranslations();
    const messageBox = useMessageBox();

    const [addClicked, setAddClicked] = useState<boolean>(false);
    const [editClicked, setEditClicked] = useState<boolean>(false);

    const deletePrompt = useCallback(() => {
        messageBox.Show({
            message: tm.Get("Are you sure you want to delete this schedule?"), 
            title: tm.GetWithParams("Delete {0}", tm.Get("Schedule")),
            yesNoPrompt: true,
            callback: (result) => {
                if (result) deleteHandler();
            }
        });
    }, [messageBox, tm, deleteHandler]);

    return <>
        <Stack direction="row" spacing={1} width={isMobile ? 1 : (1 / 3)}>
            <Autocomplete
                id="select-table"
                autoComplete
                autoHighlight
                autoSelect
                selectOnFocus
                disableClearable
                handleHomeEndKeys
                options={schedules}
                fullWidth
                size="small"
                getOptionLabel={(option : IPriceSchedule) => option.description}
                renderInput={(params) => <TextField
                    label={tm.Get("Schedule")}
                    {...params}
                />}
                value={selectedSchedule ?? {priceScheduleID: 0, description: "", gridAdderPrice: 0, gridAdderIncrement: 0}}
                onChange={(_, data) => handleScheduleChange(data)}
            />

            <Tooltip title={tm.GetWithParams("Add {0}", tm.Get("Schedule"))}>
                <IconButton onClick={() => setAddClicked(true)}>
                    <AddIcon fontSize="small" color="primary"/>
                </IconButton>
            </Tooltip>
            
            {selectedSchedule && <>
                <Tooltip title={tm.GetWithParams("Edit {0}", tm.Get("Schedule Name"))}>
                    <IconButton onClick={() => setEditClicked(true)}>
                        <EditIcon fontSize="small" color="primary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={tm.GetWithParams("Delete {0}", tm.Get("Schedule"))}>
                    <IconButton onClick={deletePrompt}>
                        <DeleteIcon fontSize="small" color="primary"/>
                    </IconButton>
                </Tooltip>
            </>}
        </Stack>

        {addClicked &&
            <AddSchedule 
                open={addClicked}
                close={() => setAddClicked(false)}
                handleAdd={(name: string) => {
                    setAddClicked(false);
                    addHandler(name);
                }}
            />   
        }   

        {editClicked && 
            <EditSchedule 
                oldName={selectedSchedule!.description}
                open={editClicked}
                close={() => setEditClicked(false)}
                handleEdit={(name: string) => {
                    setEditClicked(false);
                    editHandler(name);
                }}
            />   
        }
    </>
}

export default PricingSchedulesControls;