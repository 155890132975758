import React, { useState, useCallback } from "react";
import { Typography, Dialog, DialogContent, DialogActions, TextField, Stack } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import ErrorAdornment from "components/Common/ErrorAdornment";
import OKCancelControl from "components/Common/OKCancelControl";

interface IProps {
    oldPrice: string;
    open: boolean;
    close: () => void;
    handleEdit: (name: string) => void;
}

const EditScheduleItem: React.FC<IProps> = ({oldPrice, open, close, handleEdit}) => {
    const tm = useTranslations();
    const cf = useCurrencyFormatter("");
    const message = tm.Get("Edit the UI price");

    const [newPrice, setNewPrice] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");

    const handlePriceChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPrice(e.target.value);
    }, []);

    const validate = useCallback((price: string) => {
        let numericPrice = cf.Parse(price);
        if (price === "" || isNaN(numericPrice)) {
            return tm.Get("Price must be a valid currency value.")
        }
        return "";
    }, [tm, cf]);

    const handlePriceBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        let validateText = validate(e.target.value.trim());
        setErrorText(validateText);
    }, [validate]);
    
    const trySubmit = useCallback((price: string) => {
        let error = validate(price);
        if (error === "") {
            handleEdit(price);
        } else {
            setErrorText(error);
        }
    }, [handleEdit, validate]);

    return <>
        <Dialog fullWidth
            onClose={close}
            open={open}
        >
            <DialogTitleWithClose onClose={close}>
                {tm.GetWithParams("Edit {0}", tm.Get("UI"))}
            </DialogTitleWithClose>
            <DialogContent>
                <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={2}>
                    <Typography whiteSpace="pre-wrap">
                        {message}
                    </Typography>
                    <TextField size="small" label={tm.Get("Old Price")} value={oldPrice} fullWidth disabled InputProps={{
                            inputProps: {style: { textAlign: "right" }}
                        }}
                    />
                    <TextField size="small" label={tm.Get("New Price")} value={newPrice} onChange={handlePriceChange} onBlur={handlePriceBlur} fullWidth 
                        error={errorText !== ""}
                        InputProps={{
                            endAdornment: errorText !== "" ? 
                                <ErrorAdornment validationError={errorText}/> : null,
                            inputProps: {style: { textAlign: "right" }}
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <OKCancelControl okCommand={() => trySubmit(newPrice)} cancelCommand={close} />
            </DialogActions>
        </Dialog>
    </>
}

export default EditScheduleItem;