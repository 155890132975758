export abstract class DispatchActionsBase<T> {

    #dispatcher: React.Dispatch<T>;

    constructor(dispatcher: React.Dispatch<T>) {
        this.#dispatcher = dispatcher;
    }

    protected dispatch(action: T) {
        this.#dispatcher(action);
    }
}

