import * as React from "react";

import ItemValidationItem from "./ItemValidationItem";

import WizardHelper from "../WizardHelper";

import { IValidationInfo, IValidationInfoItem } from "../interfaces";
import LoadingOverlayContainer from "components/Common/LoadingOverlayContainer";


interface IValidationProps {
    validations: IValidationInfo;
}

const ItemValidation: React.FC<IValidationProps> = (props: IValidationProps) => {

    const vi: IValidationInfo = props.validations;

    if (vi && vi.validationEligible && vi.messages.length > 0) {
        return <LoadingOverlayContainer waiting={vi.waiting}>
            {props.validations.messages.map((vi: IValidationInfoItem) =>
                <ItemValidationItem key={vi.type + "_" + WizardHelper.GenerateStringHash(vi.message)} vi={vi} />
            )}
        </LoadingOverlayContainer>

    } else {
        return null;
    }
}

export default ItemValidation;
