import React, { useContext, useEffect } from "react"
import { Typography, Grid, Stack, Container } from "@mui/material";
import { Cancel } from "@mui/icons-material";

import ErrorContext from "components/Errors/ErrorContext";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@fenetech/translations";

interface IProps {
}

const ErrorPage: React.FC<IProps> = (props: IProps) => {

    const { errorMessage } = useContext(ErrorContext);
    const navigate = useNavigate();
    const tm = useTranslations();

    useEffect(() => {
        if (!errorMessage || errorMessage === "") {
            navigate("/");
        }
    }, [errorMessage, navigate]);

    return <>
        <Container >
            <Stack direction="column">
                <Typography variant="h4" textAlign="center" mt={1}>
                    {tm.Get("The site has encountered an internal server error")}
                </Typography>

                {errorMessage &&
                    <Grid item margin={1}>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <Cancel color="error" />
                            <Typography>
                                {errorMessage}
                            </Typography>
                        </Stack>
                    </Grid>
                }

            </Stack>
        </Container>
    </>
}

export default ErrorPage;