
import React from "react";
import { IDashboardRMA } from "helpers/interfaces";
import DashboardAPI from "../DashboardAPI";
import DashboardContext, { ActionTypeEnum, DashboardContextDispatch } from "../DashboardContext";


export function useDashboardRMAData(): IDashboardRMA[]|undefined {

    const dashboardData = React.useContext(DashboardContext);
    const dispatch = React.useContext(DashboardContextDispatch);
    const rmaData = dashboardData.rmas;

    const isExpired = (dashboardData.rmaDataExpTime < Date.now()  );

    React.useEffect(() => {

        if (dispatch) {
            if (!rmaData || isExpired) {
                DashboardAPI.QueryOpenRMAs().then(o => {
                    dispatch({ type: ActionTypeEnum.SetRMAs, value: o });
                });
            }
        }

    }, [dispatch, rmaData, isExpired]);

    return rmaData;

};