
import isNumeric from "fast-isnumeric";
import { ITranslationManager } from "@fenetech/translations";
import { IEngineeringUnitSets } from "helpers/interfaces";
import { DateTime } from "luxon";
import { PricingMethodEnum } from "./enums";
import { ILocaleNumberFormatter } from "./hooks/useLocaleNumberFormatter";
import { DateTimeIsEmpty } from "./objects";

export enum ImperialFormatModeEnum {
    SHOW_FRACTIONS = 0,
    SHOW_DECIMAL_IF_NOT_CLEAN = 1,
    SHOW_DECIMAL = 2
}

export enum MeasurementSystemEnum {
    Unknown = 0,
    Imperial = 1,
    Metric = 2
}

export interface ICallsizeArgs {
    callSize: string;
    width: number;
    height: number;
}


export default class Format {

    static DECIMAL_TOLERANCE: number = 0.0001;

    #engineeringUnits: IEngineeringUnitSets;
    #TM: ITranslationManager;
    #lnf: ILocaleNumberFormatter;

    #imperialHighPrecision: number = 1;
    #imperialDenominator: number = 1;
    #metricHighPrecision: number = 1;
    #metricDenominator: number = 1;

    public constructor(engineeringUnits: IEngineeringUnitSets, tm: ITranslationManager, lnf: ILocaleNumberFormatter) {
        this.#engineeringUnits = engineeringUnits;
        this.#TM = tm;
        this.#lnf = lnf;

        const imperial = this.#engineeringUnits.unitSets.find(s => s.setID === MeasurementSystemEnum.Imperial);
        const metric = this.#engineeringUnits.unitSets.find(s => s.setID === MeasurementSystemEnum.Metric);

        if (imperial) {
            this.#imperialDenominator = imperial.defaultUnitFractionFormat;
            this.#imperialHighPrecision = imperial.highPrecisionUnitFractionFormat;
        }

        if (metric) {
            this.#metricDenominator = metric.defaultUnitFractionFormat;
            this.#metricHighPrecision = metric.highPrecisionUnitFractionFormat;
        }

    }

    public static FormatPartDescription(partNo: string, partNoSuffix: string, description?: string): string {

        let formatted: string = partNo + ((partNoSuffix === "0000" || partNoSuffix === "") ? "" : `[${partNoSuffix}]`);

        if (description)
            formatted = formatted + " - " + description;

        return formatted;
    }

    public static FormatLineItem(lineItem: number, subLineItem: number): string {

        if (subLineItem) {
            return `${lineItem}.${this.FormatSubLineItem(subLineItem)}`;
        }
        return `${lineItem}`;
    }

    public static FormatSubLineItem(vintSubLineItem: number): string {
        //'this converts negative sub line item numbers into a readable format
        //'positive numbers have no conversion                            1 = 1
        //'negative numbers between -99 and -1 are converted to positive   -1 = 1
        //'negative numbers below -100 are actualy sub-sublineitems  -102 = 1.2,  -205 = 2.5

        if (vintSubLineItem < 0 && vintSubLineItem >= -100) {
            return (vintSubLineItem * -1).toString();
        } else if (vintSubLineItem < -100) {
            return (-1 * (vintSubLineItem - (vintSubLineItem % 100)) / 100).toString() + "." + (-1 * vintSubLineItem % 100).toString();
        } else {
            return vintSubLineItem.toString();
        }
    }

    /*This is a direct port of FV.Common.FormatMethods */

    public FormatDimensions(width: number, height: number, thickness: number, unitSet: MeasurementSystemEnum): string {

        let returnValue: string = "";

        let wTranslated: string = this.#TM.Get("W");
        let hTranslated: string = this.#TM.Get("H");
        let tTranslated: string = this.#TM.Get("T");

        if (width !== 0) {
            returnValue = this.formatDimensionText(width, unitSet, ImperialFormatModeEnum.SHOW_FRACTIONS, false) + " " + wTranslated;
        }

        if (height !== 0) {
            if (returnValue.length !== 0)
                returnValue = returnValue + " X ";

            returnValue = returnValue + this.formatDimensionText(height, unitSet, ImperialFormatModeEnum.SHOW_FRACTIONS, false) + " " + hTranslated;
        }

        if (thickness !== 0) {
            if (returnValue.length !== 0)
                returnValue = returnValue + " X ";

            returnValue = returnValue + this.formatDimensionText(thickness, unitSet, ImperialFormatModeEnum.SHOW_FRACTIONS, false) + " " + tTranslated;

        }

        return returnValue;

    }

    public formatDimensionText(dimensionLength: number, measurementSystem: MeasurementSystemEnum, imperialFormatMode: ImperialFormatModeEnum, highPrecision: boolean): string {

        if (dimensionLength === null || dimensionLength === undefined) { return ''; }

        if (measurementSystem === 0) { measurementSystem = this.#engineeringUnits.systemUnitSet; }

        let denominator: number = 0;

        switch (measurementSystem) {
            case 1:
                if (highPrecision) {
                    denominator = this.#imperialHighPrecision;
                } else {
                    denominator = this.#imperialDenominator;
                }
                break;
            case 2:
                if (highPrecision) {
                    denominator = this.#metricHighPrecision;
                } else {
                    denominator = this.#metricDenominator;
                }
                break;
        }


        let decimalValue: number | null;

        if (measurementSystem === MeasurementSystemEnum.Imperial) {

            switch (imperialFormatMode) {
                case ImperialFormatModeEnum.SHOW_FRACTIONS:
                    return Format.decToFrac(dimensionLength, denominator);

                case ImperialFormatModeEnum.SHOW_DECIMAL:
                    decimalValue = Format.fracToDec(Format.decToFrac(dimensionLength, denominator));
                    return this.#lnf.Format(decimalValue ?? dimensionLength);

                case ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN:

                    let fractionString = Format.decToFrac(dimensionLength, denominator)
                    decimalValue = Format.fracToDec(fractionString);

                    if (decimalValue === null) {
                        return this.#lnf.Format(dimensionLength);
                    }

                    if (Math.abs(decimalValue - dimensionLength) < Format.DECIMAL_TOLERANCE) {
                        /*this is an 'exact' fraction */
                        return fractionString;
                    } else {

                        decimalValue = dimensionLength;

                        /*truncate to the correct number of decimal places*/
                        var decimalPlaces = 5;
                        switch (denominator) {
                            case 64:
                                decimalPlaces = 6;
                                break;
                            case 32:
                                decimalPlaces = 5;
                                break;
                            case 16:
                                decimalPlaces = 4;
                                break;
                            case 8:
                                decimalPlaces = 3;
                                break;
                            case 4:
                                decimalPlaces = 2;
                                break;
                            case 2:
                                decimalPlaces = 1;
                                break;
                            default:
                                let str: string = denominator.toString();
                                decimalPlaces = str.length;

                        }


                        decimalValue = decimalValue * Math.pow(10, decimalPlaces);
                        decimalValue = Math.round(decimalValue);
                        decimalValue = decimalValue / Math.pow(10, decimalPlaces);

                        return this.#lnf.Format(decimalValue);
                    }

            }


        } else if (measurementSystem === MeasurementSystemEnum.Metric) {

            decimalValue = Format.fracToDec(Format.decToFrac(dimensionLength, denominator));
            return this.#lnf.Format(decimalValue ?? dimensionLength);
        } else {
            //Programming error
            return this.#lnf.Format(dimensionLength);
        }

    }


    public static decToFrac(value: number, denominator: number) {

        if (!denominator)
            throw new Error("Invalid denominator supplied");

        let isNegative: boolean = false;
        let front: number;
        let back: number;
        let numerator: number;
        let GDC: number;

        if (value < 0) { isNegative = true; }

        if (isNegative) { value = value * -1; }

        if (value >= 0) {
            front = Math.floor(value);
        } else {
            front = Math.floor(value * -1) * -1;
        }

        back = value - front;
        numerator = Math.floor(back * denominator);

        let a: number = numerator;
        let b: number = denominator;
        let t: number = 0;

        while (b !== 0) {
            t = a % b;
            a = b;
            b = t;
        }

        GDC = a;

        if (GDC !== 0 && numerator === denominator) {
            numerator = 0;
            if (isNegative) {
                front = front - 1;
            } else {
                front = front + 1;
            }
        }

        let retval: string = '';

        if (isNegative) { retval = '-'; }

        if (front > 0)
            retval = retval + front.toString();
        else if (numerator === 0)
            retval = retval + '0';

        retval = retval + ' ';

        if (numerator === 0)
            retval = retval + '';
        else {
            numerator = numerator / GDC;
            denominator = denominator / GDC;

            retval = retval + numerator.toString() + '/' + denominator.toString();
        }

        return retval.trim();
    }

    public static fracToDec(frac: string): number | null {

        let i = 1;
        let dec = 0;
        let lenFrac;
        let letter;
        let wholeNumExists = false;
        let isNegative = false;
        let numeratorExists = false;
        let sNumerator = '';
        let denominatorExists = false;
        let sWholeNumber = '';
        let sDenominator = '';

        frac = frac.trim();

        if (isNumeric(frac)) { return parseFloat(frac); }

        lenFrac = frac.length;

        if (lenFrac === 0)
            return null;

        while (i <= lenFrac) {
            letter = frac.substring(i - 1, i);

            if (i === 1 && letter === '-') {
                if (lenFrac === 1)
                    return null; //just negative sign

                isNegative = true;
            } else if (isNumeric(letter)) {
                if (isNegative && i === 2 && letter === "0" && lenFrac === 2)
                    return null; //negative 0

                if (wholeNumExists) { numeratorExists = true; }

                if (denominatorExists)
                    sDenominator = sDenominator + letter;
                else if (numeratorExists) {
                    if (i === lenFrac)
                        return null //missing denominator

                    sNumerator = sNumerator + letter;
                } else
                    sWholeNumber = sWholeNumber + letter;
            } else if (letter === '/') {
                if (i === lenFrac) { return null; }
                else if (denominatorExists) { return null; }
                else if (numeratorExists) { denominatorExists = true; }
                else if (wholeNumExists) { return null; }
                else if (i === 1 || (i === 2 && isNegative)) { return null; }
                else {
                    numeratorExists = true;
                    denominatorExists = true;
                    sNumerator = sWholeNumber;
                    sWholeNumber = '';
                }

            } else if (letter === ' ') {
                if (i === 2 && isNegative) { return null; }
                else if (numeratorExists) { return null; }
                else { wholeNumExists = true; }
            } else
                return null; //letter not understood

            i = i + 1;
        }

        if (denominatorExists) {
            if (parseInt(sDenominator) === 0) { return null; }

            if (parseInt(sNumerator) > parseInt(sDenominator)) { return null; }

            dec = parseFloat(sNumerator) / parseFloat(sDenominator);

        }

        if (!isNaN(parseInt(sWholeNumber))) { dec = dec + parseInt(sWholeNumber); }

        if (isNegative) { dec = dec * -1; }

        return dec;
    }


    public static formatCallSize(args: ICallsizeArgs) {

        let rstrSize: string = args.callSize;
        let rsngWidth: number = args.width;
        let rsngHeight: number = args.height;

        if (rstrSize.length < 4 || rstrSize.length > 13) {
            return false;
        }

        let strLeft: string = '';
        let strTempLeft: string = '';
        let strRight: string = '';
        let strTempRight: string = '';
        let strMult: string = '';
        let strCallSize: string = '';
        let idx: number;

        rsngWidth = -1;
        rsngHeight = -1;

        //'get a local copy of the callsize since its passed ByRef
        strCallSize = rstrSize;

        //first look at the second to last character to see if it's a combo unit (twin, triple, etc)
        if (strCallSize.substring(strCallSize.length - 2, strCallSize.length - 1) === '-') {
            strMult = strCallSize.substring(strCallSize.length - 1, strCallSize.length);
            strCallSize = strCallSize.substring(0, strCallSize.length - 2);
        }


        switch (strCallSize.length) {
            case 4:
                //FIFI
                if (isNumeric(strCallSize)) {
                    strLeft = strCallSize.substring(0, 2);
                    strRight = strCallSize.substring(2, 4);
                }
                break;
            case 5:
                //FI-FI
                idx = strCallSize.indexOf('-')
                if (idx > 0) {
                    if (idx === 2) {
                        strLeft = strCallSize.substring(0, 2);
                        strRight = strCallSize.substring(3, 5);
                    }
                }
                break;
            case 6:
                //FI-FII
                //FII-FI
                idx = strCallSize.indexOf('-')
                if (idx > 0) {
                    if (idx === 2) {
                        strLeft = strCallSize.substring(0, 2);
                        strRight = strCallSize.substring(3, 6);
                    } else if (idx === 3) {
                        strLeft = strCallSize.substring(0, 3);
                        strRight = strCallSize.substring(4, 6);
                    }
                }
                break;
            case 7:
                //FI-FF/I
                //FI-F/II
                //F/II-FI
                //FF/I-FI
                //FII-FII
                idx = strCallSize.indexOf('-')
                if (idx > 0) {
                    if (idx === 2) {
                        strLeft = strCallSize.substring(0, 2);
                        strRight = strCallSize.substring(3, 7);
                    } else if (idx === 3) {
                        strLeft = strCallSize.substring(0, 3);
                        strRight = strCallSize.substring(4, 7);
                    } else if (idx === 4) {
                        strLeft = strCallSize.substring(0, 4);
                        strRight = strCallSize.substring(5, 7);
                    }
                }
                break;
            case 8:
                //FI-FF/II
                //FF/II-FI
                //FII-FF/I
                //FF/I-FII
                //FII-F/II
                //F/II-FII
                idx = strCallSize.indexOf('-')
                if (idx > 0) {
                    if (idx === 2) {
                        strLeft = strCallSize.substring(0, 2);
                        strRight = strCallSize.substring(3, 8);
                    } else if (idx === 3) {
                        strLeft = strCallSize.substring(0, 3);
                        strRight = strCallSize.substring(4, 8);
                    } else if (idx === 4) {
                        strLeft = strCallSize.substring(0, 4);
                        strRight = strCallSize.substring(5, 8);
                    } else if (idx === 5) {
                        strLeft = strCallSize.substring(0, 5);
                        strRight = strCallSize.substring(6, 8);
                    }
                }
                break;
            case 9:
                //FII-FF/II
                //FF/II-FII
                //FF/I-FF/I
                //F/II-F/II
                //FF/I-F/II
                //F/II-FF/I
                idx = strCallSize.indexOf('-')
                if (idx > 0) {
                    if (idx === 3) {
                        strLeft = strCallSize.substring(0, 3);
                        strRight = strCallSize.substring(4, 9);
                    } else if (idx === 4) {
                        strLeft = strCallSize.substring(0, 4);
                        strRight = strCallSize.substring(5, 9);
                    } else if (idx === 5) {
                        strLeft = strCallSize.substring(0, 5);
                        strRight = strCallSize.substring(6, 9);
                    }
                }
                break;
            case 10:
                //FF/I-FF/II
                //FF/II-FF/I
                //F/II-FF/II
                //FF/II-F/II
                idx = strCallSize.indexOf('-')
                if (idx > 0) {
                    if (idx === 4) {
                        strLeft = strCallSize.substring(0, 4);
                        strRight = strCallSize.substring(5, 10);
                    } else if (idx === 5) {
                        strLeft = strCallSize.substring(0, 5);
                        strRight = strCallSize.substring(6, 10);
                    }
                }
                break;
            case 11:
                //FF/II-FF/II
                idx = strCallSize.indexOf('-')
                if (idx > 0) {
                    if (idx === 5) {
                        strLeft = strCallSize.substring(0, 5);
                        strRight = strCallSize.substring(6, 11);
                    }
                }
        }


        if (strLeft.length !== 0 && strRight.length !== 0) {
            if (isNumeric(strLeft) && isNumeric(strRight)) {
                rsngWidth = this.calculateWorH(strLeft);
                rsngHeight = this.calculateWorH(strRight);
            } else {
                strTempLeft = strLeft.replace('/', '');
                strTempRight = strRight.replace('/', '');

                if (isNumeric(strTempLeft) && isNumeric(strTempRight)) {
                    rsngWidth = this.calculateWorH(strLeft);
                    rsngHeight = this.calculateWorH(strRight);
                }
            }
        }

        if (strMult.length !== 0 && rsngWidth !== -1) {
            if (isNumeric(strMult)) {
                if (parseInt(parseFloat(strMult).toString()) >= 1 && parseInt(parseFloat(strMult).toString()) <= 9) {
                    rsngWidth = rsngWidth * parseInt(parseFloat(strMult).toString());
                } else {
                    rsngWidth = -1;
                }
            } else {
                rsngWidth = -1;
            }
        }

        var success = false;

        if (rsngWidth === -1 || rsngHeight === -1) {
            rstrSize = '';
            success = false;
        } else {
            success = true;
        }

        args.callSize = rstrSize;
        args.width = rsngWidth;
        args.height = rsngHeight;

        return success;

    }

    private static calculateWorH(strSize: string) {
        //Receive a 2, 3, 4 or 5 digit portion of a call size and derive the correct inches
        let strLeft: string = '';
        let strRight: string = '';

        let CalculateWorH: number = -1;

        if (strSize.length === 2) {
            strLeft = strSize.substring(0, 1);
            strRight = strSize.substring(1, 2);
        } else if (strSize.length === 3) {
            strLeft = strSize.substring(0, 1);
            strRight = strSize.substring(1, 3);
        } else if (strSize.length === 4) {
            if (strSize.indexOf('/') === 1) {
                strLeft = strSize.substring(0, 1);
                strRight = strSize.substring(2, 4);
            } else if (strSize.indexOf('/') === 2) {
                strLeft = strSize.substring(0, 2);
                strRight = strSize.substring(3, 4);
            }
        } else if (strSize.length === 5) {
            if (strSize.indexOf('/') === 2) {
                strLeft = strSize.substring(0, 2);
                strRight = strSize.substring(3, 5);
            }
        }

        if (strLeft.length > 0 && strRight.length > 0) {
            if (isNumeric(strLeft) && isNumeric(strRight)) {
                if (parseFloat(strLeft) % 1 === 0 && parseFloat(strRight) % 1 === 0) {
                    if (parseInt(strRight) < 12) {
                        CalculateWorH = parseInt(strLeft) * 12 + parseInt(strRight);
                    }
                }
            }
        }

        return CalculateWorH;

    }

    public static FormatCityStateZip(city: string, state: string, zip: string): string {
        if (state || zip) {
            return `${city}, ${state} ${zip}`;
        }
        else {
            return city;
        }
    }

    public static ConvertDateToLuxonDateTime(date: Date | string) {
        const jsDate = new Date(date);
        const dt = DateTime.fromJSDate(jsDate);
        return dt;
    }

    public static FormatDate(date: Date | string | undefined | null): string {
        if (date) {
            const dt = Format.ConvertDateToLuxonDateTime(date);
            if (DateTimeIsEmpty(dt)) {
                return "";
            }
            return dt.setLocale(navigator.language).toLocaleString(DateTime.DATE_SHORT)
        }

        return "";
    }

    public static FormatDateTime(date: Date | string | undefined | null): string {
        if (date) {
            const dt = Format.ConvertDateToLuxonDateTime(date);
            if (DateTimeIsEmpty(dt)) {
                return "";
            }
            return dt.setLocale(navigator.language).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        }

        return "";
    }

    static FormatWeight(weight: number): string {
        return weight.toFixed(2);
    }

    public static ConvertPercentToMultiplier(percent: number, pricingMethod: PricingMethodEnum): number {
        switch (pricingMethod) {
            case PricingMethodEnum.Markup:
                return 1 + (percent / 100);
            case PricingMethodEnum.Discount:
            case PricingMethodEnum.Margin:
                return 1 - (percent / 100);
        }
        return 0;
    }

    public static FormatMultiplierValue(value: string, pricingMethod: PricingMethodEnum): string {
        if (isNumeric(value)) {
            return Format.ConvertPercentToMultiplier(parseFloat(value), pricingMethod).toFixed(4);
        }
        return "";
    }

    public static FormatPercentValue(value: string): string {
        if (isNumeric(value)) {
            return parseFloat(value).toFixed(4);
        }
        return "";
    }

    public FormatStatusCode(statusCode: string): string {
        let tmpStatusDescription: string;

        switch (statusCode) {
            case "":
                tmpStatusDescription = this.#TM.Get("Released");
                break;
            case "__A__":
                tmpStatusDescription = this.#TM.Get("Accepted");
                break;
            case "__ST__":
            case "__VT__":
                tmpStatusDescription = this.#TM.Get("Staged");
                break;
            case "__L__":
            case "__VL__":
                tmpStatusDescription = this.#TM.Get("Loaded");
                break;
            case "__S__":
            case "__VS__":
                tmpStatusDescription = this.#TM.Get("Shipped");
                break;
            case "__TS__":
                tmpStatusDescription = this.#TM.Get("Transfer Shipped");
                break;
            case "__TR__":
                tmpStatusDescription = this.#TM.Get("Transfer Received");
                break;
            case "__D__":
            case "__VD__":
            case "__MD__":
            case "__VE__":
                tmpStatusDescription = this.#TM.Get("Delivered");
                break;
            case "__B__":
                tmpStatusDescription = this.#TM.Get("Back Ordered");
                break;
            case "__W__":
                tmpStatusDescription = this.#TM.Get("Work In Process");
                break;
            case "__I__":
                tmpStatusDescription = this.#TM.Get("Incomplete");
                break;
            case "__ML__":
            case "__VM__":
                tmpStatusDescription = this.#TM.Get("Manually Loaded");
                break;
            case "__U__":
            case "__VU__":
                tmpStatusDescription = this.#TM.Get("Unloaded");
                break;
            case "__MU__":
            case "__VO__":
                tmpStatusDescription = this.#TM.Get("Manually Unloaded");
                break;
            case "__C__":
                tmpStatusDescription = this.#TM.Get("Complete");
                break;
            case "__MX__":
            case "__VX__":
                tmpStatusDescription = this.#TM.Get("Manually Staged");
                break;
            case "__UG__":
            case "__VG__":
                tmpStatusDescription = this.#TM.Get("Unstaged");
                break;
            case "__MG__":
            case "__VN__":
                tmpStatusDescription = this.#TM.Get("Manually Unstaged");
                break;
            default:
                tmpStatusDescription = this.#TM.Get("Rejected");
                break;
        }

        if (statusCode.substring(0, 3) === "__V")
            tmpStatusDescription += ` [${this.#TM.Get("Vendor")}]`;

        return tmpStatusDescription;
    }

}
