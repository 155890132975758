import React from "react";
import { useState } from "react";
import Format, { ImperialFormatModeEnum } from "helpers/fv.format";
import useDimensionBlurCallback from "./useDimensionBlurCallback";

type DimensionChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
type DimensionBlurHandler = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;

export default function useDimensionValue(
    initialValue: number, measurementSetID: number, formatMethods: Format,
    setCallsize: React.Dispatch<string>,
    onChange?: (newValue: any) => void,
): [number, string, DimensionChangeHandler, DimensionBlurHandler, React.Dispatch<number>, React.Dispatch<string>] {

    const [dimension, setDimension] = useState<number>(initialValue);
    const [dimensionString, setDimensionString] = useState<string>(formatMethods.formatDimensionText(initialValue, measurementSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false));

    const handleDimensionChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDimensionString(e.target.value);
    }, [setDimensionString]);

    const handleDimensionBlur = useDimensionBlurCallback(formatMethods, measurementSetID, dimension, dimensionString, setDimensionString, setDimension, setCallsize, onChange);

    return [dimension, dimensionString, handleDimensionChange, handleDimensionBlur, setDimension, setDimensionString];

}

