import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import LineItemExceptionIcon from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemExceptionIcon";

import { ILineItem } from "helpers/interfaces";

export default class LineItemExceptionIconColumn extends LineItemColumn {
    onClick: (lineItem: ILineItem) => void;

    public constructor(columnName: string, headerText: string, width: string, onClick: (lineItem: ILineItem) => void) {
        super(columnName, headerText, width);
        this.align = "center";
        this.onClick = onClick;
    }

    public CellContent(props: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {

        return <LineItemExceptionIcon
            lineItem={lineItem}
            validationExceptionsOnly={false}
            onClick={this.onClick}
        />

    }

    public FooterContent(quoteProps: IQuoteLevelProps): JSX.Element {
        return <></>;
    }

}
