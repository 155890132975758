import React from "react";
import { Dialog, DialogContent, Typography, Link } from "@mui/material"
import { IProductNavigatorNode } from "helpers/interfaces";

interface IProps {
    open: boolean;
    node: IProductNavigatorNode;
    close: () => void;
}

const ProductNavigatorDescriptionMessageBox: React.FC<IProps> = ({open, node, close}) => {
    return <>
        <Dialog fullWidth
        onClose={close}
        open={open}
        >
            <DialogContent>
                {node.hyperLink !== "" &&
                    <Link href={node.hyperLink} target="_blank" rel="noopener noreferrer" whiteSpace="pre-wrap" underline="hover">
                        {node.hyperLinkText === "" ? node.hyperLink : node.hyperLinkText}
                    </Link>
                }
                {node.description !== "" &&
                    <Typography whiteSpace="pre-wrap">
                        {node.description}
                    </Typography>
                }
            </DialogContent>
        </Dialog>
    </>
}

export default ProductNavigatorDescriptionMessageBox