import { useCallback } from "react";
import { Link, Box, Typography, Tooltip } from "@mui/material";

import APIHelper from "helpers/APIHelper";
import { IShapeDisplayInfo } from "helpers/interfaces";
import { useWizardStateActions } from "components/OptionsWizard/WebDesigner/useWizardState";
import { WizardViewEnum } from "components/OptionsWizard/WizardContext";
import ShapeAPI from "components/OptionsWizard/ShapeAPI";
import useWizardInteractions from "components/OptionsWizard/useWizardInteractions";

interface IProps {
    shape: IShapeDisplayInfo
}

function ShapeDisplay({shape}: IProps) {
    const wizardActions = useWizardStateActions();
    const wizardInteractions = useWizardInteractions();

    const getImageSource = useCallback(() => {
        let url = new URL(`${APIHelper.GetBaseURL()}api/Images/ShapeLibraryImage`);

        let params = {
            id: shape.shapeNumber
        };

        url.search = new URLSearchParams(params as any).toString();
        return url.toString();
    }, [shape.shapeNumber]);

    const handleShapeClick = useCallback(() => {
        if (shape.supportsShapeEditor) {
            ShapeAPI.InitEditor(shape.question, shape.code).then(() => {
                wizardActions.SwitchToView(WizardViewEnum.ShapeEditor);
            })
        } else {
            wizardInteractions.SelectShapeCodeAsync(shape.shapeNumber);
            wizardActions.SwitchToView(WizardViewEnum.Wizard);
        }
    }, [shape, wizardInteractions, wizardActions]);

    return <>
        <Box margin="5px" display="inline-block">
            <Tooltip title={shape.shapeCodeAndDescription}>
                <Link maxHeight="280px" maxWidth="250px" minHeight="280px" minWidth="250px" component="button" underline="hover" onClick={() => handleShapeClick()}>
                    <img height="250px" width="auto" style={{ maxWidth: "250px" }} src={getImageSource()} alt=""/>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography display="flex">{shape.shapeCodeAndDescription}</Typography>
                    </Box>
                </Link>
            </Tooltip>
                
        </Box>
    </>
}

export default ShapeDisplay