import React from 'react';
import { Container, Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import { useTranslations } from "@fenetech/translations";
import SetupAPI from "../SetupAPI";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { ITerms } from 'models/ITerms';
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useWait from "helpers/context/Page/useWait";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";
import TermsShipViaRename from "./TermsShipViaRename";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { ThemeColorEnum } from "helpers/enums";
import { IActionButton } from "helpers/context/Page/PageContext";
import TermsShipViaAdd from "./TermsShipViaAdd";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import editDeleteCommandColumn from "./TermsShipViaCommandsColumn";
import { useTheme } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

const Terms = () => {

    const tm = useTranslations();
    const wait = useWait();
    useWindowTitle(tm.Get("Terms"));

    const [rows, setRows] = React.useState<ITerms[] | undefined>(undefined);
    const [alertText, setAlertText] = React.useState("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);
    const [editingName, setEditingName] = React.useState<string>("");
    const [addTermsOpen, setAddTermsOpen] = React.useState<boolean>(false);

    const selectionValuesActions = useSelectionValuesActions();
    const actionButtons = useActionButtons();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("Terms");

    async function fetchTerms() {
        setIsLoading(true);
        try {
            let result: ITerms[] = await SetupAPI.GetTerms();
            setRows(result);
        } finally {
            setIsLoading(false);
        }
    }

    function clearCache() {
        selectionValuesActions.ClearTermsCache();
    }

    useEffectOnLoad(() => {
        fetchTerms();

        const addTermsButton: IActionButton = {
            text: tm.GetWithParams("Add {0}", tm.Get("Terms")),
            color: ThemeColorEnum.Secondary,
            disabled: (isLoading || isPosting),
            onClick: (() => setAddTermsOpen(true))
        };
        actionButtons.Set(0, addTermsButton);
    });

    React.useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    const checkTerms = (description: string) => {
        if (!description || description.trim() === '') {
            setAlertText(tm.GetWithParams("{0} cannot be blank.", tm.Get("Terms")));
            return false;
        }
        if (description.trim().length > 20) {
            setAlertText(tm.GetWithParams("{0} must be less than or equal to {1} characters", tm.Get("Terms"), "20"));
            return false;
        }
        return true;
    }

    async function insertTerms(description: string) {
        if (!checkTerms(description)) {
            setAddTermsOpen(false);
            return;
        }
        setIsPosting(true);
        try {
            let result = await SetupAPI.InsertTerms({ "description": description });
            if (result.message) {
                const translated = tm.Get(result);
                setAlertText(translated);
            } else {
                clearCache();
                await fetchTerms();
            }
        } finally {
            setIsPosting(false);
            setAddTermsOpen(false);
        }
    }

    async function deleteTerms(description: string) {
        if (!checkTerms(description)) return;
        setIsPosting(true);
        try {
            let result = await SetupAPI.DeleteTerms({ "description": description });
            if (result.message) {
                const translated = tm.Get(result);
                setAlertText(translated);
            } else {
                clearCache();
                await fetchTerms();
            }
        } finally {
            setIsPosting(false);
        }
    }

    async function renameTerms(oldDescription: string, newDescription: string): Promise<boolean> {
        if (!checkTerms(newDescription)) {
            setEditingName("");
            return false;
        }
        if (oldDescription.trim() === newDescription.trim()) return true;
        setIsPosting(true);
        try {
            let result = await SetupAPI.RenameTerms({ "oldDescription": oldDescription, "newDescription": newDescription });
            if (result.message) {
                const translated = tm.Get(result);
                setAlertText(translated);
                return false;
            } else {
                clearCache();
                await fetchTerms();
                return true;
            }
        } finally {
            setIsPosting(false);
            setEditingName("");
        }
    }

    const onAlertClose = (event: React.SyntheticEvent<Element, Event>) => {
        setAlertText('');
    }

    const onAlertCloseSnackbar = (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway')
            return;

        setAlertText('');
    }

    const generator = new DataGridColumnGenerator(tm, rows, theme, isMobile);
    if (rows && rows.length > 0) {
        generator.TryAddTextColumn("description", "Terms", { hideInMobile: false });
        generator.AddColumn(editDeleteCommandColumn(setEditingName, deleteTerms, tm, "description", "Terms"));
    }

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);
    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        return row["description"];
    };

    return <>
        <Snackbar open={alertText !== ""} autoHideDuration={5000} onClose={onAlertCloseSnackbar} >
            <Alert onClose={onAlertClose} severity='error' variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alertText}</Alert>
        </Snackbar>
        <Container sx={{ marginTop: 1 }}>
            <CustomDataGridPro
                onPageSizeChange={onPageSizeChange}
                pageSize={rowsPerPage.pageSize}
                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                getRowId={getRowId}
                columns={generator.GetColumns()}
                rows={rows}
            />
        </Container>
        {addTermsOpen && <>
            <TermsShipViaAdd
                subHeaderText="Terms"
                open={addTermsOpen}
                close={() => setAddTermsOpen(false)}
                handleAdd={insertTerms}
            />
        </>}
        {editingName !== "" && <>
            <TermsShipViaRename
                oldName={editingName}
                open={editingName !== ""}
                subHeaderText="Terms"
                close={() => setEditingName("")}
                handleRename={async (newName) => await renameTerms(editingName, newName)}
            />
        </>}
    </>;
}

export default Terms;