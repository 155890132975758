import React from "react";
import OrderContext, { IOrderData } from "components/Orders/OrderContext";

export function useOrderData(): IOrderData {

    const orderData = React.useContext(OrderContext);
    return orderData;
};

export default useOrderData;
