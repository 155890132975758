
import { AccordionSummary, AccordionSummaryProps } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const style = {
    minHeight: 0,
    padding: 1,
    margin: 0,
    '&.Mui-expanded':
    {
        minHeight: 0,
        margin: 0,
    },
    '.MuiAccordionSummary-content':
    {
        margin: 0,
        color: "secondary.contrastText"
    },
    '.MuiAccordionSummary-expandIconWrapper':
    {
        color: "secondary.contrastText"
    },
    bgcolor: "secondary.main"
};


export default function AccordionSummarySlim(props: React.PropsWithChildren<AccordionSummaryProps>) {
    return <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ ...style, ...props.sx }}
    >
        {props.children}
    </AccordionSummary>
}