import React, { useState } from "react";
import QuotesAPI from "components/Quotes/QuotesAPI";
import { IPartListItem } from "helpers/interfaces";


export function usePartList(mfgCustomerID?: string, mfgSiteID?: string, categoryID?: number): { partList: IPartListItem[], isLoading: boolean } {

    const [parts, setParts] = useState<{ mfgCustomerID: string, mfgSiteID: string, categoryID: number, list: IPartListItem[] }>({ mfgCustomerID: "", mfgSiteID: "", categoryID: 0, list: [] });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const isExpired = false;

    const loadPartListAsync = async (mfgCustomerID: string, mfgSiteID: string, categoryID: number) => {
        setIsLoading(true);
        const response = await QuotesAPI.GetPartList(mfgCustomerID, mfgSiteID, categoryID);
        const pl = response.Result;
        if (response.IsOk()) {
            setParts({ mfgCustomerID: mfgCustomerID, mfgSiteID: mfgSiteID, categoryID: categoryID, list: pl });
        }
        setIsLoading(false);
    }

    React.useEffect(() => {
        if (mfgCustomerID && mfgSiteID && categoryID) {
            if (isExpired || parts.mfgCustomerID !== mfgCustomerID || parts.mfgSiteID !== mfgSiteID || parts.categoryID !== categoryID) {
                loadPartListAsync(mfgCustomerID, mfgSiteID, categoryID);
            }
        }

    }, [mfgCustomerID, mfgSiteID, categoryID, parts, isExpired]);

    return { partList: parts.list, isLoading: isLoading };

};

export default usePartList