import React from 'react';
import { Button, Table, TableBody, TableContainer, Box, DialogContent, DialogActions } from "@mui/material";

import TaxHeader from "components/Quotes/QuoteEntry/Taxes/TaxHeader";
import TaxDetail, { ITaxDetailProps } from "components/Quotes/QuoteEntry/Taxes/TaxDetail";
import { useTranslations } from "@fenetech/translations";
import { FormProvider, useForm } from "react-hook-form";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { FindAllNestedProps } from "helpers/objects";
import OKCancelControl from "components/Common/OKCancelControl";
import { ThemeColorEnum } from "helpers/enums";

export interface ITaxesInfo {
    details: ITaxDetailProps[];
}

interface ITaxesFormFields {
    taxes: { percent: string }[];
}

interface IProps {
    initialState: ITaxesInfo,
    isReadOnly: boolean,
    onCancel: () => void,
    onSubmit: (originalState: ITaxesInfo, newState: ITaxesInfo) => void;
};

const Taxes: React.FC<IProps> = ({ initialState, isReadOnly, onCancel, onSubmit }: IProps) => {

    const formDefault: ITaxesFormFields = { taxes: [] };
    const tm = useTranslations();
    const formMethods = useForm({ mode: "onChange", defaultValues: formDefault });
    const { handleSubmit } = formMethods;
    const messageBox = useMessageBox();

    const onSubmitInternal = React.useCallback((formData: ITaxesFormFields, e: any) => {

        const fields = formData.taxes;

        const newState: ITaxesInfo = {
            details: [],
        };

        initialState.details.forEach((t, index) => {

            let newRate = 0;
            let rateChanged = false;

            let newValueString = fields[index]?.percent;
            if (newValueString) {
                const newValue = Number.parseFloat(newValueString);
                newRate = newValue;
                rateChanged = (newRate !== t.percent);
            }
            else {
                newRate = t.percent;
                rateChanged = false;
            }

            newState.details.push({ ...t, percent: newRate, percentChanged: rateChanged });
        });

        onSubmit(initialState, newState);

    }, [initialState, onSubmit]);

    const onError = React.useCallback((errors: { [x: string]: any }, e: any) => {

        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }, [messageBox, tm]);

    return <>
        <FormProvider {...formMethods}>
            <form id="surcharges" onSubmit={handleSubmit(onSubmitInternal, onError)}>
                <DialogContent>
                    <Box display="flex" flexDirection="column">
                        <TableContainer>
                            <Table>
                                <TaxHeader />
                                <TableBody>
                                    {initialState.details.map((t) => (
                                        <TaxDetail initialState={t} key={t.index} isReadOnly={isReadOnly} />
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                </DialogContent>
                <DialogActions>
                    {isReadOnly ?
                        <Button variant="contained" onClick={onCancel} color={ThemeColorEnum.Secondary} sx={{marginRight: "16px"}}>{tm.Get("OK")}</Button>
                        :
                        <OKCancelControl cancelCommand={onCancel} okText={tm.Get("Submit")} okButtonType={"submit"} />
                    }
                </DialogActions>
            </form>
        </FormProvider>
    </>;
}

export default Taxes;