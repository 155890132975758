import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";
import LineItemUnitPrice from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemUnitPrice";
import OrderTotalLabels from "components/Orders/OrderView/LineItems/Fields/OrderTotalLabels";

import { IOrderLineItem } from "helpers/interfaces";

export interface ILineItemUnitPriceEvents {
    onSurchargeClick: () => void;
    onTaxClick: () => void;
}

export default class LineItemUnitPriceColumn extends LineItemColumn {

    events: ILineItemUnitPriceEvents;
    showSqFtPrice: boolean;
    showTotalLabels: boolean

    public constructor(columnName: string, headerText: string, width: string, showSqFtPrice: boolean, showTotalLabels: boolean, events: ILineItemUnitPriceEvents) {
        super(columnName, headerText, width);
        this.align = "right";
        this.events = events;
        this.showSqFtPrice = showSqFtPrice;
        this.showTotalLabels = showTotalLabels;
    }

    public CellContent(props: IOrderLevelProps, lineItem: IOrderLineItem): JSX.Element {

        return <LineItemUnitPrice
            lineItem={lineItem}
            variant="body2"
            showQuantity={false}
            showSqFtPrice={this.showSqFtPrice}
            isOrder={true}            
        />;
    }

    public FooterContent({ lineItemGridProps }: IOrderLevelProps): JSX.Element {
        if (this.showTotalLabels)
        {
            return <OrderTotalLabels
                variant="body2"
                onSurchargeClick={this.events.onSurchargeClick}
                onTaxClick={this.events.onTaxClick}
            />;
        }
        return <></>;
    }

}

