import React from "react";
import { useTheme, Grid, Stack, Typography } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";

import useIsMobile from "helpers/hooks/useIsMobile";
import { useTranslations } from "@fenetech/translations";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { AddColumns } from "components/LineItemPriceBreakdown/LineItemPriceBreakdownColumns"
import { ILineItemPriceBreakdownProps } from "components/LineItemPriceBreakdown/LineItemPriceBreakdown"
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

const PBOptionsGrid: React.FC<ILineItemPriceBreakdownProps> = ({editEnabled, discountTable, optionsData, setEditingOption, cf}) => {
    const tm = useTranslations();
    const isMobile = useIsMobile();
    const theme = useTheme();
    const rowsPerPage = useRowsPerPage("PBOptions");
    
    const generator = new DataGridColumnGenerator(tm, optionsData, theme, isMobile);
    AddColumns(generator, isMobile, editEnabled, setEditingOption, cf);
    const getRowId: GridRowIdGetter = (row: GridRowModel) => row["index"];

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Grid container direction="column" justifyItems="stretch">
            <Grid item>
                <Stack direction="row" justifyContent="space-between">
                    {optionsData.length > 0 && 
                        <Typography>{tm.Get("Option Prices") + ":"}</Typography>
                    }
                    {discountTable !== "" &&
                        <Typography>{tm.Get("Discount Table") + ": " + discountTable}</Typography>
                    }
                </Stack>
            </Grid>
            {optionsData.length > 0 && 
                <Grid item>
                    <CustomDataGridPro
                        getRowId={getRowId}
                        columns={generator.GetColumns()}
                        rows={optionsData}
                        onPageSizeChange={onPageSizeChange}
                        pageSize={rowsPerPage.pageSize}
                        rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                    />
                </Grid>
            }
        </Grid>
    </>
}

export default PBOptionsGrid