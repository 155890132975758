import { Grid } from '@mui/material';
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import OrdersGrid from '../Orders/OrdersGrid';
import SelectOrderType, { OrderDataTypeEnum } from '../Orders/SelectOrderType';
import PickupsGrid from '../Pickups/PickupsGrid';
import RMAsGrid from '../RMAs/RMAsGrid';

const DashboardOrders = () => {

  const { dashboardOrderType } = useSelectionValuesData();

  return <>
      <Grid container direction="row" mt={1} mb={1}>
        <Grid container item xs={3} alignItems="stretch" direction="column"  >
            <SelectOrderType />
        </Grid>
        <Grid item xs />
      </Grid>

      {dashboardOrderType === OrderDataTypeEnum.Orders && <OrdersGrid />}
      {dashboardOrderType === OrderDataTypeEnum.Pickups && <PickupsGrid />}
      {dashboardOrderType === OrderDataTypeEnum.RMAs && <RMAsGrid />}
  </>;
};

export default DashboardOrders;