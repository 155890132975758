import { useEffect } from 'react';

import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useWebCustomer(webCustomerID: string) {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const webCustomers = selectionValues.webCustomerNames;

    const isExpired = false;

    const cacheKey = `${webCustomerID}`;
    
    useEffect(() => {
        
        if (webCustomerID) {
            if (!webCustomers.has(cacheKey) || isExpired)
            {
                actions.LoadWebCustomerAsync(webCustomerID, cacheKey);
            }
        }
        
    }, [actions, webCustomerID, webCustomers, isExpired, cacheKey]);

    return webCustomers.get(cacheKey);
}

export default useWebCustomer;

