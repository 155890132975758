




export class LocalCacheHelper {

    public static ClearLocalCache() {

        localStorage.removeItem("LANGUAGE_DEFAULT");
        localStorage.removeItem("LANGUAGES_CONFIGURED");

        for(let i:number = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if ( key?.startsWith("LANGUAGE_TRANSLATIONS_")) {
                localStorage.removeItem(key);
            }

        }

        sessionStorage.clear();

    }

}


