import { useCallback } from "react";
import { Typography } from "@mui/material";

import { ILineItem } from "models/IQuote";
import useFormatHelper from "helpers/hooks/useFormatHelper";

interface IProps {
    lineItem: ILineItem,
    engineeringUnitSetID: number,
    variant: any,
}

const LineItemSize: React.FC<IProps> = ({ lineItem, engineeringUnitSetID, variant }: IProps) => {
    
    const formatMethods = useFormatHelper();
    
    const formatLineItemDimensions = useCallback((lineItem: ILineItem) => {
        return formatMethods.FormatDimensions(lineItem.displayWidth, lineItem.displayHeight, lineItem.displayThickness, engineeringUnitSetID);
    }, [formatMethods, engineeringUnitSetID]);

    return <Typography variant={variant} textAlign="left">
        {lineItem.callSize ? lineItem.callSize : formatLineItemDimensions(lineItem)}
    </Typography>;
};

export default LineItemSize;