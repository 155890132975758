import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Stack, Grid, Link, IconButton } from "@mui/material";
import { Room, Phone, Edit } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";
import { useTranslations } from "@fenetech/translations";
import useQuoteData from "components/Quotes/useQuoteData";
import QuoteNavigation from "components/Quotes/QuoteNavigation";

function formatCityStateZip(city: string, state: string, zip: string): string {
    if (state || zip) {
        return `${city}, ${state} ${zip}`;
    }
    else {
        return city;
    }
}

function BillingCard() {
    const tm = useTranslations();
    const { quote, permissions, lock } = useQuoteData();
    const navigate = useNavigate();

    const canEditBilling = useMemo(() => {
        if (permissions && lock) {
            return permissions.billingAddress && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    return <>
        <Paper sx={{ display: "flex", width: "100%" }}>
            <Stack display="flex" width="100%" m={1}>
                <Grid container direction="row">
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="h6">
                            {tm.Get("Billing")}
                        </Typography>
                    </Grid>
                    <Grid item justifyContent="right">
                        {canEditBilling &&
                            <IconButton onClick={() => navigate(QuoteNavigation.QuoteEditURL(quote?.oKey ?? 0, "billing"))}>
                                <Edit />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
                <Grid container mt={1}>
                    <Grid item mr={1}>
                        <Room color="primary"/>
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {quote?.billing?.name ?? ""}
                            <br />
                            {quote?.billing?.address?.address1 ?? ""}
                            <br />
                            {quote?.billing?.address?.address2 ?? ""}
                            <br />
                            {formatCityStateZip(quote?.billing?.address?.city ?? "", quote?.billing?.address?.state ?? "", quote?.billing?.address?.zipCode ?? "")}
                            <br />
                            {quote?.billing?.address?.country ?? ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Phone color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Phone")} text={
                            <Link href={`tel:${quote?.billing?.phone ?? ""}`} underline="always" tabIndex={(quote?.billing?.phone ?? "") === "" ? -1 : undefined}>
                                {quote?.billing?.phone ?? ""}
                            </Link>
                        } />
                    </Grid>
                </Grid>

                {quote?.billing?.fax &&
                    <Grid container mt={1} direction="row">
                        <Grid item mr={1} display="flex" alignItems="center">
                            <Phone opacity={0} />
                        </Grid>
                        <Grid item xs display="flex" alignItems="center">
                            <LabeledText label={tm.Get("Fax")} text={quote.billing.fax} />
                        </Grid>
                    </Grid>
                }

            </Stack>
        </Paper>

    </>

}


export default BillingCard;