import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { IOptionValueSelection } from "models/GlobalOptions/IGlobalOptionsSelections";
import { useTranslations } from "@fenetech/translations";
import useGlobalOptions from "components/GlobalOptions/useGlobalOptions";

const NewOptionValueSelection: React.FC<any> = () => {

    const tm = useTranslations();
    const { selections, onNewOptionValueChange, newOptionValueText, onNewOptionValueTextChange } = useGlobalOptions();

    const selectedNewOptionValue = useMemo(() => {
        let selection = selections.newOptionValueSelections?.find((q) => q.value === selections.selectedNewOptionValue);

        if (!selection) {
            selection = { description: "", value: "" };
        }
        return selection;

    }, [selections]);

    const isTextInput = useMemo(() => {

        if (selections.newOptionValueSelections?.length > 1) {
            return false;
        }

        return true;

    }, [selections])

    const isValueVisible = useMemo(() => {
        return selections.isNewValueVisible
    }, [selections]);

    if (!isValueVisible) {
        return null;
    }

    return <>
        {isTextInput ?
            <TextField
                size="small"
                variant="outlined"
                placeholder={tm.Get("{Default}")}
                label={tm.Get("New Value")}
                InputLabelProps={{ shrink: true }}
                value={newOptionValueText}
                onChange={(e) => onNewOptionValueTextChange(e.target.value)}
            />
            :
            <Autocomplete
                autoComplete
                disableClearable
                autoSelect
                autoHighlight
                selectOnFocus
                handleHomeEndKeys
                options={selections.newOptionValueSelections}
                getOptionLabel={(s: IOptionValueSelection) => s.description}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        label={tm.Get("New Value")}
                        InputProps={{
                            ...params.InputProps,
                        }}
                    />
                )}
                onChange={(_, value) => onNewOptionValueChange(value.value)}
                value={selectedNewOptionValue}
            />
        }
    </>;
};

export default NewOptionValueSelection;
