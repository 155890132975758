import { IClientSettings } from "./helpers/interfaces";

const dev: IClientSettings = {
    "translations": {
        "contentPrefix": "*",
        "missingPrefix": "M"
    },
    // "apiRootPath": "http://localhost/WEB",
    // "apiRootPath": "http://localhost:5000",    // .net core
    "apiRootPath": "https://localhost:5001",   // .net core + https
    //"apiRootPath": "http://localhost:55555",   // iis express
    // "apiRootPath": "https://localhost:44314",  // iis express + https

};

const prod: IClientSettings = {
};

const config = process.env.NODE_ENV === 'production' ? prod : dev;


export default {
    //add any common config values here
    ...config
}
