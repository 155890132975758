import { useContext } from "react";
import ApiUser from "models/ApiUser";
import { UserContext } from "helpers/context/User/UserContext";


const defaultInfo = new ApiUser({
    mfgCustomerID: "",
    parentCustomerID: "",
    userID: "",
    fullName: "",
    employeeID: 0,
    roles: [],
    permissions: 0,
    companyPermissions: 0,
    securityLevel: 0,
    locationCurrencyCulture: "en-US",
    isMultiSite: false,
    isAccessedExternal: false,
});


export default function useUserInfo(): ApiUser {

    const userInfo = useContext(UserContext);

    return userInfo || defaultInfo;
}


