
import { ICode, IQuestion, IPartPreferences, IValidationInfo, ILineItemInfo, IWebDesigner, IDisplayViewSettings, ILineItemPriceInfo } from "components/OptionsWizard/interfaces";
import jsSHA from "jssha";
import React from "react";

interface IHashableProps {
    options: Array<ICode | IQuestion> | null;
    partPreferences: IPartPreferences | null;
    validations: IValidationInfo | null;
    itemInfo: ILineItemInfo | null;
    webDesigner: IWebDesigner | null;
    displayViewSettings: IDisplayViewSettings | null;
    focusedCode: ICode | null;
    prices: ILineItemPriceInfo | null;
}

const getOptionHash = (newProps: IHashableProps) => {

    if (newProps.webDesigner && newProps.webDesigner.hash) {
        return newProps.webDesigner.hash;
    } else {
        //Include everything in the hash except price and validation, because those return async, we were causing two image downloads
        // remove selection from web designer
        const webDesigner = { ...newProps.webDesigner, CurrentSection: null };
        let jsonString: string = JSON.stringify({ ...newProps, focusedCode: null, validations: null, prices: null, webDesigner });

        let objHash: any = new jsSHA("SHA-1", "TEXT");
        objHash.update(jsonString);
        return objHash.getHash("HEX") as string;
    }
}


export default function useOptionHash(props: IHashableProps): string {

    const optionHash = React.useMemo(() => getOptionHash(props), [props]);

    return optionHash;

}
