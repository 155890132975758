import { useState, useEffect } from 'react';

import QuotesAPI from 'components/Quotes/QuotesAPI';

function useCustomerNotes(webCustomerID?: string) {

    const [webCustomerNotes, setWebCustomerNotes] = useState<string>("");

    const loadCustomerNotesAsync = async (webCustomerID: string) => {
        setWebCustomerNotes("");
        const response = await QuotesAPI.GetWebCustomerNotes(webCustomerID);
        const customerNotes = response.Result;
        if (response.IsOk()) {
            setWebCustomerNotes(customerNotes);
        }
    }

    useEffect(() => {

        if (webCustomerID) {
            loadCustomerNotesAsync(webCustomerID);
        }

    }, [webCustomerID]);

    return webCustomerNotes;
}

export default useCustomerNotes;