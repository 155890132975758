import APIHelper from "helpers/APIHelper";
import { ILineItemPriceBreakdown } from "helpers/interfaces";

export default class LineItemPriceBreakdownAPI {
    
    public static async PriceBreakdown(oKey: number, odKey: number, isOrder: boolean): Promise<ILineItemPriceBreakdown | null> {
        const url: string = `api/LineItemPriceBreakdown/${oKey}/${odKey}`;
        const params = {
            isOrder: isOrder,
        }
        const response = await APIHelper.GetAsync<ILineItemPriceBreakdown>(url, params);
        return response.Result;
    }

    public static async ChangeQuantity(selectedItem: number, newQty: number): Promise<ILineItemPriceBreakdown | null> {
        const url: string = `api/LineItemPriceBreakdown/ChangeQuantity`;
        const body = {
            selectedItem,
            value: newQty
        };
        const response = await APIHelper.PostAsync<ILineItemPriceBreakdown>(url, undefined, body);
        return response.Result;
    }

    public static async ChangePartPrice(selectedItem: number, newPartPrice: number): Promise<ILineItemPriceBreakdown | null> {
        const url: string = `api/LineItemPriceBreakdown/ChangePartPrice`;
        const body = {
            selectedItem,
            value: newPartPrice
        };
        const response = await APIHelper.PostAsync<ILineItemPriceBreakdown>(url, undefined, body);
        return response.Result;
    }

    public static async ChangeOverallSqFtPrice(newPrice: number): Promise<ILineItemPriceBreakdown | null> {
        const url: string = `api/LineItemPriceBreakdown/ChangeOverallSqFtPrice`;
        const response = await APIHelper.PostAsync<ILineItemPriceBreakdown>(url, undefined, newPrice);
        return response.Result;
    }

    public static async ChangeOverallPrice(newPrice: number): Promise<ILineItemPriceBreakdown | null> {
        const url: string = `api/LineItemPriceBreakdown/ChangeOverallPrice`;
        const response = await APIHelper.PostAsync<ILineItemPriceBreakdown>(url, undefined, newPrice);
        return response.Result;
    }

    public static async ChangeOptionPrice(selectedItem: number, optionCode: string, optionGroup: string, newOptionPrice: number): Promise<ILineItemPriceBreakdown | null> {
        const url: string = `api/LineItemPriceBreakdown/ChangeOptionPrice`;
        const body = {
            selectedItem,
            optionCode,
            optionGroup,
            value: newOptionPrice
        };
        const response = await APIHelper.PostAsync<ILineItemPriceBreakdown>(url, undefined, body);
        return response.Result;
    }

    public static async Save(): Promise<void | null> {
        const url: string = `api/LineItemPriceBreakdown/Save`;
        const response = await APIHelper.PostAsync<void>(url);
        return response.Result;
    }

    public static async Reprice(): Promise<void | null> {
        const url: string = `api/LineItemPriceBreakdown/Reprice`;
        const response = await APIHelper.PostAsync<void>(url);
        return response.Result;
    }
}