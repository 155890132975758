import { useEffect } from 'react';
import { IActionButton } from 'helpers/context/Page/PageContext';
import useUserInfo from "../User/useUserInfo";
import { UserPermissionTypes } from "helpers/enums";
import usePageActions from "./usePageActions";

/*
    Use this hook to set the action button properties
*/

export interface IActionButtons {
    Set: (index: number, actionButton: IActionButton) => void;
    Remove: (index: number) => void;
    Clear: () => void;
    SetBackButton: (index: number, navigateURL: string) => void;
    SetCreateQuoteButton: (index: number) => void;
}

export default function useActionButtons(): IActionButtons {

    const pageActions = usePageActions();
    const user = useUserInfo();

    const actionButtons: IActionButtons = {
        Set: (index: number, actionButton: IActionButton) => {
            pageActions.SetActionButton(index, actionButton);
        },
        Remove: (index: number) => {
            pageActions.RemoveActionButton(index);
        },
        Clear: () => {
            pageActions.ClearActionButtons();
        },
        SetBackButton: (index: number, navigateURL: string) => {
            pageActions.SetBackButton(index, navigateURL);
        },
        SetCreateQuoteButton: (index: number) => {
            if (user.HasPermission(UserPermissionTypes.CreateModifyQuotes))
                pageActions.SetCreateQuoteButton(index);
        }
    };

    useEffect(() => {
        return () => {
            pageActions.ClearActionButtons();
        };
    }, [pageActions]);

    return actionButtons;

}