import React, { } from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import useFeneVisionTheme from "helpers/context/Theme/useFeneVisionTheme";
import { useTranslations } from "@fenetech/translations";

interface IProps {

}

const ThemeSelector: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const [themeContext, themeActions] = useFeneVisionTheme();

    const handleChange = (event: SelectChangeEvent) => {
        const themeName = event.target.value;
        themeActions?.SetSelectedTheme(themeName);
        localStorage.setItem("THEME", themeName);
    }

    return <>
        <FormControl fullWidth>
            <InputLabel>{tm.Get("Theme")}</InputLabel>
            <Select variant="outlined" label={tm.Get("Theme")} value={themeContext.selectedThemeInfo?.themeName} onChange={handleChange}>
                {themeContext.themeInfos?.map(item => (
                    <MenuItem key={item.themeName} value={item.themeName}>{item.themeName}</MenuItem>
                ))}
            </Select>
        </FormControl>
    </>;

}

export default ThemeSelector;