import LineItemImage from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemImage";
import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import { ILineItem } from "helpers/interfaces";

export default class LineItemImageColumn extends LineItemColumn {

    onClick: (imageSource: string) => void;

    public constructor(columnName: string, headerText: string, width: string, onClick: (imageSource: string) => void) {
        super(columnName, headerText, width);
        this.rowSpan = 3;
        this.onClick = onClick;
    }

    public CellContent({ quote, permissions }: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {

        return <>
            <LineItemImage
                oKey={quote.oKey}
                lineItem={lineItem}
                imageAccessKey={permissions.imageAccessKey}
                isOrder={false}
                onClick={this.onClick}
                showMediumSize={false}
            />
        </>;
    }

    public FooterContent(quoteProps: IQuoteLevelProps): JSX.Element {
        return <></>;
    }
}
