import { IQuote } from "helpers/interfaces";

export const BlankQuote : IQuote = {
    oKey: 0,
    parentCustomerID: "",
    parentSiteID: "",
    mfgCustomerID: "",
    mfgSiteID: "",
    orderNumber: "",
    customerID: "",
    orderNotes: "",
    customerRef: "",
    poNumber: "",
    orderContact: "",
    orderComment: "",
    status: 0,
    classificationID: 1,
    engineeringUnitSetID: 1,
    enteredBy: -1,
    salespersonID: 0,
    currencyCulture: "en-US",
    billing: {
        name: "",
        address: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
            country: ""
        },
        phone: "",
        fax: "",
        terms: ""
    },
    shipping: {
        name: "",
        address: {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
            country: ""
        },
        isAddressSameAsBilling: false,
        phone: "",
        fax: "",
        shipVia: "",
        dropShip: false,
        shippingComment: "",
        mfgShipToGUID: ""
    },
    pricing: {
        pricingMethod: 0,
        orderDiscount: null,
        orderMarkdown: null,
    },
    isDealerCreatedQuote: false,
    isContractorCreatedQuote: false,
    isSubmitted: false,
    contractorOrderNumber: "",
    contractorOKey: 0,
    dealerOrderNumber: "",
    dealerOKey: 0,
};
