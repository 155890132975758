import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import useIsMobile from "helpers/hooks/useIsMobile";
import { IReportSelection } from "helpers/context/SelectionValues/useReports";
import OKCancelControl from "components/Common/OKCancelControl";

interface IProps {
    title: string,
    reports: IReportSelection[],
    dialogVisible: boolean,
    onSubmit: (selectedReport: IReportSelection) => void
    onCancel: () => void,
}

const ReportSelectionDialog: React.FC<IProps> = ({ title, reports, dialogVisible, onSubmit, onCancel }: IProps) => {

    const isMobile = useIsMobile();
    const [selectedReport, setSelectedReport] = useState<IReportSelection | undefined>(undefined);

    const defaultReportID = useMemo(() => {
        if (reports && reports.length > 0) {
            return reports[0].reportID;
        }
        return undefined;
    }, [reports]);

    useEffect(() => {
        if (!selectedReport && defaultReportID) {
            setSelectedReport(reports.find(r => r.reportID === defaultReportID));
        }
    }, [reports, selectedReport, defaultReportID, setSelectedReport]);


    const handleSelectChange = (event: SelectChangeEvent<unknown>): void => {
        setSelectedReport(reports.find(r => r.reportID === event.target.value));
    }

    const handleOkClick = useCallback(() => {
        if (selectedReport) {
            onSubmit(selectedReport)
        }
        setSelectedReport(undefined);
    }, [selectedReport, onSubmit]);

    const handleCancelClick = useCallback(() => {
        setSelectedReport(undefined);
        onCancel();
    }, [onCancel]);

    return <>
        <Dialog
            open={dialogVisible}
            onClose={handleCancelClick}
            fullWidth={isMobile}
            maxWidth={isMobile ? false : undefined}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {defaultReportID &&
                    <Select
                        sx={{ minWidth: "250px" }}
                        variant="outlined"
                        fullWidth
                        defaultValue={defaultReportID}
                        onChange={(event) => handleSelectChange(event)}
                    >
                        {reports.map(item => (
                            <MenuItem key={item.reportID} value={item.reportID}>{item.menuCaption}</MenuItem>
                        ))}
                    </Select>
                }
            </DialogContent>

            <DialogActions>
                <OKCancelControl okCommand={handleOkClick} cancelCommand={handleCancelClick} />
            </DialogActions>
        </Dialog>
    </>;

}

export default ReportSelectionDialog;