import React from "react";
import { HomeAddressContext } from "./HomeAddressContext";
/*
    Use this hook to set the get the Home Address setting from config
*/

export default function useHomeAddress(): string {

    const homeAddress: string = React.useContext(HomeAddressContext);
    return homeAddress;
}
