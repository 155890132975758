import React from "react"
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useIsMobile from "helpers/hooks/useIsMobile";
import { useTheme } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import { IDashboardQuote } from "models/IDashboardQuote";
import useDashboardCustomerQuoteData from "./useDashboardCustomerQuoteData";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

const CustomerQuotesGrid: React.FC = () => {

    const tm = useTranslations();
    const theme = useTheme();
    const cf = useCurrencyFormatter();
    const data: IDashboardQuote[] | undefined = useDashboardCustomerQuoteData();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("DashboardCustomerQuote");

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        return row.oKey;
    }

    const generator = new DataGridColumnGenerator(tm, data, theme, isMobile, cf);

    generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Quote", "/quotes", { hideable: false });
    generator.TryAddWebCustomerColumn("webCenterCustomerName", "customerInactive", "Customer", { hideInMobile: true });
    generator.TryAddDateColumn("orderDate", "Date", { hideInMobile: true });
    generator.TryAddTextColumn("poNumber", "PO Number", { hideInMobile: true });
    generator.TryAddTextColumn("customerRef", "Customer Ref", { hideInMobile: true });

    generator.TryAddTotalPriceColumn({});

    generator.TryAddCheckColumn("submitted", "Submitted", { tooltip: null }, { hideInMobile: true });
    generator.TryAddTranslatedTextColumn("classificationDescription", "Status", {})
    generator.TryAddDateColumn("expirationDate", "Expires", {});

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <CustomDataGridPro
            rows={data}
            columns={generator.GetColumns()}
            getRowId={getRowId}
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
        />
    </>;

};

export default CustomerQuotesGrid;