import React, { useCallback, useState } from 'react';
import { IProductNavigatorPartFilterPart } from "models/IProductNavigator"
import { useTranslations } from "@fenetech/translations";
import Constants from "helpers/constants";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { Error } from "@mui/icons-material";
import { ThemeColorEnum } from "helpers/enums";

type Props = {
    filterPart: IProductNavigatorPartFilterPart;
    onChangeCallback: (partID: number, qty: number, validationErrorExists: boolean) => void;
};

const ProductNavigatorPartFilterQtyCell: React.FC<Props> = (props) => {
    const tm = useTranslations();
    const [validationError, setValidationError] = useState<string>("");


    const validateValue = useCallback((value: string): string | boolean => {

        if (value) {
            const floatValue = Number(value);
            const minValue = Constants.Min.Quantity;
            const maxValue = props.filterPart.availableQty !== null ? props.filterPart.availableQty: Constants.Max.Quantity;

            if (isNaN(floatValue)) {
                const message = tm.GetWithParams("Quantity must be between {0} and {1}.", minValue.toString(), maxValue.toString());
                return message;
            }
            else {
                if (floatValue > maxValue || floatValue < minValue) {
                    const message = tm.GetWithParams("Quantity must be between {0} and {1}.", minValue.toString(), maxValue.toString());
                    return message;
                }
            }
        }

        return true;
    }, [tm, props.filterPart.availableQty]);

    const handleOnChange = useCallback((e: any) => {
        let result = validateValue(e.target.value);
        if (typeof result === 'string') {
            setValidationError(result);
            props.onChangeCallback(props.filterPart.partID, e.target.value, true);
        }
        else {
            setValidationError("");
            props.onChangeCallback(props.filterPart.partID, e.target.value, false);
        }
    }, [setValidationError, props, validateValue]);

    const ErrorAdornment = () => {
        return <>
            <Tooltip title={validationError}>
                <InputAdornment position="end">
                    <Error fontSize="small" color={ThemeColorEnum.Error} />
                </InputAdornment>
            </Tooltip>
        </>
    }

    return <>
        <TextField
            variant="standard"
            sx={{ width: "50px" }}
            inputProps={{ style: { textAlign: "center" } }}
            fullWidth
            onChange={handleOnChange}
            value={props.filterPart.qty}
            error={validationError ? true : false}
            InputProps={{
                endAdornment:
                    validationError ? <ErrorAdornment /> : null
            }}
        />
    </>;
};

export default ProductNavigatorPartFilterQtyCell;