import React, { useState, useCallback } from "react";
import { Typography, Tooltip, IconButton } from "@mui/material";
import { StyledRow, StyledCell } from "components/Setup/PriceTables/RowStyles";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { useTranslations } from "@fenetech/translations";
import { IPriceScheduleItem } from "helpers/interfaces";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import EditScheduleItem from "./EditScheduleItem";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";

interface IProps {
    item : IPriceScheduleItem,
    editHandler: (price: number) => void
    deleteHandler: () => void,
    cf: ICurrencyFormatter,
    lnf: ILocaleNumberFormatter
}

const PriceScheduleItemsGridRow = ({item, editHandler, deleteHandler, cf, lnf}: IProps) => {
    const tm = useTranslations();
    const messageBox = useMessageBox();

    const [editClicked, setEditClicked] = useState<boolean>(false);

    const deletePrompt = useCallback(() => {
        messageBox.Show({
            message: tm.Get("Are you sure you want to delete this UI?"), 
            title: tm.GetWithParams("Delete {0}", tm.Get("UI")),
            yesNoPrompt: true,
            callback: (result) => {
                if (result) deleteHandler();
            }
        });
    }, [messageBox, tm, deleteHandler]);

    return <>
        <StyledRow>
            <StyledCell>
                <Typography textAlign="center">{lnf.Format(item.increment)}</Typography>
            </StyledCell>
            <StyledCell>
                <Typography textAlign="center">{cf.Format(item.price)}</Typography>
            </StyledCell>
            <StyledCell align="center">
                <Tooltip title={tm.Get("Edit")}>
                    <IconButton onClick={() => {setEditClicked(true)}}>
                        <EditIcon fontSize="small" color="primary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={tm.Get("Delete")}>
                    <IconButton onClick={deletePrompt}>
                        <DeleteIcon fontSize="small" color="primary"/>
                    </IconButton>
                </Tooltip>
            </StyledCell>
        </StyledRow> 

        {editClicked && 
            <EditScheduleItem
                oldPrice={cf.Format(item.price)}
                open={editClicked}
                close={() => setEditClicked(false)}
                handleEdit={(price: string) => {
                    setEditClicked(false);
                    editHandler(cf.Parse(price));
                }}
            />
        }   
    </>
}

export default PriceScheduleItemsGridRow;