import React, { useState, useCallback } from "react"
import { Dialog, DialogContent, DialogActions, TextField } from "@mui/material"
import { useTranslations } from "@fenetech/translations";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import OKCancelControl from "components/Common/OKCancelControl";
import ErrorAdornment from "components/Common/ErrorAdornment";

interface IProps {
    subHeaderText: string;
    open: boolean;
    close: () => void;
    handleAdd: (name: string) => void;
}

const TermsShipViaAdd: React.FC<IProps> = ({ subHeaderText, open, close, handleAdd }) => {
    const tm = useTranslations();

    const [name, setName] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    const validate = useCallback((name: string) => {
        if (name.trim() === "") {
            return tm.Get(`${subHeaderText} cannot be blank.`)
        }
        return "";
    }, [subHeaderText, tm]);

    const handleNameBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setErrorText(validate(e.target.value));
    }, [validate]);

    const trySubmit = useCallback((name: string) => {
        let error = validate(name);
        if (error === "") {
            handleAdd(name);
        } else {
            setErrorText(error);
        }
    }, [handleAdd, validate]);

    return <>
        <Dialog fullWidth
            onClose={close}
            open={open}
        >
            <DialogTitleWithClose onClose={close}>
                {tm.GetWithParams("Add {0}", tm.Get(subHeaderText))}
            </DialogTitleWithClose>
            <DialogContent>
                <TextField size="small" label={tm.Get(subHeaderText)} value={name} onChange={handleNameChange} onBlur={handleNameBlur} fullWidth sx={{ marginTop: 2 }}
                    error={errorText !== ""}
                    InputProps={{
                        endAdornment: errorText !== "" ?
                            <ErrorAdornment validationError={errorText} /> : null
                    }}
                />
            </DialogContent>
            <DialogActions>
                <OKCancelControl okCommand={() => trySubmit(name.trim())} okText={tm.Get("Add")} cancelCommand={close} />
            </DialogActions>
        </Dialog>
    </>
}

export default TermsShipViaAdd;