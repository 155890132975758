import { Box, Grid, Paper, Stack } from "@mui/material"

import QuoteTotalLabels from "components/Quotes/QuoteEntry/LineItems/Fields/QuoteTotalLabels";
import QuoteTotals from "components/Quotes/QuoteEntry/LineItems/Fields/QuoteTotals";

interface IProps {
    onSurchargeClick: () => void,
    onTaxClick: () => void,
}

const LineItemFooterCard: React.FC<IProps> = ({ onSurchargeClick, onTaxClick }: IProps) => {

    return <>

        <Paper>
            <Grid container display="flex" direction="row">
                <Box m={1} display="flex" alignSelf="flex-end" marginLeft="auto">
                    <Stack direction="row" gap={2}>
                        <QuoteTotalLabels
                            variant="body1"
                            onSurchargeClick={onSurchargeClick}
                            onTaxClick={onTaxClick}
                        />
                        <QuoteTotals
                            variant="body1"
                        />
                    </Stack>
                </Box>
            </Grid>
        </Paper>

    </>;
}

export default LineItemFooterCard;
