import { IUserInfoFull } from "helpers/interfaces";

export const BlankUser: IUserInfoFull  = {
    user: {
        employeeID: -1,
        firstName: "",
        lastName: "",
        emailAddress: "",
        inactive: false,
        mfgInactive: false,
        customerInactive: false,
        userName: "",
        authenticationType: 0,
        customerID: "",
        customerName: "",
        siteName: "",
        mfgCreatedEmployee: false,
        securityLevel: 0
    },
    availableSites: [],
    assignedSites: [],
    loginType: 0,
    canSelectAllSites: false,
    permissions: []
}