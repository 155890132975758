import * as React from 'react';

import type { } from '@mui/x-data-grid-pro/themeAugmentation';
import { GridRowModel, GridRowIdGetter, GridCallbackDetails } from '@mui/x-data-grid-pro';
import { useTheme } from '@mui/material';

import { useTranslations } from '@fenetech/translations';
import { useDashboardRMAData } from './useDashboardRMAData';
import { generateRMAColumns } from "./RMAsGridColumns";
import useIsMobile from 'helpers/hooks/useIsMobile';
import useUserInfo from "helpers/context/User/useUserInfo";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

const RMAsGrid: React.FC<any> = (props: any) => {

  const tm = useTranslations();
  const theme = useTheme();
  const data = useDashboardRMAData();
  const isMobile = useIsMobile();
  const user = useUserInfo();
  const rowsPerPage = useRowsPerPage("DashboardRMA");

  const getRowId: GridRowIdGetter = (row: GridRowModel) => {
      return row.rmaKey;
  }

  const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

  return <>
      <CustomDataGridPro
          rows={data}
          columns={generateRMAColumns(tm, data, theme,isMobile, user)}
          getRowId={getRowId}
          onPageSizeChange={onPageSizeChange}
          pageSize={rowsPerPage.pageSize}
          rowsPerPageOptions={rowsPerPage.pageSizeOptions}
      />

  </>;
};

export default RMAsGrid;