import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";
import { IOrderLineItem } from "helpers/interfaces";

export default class LineItemTextColumn extends LineItemColumn {

    getText: (lineItem: IOrderLineItem) => string;

    public constructor(columnName: string, headerText: string, width: string, align: "inherit" | "left" | "center" | "right" | "justify" | undefined, getText: (lineItem: IOrderLineItem) => string) {
        super(columnName, headerText, width);
        this.align = align;
        this.getText = getText;
    }
    public CellContent(props: IOrderLevelProps, lineItem: IOrderLineItem): JSX.Element {

        return <>
            {this.getText(lineItem)}
        </>;
    }

    public FooterContent(props: IOrderLevelProps): JSX.Element {
        return <></>;
    }
}
