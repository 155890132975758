import * as React from "react";


import { IQuestionGroup } from "../models";
import { IUserPreferences } from "../interfaces";
import OWQuestions from "./OWQuestions";
import OWExpanderAccordian from "components/OptionsWizard/OptionsWizard/OWExpanderAccordian";

interface IOWExpanderProps {
    questionGroup: IQuestionGroup;
    preferences: IUserPreferences;
    readOnlyMode: boolean;
}



const OWExpander: React.FC<IOWExpanderProps> = (props: IOWExpanderProps) => {


    let q: IQuestionGroup = props.questionGroup;

    return <OWExpanderAccordian headerText={q.groupName}  key={'GRPX_' + q.groupName}  defaultExpanded={true} >
        <OWQuestions preferences={props.preferences} key={'GRP_' + q.groupName} questionGroup={q} readOnlyMode={props.readOnlyMode} />
    </OWExpanderAccordian>


}

export default OWExpander;


