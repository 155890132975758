import React from "react";
import Format, { ImperialFormatModeEnum } from "helpers/fv.format";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";

export default function useDimensionBlurCallback(
    formatMethods: Format,
    measurementSetID: number,
    dimension: number,
    dimensionString: string | undefined,
    setDimensionString: (value: string) => void,
    setDimension: (value: number) => void,
    setCallsize: ((value: string) => void) | undefined,
    onCommitted?: ((newValue: any) => void) | undefined
) {

    const lnf = useLocaleNumberFormatter();

    return React.useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        let stringValue: string = e.target.value;
        let numericValue = lnf.Parse(stringValue);

        if (isNaN(numericValue)) {
            numericValue = Format.fracToDec(stringValue) ?? 0;
        }

        stringValue = formatMethods.formatDimensionText(numericValue, measurementSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        if (dimension !== numericValue || dimensionString !== stringValue) {
            setDimensionString(stringValue);
            setDimension(numericValue);
            if (setCallsize) {
                setCallsize("");
            }
            if (onCommitted) {
                onCommitted(numericValue);
            }
        }

    }, [dimension, dimensionString, measurementSetID, setDimensionString, setDimension, setCallsize, formatMethods, lnf, onCommitted]);
}