import React, { useState, useCallback } from "react"
import { Typography, Dialog, DialogContent, DialogActions, TextField, MenuItem, Stack } from "@mui/material"
import { useTranslations } from "@fenetech/translations";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import { ICategoryListItem } from "helpers/interfaces";
import OKCancelControl from "components/Common/OKCancelControl";
import ErrorAdornment from "components/Common/ErrorAdornment";

interface IProps {
    categories: ICategoryListItem[];
    defaultCategory: number;
    open: boolean;
    close: () => void;
    handleAdd: (name: string, category: number) => void;
}

const AddTable: React.FC<IProps> = ({categories, defaultCategory, open, close, handleAdd}) => {
    const tm = useTranslations();
    const message = tm.Get("Please create a new table by choosing a name, and then assigning the table to a category.");

    const [name, setName] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<number>(defaultCategory === -1 ? categories[0].categoryID : defaultCategory);
    const [errorText, setErrorText] = useState<string>("");

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    const validate = useCallback((name: string) => {
        if (name.trim() === "") {
            return tm.Get("The table name cannot be an empty string.")
        }
        return "";
    }, [tm]);

    const handleNameBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setErrorText(validate(e.target.value));
    }, [validate]);

    const handleCategoryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCategory(parseInt(e.target.value));
    }, []);

    const trySubmit = useCallback((name: string, category: number) => {
        let error = validate(name);
        if (error === "") {
            handleAdd(name, category);
        } else {
            setErrorText(error);
        }
    }, [handleAdd, validate]);

    return <>
        <Dialog fullWidth
            onClose={close}
            open={open}
        >
            <DialogTitleWithClose onClose={close}>
                {tm.GetWithParams("Add {0}", tm.Get("Price Table"))}
            </DialogTitleWithClose>
            <DialogContent>
                <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={2}>
                    <Typography whiteSpace="pre-wrap">
                        {message}
                    </Typography>
                    <TextField size="small" label={tm.Get("Table Name")} value={name} onChange={handleNameChange} onBlur={handleNameBlur} fullWidth
                        error={errorText !== ""}
                        InputProps={{
                            endAdornment: errorText !== "" ? 
                                <ErrorAdornment validationError={errorText}/> : null
                        }}
                    />
                    <TextField size="small" label={tm.Get("Pricing Category")} value={selectedCategory} onChange={handleCategoryChange} fullWidth select>
                        {categories.map((c) => <MenuItem value={c.categoryID} key={c.categoryID}>{c.description}</MenuItem>)}
                    </TextField>
                </Stack>
            </DialogContent>
            <DialogActions>
                <OKCancelControl okCommand={() => trySubmit(name.trim(), selectedCategory)} okText={tm.Get("Add")} cancelCommand={close} />
            </DialogActions>
        </Dialog>
    </>
}

export default AddTable;