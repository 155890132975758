import React from "react";
import {Typography} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation } from "react-router";

const NotFound:React.FC<any> = (props:any) => {
    const location = useLocation();

    return <Box>
        <Typography>Invalid path encountered: {location.pathname}{location.search} </Typography>
    </Box>
}

export default NotFound;
