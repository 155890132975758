import React from "react"
import { Typography, Grid } from "@mui/material";
import Header from "../Layout/TopHeader";
import Footer from "../Layout/Footer";
import { useTranslations } from "@fenetech/translations";

const SomethingWentWrong: React.FC<any> = () => {

    const tm = useTranslations();

    return <>
        <Header /> 
        <div className="article">
            <Grid container justifyContent="center" padding="10px" >
                <Grid item justifyContent="center" alignItems='stretch'>
                    <Grid item margin={1}>
                        <Typography variant="h3" textAlign="center">{tm.Get("Something went wrong.")}</Typography>
                    </Grid>

                    <Grid item margin={1}>
                        <Typography textAlign="center">{tm.Get("Please check your URL and try again.")}</Typography>
                    </Grid>

                </Grid>
            </Grid>
        </div>
        <div className="footer">
            <Footer />
        </div>
    </>
}

export default SomethingWentWrong;