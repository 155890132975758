import { ArrowBack } from "@mui/icons-material";
import { Box, Paper, Divider, IconButton, List, ListItem, ListItemText, useTheme } from "@mui/material";

import React from "react";
import { useNavigate } from "react-router-dom";
import NavMenuItem from "./NavMenuItem";
import useMenuItems from "helpers/hooks/useMenuItems";

interface IProps {
    closeMenu: () => void;
}



const NavMenuMobile: React.FC<IProps> = (props: IProps) => {

    const navigate = useNavigate();
    const theme = useTheme();
    const menuItems = useMenuItems();

    const handleNavClick = (uri: string) => {
        props.closeMenu();
        navigate(uri);
    };

    return <Paper sx={{ width: 250, flexGrow: 1, overflow: "auto" }}>
        <Box
            role="presentation"
            onKeyDown={props.closeMenu}
        >

            <List sx={{ zIndex: 9999 }}>

                <ListItem secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={props.closeMenu} >
                        <ArrowBack color="secondary" />
                    </IconButton>
                }
                >
                    {/* This is required to hold the space, since the button is defined as a secondary action to put it on the right side */}
                    <ListItemText />
                </ListItem>

                <Divider />

                {menuItems.map((i) =>
                    <NavMenuItem component="list" key={i.label + i.uri} record={i} handleNavClick={handleNavClick} theme={theme} />
                )}
            </List>
        </Box>
    </Paper>;
};

export default NavMenuMobile;