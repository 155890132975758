

import Dashboard from "components/Dashboard/Dashboard";
import { DashboardContextProvider } from "components/Dashboard/DashboardContext";
import NotFound from "components/Layout/NotFound";
import Confirmation from "components/Maintenance/Confirmation";
import Maintenance from "components/Maintenance/Maintenance";
import { OrderContextProvider } from "components/Orders/OrderContext";
import OrderViewMain from "components/Orders/OrderView/OrderViewMain";
import Preferences from "components/Preferences/Preferences";
import AddQuote from "components/Quotes/AddQuote/AddQuote";
import { QuoteLockContextProvider } from "components/Quotes/QuoteLockContext";
import { AddQuoteContextProvider } from "components/Quotes/AddQuote/AddQuoteContext";
import QuoteEntryMain from "components/Quotes/QuoteEntry/QuoteEntryMain";
import Search from "components/Search/Search";
import ShipVia from "components/Setup/TermsShipVia/ShipVia";
import Customers from "components/Customers/Customers";
import CustomerInfo from "components/Customers/CustomerInfo";
import Terms from "components/Setup/TermsShipVia/Terms";
import Profile from "components/User/Profile";
import Documents from "components/Documents/Documents";
import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Users from "components/Users/Users";
import AddUser from "components/Users/AddUser";
import UserInfo from "components/Users/UserInfo";
import { UserSetupContextProvider } from "components/Users/UserSetupContext";
import PriceTables from "components/Setup/PriceTables/PriceTables";
import PriceSchedules from "components/Setup/PriceSchedules/PriceSchedules";
import OrderHistory from "components/History/OrderHistory";
import PurchaseHistory from "components/History/PurchaseHistory";
import ContainerStatus from "components/Orders/ContainerStatus/ContainerStatus";
import ProdStatus from "components/Orders/ProdStatus/ProdStatus";
import Company from "components/Company/Company";
import CompanyInfo from "components/Company/CompanyInfo";

interface IProps {

}

const AppRoutes: React.FC<IProps> = (props: IProps) => {


    return <Routes>
        <Route path="/" element={<Navigate to="dashboard" replace />} />
        <Route path="index.html" element={<Navigate to="/dashboard" replace />} />

        <Route path="/dashboard/*" element={
            <DashboardContextProvider >
                <Routes>
                    <Route path="" element={<Dashboard />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </DashboardContextProvider>
        } />

        <Route path="quotes/*" element={
            <Routes>
                <Route path="add" element={
                    <AddQuoteContextProvider>
                        <AddQuote />
                    </AddQuoteContextProvider>
                } />
                <Route path="*" element={
                    <QuoteLockContextProvider >
                        <QuoteEntryMain />
                    </QuoteLockContextProvider>
                } />
            </Routes>
        } />

        <Route path="orders/*" element={
            <OrderContextProvider>
                <Routes>
                    <Route path="containerStatus" element={<ContainerStatus />} />
                    <Route path="prodStatus" element={<ProdStatus />} />
                    <Route path="*" element={<OrderViewMain />} />
                </Routes>
            </OrderContextProvider>
        } />

        <Route path="search" element={<Search />} />

        <Route path="setup/*" element={
            <Routes>
                <Route path="terms" element={<Terms />} />
                <Route path="shipvia" element={<ShipVia />} />
                <Route path="users/*" element={
                    <UserSetupContextProvider>
                        <Routes>
                            <Route path="view/:employeeID" element={<UserInfo isEditMode={false} />} />
                            <Route path="edit/:employeeID" element={<UserInfo isEditMode={true} />} />
                            <Route path="add/:contractorCustomerGUID" element={<AddUser />} />
                            <Route path="add" element={<AddUser />} />
                            <Route path="" element={<Users />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </UserSetupContextProvider>
                } />
                <Route path="customers/*" element={
                    <Routes>
                        <Route path="view/:customerID" element={<CustomerInfo isEditMode={false} />} />
                        <Route path="edit/:customerID" element={<CustomerInfo isEditMode={true} />} />
                        <Route path="" element={<Customers />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                } />
                <Route path="company/*" element={
                    <Routes>
                        <Route path="view/:siteID" element={<CompanyInfo isEditMode={false} />} />
                        <Route path="edit/:siteID" element={<CompanyInfo isEditMode={true} />} />
                        <Route path="" element={<Company />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                } />
                <Route path="*" element={<NotFound />} />
                <Route path="pricing/*" element={
                    <Routes>
                        <Route path="tables" element={<PriceTables />} />
                        <Route path="schedules" element={<PriceSchedules />} />
                    </Routes>
                } />
            </Routes>
        } />

        <Route path="user/preferences" element={<Preferences />} />
        <Route path="maintenance/confirmation" element={<Confirmation />} />
        <Route path="maintenance" element={<Maintenance />} />
        <Route path="orderhistory" element={<OrderHistory />} />
        <Route path="purchasehistory" element={<PurchaseHistory />} />
        <Route path="user/profile" element={<Profile />} />
        <Route path="documents" element={<Documents />} />

        <Route path="login/*" element={
            <Routes>
                <Route path="csr" element={<Navigate to="/maintenance" replace />} />
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Routes>
        } />

        <Route path="*" element={<NotFound />} />

    </Routes>;
}

export default AppRoutes;