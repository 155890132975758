import { Grid, TextField, Typography } from "@mui/material";
import * as React from "react";

import { HeaderValuePair } from "../WizardHelper";

interface IProps {
    hvp: HeaderValuePair;
    handleSplitOptionValueChange: (hvp: HeaderValuePair, newValue: string) => void
    locked: boolean;
    readOnlyMode: boolean;
}

export default function NonTiledSplitOption(props: IProps) {

    const [value, setValue] = React.useState<string>(props.hvp.value);

    React.useEffect(() => {

        if (value !== props.hvp.value) {
            setValue(props.hvp.value);
        }

    }, [props.hvp.value]);


    const handleOptionValueTextChange = (event: React.FocusEvent<HTMLInputElement>) => {
        let input: HTMLInputElement = event.target as HTMLInputElement;

        const newValue:string = input.value;

        //Only submit the API request if something actualy changed
        if ( newValue !== props.hvp.value) {
            commitInputValueTextChange(input);
        }

    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            commitInputValueTextChange((e.target as HTMLInputElement));
        }
    }

    const commitInputValueTextChange = (e: HTMLInputElement) => {
        let newValue: string = value.trim();
        props.handleSplitOptionValueChange(props.hvp, newValue);
    }



    let hvp: HeaderValuePair = props.hvp;

    return <Grid item container direction="row" alignItems="center">

        <Grid item mr={1} xs="auto">
            <Typography>{hvp.header}</Typography>
        </Grid>

        <Grid item xs>

            <TextField
                fullWidth
                value={value}
                onBlur={handleOptionValueTextChange}
                onKeyDown={handleKeyDown}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue((e.target as HTMLInputElement).value);
                }}
                disabled={props.locked || props.readOnlyMode}
            />

        </Grid>

    </Grid>

}


