import { useMemo } from "react";
import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";

import SelectTerms from "components/Quotes/SelectFields/SelectTerms";
import SelectSalesperson from "components/Quotes/SelectFields/SelectSalesperson";
import SelectPricingValue from "components/Quotes/SelectFields/SelectPricingValue";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim"

import { useTranslations } from "@fenetech/translations";

import { PricingMethodEnum } from "helpers/enums";
import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    parentCustomerID : string,
    parentSiteID : string,
    expanded : boolean,
    pricingMethod : PricingMethodEnum,
    permissions? : IQuotePermissions,
    markdownVisible? : boolean,
    onExpandedChange : (event: React.SyntheticEvent, expanded: boolean) => void,    
}

function Sales({ parentCustomerID, parentSiteID, expanded, pricingMethod, permissions, markdownVisible, onExpandedChange } : IProps)
{
    const tm = useTranslations();

    const pricingValueVisible = useMemo(() => {
        return permissions?.pricingValueVisible ?? false;
    }, [permissions]);

return <>
    <Accordion expanded={expanded} onChange={onExpandedChange} disableGutters >
        <AccordionSummarySlim>
            <Typography>
                {tm.Get("Sales")}
            </Typography>
        </AccordionSummarySlim>
        <AccordionDetails>
        <Grid container spacing={2} padding={0}>
            <Grid item xs={12} sm={6}>
                {/* Salesperson */}
                <SelectSalesperson 
                    parentCustomerID={parentCustomerID} 
                    parentSiteID={parentSiteID} 
                    permissions={permissions}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                {/* Terms */}
                <SelectTerms 
                    parentCustomerID={parentCustomerID} permissions={permissions}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                {/* Margin / Markup / Discount */}
                {pricingValueVisible && 
                    <SelectPricingValue 
                        fieldName="pricing.orderDiscount"
                        permissionName="pricingValue"
                        pricingMethod={pricingMethod} permissions={permissions}
                        fullWidth={true}
                        />  
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                {/* Markdown */}
                {markdownVisible && 
                    <SelectPricingValue 
                        fieldName="pricing.orderMarkdown"
                        permissionName="markdown"
                        pricingMethod={PricingMethodEnum.Markdown} permissions={permissions}
                        fullWidth={true}
                    />              
                }
            </Grid>
        </Grid>
        </AccordionDetails>
    </Accordion>
</>

}


export default Sales;