
import React from "react";
import { IDashboardQuote } from "helpers/interfaces";
import DashboardAPI from "../DashboardAPI";
import DashboardContext, { ActionTypeEnum, DashboardContextDispatch } from "../DashboardContext";


export default function useDashboardCustomerQuoteData(): IDashboardQuote[]|undefined {

    const dashboardData = React.useContext(DashboardContext);
    const dispatch = React.useContext(DashboardContextDispatch);
    const QuoteData = dashboardData.customerQuotes;

    const isExpired = (dashboardData.customerQuotesExpTime < Date.now()  );

    React.useEffect(() => {

        if (dispatch) {
            if (!QuoteData || isExpired) {
                DashboardAPI.QueryCustomerQuotes().then(o => {
                    dispatch({ type: ActionTypeEnum.SetCustomerQuotes, value: o });
                });
            }
        }

    }, [dispatch, QuoteData, isExpired]);

    return QuoteData;

};