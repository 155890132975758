import React from "react"
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator, { AddStatusColumnData } from "components/Common/DataGridColumnGenerator";
import useIsMobile from "helpers/hooks/useIsMobile";
import { Grid, Typography, useTheme } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import useDashboardSubmittedQuoteData from "./useDashboardSubmittedQuoteData";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

interface IProps {

}

const SubmittedQuotesGrid: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const theme = useTheme();
    const cf = useCurrencyFormatter();
    const rawData = useDashboardSubmittedQuoteData();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("DashboardSubmittedQuote");

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        return row.oKey;
    }

    const data = React.useMemo(() => {
        if (rawData) {
            return rawData.map((d) => AddStatusColumnData(d, "Received", theme, tm));
        } else {
            return null;
        }
    }, [rawData, theme, tm]);

    const generator = new DataGridColumnGenerator(tm, data, theme, isMobile, cf);

    generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Quote", "/quotes", { hideable: false });
    generator.TryAddDateColumn("orderDate", "Date", { hideInMobile: true });
    generator.TryAddTextColumn("poNumber", "PO Number", { hideInMobile: true });
    generator.TryAddTextColumn("customerRef", "Customer Ref", { hideInMobile: true });
    generator.TryAddTotalPriceColumn({});
    generator.TryAddDateColumn("expirationDate", "Expires", { hideInMobile: false });
    generator.TryAddStatusColumn();

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Grid container direction="row" mt={1} mb={1}>
            <Typography variant="h6" >
                {tm.Get("Submitted Quotes")}
            </Typography>
        </Grid>

        <CustomDataGridPro
            rows={data}
            columns={generator.GetColumns()}
            getRowId={getRowId}
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
        />
    </>;

};

export default SubmittedQuotesGrid;