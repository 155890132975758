import { DispatchActionsBase } from "components/Common/DispatchActionsBase";
import usePageLoadBlocker from "helpers/hooks/usePageLoadBlocker";
import React from "react";

export interface INavigationBlocker {
    isBlocked: boolean;
};


const initialState: INavigationBlocker = {
    isBlocked: false,
};

export enum NavigationBlockerActionEnum {
    SetIsBlocked,
}

/*Define Reducer and Actions*/
export type NavigationBlockerActionType =
    | { type: NavigationBlockerActionEnum.SetIsBlocked, value: boolean }

const navigationBlockerReducer: React.Reducer<INavigationBlocker, NavigationBlockerActionType> = (state: INavigationBlocker, action) => {

    switch (action.type) {
        case NavigationBlockerActionEnum.SetIsBlocked:
            return { ...state, isBlocked: action.value };
    }
}

/*Create context for state and reducer dispatch*/

export const NavigationBlockerContextDispatch = React.createContext<NavigationBlockerActions | undefined>(undefined);
export const NavigationBlockerContext = React.createContext<INavigationBlocker>(initialState);

export class NavigationBlockerActions extends DispatchActionsBase<NavigationBlockerActionType> {

    #unblockRef: React.MutableRefObject<() => void>

    constructor(dispatcher: React.Dispatch<NavigationBlockerActionType>, unblockRef: React.MutableRefObject<() => void>) {
        super(dispatcher);
        this.#unblockRef = unblockRef;
    }

    Unblock() {
        this.#unblockRef.current();
        this.dispatch({ type: NavigationBlockerActionEnum.SetIsBlocked, value: false });
    }

    Block() {
        this.dispatch({ type: NavigationBlockerActionEnum.SetIsBlocked, value: true });
    }
}

/*Create provider*/
export const NavigationBlockerContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(navigationBlockerReducer, initialState);

    const unblockRef = React.useRef(function () {
        [].forEach((e) => e);
    })

    const actions = React.useMemo<NavigationBlockerActions>(() => {
        return new NavigationBlockerActions(dispatch, unblockRef);
    }, [dispatch, unblockRef]);

    usePageLoadBlocker(unblockRef, state.isBlocked);

    return <NavigationBlockerContext.Provider value={state}>
        <NavigationBlockerContextDispatch.Provider value={actions}>
            {props.children}
        </NavigationBlockerContextDispatch.Provider>
    </NavigationBlockerContext.Provider>
}

export default NavigationBlockerContext;
