import { Avatar } from "@mui/material"
import React from "react"
import useUserInfo from "../../helpers/context/User/useUserInfo"


const UserAvatar: React.FC = () => {

    const userInfo = useUserInfo();
    return <Avatar {...stringAvatar(userInfo.DisplayUserName())} />
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += value.toString(16).padStart(2, '0');
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    if (!name)
        return null;

    let initials: string = "";

    if (name.indexOf(' ') >= 0) {
        const nameParts = name.split(' ').filter(np => (np?.length ?? 0) > 0);
        if (nameParts.length > 1) {
            initials = `${nameParts[0][0]}${nameParts[1][0]}`
        }
        else if (nameParts.length === 1) {
            initials = nameParts[0];
        }
        else {
            initials = name[0];
        }
    } else {
        initials = name[0];
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initials,
    };
}

export default UserAvatar;

