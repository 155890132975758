
import { CardHeader, CardHeaderProps } from '@mui/material';

const style = {
    '.MuiCardHeader-content':
    {
        color: "secondary.contrastText"
    },
    bgcolor: "secondary.light"
};


export default function CustomCardHeader(props: React.PropsWithChildren<CardHeaderProps>) {
    return <CardHeader
        {...props}
        sx={{ ...style, ...props.sx }}
    >
        {props.children}
    </CardHeader>
}