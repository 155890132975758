import { useCallback, useEffect, useMemo, useState } from "react";
import { Container, useTheme, alpha, Stack, Link, Tooltip } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { GridCallbackDetails, GridCellParams, GridRenderCellParams, GridRowClassNameParams, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import { useNavigate, useLocation } from "react-router-dom";
import { DateTime } from "luxon";

import CustomDataGridPro from "components/Common/CustomDataGridPro";
import useOrderActions from "components/Orders/useOrderActions";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { useTranslations } from "@fenetech/translations";
import useOrderData from "components/Orders/useOrderData";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useIsMobile from "helpers/hooks/useIsMobile";
import { IProdStatusSummary } from "models/IOrder";
import { ProdStatusTypeEnum } from "helpers/enums";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import Format from "helpers/fv.format";
import ProdStatusUnitHistory from "components/Orders/ProdStatus/ProdStatusUnitHistory";
import ProdStatusUnitRejectHistory from "components/Orders/ProdStatus/ProdStatusUnitRejectHistory";
import useWait from "helpers/context/Page/useWait";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

interface IProdStatusRow extends IProdStatusSummary {
    orderItemAndQty: string,
    formattedPart: string,
}

function getStatusType(statusCode: string): ProdStatusTypeEnum {

    switch (statusCode) {
        case "__L__":
        case "__S__":
        case "__TS__":
        case "__D__":
        case "__MD__":
        case "__TR__":
        case "__ML__":
        case "__U__":
        case "__MU__":
        case "__C__":
        case "__ST__":
        case "__MX__":
        case "__UG__":
        case "__MG__":
            return ProdStatusTypeEnum.Complete;
        default:
            return isRejectCode(statusCode) ? ProdStatusTypeEnum.Rejected : ProdStatusTypeEnum.Released;
    }
}

// This logic was pulled from the old usp_biSelectOrderProdStatus (v 10.11)
function isRejectCode(statusCode: string): boolean {
    if (statusCode.length > 0) {
        if (statusCode === "__PB__" || statusCode === "__B__") {
            return true;
        }
        else if (statusCode.length < 4) {
            return true;
        }
        else if (!statusCode.startsWith("__") || !statusCode.endsWith("__")) {
            return true;
        }
    }

    return false;
}

const ProdStatus: React.FC<any> = () => {

    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const oKeyString = query.get("oKey") ?? undefined;

    const [unitHistoryDialogVisible, setUnitHistoryDialogVisible] = useState<boolean>(false);
    const [unitRejectHistoryDialogVisible, setUnitRejectHistoryDialogVisible] = useState<boolean>(false);

    const orderActions = useOrderActions();
    const { order, prodStatus } = useOrderData();
    const theme = useTheme();
    const wait = useWait();
    const isMobile = useIsMobile();
    const tm = useTranslations();
    const appInfo = useApplicationInfo();
    const formatHelper = useFormatHelper();
    const actionButtons = useActionButtons();
    const rowsPerPage = useRowsPerPage("ProdStatus");

    if (!oKeyString) {
        //No okey 
        navigate(-1);
    }

    const oKey = oKeyString ? parseInt(oKeyString) : 0;

    const title = useMemo(() => {
        if (order) {
            return `${tm.Get("Order Production Status")} - #${order?.orderNumber}`;
        }
        return "";
    }, [tm, order]);

    useWindowTitle(title);

    useEffect(() => {
        if (oKey) {
            orderActions.LoadOrderAsync(oKey);
            orderActions.LoadProdStatusAsync(oKey);
        }
    }, [oKey, orderActions]);

    useEffect( () => {
        actionButtons.SetBackButton(0, `/orders?oKey=${oKey}`)
    }, [oKey, actionButtons])

    const prodStatusRows = useMemo(() => {
        const details: IProdStatusRow[] = [];

        if (order && prodStatus) {
            prodStatus.forEach((pd) => {
                const props: IProdStatusRow = {
                    ...pd,
                    orderItemAndQty: `${pd.orderNumber} - ${Format.FormatLineItem(pd.lineItem, pd.subLineItem)}/${pd.orderItemXofOrderQty}`,
                    formattedPart: `${Format.FormatPartDescription(pd.partNo, pd.partNoSuffix, pd.description)}`,
                };
                details.push(props);
            });
            
            return details;

        } else {
            return null;
        }

    }, [prodStatus, order]);

    const getStatusText = useCallback((row: IProdStatusRow) => {
        return formatHelper.FormatStatusCode(row.code);
    }, [formatHelper]);

    const getStatusColor = useCallback((row: IProdStatusRow) => {
        const statusType = getStatusType(row.code);
        switch (statusType) {
            case ProdStatusTypeEnum.Complete:
                return theme.palette.success.contrastText;
            case ProdStatusTypeEnum.Rejected:
                return theme.palette.error.contrastText;
            default:
                return undefined;
        }
    }, [theme.palette.success.contrastText, theme.palette.error.contrastText]);


    const onStatusClick = useCallback(async (row: IProdStatusRow) => {
        if (oKey && row.schedID && row.unitID) {
            wait.Show(true);
            await orderActions.LoadProdStatusUnitHistoryAsync(oKey, row);
            setUnitHistoryDialogVisible(true);
            wait.Show(false);
        }
    }, [oKey, wait, orderActions]);

    const getRejectIsVisible = useCallback((row: IProdStatusRow) => {
        const statusType = getStatusType(row.code);
        return statusType === ProdStatusTypeEnum.Rejected && appInfo.parameters.displayProdStatusRejectLink;
    }, [appInfo.parameters.displayProdStatusRejectLink]);

    const onRejectClick = useCallback(async (row: IProdStatusRow) => {
        if (oKey && row.schedID && row.unitID && appInfo.parameters.displayProdStatusRejectLink) {
            wait.Show(true);
            await orderActions.LoadProdStatusUnitRejectHistoryAsync(oKey, row);
            setUnitRejectHistoryDialogVisible(true);
            wait.Show(false);
        }
    }, [oKey, wait, orderActions, appInfo.parameters.displayProdStatusRejectLink]);

    const renderStatusCell = useCallback((params: GridRenderCellParams) => {
        return <>
            <Stack direction="row" gap={1}>
                <Link color={getStatusColor(params.row)} variant="body2" component="button" underline="hover" onClick={() => onStatusClick(params.row)}>
                    {getStatusText(params.row)}
                </Link >
                {getRejectIsVisible(params.row) &&
                    <Tooltip title={tm.Get("Reject Status")}>
                        <Link component="button" underline="none" onClick={() => onRejectClick(params.row)} >
                            <ErrorOutline sx={{ color: theme.palette.error.contrastText }} />
                        </Link>
                    </Tooltip>
                }
            </Stack>
        </>;
    }, [theme.palette.error.contrastText, tm, getRejectIsVisible, getStatusColor, getStatusText, onRejectClick, onStatusClick]);

    const generator = useMemo(() => {
        const columns = new DataGridColumnGenerator(tm, prodStatusRows, theme, isMobile);
        columns.TryAddNumericColumn("schedID", "Schedule", undefined, { hideInMobile: false, flex: 1 });
        columns.TryAddNumericColumn("unitID", "Unit", undefined, { hideInMobile: false, flex: 1 });
        columns.TryAddNumericColumn("batchID", "Batch", undefined, { hideInMobile: true, flex: 1 });
        columns.TryAddNumericColumn("bin", "Bin", undefined, { hideInMobile: true, flex: 1 });
        columns.TryAddTextColumn("orderItemAndQty", "Item/Order", { hideInMobile: true, flex: 2 });
        columns.TryAddTextColumn("formattedPart", "Part", { hideInMobile: false, flex: 4 });
        columns.TryAddTextColumn("stationID", "Station", { hideInMobile: true, flex: 2 });
        columns.TryAddDateTimeColumn("dateTime", "Date", DateTime.DATETIME_SHORT_WITH_SECONDS, { flex: 3 });
        columns.TryAddRenderCellColumn("code", "Status", renderStatusCell, { hideInMobile: false, flex: 2 });
        columns.TryAddTextColumn("containerID", "Container", { hideInMobile: true, flex: 1 });
        return columns;
    }, [tm, prodStatusRows, theme, isMobile, renderStatusCell]);

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        const prodStatus = row as IProdStatusSummary;
        return `${prodStatus.schedID}'${prodStatus.unitID}'${prodStatus.masterKey}'${prodStatus.parentKey}`;
    };

    const getRowClassName = (params: GridRowClassNameParams<any>): string => {
        const prodStatus = params.row as IProdStatusRow;
        return prodStatus.shipsSeparately ? "shipSeparate" : "";
    };

    const getCellClassName = (params: GridCellParams<any>) => {
        if (params.field === "code") {
            const prodStatus = params.row as IProdStatusRow;
            const statusType = getStatusType(prodStatus.code);
            if (statusType === ProdStatusTypeEnum.Complete) {
                return "completed";
            } else if (statusType === ProdStatusTypeEnum.Rejected) {
                return "rejected"
            }
            return "";
        }
        return "";
    };

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Container maxWidth="xl" sx={{ marginTop: 1 }}>
            <CustomDataGridPro
                getRowId={getRowId}
                columns={generator.GetColumns()}
                rows={prodStatusRows}
                onPageSizeChange={onPageSizeChange}
                pageSize={rowsPerPage.pageSize}
                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                getRowClassName={getRowClassName}
                getCellClassName={getCellClassName}
                sx={(theme) => {
                    return {
                        "& .shipSeparate": {
                            backgroundColor: alpha(theme.palette.primary.light, 0.2),
                        },
                        "& .completed": {
                            backgroundColor: theme.palette.success.main,
                        },
                        "& .rejected": {
                            backgroundColor: theme.palette.error.main,
                        },
                        "& .MuiDataGrid-iconButtonContainer, .MuiDataGrid-menuIcon": {
                            "& svg": {
                                fill: theme.palette.secondary.contrastText,
                            }
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            background: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText
                        },

                    }
                }}
            />

        </Container>

        <ProdStatusUnitHistory
            dialogVisible={unitHistoryDialogVisible}
            onClose={() => setUnitHistoryDialogVisible(false)}
        />

        <ProdStatusUnitRejectHistory
            dialogVisible={unitRejectHistoryDialogVisible}
            onClose={() => setUnitRejectHistoryDialogVisible(false)}
        />
    </>;

};

export default ProdStatus;