import { useMemo } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { ArrowDownward, ArrowUpward, FileCopy, Delete } from "@mui/icons-material";

import { ILineItem } from "models/IQuote";
import { ILineItemGridProps } from "components/Quotes/QuoteEntry/LineItems/LineItemGrid";
import { ThemeColorEnum } from "helpers/enums";
import useQuoteData from "components/Quotes/useQuoteData";

export interface ILineItemCommandEvents {
    onDeleteClick: (lineItem: ILineItem) => void;
    onCopyClick: (lineItem: ILineItem) => void;
    onMoveUpClick: (lineItem: ILineItem) => void;
    onMoveDownClick: (lineItem: ILineItem) => void;
}

interface IProps {
    lineItem: ILineItem,
    lineItemGridProps: ILineItemGridProps,
    events: ILineItemCommandEvents,
}

const LineItemCommands: React.FC<IProps> = ({ lineItem, lineItemGridProps, events }: IProps) => {
    const tm = lineItemGridProps.tm;

    const { lineItems } = useQuoteData();

    const lineItemCount = useMemo(() => {
        return lineItems?.filter(li => !li.isSurchargePart).length ?? 0;
    }, [lineItems]);

    // Prevent any of the links in this section from acting as "submit"
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return <>
        <Stack direction="row" onClick={preventDefault}>
            <Tooltip title={tm.Get("Copy")}>
                <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => events.onCopyClick(lineItem)}>
                    <FileCopy />
                </IconButton>
            </Tooltip>
            {lineItem.lineItemNumber !== 1 &&
                <Tooltip title={tm.Get("Move up")}>
                    <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => events.onMoveUpClick(lineItem)}>
                        <ArrowUpward />
                    </IconButton>
                </Tooltip>}
            {lineItem.lineItemNumber !== lineItemCount &&
                <Tooltip title={tm.Get("Move down")}>
                    <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => events.onMoveDownClick(lineItem)}>
                        <ArrowDownward />
                    </IconButton>
                </Tooltip>}
            <Tooltip title={tm.Get("Delete")}>
                <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => events.onDeleteClick(lineItem)}>
                    <Delete />
                </IconButton>
            </Tooltip>
        </Stack>
    </>;

};

export default LineItemCommands;


