import React from 'react';
import { Dialog, DialogContent } from "@mui/material";

import useIsMobile from "helpers/hooks/useIsMobile";

import Policies from "./Policies";

interface IProps {
    dialogVisible: boolean;
    onClose: () => void;
    onApprove: () => Promise<string>;
}

const PoliciesDialog: React.FC<IProps> = ({ dialogVisible, onClose, onApprove }: IProps) => {

    const isMobile = useIsMobile();

    return <>
        <Dialog
            open={dialogVisible}
            onClose={onClose}
            fullWidth={isMobile}
            maxWidth={isMobile ? false : undefined}
        >
            <DialogContent sx={{ padding: 1 }}>
                <Policies
                    onApprove={onApprove}
                />
            </DialogContent>
        </Dialog>
    </>;

}

export default PoliciesDialog;