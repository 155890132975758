import { Chip } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

import { useTranslations } from "@fenetech/translations";
import useOrderData from "components/Orders/useOrderData";
import { useMemo } from "react";

interface IProps {
    onClick: () => void;
}

function PoliciesChip({ onClick }: IProps) {
    const tm = useTranslations();
    const { policies } = useOrderData();

    const chipColor = useMemo(() => {
        if (policies?.find(p => p.webApproval === true)) {
            return "warning";
        }
        return "error";
    }, [policies]);

    return <>
        {policies && policies.length > 0 &&
            <Chip
                color={chipColor}
                label={tm.Get("On Hold")}
                onClick={onClick}
                onDelete={onClick}
                deleteIcon={<MoreHoriz />}
            />
        }
    </>

}

export default PoliciesChip;