import { DateTime, Info } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

class CustomLuxonAdapter extends AdapterLuxon {

    private static convertAmericanDayToLuxonOffset = (day: number) => {
        return (8 - day) % 7;
    }

    private getStartDayOffsetForLocale = () => {
        if (window.cultureDayOfWeekOffsets.has(this.locale)) {
            return CustomLuxonAdapter.convertAmericanDayToLuxonOffset(window.cultureDayOfWeekOffsets.get(this.locale)!);
        } else {
            if (window.cultureDayOfWeekOffsets.has(this.locale.split('-')[0])) {
                return CustomLuxonAdapter.convertAmericanDayToLuxonOffset(window.cultureDayOfWeekOffsets.get(this.locale.split('-')[0])!);
            } else {
                // Default to American way
                return 1;
            }
        }
    }

    public getWeekdays = () => {
        const days = [...Info.weekdaysFormat('narrow', {locale: this.locale})];
        let offset = this.getStartDayOffsetForLocale();
        for (let i = 0; i < offset; i++) {
            days.unshift(days.pop()!);
        }
        return days;
    }

    public getWeekArray = (date: DateTime) => {
        let offset = this.getStartDayOffsetForLocale();

        const { days } = date
            .endOf("month")
            .plus({days: offset})
            .endOf("week")
            .diff(date.startOf("month").startOf("week").minus({days: offset}), "days")
            .toObject();

        let weeks: DateTime[][] = [];
        new Array<number>(Math.round(days!))
            .fill(0)
            .map((_, i) => i)
            .map(day => date.startOf("month").startOf("week").minus({ days: offset }).plus({ days: day }))
            .forEach((v, i) => {
                if (i === 0 || (i % 7 === 0 && i > 6)) {
                    weeks.push([v]);
                    return;
                }

                weeks[weeks.length - 1].push(v);
            });

        weeks = weeks.filter(week => {
            return (
                week[0].hasSame(date, "month") ||
                week[week.length - 1].hasSame(date, "month")
            );
        });

        return weeks;
    }
}

export default CustomLuxonAdapter;