import APIHelper from "helpers/APIHelper";
import { IFolder, IFile } from "helpers/interfaces";

export default class DocumentsAPI {

    public static async QueryFolders(): Promise<IFolder> {
        var url = 'api/documents/folders';
        const wr = await APIHelper.GetAsync<IFolder>(url);
        return wr.Result;
    }

    public static async QueryFiles(path: string): Promise<IFile[]> {
        var url = 'api/documents/files';
        var params = {
            path: path,
        }
        const wr = await APIHelper.GetAsync<IFile[]>(url, params);
        return wr.Result;
    }

}
