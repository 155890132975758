import { Box, Grid, Paper, Stack } from "@mui/material"


import OrderTotalLabels from "components/Orders/OrderView/LineItems/Fields/OrderTotalLabels";
import OrderTotals from "components/Orders/OrderView/LineItems/Fields/OrderTotals";

interface IProps {
    onSurchargeClick: () => void,
    onTaxClick: () => void,
}

const LineItemFooterCard: React.FC<IProps> = ({ onSurchargeClick, onTaxClick }: IProps) => {

    return <>

        <Paper>
            <Grid container display="flex" direction="row">
                <Box m={1} display="flex" alignSelf="flex-end" marginLeft="auto">
                    <Stack direction="row" gap={2}>
                        <OrderTotalLabels
                            variant="body1"
                            onSurchargeClick={onSurchargeClick}
                            onTaxClick={onTaxClick}
                        />
                        <OrderTotals
                            variant="body1"
                        />
                    </Stack>
                </Box>
            </Grid>
        </Paper>

    </>;
}

export default LineItemFooterCard;
