import React from "react";
import { useTheme, Divider, Menu, MenuItem, Grid } from '@mui/material';
import useUserInfo from 'helpers/context/User/useUserInfo';
import { StateSetter } from "helpers/types";
import { Logout, Settings, Palette } from "@mui/icons-material";
import UserAvatar from "./UserAvatar";
import useFeneVisionTheme from "helpers/context/Theme/useFeneVisionTheme";
import { RoleEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";

interface IProps {
    menuOpen: boolean;
    setMenuOpen: StateSetter<boolean>;
    signOut: () => void;
    navigateToProfile: () => void;
    navigateToPreferences: () => void;
    openThemeSelector: () => void;
    anchorElement: HTMLElement | null;
}

const UserMenu: React.FC<IProps> = (props: IProps) => {

    const userInfo = useUserInfo();
    const theme = useTheme();
    const [fvThemeContext] = useFeneVisionTheme();
    const tm = useTranslations();

    return <>
        <Menu
            sx={{ zIndex: 9999 }}
            open={props.menuOpen}
            anchorEl={props.anchorElement}
            onClose={() => props.setMenuOpen(false)}
            onClick={() => props.setMenuOpen(false)}
            PaperProps={{
                elevation: 0,
                sx: {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        bgcolor: theme.palette.secondary.main,
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },

                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {userInfo.HasRole(RoleEnum.CSR) ?
                <MenuItem sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent", cursor: "default" } }}>
                    <UserAvatar /> {userInfo.DisplayUserName()}
                </MenuItem>
                :
                <MenuItem onClick={props.navigateToProfile}>
                    <UserAvatar /> {userInfo.DisplayUserName()}
                </MenuItem>
            }
            <Divider />
            {fvThemeContext.themeInfos.length > 1 &&
                <MenuItem onClick={props.openThemeSelector}>
                    <Grid container gap={1}>
                        <Palette />
                        {tm.Get("Theme")}
                    </Grid>
                </MenuItem>
            }
            {!userInfo.HasRole(RoleEnum.CSR) &&
                <MenuItem onClick={props.navigateToPreferences}>
                    <Grid container gap={1}>
                        <Settings />
                        {tm.Get("Preferences")}
                    </Grid>
                </MenuItem>
            }

            <MenuItem onClick={props.signOut}>
                <Grid container gap={1}>
                    <Logout />
                    {tm.Get("Sign Out")}
                </Grid>
            </MenuItem>
        </Menu>
    </>;


}

export default UserMenu;


