import { IMfgCustomer } from 'helpers/interfaces';

export default class MfgCustomersAndSites {

    public constructor(mfgCustomersAndSites: IMfgCustomer[]) {
        this.mfgCustomersAndSites = mfgCustomersAndSites;
    }

    mfgCustomersAndSites : IMfgCustomer[];

    public GetDistinctMfgCustomers() {
        let distinctCustomers : IMfgCustomer[] = [];

        const map = new Map();
        for (const mfgCustomer of this.mfgCustomersAndSites) {
            if (!map.has(mfgCustomer.customerID)) {
                map.set(mfgCustomer.customerID, mfgCustomer);
                distinctCustomers.push(mfgCustomer);
            }
        }

        return distinctCustomers;
    }

    public GetMfgSitesFromMfgCustomerID(mfgCustomerID: string) {
        let mfgSites : IMfgCustomer[] = this.mfgCustomersAndSites.filter((c) => c.customerID === mfgCustomerID);
        return mfgSites;
    }

}