import { Theme } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid-pro";
import { IStatusProperties } from "components/Common/DataGridColumnGenerator";
import { ITranslationManager } from "@fenetech/translations";
import { IDashboardPickup } from "helpers/interfaces";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import ApiUser from "models/ApiUser";

export const generatePickupColumns = (tm: ITranslationManager, data: (IDashboardPickup & IStatusProperties)[] | null, theme: Theme, isMobile: boolean, user: ApiUser) => {

  if (!data || data.length === 0) return [] as GridColumns;

  const generator = new DataGridColumnGenerator(tm, data, theme, isMobile);

  generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Order", "/orders", { hideable: false });
  generator.TryAddDateColumn("orderDate", "Date", { hideInMobile: true });
  generator.TryAddTextColumn("customerRef", "Customer Ref", { hideInMobile: true });
  generator.TryAddDateColumn("pickupDate", "Est. Pickup Date", { hideInMobile: true });
  if (user.isMultiSite) {
    generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", { hideInMobile: true });
  }
  generator.TryAddStatusColumn();

  return generator.GetColumns();

}
