import { useCallback, useEffect, useState } from "react";
import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import useQuoteData from "components/Quotes/useQuoteData";
import OrdersAPI from "components/Orders/OrdersAPI";

import { useTranslations } from "@fenetech/translations";

interface IProps {
    mfgOrderNumber: string | undefined
}

const RelatedOrderLink: React.FC<IProps> = ({ mfgOrderNumber }: IProps) => {

    const { quote } = useQuoteData();
    const tm = useTranslations();
    const [isOrderAccessAllowed, setIsOrderAccessAllowed] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (quote && quote.mfgOKey && quote.mfgOKey > 0) {
            OrdersAPI.GetOrderAccessAllowed(quote.mfgOKey).then((isAllowed) => {
                setIsOrderAccessAllowed(isAllowed);
            });
        }
    }, [quote]);


    const getLabel = useCallback((): { label: string, url: string } => {

        let label = "";
        let url = "";
        if (quote?.mfgOKey && quote.mfgOKey > 0 && mfgOrderNumber) {
            label = tm.Get("Order");
            label += ": " + mfgOrderNumber;
            url = `/orders?oKey=${quote.mfgOKey}`;
        }

        return { label, url };

    }, [quote?.mfgOKey, mfgOrderNumber, tm]);

    return <>
        {(isOrderAccessAllowed && getLabel().label && getLabel().url) ?
            <Link component={RouterLink} to={getLabel().url} >
                {getLabel().label}
            </Link>
            :
            <>
                <Typography>
                    {getLabel().label}
                </Typography>
            </>
        }
    </>;

}

export default RelatedOrderLink;