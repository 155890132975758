import React from 'react';

import { IWebCustomer } from 'helpers/interfaces';
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useWebCustomers(isContractorQuote?: boolean, parentCustomerID?: string, parentSiteID?: string): IWebCustomer[] | undefined {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const webCustomers = selectionValues.webCustomers;

    const isExpired = false;

    const cacheKey = `${parentCustomerID ?? ""}~|~${parentSiteID ?? ""}`;
    
    React.useEffect(() => {

        if (parentCustomerID && parentSiteID && parentCustomerID && isContractorQuote !== undefined) {
            if (!webCustomers.has(cacheKey) || isExpired)
            {
                if (!isContractorQuote)  // we are viewing a dealer quote so show a list of the dealer's customers
                {
                    actions.LoadWebCustomerListAsync(parentCustomerID, parentSiteID, cacheKey);
                }
                else  // contractor quote, so show a list of the contractor's customers
                {
                    actions.LoadContractorCustomerListAsync(parentCustomerID, cacheKey);
                }

            }
        }

    }, [actions, isContractorQuote, parentCustomerID, parentSiteID, webCustomers, isExpired, cacheKey]);

    return webCustomers.get(cacheKey);

};

export default useWebCustomers;