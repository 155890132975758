import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";

import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";

import useOrderData from "components/Orders/useOrderData";

interface IProps {
    variant: any,
}

const OrderTotals: React.FC<IProps> = ({ variant }: IProps) => {

    const { order, lineItems, totals } = useOrderData();

    const isSurchargeOverridden = useMemo(() => {
        var overriddenSurcharge = lineItems?.find((li) => li.isSurchargePart && li.priceOverride);
        return overriddenSurcharge ? true : false;
    }, [lineItems]);

    const appInfo = useApplicationInfo();
    const currencyFormatter = useCurrencyFormatter(order?.currencyCulture);

    if (!lineItems || !totals) {
        return null;
    }

    return <>
        <Stack direction="column">
            {appInfo.surchargesConfigured &&
                <Typography variant={variant} textAlign="right" fontWeight={isSurchargeOverridden ? "bold" : undefined} noWrap >
                    {currencyFormatter.Format(totals.surcharge)}
                </Typography>
            }
            <Typography variant={variant} textAlign="right" noWrap >
                {currencyFormatter.Format(totals.subTotal)}
            </Typography>
            <Typography variant={variant} textAlign="right" noWrap >
                {currencyFormatter.Format(totals.tax)}
            </Typography>
            <Typography variant={variant} textAlign="right" noWrap >
                {currencyFormatter.Format(totals.nonTaxableMisc)}
            </Typography>
            <Typography variant={variant} textAlign="right" noWrap >
                {currencyFormatter.Format(totals.subTotal + totals.tax + totals.nonTaxableMisc)}
            </Typography>
        </Stack>
    </>;

};

export default OrderTotals;