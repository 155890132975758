import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import React from "react";
import API from "../../API";

export const TitleContext = React.createContext<string>("FeneVision WEB");

/*Create provider*/
export const TitleContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [appTitle, setAppTitle] = React.useState<string | null>(null);

    useEffectOnLoad(() => {
        API.GetApplicationTitle().then(t => {
            window.document.title = t;
            setAppTitle(t);
        }).catch(() => {
            setAppTitle(null);
        });
    });

    return <TitleContext.Provider value={appTitle ?? ""}>
        {props.children}
    </TitleContext.Provider>
}


