import isNumeric from "fast-isnumeric";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Container, Box } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useUserInfo from "helpers/context/User/useUserInfo";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useWait from "helpers/context/Page/useWait";
import { IActionButton } from "helpers/context/Page/PageContext";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { RoleEnum, ThemeColorEnum, UserPermissionTypes } from "helpers/enums";
import useOrderData from "components/Orders/useOrderData";
import WizardAPI, { ILineItemSimple } from "components/OptionsWizard/WizardAPI";
import LineItemPriceBreakdownAPI from "components/LineItemPriceBreakdown/LineItemPriceBreakdownAPI";
import { ILineItemPriceBreakdown, ILineItemPBOption, ILineItemPBDetails } from "helpers/interfaces";
import PBHeader from "components/LineItemPriceBreakdown/PBHeader"
import PBOptionsGrid from "components/LineItemPriceBreakdown/PBOptionsGrid"
import PBPricing from "components/LineItemPriceBreakdown/PBPricing"
import { ILineItemPriceBreakdownProps } from "components/LineItemPriceBreakdown/LineItemPriceBreakdown";
import Format from "helpers/fv.format";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderNavigation from "components/Orders/OrderNavigation";
import NotFound from "components/Layout/NotFound";
import useOnlineAckToken from "helpers/hooks/useOnlineAckToken";

function OrderLineItemPriceBreakdown() {
    const user = useUserInfo();
    const tm = useTranslations();
    const wait = useWait();
    const navigate = useNavigate();
    const actionButtons = useActionButtons();
    const formatMethods = useFormatHelper();
    const [title, setTitle] = useState<string>("");
    const [data, setData] = useState<ILineItemPriceBreakdown | null>(null);
    const [lineItems, setLineItems] = useState<ILineItemPBDetails[]>([]);

    const [simpleLineItem, setSimpleLineItem] = React.useState<ILineItemSimple | null>(null)

    const { order, permissions } = useOrderData();
    const cf = useCurrencyFormatter(order?.currencyCulture);
    const [viewCost, setViewCost] = useState<boolean>(false);
    const [anyPriceOverridden, setAnyPriceOverridden] = useState<boolean>(false);

    const oKey = order?.oKey;
    const [query] = useSearchParams();
    const odKeyString = query.get("odKey") ?? undefined;
    const odKey = odKeyString ? parseInt(odKeyString) : 0;
    const onlineAckToken = useOnlineAckToken();

    // Header
    const [qty, setQty] = useState<string>("");
    const [qtyOverride, setQtyOverride] = useState<boolean>(false);
    const [item, setItem] = useState<number>(0);
    const [size, setSize] = useState<string>("");
    const [partPrice, setPartPrice] = useState<string>("");
    const [partPriceOverride, setPartPriceOverride] = useState<boolean>(false);
    const [qtyErrorMessage, setQtyErrorMessage] = useState<string>("");
    const [partPriceErrorMessage, setPartPriceErrorMessage] = useState<string>("");
    const [currentQty, setCurrentQty] = useState<string>("");
    const [currentPartPrice, setCurrentPartPrice] = useState<string>(""); 
    
    // Options
    const [discountTable, setDiscountTable] = useState<string>("");

    // Pricing
    const [itemWeight, setItemWeight] = useState<string>("");
    const [itemCost, setItemCost] = useState<string>("");
    const [itemSqFtPrice, setItemSqFtPrice] = useState<string>("0.00");
    const [itemPrice, setItemPrice] = useState<string>("0.00");
    const [overallWeight, setOverallWeight] = useState<string>("");
    const [overallCost, setOverallCost] = useState<string>("");
    const [overallSqFtPrice, setOverallSqFtPrice] = useState<string>("0.00");
    const [overallPrice, setOverallPrice] = useState<string>("0.00");
    const [overallSqFtPriceErrorMessage, setOverallSqFtPriceErrorMessage] = useState<string>("");
    const [overallPriceErrorMessage, setOverallPriceErrorMessage] = useState<string>("");
    const [currentOverallSqFtPrice, setCurrentOverallSqFtPrice] = useState<string>("");
    const [currentOverallPrice, setCurrentOverallPrice] = useState<string>("");

    const orderedQty = useMemo<number>(() => {
        if (data) {
            return data.lineItems[0].qty;
        } else {
            return 0;
        }
    }, [data]);

    const selectedLineItem = useMemo<ILineItemPBDetails | undefined>(() => {
        return data?.lineItems.find(l => l.subLineItem === item);
    }, [data, item]);

    const optionsData = useMemo<ILineItemPBOption[]>(() => {
        if (selectedLineItem) {
            return selectedLineItem.options;
        } else {
            return [];
        }
    }, [selectedLineItem]);

    useEffect(() => {
        if (oKey && odKey) {
            WizardAPI.GetLineItemInfoSimple(oKey, odKey, true).then((li) => {
                setSimpleLineItem(li);
            });
        }
    }, [oKey, odKey]);

    useEffect(() => {
        if (oKey && odKey && data === null) {
            LineItemPriceBreakdownAPI.PriceBreakdown(oKey, odKey, true).then((pd) => {
                setData(pd);
            })
        }
    }, [oKey, odKey, data]);

    useWindowTitle(title);
    useEffect(() => {
        if (order && simpleLineItem?.lineItem) {
            setTitle(`${tm.Get("Price Breakdown")} - #${order.orderNumber ?? ""}-${simpleLineItem?.lineItem}`);
        }
    }, [order, tm, simpleLineItem]);

    useEffect(() => {
        if (!permissions?.viewCost) {
            setViewCost(false);
        } else {
            setViewCost(true);
        }
    }, [user, order, permissions]);

    useEffect(() => {
        if (oKey && odKey && (data === null)) {
            wait.Show(true);
        } else {
            wait.Show(false);
        }
    }, [oKey, odKey, wait, data])

    useEffect(() => {
        if (data && selectedLineItem && order) {
            setQty(selectedLineItem.qty.toString());
            setQtyOverride(selectedLineItem.qtyOverride);
            setSize(formatMethods.FormatDimensions(selectedLineItem.width, selectedLineItem.height, selectedLineItem.thickness, order.engineeringUnitSetID));
            setPartPrice(cf.Format(selectedLineItem.partPrice));
            setPartPriceOverride(selectedLineItem.priceOverride);
            setDiscountTable(selectedLineItem.discountTable);
            setLineItems(data.lineItems);
            setItemWeight(selectedLineItem.itemWeight === 0 ? "" : Format.FormatWeight(selectedLineItem.itemWeight));
            setItemCost(selectedLineItem.itemCost === 0 ? "" : cf.Format(selectedLineItem.itemCost));
            setItemSqFtPrice(cf.Format(selectedLineItem.itemSqFtPrice));
            setItemPrice(cf.Format(selectedLineItem.itemPrice));
            setOverallWeight(data.topLevelDetails.overallWeight === 0 ? "" : Format.FormatWeight(data.topLevelDetails.overallWeight));
            setOverallCost(data.topLevelDetails.overallCost === 0 ? "" : cf.Format(data.topLevelDetails.overallCost));
            setOverallSqFtPrice(cf.Format(data.topLevelDetails.overallSqFtPrice));
            setOverallPrice(cf.Format(data.topLevelDetails.overallPrice));
            setAnyPriceOverridden(data.topLevelDetails.anyPriceOverridden);
            setCurrentQty(selectedLineItem.qty.toString());
            setCurrentPartPrice(cf.Format(selectedLineItem.partPrice));
            setCurrentOverallSqFtPrice(cf.Format(data.topLevelDetails.overallSqFtPrice));
            setCurrentOverallPrice(cf.Format(data.topLevelDetails.overallPrice));
            setQtyErrorMessage("");
            setPartPriceErrorMessage("");
            setOverallSqFtPriceErrorMessage("");
            setOverallPriceErrorMessage("");
        }
    }, [data, selectedLineItem, cf, formatMethods, order])

    const handleItemChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            if (data && order) {
                let i = parseInt(e.target.value);

                const subLineItem = data.lineItems.find(l => l.subLineItem === i);

                if (subLineItem) {
                    setQty(subLineItem.qty.toString());
                    setQtyOverride(subLineItem.qtyOverride);
                    setSize(formatMethods.FormatDimensions(subLineItem.width, subLineItem.height, subLineItem.thickness, order.engineeringUnitSetID));
                    setPartPrice(cf.Format(subLineItem.partPrice));
                    setPartPriceOverride(subLineItem.priceOverride);
                    setDiscountTable(subLineItem.discountTable);
                    setItemWeight(subLineItem.itemWeight === 0 ? "" : Format.FormatWeight(subLineItem.itemWeight));
                    setItemCost(subLineItem.itemCost === 0 ? "" : cf.Format(subLineItem.itemCost));
                    setItemSqFtPrice(cf.Format(subLineItem.itemSqFtPrice));
                    setItemPrice(cf.Format(subLineItem.itemPrice));
                    setCurrentQty(subLineItem.qty.toString());
                    setCurrentPartPrice(cf.Format(subLineItem.partPrice));
                    setItem(i);
                }
            }
        }
    };

    const handleOKClick = useCallback(() => {
        navigate(OrderNavigation.OrdersURL(order?.oKey ?? 0, onlineAckToken));
    }, [navigate, order, onlineAckToken])

    useEffect(() => {
        actionButtons.Clear();
        const submitButton: IActionButton = {
            text: tm.Get("OK"),
            color: ThemeColorEnum.Secondary,
            disabled: false,
            onClick: (handleOKClick)
        };
        actionButtons.Set(0, submitButton);

    }, [oKey, actionButtons, tm, handleOKClick]);

    if (!odKey) {
        return <NotFound />
    }

    if (order && (
        (!user.HasPermission(UserPermissionTypes.ViewPricing)))
    ) {
        // Insufficient Permissions
        navigate(OrderNavigation.OrdersURL(order?.oKey ?? 0, onlineAckToken));
        return null;
    }

    let props: ILineItemPriceBreakdownProps = {
        qty,
        orderedQty,
        qtyOverride,
        item,
        lineItems,
        size,
        partPrice,
        partPriceOverride,
        currentQty,
        currentPartPrice,
        discountTable: user.HasRole(RoleEnum.CSR) ? discountTable : "",
        optionsData,
        itemWeight,
        itemCost,
        itemSqFtPrice,
        itemPrice,
        overallWeight,
        overallCost,
        overallSqFtPrice,
        overallPrice,
        currentOverallSqFtPrice,
        currentOverallPrice,
        viewCost,
        anyPriceOverridden,
        qtyErrorMessage,
        partPriceErrorMessage,
        overallSqFtPriceErrorMessage,
        overallPriceErrorMessage,
        setPartPriceErrorMessage,
        setOverallSqFtPriceErrorMessage,
        setOverallPriceErrorMessage,
        handleItemChange,
        setData,
        editEnabled: false,
        subLineQuantitiesCanChange: false,
        setEditingOption: () => { },
        setIsPosting: () => { },
        setQtyErrorMessage: () => { },
        handleQtyChange: () => { },
        handlePartPriceChange: () => { },
        handleOverallSqFtPriceChange: () => { },
        handleOverallPriceChange: () => { },
        handleRepriceClick: () => { },
        cf
    }

    return <>
        {order && lineItems.length > 0 &&
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" p={1} gap={1} mt={1}>
                    <PBHeader {...props} />
                    <PBOptionsGrid {...props} />
                    <PBPricing {...props} />
                </Box>
            </Container>
        }
    </>
}

export default OrderLineItemPriceBreakdown;