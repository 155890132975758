import { Stack, CircularProgress } from "@mui/material";
import { ILineItemPriceInfo } from "components/OptionsWizard/interfaces";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import { useTranslations } from "@fenetech/translations";
import React from 'react';

interface IProps {
    prices: ILineItemPriceInfo | null;
    currencyCulture: string;
    hidePricingLabel?: boolean;
}

const PricingLabel: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const cf = useCurrencyFormatter(props.currencyCulture);
    let pricingLabel: string = tm.Get("Pricing")

    if (!props.prices || props.prices.waiting) {

        const label = (props.hidePricingLabel ? "" : pricingLabel + " - ");

        return <Stack direction="row" alignItems="center" spacing={.5}>
            <span>{label}</span>
            <CircularProgress size={16} />
        </Stack>

    } else {

        let label: string = "";
        if (props.prices && !props.prices.waiting) {
            let parsedPrice: number = Number(props.prices.overallPrice);
            if (props.hidePricingLabel)
                label = isNaN(parsedPrice) ? props.prices.overallPrice : cf.Format(parsedPrice);
            else
                label = pricingLabel + "  -  [" + (isNaN(parsedPrice) ? props.prices.overallPrice : cf.Format(parsedPrice)) + "]";
        } else {
            label = pricingLabel;
        }

        return <span>{label}</span>;

    }

}

export default PricingLabel;

