import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Container, Stack, Box, TableContainer, Typography, Link, Grid, Table, TableHead, TableBody, TableRow, TableCell, styled, IconButton, alpha,  Snackbar, Alert, SnackbarCloseReason } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import { Print } from "@mui/icons-material";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useUserInfo from "helpers/context/User/useUserInfo";
import useFileDownload from "helpers/hooks/useFileDownload";
import useReports from "helpers/context/SelectionValues/useReports";
import { RoleEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";
import { IUploadedOrSubmittedQuotes } from "helpers/interfaces";
import useWait from "helpers/context/Page/useWait";

const Confirmation: React.FC<any> = () => {
    
    const user = useUserInfo();
    const tm = useTranslations();
    const navigate = useNavigate();
    const location = useLocation();
    const wait = useWait();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [alertText, setAlertText] = React.useState<string | undefined>(undefined);

    const { getReportDownloadAPI } = useReports(user.HasRole(RoleEnum.Contractor) ? "web" : "core", user.HasRole(RoleEnum.Contractor) ? "quote" : "order");

    useWindowTitle(tm.Get("Confirmation"));

    useEffect(() => {
        wait.Show(isLoading);
    }, [isLoading, wait]);

    const fileDownload = useFileDownload({
        preDownload: () => setIsLoading(true),
        postDownload: () => setIsLoading(false),
        onError: () => handlePrintError(),
    });

    const handlePrintError = () => {
        setIsLoading(false);
        setAlertText(tm.Get("An unknown error has occurred."));
    };
    
    const onAlertClose = (event: React.SyntheticEvent<Element, Event>) => {
        setAlertText(undefined);
    }

    const onAlertCloseSnackbar = (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway')
            return;

        setAlertText(undefined);
    }

    const separateSuccessesAndFails = React.useCallback((fullSet: IUploadedOrSubmittedQuotes[] | undefined) => {
        var successSet: IUploadedOrSubmittedQuotes[] = [];
        var failSet: IUploadedOrSubmittedQuotes[] = [];
        if (fullSet) {
            fullSet.forEach((e: IUploadedOrSubmittedQuotes) => {
                if (e.hasError) {
                    failSet.push(e);
                } else {
                    successSet.push(e);
                }
            })
        }
        return [successSet, failSet];
    }, []);

    const formattedMessage = React.useCallback((message: string) => {
        return <>
            {message.split("\n").map((s) => (
                <>
                    {s}
                    <br/>
                </>
            ))}
        </>
    }, []);

    const uploadedOrSubmittedQuotes = location.state as IUploadedOrSubmittedQuotes[] | undefined;

    const [successQuotes, failedQuotes] = separateSuccessesAndFails(uploadedOrSubmittedQuotes);
    
    const printQuoteHandler = React.useCallback((okey: number) => {
        console.log(okey);
        fileDownload.download({ apiCall: () => getReportDownloadAPI(okey, "OrderAck", "PDF") });
    }, [fileDownload, getReportDownloadAPI]);
    
    var firstColumnHeader = React.useMemo(() => {
        if (user.HasRole(RoleEnum.Contractor)) {
            return tm.Get("Submitted Quote");
        } else {
            return tm.Get("Order");
        }
    }, [user, tm])
    
    if (user.HasRole(RoleEnum.CSR)) {
        // Not Allowed
        navigate("/maintenance", {replace:true});
        return null;
    }
    if (!uploadedOrSubmittedQuotes) {
        // Got here directly
        navigate("/dashboard", {replace:true});
        return null;
    }

    const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    }));

    const StyledCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    }));

    const StyledRow = styled(TableRow)(({ theme }) => ({
        [`&:nth-of-type(even)`]: {
            backgroundColor: alpha(theme.palette.secondary.light, 0.1),
        },
    }));
    
    return <>
        <Container maxWidth="xl">
            <Box display="flex" flexDirection="column" p={1} gap={1}>

                <Stack direction="column" m={1} spacing={3}>

                    <Snackbar open={alertText ? true : false} autoHideDuration={5000} onClose={onAlertCloseSnackbar} >
                        <Alert onClose={onAlertClose} severity='error' variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alertText}</Alert>
                    </Snackbar>

                    {/* Hidden link to host file downloads */}
                    <a hidden ref={fileDownload.ref} />

                    {successQuotes.length > 0 &&
                        <Grid container direction="column" justifyItems="stretch">
                            <Grid item>
                                <Typography>{tm.Get("Succeeded")}</Typography>
                            </Grid>
                            <Grid item>
                                <TableContainer>
                                    <Table sx={{ width: "100%" }} size="small" padding="none">
                                        <TableHead>
                                            <TableRow>
                                                <StyledHeaderCell style={{ width: 130 }}>{firstColumnHeader}</StyledHeaderCell>
                                                <StyledHeaderCell style={{ width: 70 }}>{tm.Get("Quote")}</StyledHeaderCell>
                                                <StyledHeaderCell>{tm.Get("Message")}</StyledHeaderCell>
                                                <StyledHeaderCell style={{ width: 30 }}>&nbsp;</StyledHeaderCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {successQuotes.map((quote) => (
                                                <StyledRow key={quote.oldOrderNumber}>
                                                    <StyledCell>
                                                        <Link variant="body2" underline="hover" component={RouterLink} 
                                                        to={{ pathname: user.HasRole(RoleEnum.Contractor) ? `/quotes/` : `/orders/`, search: `oKey=${quote.newOKey}`}} >
                                                            {quote.newOrderNumber}
                                                        </Link>
                                                    </StyledCell>
                                                    <StyledCell>{quote.oldOrderNumber}</StyledCell>
                                                    <StyledCell>{formattedMessage(quote.messages)}</StyledCell>
                                                    <StyledCell>
                                                        <IconButton onClick={() => printQuoteHandler(quote.newOKey)}>
                                                            <Print fontSize="small" color="primary"/>
                                                        </IconButton>
                                                    </StyledCell>
                                                </StyledRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    }

                    {failedQuotes.length > 0 &&
                        <Grid container direction="column" justifyItems="stretch">
                            <Grid item>
                                <Typography>{tm.Get("Failed")}</Typography>
                            </Grid>
                            <Grid item>
                                <TableContainer>
                                    <Table sx={{ width: "100%" }} size="small" padding="none">
                                        <TableHead>
                                            <TableRow>
                                                <StyledHeaderCell style={{ width: 70 }}>{tm.Get("Quote")}</StyledHeaderCell>
                                                <StyledHeaderCell>{tm.Get("Message")}</StyledHeaderCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {failedQuotes.map((quote) => (
                                                <StyledRow key={quote.oldOrderNumber}>
                                                    <StyledCell>
                                                        <Link variant="body2" underline="hover" component={RouterLink} 
                                                        to={{ pathname: `/quotes/`, search: `oKey=${quote.oldOKey}`}} >
                                                            {quote.oldOrderNumber}
                                                        </Link>
                                                    </StyledCell>
                                                    <StyledCell>{formattedMessage(quote.messages)}</StyledCell>
                                                </StyledRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    }

                </Stack>

            </Box>
        </Container>
    </>
}

export default Confirmation;
