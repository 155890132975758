import { useState, useEffect } from "react";


type InputChangeEvent = React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>;

export default function useFormInput<T>(defaultValue: T): [T, (e: InputChangeEvent) => void] {

    const [state, setState] = useState<T>(defaultValue);

    useEffect(() => {
        if (defaultValue !== state) {
            setState(defaultValue);
        }
    }, [defaultValue]);

    const handleInputChange: (e: InputChangeEvent) => void = (e: InputChangeEvent) => {
        const val: T = e.target.value as unknown as T;
        setState(val);
    };

    return [state, handleInputChange ];

}