import LineItemImage from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemImage";
import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";
import { IOrderLineItem } from "helpers/interfaces";

export default class LineItemImageColumn extends LineItemColumn {

    onClick: (imageSource: string) => void;

    public constructor(columnName: string, headerText: string, width: string, onClick: (imageSource: string) => void) {
        super(columnName, headerText, width);
        this.rowSpan = 3;
        this.onClick = onClick;
    }

    public CellContent({ order, permissions }: IOrderLevelProps, lineItem: IOrderLineItem): JSX.Element {

        return <>
            <LineItemImage
                oKey={order.oKey}
                lineItem={lineItem}
                imageAccessKey={permissions.imageAccessKey}
                isOrder={true}
                onClick={this.onClick}
                showMediumSize={false}
            />
        </>;
    }

    public FooterContent(props: IOrderLevelProps): JSX.Element {
        return <></>;
    }
}
