import { Stack, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import Constants from "helpers/constants";
import useMfgCustomersAndSites from "helpers/context/SelectionValues/useMfgCustomersAndSites";
import useQuoteClassifications from "helpers/context/SelectionValues/useQuoteClassifications";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum, ClassificationTypeEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";
import { IQuoteClassification } from "helpers/interfaces";
import { IMfgCustomer } from "models/IMfgCustomer";
import React, { ReactNode } from "react";

interface IProps {
    handleChangeMfgCustomerName: (customerName: string | null, siteName: string | null) => void;
}

const ALL_VALUE: string = "~!~!~!~"

const SelectStatusType = ({ handleChangeMfgCustomerName }: IProps) => {

    const tm = useTranslations();
    const userInfo = useUserInfo();
    const appClassifications = useQuoteClassifications();
    const mfgCustomers = useMfgCustomersAndSites();
    const selectionValuesActions = useSelectionValuesActions();
    const { maintenanceClassification } = useSelectionValuesData();

    const [mfgCustomerID, setMfgCustomerIDInternal] = React.useState<string>(ALL_VALUE);
    const [mfgSiteID, setMfgSiteIDInternal] = React.useState<string>(ALL_VALUE);

    const allClassifications = React.useMemo<IQuoteClassification[]>(() => {

        const csr = userInfo.HasRole(RoleEnum.CSR);
        const dealer = userInfo.HasRole(RoleEnum.Dealer);
        const contractor = userInfo.HasRole(RoleEnum.Contractor);
        const classifications: IQuoteClassification[] = [];

        if (appClassifications) {

            if (csr) {
                classifications.push({
                    classificationID: ClassificationTypeEnum.Recent,
                    classificationType: ClassificationTypeEnum.Recent,
                    description: "Recent"
                })
            }

            const onHoldBeforeUpload = appClassifications?.classifications.filter(c => c.classificationType === ClassificationTypeEnum.OnHoldBeforeUpload);
            const availBeforeUpload = appClassifications?.classifications.filter(c => c.classificationType === ClassificationTypeEnum.AvailableBeforeUpload);
            const afterUpload = appClassifications?.classifications.filter(c => c.classificationType === ClassificationTypeEnum.AfterUpload);

            if (availBeforeUpload) classifications.push(...availBeforeUpload);
            if (onHoldBeforeUpload) classifications.push(...onHoldBeforeUpload);

            if (dealer || csr) {
                classifications.push({
                    classificationType: ClassificationTypeEnum.Uploaded,
                    classificationID: ClassificationTypeEnum.Uploaded,
                    description: "Uploaded"
                });

            } else if (contractor) {
                classifications.push({
                    classificationType: ClassificationTypeEnum.Submitted,
                    classificationID: ClassificationTypeEnum.Submitted,
                    description: "Submitted"
                });

            }

            if (dealer || csr) {
                if (afterUpload) classifications.push(...afterUpload);
            }

            classifications.push({
                classificationType: ClassificationTypeEnum.Expired,
                classificationID: ClassificationTypeEnum.Expired,
                description: "Expired"
            })
        }

        return classifications;
    }, [userInfo, appClassifications]);

    React.useEffect(() => {
        if (allClassifications.length > 0) {
            if (maintenanceClassification === null) {
                selectionValuesActions.SetMaintenanceClassification(allClassifications[0]);
            }
        }
    }, [allClassifications, maintenanceClassification, selectionValuesActions])

    const allSelection: IMfgCustomer = React.useMemo(() => {
        return {
            name: `{${tm.Get("All")}}`,
            siteName: `{${tm.Get("All")}}`,
            customerID: ALL_VALUE,
            siteID: ALL_VALUE,
            customerGUID: "",
        }
    }, [tm]);


    const mfgCustomerList = React.useMemo(() => {

        if (mfgCustomers) {
            let customerList = mfgCustomers.GetDistinctMfgCustomers();
            return [allSelection, ...customerList];
        } else {
            return null;
        }

    }, [allSelection, mfgCustomers]);

    const allCustomerSelection: IMfgCustomer = React.useMemo(() => {

        let siteName = `{${tm.Get("All")}}`
        let customerName = siteName;

        if (mfgCustomerList && mfgCustomerID !== ALL_VALUE) {
            const customer = mfgCustomerList.filter(c => c.customerID === mfgCustomerID);
            if (customer && customer.length) {
                customerName = customer[0].name;
            }
        }

        return {
            name: customerName,
            siteName: siteName,
            customerID: ALL_VALUE,
            siteID: ALL_VALUE,
            customerGUID: "",
        }
    }, [tm, mfgCustomerList, mfgCustomerID]);


    const mfgSiteList = React.useMemo(() => {

        if (mfgCustomers && mfgCustomerID !== ALL_VALUE) {
            let sitesList = mfgCustomers.GetMfgSitesFromMfgCustomerID(mfgCustomerID)
            return [allCustomerSelection, ...sitesList];
        } else {
            return [allCustomerSelection];
        }

    }, [allCustomerSelection, mfgCustomers, mfgCustomerID])


    const csrMode = userInfo.HasRole(RoleEnum.CSR);
    const recent = maintenanceClassification?.classificationType === ClassificationTypeEnum.Recent;

    const handleStatusChange = React.useCallback((e: SelectChangeEvent<number>, child: ReactNode) => {

        const id = Number(e.target.value);
        const classification = allClassifications.filter(c => c.classificationID === id);

        selectionValuesActions.SetMaintenanceClassification(classification[0]);
        setMfgCustomerIDInternal(ALL_VALUE);
        setMfgSiteIDInternal(ALL_VALUE);

        handleChangeMfgCustomerName(null, null);

    }, [allClassifications, handleChangeMfgCustomerName, selectionValuesActions]);

    const handleCustomerChange = React.useCallback((e: SelectChangeEvent<string>, child: ReactNode) => {

        const id = e.target.value as string;

        setMfgCustomerIDInternal(id);
        setMfgSiteIDInternal(ALL_VALUE);

        const customer = mfgCustomerList?.filter(c => c.customerID === id && id !== ALL_VALUE)
        const customerName = customer?.length ? customer[0].name : null
        handleChangeMfgCustomerName(customerName, null);

    }, [mfgCustomerList, handleChangeMfgCustomerName]);

    const handleSiteChange = React.useCallback((e: SelectChangeEvent<string>, child: ReactNode) => {

        const id = e.target.value as string;
        setMfgSiteIDInternal(id);

        const site = mfgSiteList.filter(s => s.siteID === id);
        if (site && site.length) {
            let singleSiteName: string | null = site[0].siteName;

            if (site[0].siteID === ALL_VALUE)
                singleSiteName = null;

            handleChangeMfgCustomerName(site[0].name, singleSiteName);
        } else {
            handleChangeMfgCustomerName(null, null);
        }

    }, [mfgSiteList, handleChangeMfgCustomerName]);

    return <Stack direction="row" justifyContent="flex-start" spacing={1}>

        {maintenanceClassification &&

            <Select sx={{ minWidth: 1 / 5 }}
                value={maintenanceClassification.classificationID}
                onChange={handleStatusChange}
            >
                {allClassifications.map((c) =>
                    <MenuItem key={c.classificationID} value={c.classificationID} >
                        {Constants.Strings.Classifications.includes(c.description) ? tm.Get(c.description) : c.description}
                    </MenuItem>
                )}

            </Select>
        }

        {csrMode && !recent && mfgCustomerList && <>
            <Select sx={{ minWidth: 1 / 5 }}
                value={mfgCustomerID}
                onChange={handleCustomerChange}
            >
                {mfgCustomerList.map(c =>
                    <MenuItem key={c.customerID} value={c.customerID} >
                        {c.name}
                    </MenuItem>)}
            </Select>

            <Select sx={{ minWidth: 1 / 5 }}
                value={mfgSiteID}
                onChange={handleSiteChange}
            >
                {mfgSiteList && mfgSiteList.map(c =>
                    <MenuItem key={c.siteID} value={c.siteID}>
                        {c.siteName}
                    </MenuItem>)}
            </Select>
        </>}

    </Stack>

};


export default SelectStatusType;
