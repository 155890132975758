import API from "helpers/API";
import useAuthHandler from "helpers/context/Authentication/useAuthHandler";
import React from "react";
import ApplicationInfo from "../../../models/ApplicationInfo";

export const ApplicationContext = React.createContext<ApplicationInfo | undefined>(undefined);

/*Create provider*/
export const ApplicationContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [appInfo, setAppInfo] = React.useState<ApplicationInfo | undefined>(undefined);
    const authHandler = useAuthHandler();

    React.useEffect(() => {
        if (appInfo === undefined) {
            API.GetApplicationInfo().then(a => {
                setAppInfo(new ApplicationInfo(a));
                authHandler.ChangeTimeout(a.timeout);
            }).catch(() => {
                setAppInfo(undefined);
            });
        }
    }, [appInfo, authHandler]);

    return <ApplicationContext.Provider value={appInfo}>
        {props.children}
    </ApplicationContext.Provider>
}





