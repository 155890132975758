import { IDashboardOrder } from "models/IDashboardOrder";
import React from "react";
import DashboardAPI from "../DashboardAPI";
import DashboardContext, { ActionTypeEnum, DashboardContextDispatch } from "../DashboardContext";


export function useDashboardOrderData(): IDashboardOrder[]|undefined {

    const dashboardData = React.useContext(DashboardContext);
    const dispatch = React.useContext(DashboardContextDispatch);

    const orderData = dashboardData.orders;

    const isExpired = (dashboardData.orderDataExpTime < Date.now()  );

    React.useEffect(() => {

        if (dispatch) {
            if (!orderData || isExpired) {
                DashboardAPI.QueryOpenOrders().then(o => {
                    dispatch({ type: ActionTypeEnum.SetOrders, value: o });
                });
            }
        }

    }, [dispatch, orderData, isExpired]);

    return orderData;

};

