import React from "react";
import ApiUser from "models/ApiUser";
import API from "helpers/API";
import useAuthInfo from "helpers/context/Authentication/useAuthInfo";

export const UserContext = React.createContext<ApiUser|undefined>(undefined);

/*Create provider*/
export const UserContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [apiUser, setApiUser] = React.useState<ApiUser|undefined>(undefined);
    const authInfo = useAuthInfo();

    React.useEffect(() => {
        if (authInfo.signedIn) {
            API.GetUserInfo().then((i) => {
                if (i)
                    setApiUser(new ApiUser(i));
                else
                    setApiUser(undefined);
            });
        } else {
            //Don't bother hitting the API if we don't have a token
            setApiUser(undefined);
        }

    }, [authInfo.signedIn]);

    return <UserContext.Provider value={apiUser}>
        {props.children}
    </UserContext.Provider>
}

