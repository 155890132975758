import { Checkbox, Container, FormControl, FormControlLabel, Grid, MenuItem, RadioGroup, Select, Typography } from "@mui/material";
import React from "react";
import { useTranslations } from "@fenetech/translations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";

import SearchAPI from "./SearchAPI";
import SearchGrid from "./SearchGrid";
import useUserInfo from "helpers/context/User/useUserInfo";
import useDefaultActionButtons from "helpers/context/Page/useDefaultActionButtons";
import { OrderTypesEnum, RoleEnum } from "helpers/enums";
import { useNavigate } from "react-router-dom";
import SearchField from "./SearchField";
import { LoadingButton } from "@mui/lab";
import useLoadingForm from "helpers/hooks/useLoadingForm";
import LoadingForm from "components/Common/LoadingForm";
import useIsMobile from "helpers/hooks/useIsMobile";


export enum SearchTypeEnum {
    Order = "Order",
    Quote = "Quote",
}

export enum FieldTypeEnum {
    QuoteNumber = "Quote Number",
    OrderNumber = "Order Number",
    PONumber = "PO Number",
    CustomerRef = "Customer Ref",
    Address = "Address",
    PhoneNumber = "Phone Number",
    Customer = "Customer",
}

export interface ISearchParams {
    searchType: SearchTypeEnum,
    fieldType: string,
    searchText: string,
}


export interface ISearchResult {
    key: number;
    documentNumber: string;
    documentType: OrderTypesEnum;
    orderDate: string;
    poNumber: string;
    customerRef: string;
    customerName: string;
    siteName: string;
}

const Search: React.FC = () => {

    const user = useUserInfo();
    const tm = useTranslations();
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    useWindowTitle(tm.Get("Search"));
    useDefaultActionButtons();

    const [searchType, setSearchType] = React.useState<SearchTypeEnum>(SearchTypeEnum.Quote);
    const [autoRedirect, setAutoRedirect] = React.useState<boolean>(true);
    const [fieldType, setFieldType] = React.useState<string>(FieldTypeEnum.QuoteNumber);
    const [results, setResults] = React.useState<ISearchResult[] | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLElement>, value: string) => {
        setFieldType(value);
    };

    const handleSearchTypeChange = (e: any) => {
        setResults(null); //clear previous search results

        const newSearchType = (e.target.value as any as SearchTypeEnum)

        if (newSearchType === SearchTypeEnum.Order)
            setFieldType(FieldTypeEnum.OrderNumber);
        else
            setFieldType(FieldTypeEnum.QuoteNumber);

        setSearchType(newSearchType);
    }

    const submitHandler = (form: HTMLFormElement) => {

        setResults(null);

        let searchParams: ISearchParams = {
            searchType: searchType,
            fieldType: fieldType as FieldTypeEnum,
            searchText: ""
        };

        switch (fieldType) {
            case FieldTypeEnum.OrderNumber:
                searchParams.searchText = form.OrderNumber.value;
                break;
            case FieldTypeEnum.QuoteNumber:
                searchParams.searchText = form.QuoteNumber.value;
                break;
            case FieldTypeEnum.PONumber:
                searchParams.searchText = form.PONumber.value;
                break;
            case FieldTypeEnum.Address:
                searchParams.searchText = form.Address.value;
                break;
            case FieldTypeEnum.Customer:
                searchParams.searchText = form.Customer.value;
                break;
            case FieldTypeEnum.CustomerRef:
                searchParams.searchText = form.CustomerRef.value;
                break;
            case FieldTypeEnum.PhoneNumber:
                searchParams.searchText = form.PhoneNumber.value
                break;
        }

        //The API needs the name of the field type, not the value.
        const fieldKeys = Object.keys(FieldTypeEnum);
        const fieldKey = fieldKeys.filter(k => (FieldTypeEnum as any)[k] === fieldType);

        return new Promise(resolve => {
            if (fieldKey.length >= 0) {
                searchParams.fieldType = fieldKey[0] as FieldTypeEnum;
                SearchAPI.SubmitSearchAsync(searchParams).then(r => {

                    if (autoRedirect && r.length === 1) {
                        const singleResult = r[0];
                        if (singleResult.documentType === OrderTypesEnum.Quote) {
                            navigate( `/quotes?oKey=${singleResult.key}`);
                        } else {
                            navigate( `/orders?oKey=${singleResult.key}`);
                        }
                    } else {
                        setResults(r);
                    }

                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    resolve(true);
                });

            } else {
                resolve(false);
            }

        });
    };

    const loadingFormState = useLoadingForm(submitHandler);

    const handleSetType = (value: string) => {
        setFieldType(value);
    }


    const isContractor: boolean = user.HasRole(RoleEnum.Contractor);

    let fields = Object.keys(FieldTypeEnum).filter((key) => {
        const value = (FieldTypeEnum as any)[key];
        if ((value === FieldTypeEnum.OrderNumber && searchType === SearchTypeEnum.Quote) ||
            (value === FieldTypeEnum.QuoteNumber && searchType === SearchTypeEnum.Order) ||
            (value === FieldTypeEnum.Customer && searchType === SearchTypeEnum.Order)) {
            return false;
        } else {
            return true;
        }
    });

    return <>

        <Container maxWidth={isMobile ? "md" : "xl"}>

            {!isContractor &&
                <Select value={searchType} sx={{marginTop: 1, width: isMobile ? 1 : 1 / 3}} onChange={handleSearchTypeChange} >
                    <MenuItem key={SearchTypeEnum.Quote} value={SearchTypeEnum.Quote}>{tm.Get("Quote")}</MenuItem>
                    <MenuItem key={SearchTypeEnum.Order} value={SearchTypeEnum.Order}>{tm.Get("Order")}</MenuItem>
                </Select>
            }

            <LoadingForm {...loadingFormState}>
                <RadioGroup
                    name="type"
                    value={fieldType}
                    onChange={handleInputChange}
                >
                    {
                        fields.map(key =>
                            <SearchField key={key} fieldType={key as FieldTypeEnum} setFieldType={handleSetType} />
                        )
                    }

                    <FormControl>
                        <FormControlLabel
                            sx={{ justifyContent: "left" }}
                            control={<Typography>{tm.Get("Automatically open " + (searchType === SearchTypeEnum.Quote ? "Quote Entry" : "Order Status") + " if match is found")}</Typography>}
                            label={<Checkbox size="small" checked={autoRedirect} onChange={() => setAutoRedirect(!autoRedirect)} />}
                            labelPlacement="start"
                        />
                    </FormControl>

                </RadioGroup>

                <Grid container justifyContent="center" padding={1}>
                    <Grid item justifySelf="center"  >
                        <LoadingButton loading={loadingFormState.submitting} color="secondary" variant="contained" type="submit" >{tm.Get("Search")}</LoadingButton>
                    </Grid>
                </Grid>

            </LoadingForm>

            {results && <>
                <SearchGrid results={results} searchType={searchType} />
            </>
            }

        </Container>

    </>;

}

export default Search;
