import React, { useEffect } from 'react';
import { Container, Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import { useTranslations } from "@fenetech/translations";
import SetupAPI from "../SetupAPI";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { IShipVia } from 'models/IShipVia';
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useWait from "helpers/context/Page/useWait";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";
import TermsShipViaRename from "./TermsShipViaRename";
import { ThemeColorEnum } from "helpers/enums";
import { IActionButton } from "helpers/context/Page/PageContext";
import useActionButtons from "helpers/context/Page/useActionButtons";
import TermsShipViaAdd from "./TermsShipViaAdd";
import { useTheme } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import editDeleteCommandColumn from "./TermsShipViaCommandsColumn";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

const ShipVia = () => {

    const tm = useTranslations();
    const wait = useWait();
    useWindowTitle(tm.Get("Ship Via"));

    const [rows, setRows] = React.useState<IShipVia[] | undefined>(undefined);
    const [alertText, setAlertText] = React.useState("");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);
    const [editingName, setEditingName] = React.useState<string>("");
    const [addShipViaOpen, setAddShipViaOpen] = React.useState<boolean>(false);

    const selectionValuesActions = useSelectionValuesActions();
    const actionButtons = useActionButtons();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("ShipVia");

    async function fetchShipVia() {
        setIsLoading(true);
        try {
            let result: IShipVia[] = await SetupAPI.GetShipVia();
            setRows(result);
        } finally {
            setIsLoading(false);
        }
    }

    function clearCache() {
        selectionValuesActions.ClearShipViaCache();
    }

    useEffectOnLoad(() => {
        fetchShipVia();

        const addShipViaButton: IActionButton = {
            text: tm.GetWithParams("Add {0}", tm.Get("Ship Via")),
            color: ThemeColorEnum.Secondary,
            disabled: (isLoading || isPosting),
            onClick: (() => setAddShipViaOpen(true))
        };
        actionButtons.Set(0, addShipViaButton);
    });

    useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    const checkShipVia = (description: string) => {
        if (!description || description.trim() === '') {
            setAlertText(tm.GetWithParams("{0} cannot be blank.", tm.Get("Ship Via")));
            return false;
        }
        if (description.trim().length > 20) {
            setAlertText(tm.GetWithParams("{0} must be less than or equal to {1} characters", tm.Get("Ship Via"), "20"));
            return false;
        }
        return true;
    }

    async function insertShipVia(description: string) {
        if (!checkShipVia(description)) {
            setAddShipViaOpen(false);
            return;
        }
        setIsPosting(true);
        try {
            let result = await SetupAPI.InsertShipVia({ "description": description });
            if (result.message) {
                const translated = tm.Get(result);
                setAlertText(translated);
            } else {
                clearCache();
                await fetchShipVia();
            }
        } finally {
            setIsPosting(false);
            setAddShipViaOpen(false);
        }
    }

    async function deleteShipVia(description: string) {
        if (!checkShipVia(description)) return;
        setIsPosting(true);
        try {
            let result = await SetupAPI.DeleteShipVia({ "description": description });
            if (result.message) {
                const translated = tm.Get(result);
                setAlertText(translated);
            } else {
                clearCache();
                await fetchShipVia();
            }
        } finally {
            setIsPosting(false);
        }
    }

    async function renameShipVia(oldDescription: string, newDescription: string): Promise<boolean> {
        if (!checkShipVia(newDescription)) return false;
        if (oldDescription.trim() === newDescription.trim()) return true;
        setIsPosting(true);
        try {
            let result = await SetupAPI.RenameShipVia({ "oldDescription": oldDescription, "newDescription": newDescription });
            if (result.message) {
                const translated = tm.Get(result);
                setAlertText(translated);
                return false;
            } else {
                clearCache();
                await fetchShipVia();
                return true;
            }
        } finally {
            setIsPosting(false);
            setEditingName("");
        }
    }

    const onAlertClose = (event: React.SyntheticEvent<Element, Event>) => {
        setAlertText('');
    }

    const onAlertCloseSnackbar = (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway')
            return;

        setAlertText('');
    }

    const generator = new DataGridColumnGenerator(tm, rows, theme, isMobile);
    if (rows && rows.length > 0) {
        generator.TryAddTextColumn("description", "Ship Via", { hideInMobile: false });
        generator.AddColumn(editDeleteCommandColumn(setEditingName, deleteShipVia, tm, "description", "Ship Via"));
    }

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);
    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        return row["description"];
    };

    return <>
        <Snackbar open={alertText !== ""} autoHideDuration={5000} onClose={onAlertCloseSnackbar} >
            <Alert onClose={onAlertClose} severity='error' variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alertText}</Alert>
        </Snackbar>
        <Container sx={{ marginTop: 1 }}>
            <CustomDataGridPro
                onPageSizeChange={onPageSizeChange}
                pageSize={rowsPerPage.pageSize}
                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                getRowId={getRowId}
                columns={generator.GetColumns()}
                rows={rows}
            />
        </Container>
        {addShipViaOpen && <>
            <TermsShipViaAdd
                subHeaderText="Ship Via"
                open={addShipViaOpen}
                close={() => setAddShipViaOpen(false)}
                handleAdd={insertShipVia}
            />
        </>}
        {editingName !== "" && <>
            <TermsShipViaRename
                oldName={editingName}
                open={editingName !== ""}
                subHeaderText="Ship Via"
                close={() => setEditingName("")}
                handleRename={async (newName) => await renameShipVia(editingName, newName)}
            />
        </>}
    </>;
}

export default ShipVia;