import { IGlobalOptions } from "components/GlobalOptions/GlobalOptionsContext";
import NewOptionValue from "components/GlobalOptions/Results/Fields/NewOptionValue";
import ResultColumn from "components/GlobalOptions/Results/Columns/ResultColumn";

import { IGlobalItem } from "models/GlobalOptions/IGlobalItem";

export default class NewValueColumn extends ResultColumn {

    getText: (globalItem: IGlobalItem) => string;

    public constructor(columnName: string, headerText: string, width: string, align: "inherit" | "left" | "center" | "right" | "justify" | undefined, getText: (globalItem: IGlobalItem) => string) {
        super(columnName, headerText, width);
        this.align = align;
        this.getText = getText;
    }
    public CellContent(globalOptions: IGlobalOptions, globalItem: IGlobalItem, previousItem?: IGlobalItem): JSX.Element {
        return <NewOptionValue globalOptions={globalOptions} globalItem={globalItem} />;
    }

}
