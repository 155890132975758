import React from "react";

import { DispatchActionsBase } from "components/Common/DispatchActionsBase";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import Format, { ImperialFormatModeEnum } from "helpers/fv.format";
import { SizingModeEnum, OpeningShapeEnum, ShapeDirectionEnum } from "helpers/enums";

export interface IItemPropertiesData {
    qty: number;
    callSize: string | null;
    width: number;
    height: number;
    thickness: number;
    comment: string;
    sizing: SizingModeEnum;
    shape: OpeningShapeEnum;
    direction: ShapeDirectionEnum;
    legHeight: number;
    radius: number;
}

export interface IItemPropertiesBuffer {
    qtyBuffer: string;
    callSizeBuffer: string;
    widthBuffer: string;
    heightBuffer: string;
    thicknessBuffer: string;
    commentBuffer: string;
    sizingBuffer: SizingModeEnum;
    shapeBuffer: OpeningShapeEnum;
    directionBuffer: ShapeDirectionEnum;
    legHeightBuffer: string;
    radiusBuffer: string;
}

interface IItemPropertiesDataStates {
    loaded: boolean;
    currentState: IItemPropertiesData;
    currentBufferState: IItemPropertiesBuffer;
    previousState: IItemPropertiesData;
    previousBufferState: IItemPropertiesBuffer;
}

export enum ItemPropertiesActionTypeEnum {
    SetState,
    RollbackState,
    ClearState,
    SetQty,
    SetQtyBuffer,
    SetCallSize,
    SetCallSizeBuffer,
    SetWidth,
    SetWidthBuffer,
    SetHeight,
    SetHeightBuffer,
    SetThickness,
    SetThicknessBuffer,
    SetComment,
    SetCommentBuffer,
    SetLegHeight,
    SetLegHeightBuffer,
    SetRadius,
    SetRadiusBuffer,
    SetSizing,
    SetSizingBuffer,
    SetShape,
    SetShapeBuffer,
    SetDirection,
    SetDirectionBuffer,
}

export type ItemPropertiesActionType =
    { type: ItemPropertiesActionTypeEnum.SetState, dataState: IItemPropertiesData, bufferState: IItemPropertiesBuffer } |
    { type: ItemPropertiesActionTypeEnum.RollbackState } |
    { type: ItemPropertiesActionTypeEnum.ClearState } |
    { type: ItemPropertiesActionTypeEnum.SetQty, value: number } |
    { type: ItemPropertiesActionTypeEnum.SetQtyBuffer, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetCallSize, value: string | null } |
    { type: ItemPropertiesActionTypeEnum.SetCallSizeBuffer, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetWidth, value: number } |
    { type: ItemPropertiesActionTypeEnum.SetWidthBuffer, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetHeight, value: number } |
    { type: ItemPropertiesActionTypeEnum.SetHeightBuffer, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetThickness, value: number } |
    { type: ItemPropertiesActionTypeEnum.SetThicknessBuffer, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetComment, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetCommentBuffer, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetLegHeight, value: number } |
    { type: ItemPropertiesActionTypeEnum.SetLegHeightBuffer, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetRadius, value: number } |
    { type: ItemPropertiesActionTypeEnum.SetRadiusBuffer, value: string } |
    { type: ItemPropertiesActionTypeEnum.SetSizing, value: SizingModeEnum } |
    { type: ItemPropertiesActionTypeEnum.SetSizingBuffer, value: SizingModeEnum } |
    { type: ItemPropertiesActionTypeEnum.SetShape, value: OpeningShapeEnum } |
    { type: ItemPropertiesActionTypeEnum.SetShapeBuffer, value: OpeningShapeEnum } |
    { type: ItemPropertiesActionTypeEnum.SetDirection, value: ShapeDirectionEnum } |
    { type: ItemPropertiesActionTypeEnum.SetDirectionBuffer, value: ShapeDirectionEnum };


const ItemPropertiesReducer: React.Reducer<IItemPropertiesDataStates, ItemPropertiesActionType> = (data: IItemPropertiesDataStates, action) => {

    switch (action.type) {
        case ItemPropertiesActionTypeEnum.SetState:
            return { loaded: true, previousState: { ...action.dataState }, previousBufferState: { ...action.bufferState }, currentState: action.dataState, currentBufferState: action.bufferState };
        case ItemPropertiesActionTypeEnum.RollbackState:
            return { loaded: true, previousState: { ...data.previousState }, previousBufferState: { ...data.previousBufferState }, currentState: { ...data.previousState }, currentBufferState: { ...data.previousBufferState } };
        case ItemPropertiesActionTypeEnum.ClearState:
            return { ...initialState };
        case ItemPropertiesActionTypeEnum.SetQty:
            return { ...data, currentState: { ...data.currentState, qty: action.value } };
        case ItemPropertiesActionTypeEnum.SetCallSize:
            return { ...data, currentState: { ...data.currentState, callSize: action.value } };
        case ItemPropertiesActionTypeEnum.SetWidth:
            return { ...data, currentState: { ...data.currentState, width: action.value } };
        case ItemPropertiesActionTypeEnum.SetHeight:
            return { ...data, currentState: { ...data.currentState, height: action.value } };
        case ItemPropertiesActionTypeEnum.SetThickness:
            return { ...data, currentState: { ...data.currentState, thickness: action.value } };
        case ItemPropertiesActionTypeEnum.SetComment:
            return { ...data, currentState: { ...data.currentState, comment: action.value } };
        case ItemPropertiesActionTypeEnum.SetLegHeight:
            return { ...data, currentState: { ...data.currentState, legHeight: action.value } };
        case ItemPropertiesActionTypeEnum.SetRadius:
            return { ...data, currentState: { ...data.currentState, radius: action.value } };
        case ItemPropertiesActionTypeEnum.SetSizing:
            return { ...data, currentState: { ...data.currentState, sizing: action.value } };
        case ItemPropertiesActionTypeEnum.SetShape:
            return { ...data, currentState: { ...data.currentState, shape: action.value } };
        case ItemPropertiesActionTypeEnum.SetDirection:
            return { ...data, currentState: { ...data.currentState, direction: action.value } };
        case ItemPropertiesActionTypeEnum.SetQtyBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, qtyBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetCallSizeBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, callSizeBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetWidthBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, widthBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetHeightBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, heightBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetThicknessBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, thicknessBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetCommentBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, commentBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetLegHeightBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, legHeightBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetRadiusBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, radiusBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetSizingBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, sizingBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetShapeBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, shapeBuffer: action.value } };
        case ItemPropertiesActionTypeEnum.SetDirectionBuffer:
            return { ...data, currentBufferState: { ...data.currentBufferState, directionBuffer: action.value } };
    }
};

const initialPropertyState: IItemPropertiesData = {
    qty: 1,
    callSize: null,
    width: 0,
    height: 0,
    thickness: 0,
    comment: "",
    direction: ShapeDirectionEnum.Left,
    shape: OpeningShapeEnum.Standard,
    sizing: SizingModeEnum.Fixed,
    legHeight: 0,
    radius: 0
};

const initialBufferState: IItemPropertiesBuffer = {
    qtyBuffer: "1",
    callSizeBuffer: "",
    widthBuffer: "0",
    heightBuffer: "0",
    thicknessBuffer: "0",
    commentBuffer: "",
    directionBuffer: ShapeDirectionEnum.Left,
    shapeBuffer: OpeningShapeEnum.Standard,
    sizingBuffer: SizingModeEnum.Fixed,
    legHeightBuffer: "0",
    radiusBuffer: "0"
};

const initialState: IItemPropertiesDataStates = {
    loaded: false,
    currentState: initialPropertyState,
    currentBufferState: initialBufferState,
    previousState: initialPropertyState,
    previousBufferState: initialBufferState
};

export class ItemPropertiesActions extends DispatchActionsBase<ItemPropertiesActionType> {

    #formatMethods: Format;

    constructor(dispatcher: React.Dispatch<ItemPropertiesActionType>, formatMethods: Format) {
        super(dispatcher);
        this.#formatMethods = formatMethods;
    }

    public SetState(value: IItemPropertiesData, unitSetID: number) {

        const qtyBuffer = value.qty.toString();
        const widthBuffer = this.#formatMethods.formatDimensionText(value.width, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const heightBuffer = this.#formatMethods.formatDimensionText(value.height, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const thicknessBuffer = this.#formatMethods.formatDimensionText(value.thickness, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const callSizeBuffer = value.callSize ?? "";
        const commentBuffer = value.comment;

        const directionBuffer = value.direction;
        const legHeightBuffer = this.#formatMethods.formatDimensionText(value.legHeight, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const radiusBuffer = this.#formatMethods.formatDimensionText(value.radius, unitSetID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const shapeBuffer = value.shape;
        const sizingBuffer = value.sizing;

        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetState, dataState: value, bufferState: { qtyBuffer, callSizeBuffer, widthBuffer, heightBuffer, thicknessBuffer, commentBuffer, directionBuffer, legHeightBuffer, radiusBuffer, shapeBuffer, sizingBuffer } });
    }

    public RollbackState() {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.RollbackState });
    }

    public ClearState() {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.ClearState });
    }

    public SetQty(value: number) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetQty, value: value });
    }

    public SetQtyBuffer(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetQtyBuffer, value: value });
    }

    public SetCallSize(value: string | null) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetCallSize, value: value });
    }

    public SetCallSizeBuffer(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetCallSizeBuffer, value: value });
    }

    public SetWidth(value: number) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetWidth, value: value });
    }

    public SetWidthBuffer(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetWidthBuffer, value: value });
    }

    public SetHeight(value: number) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetHeight, value: value });
    }

    public SetHeightBuffer(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetHeightBuffer, value: value });
    }

    public SetThickness(value: number) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetThickness, value: value });
    }

    public SetThicknessBuffer(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetThicknessBuffer, value: value });
    }

    public SetComment(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetComment, value: value });
    }

    public SetCommentBuffer(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetCommentBuffer, value: value });
    }

    public SetLegHeight(value: number) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetLegHeight, value: value });
    }

    public SetLegHeightBuffer(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetLegHeightBuffer, value: value });
    }

    public SetRadius(value: number) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetRadius, value: value });
    }

    public SetRadiusBuffer(value: string) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetRadiusBuffer, value: value });
    }

    public SetSizing(value: SizingModeEnum) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetSizing, value: value });
    }

    public SetSizingBuffer(value: SizingModeEnum) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetSizingBuffer, value: value });
    }

    public SetShape(value: OpeningShapeEnum) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetShape, value: value });
    }

    public SetShapeBuffer(value: OpeningShapeEnum) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetShapeBuffer, value: value });
    }

    public SetDirection(value: ShapeDirectionEnum) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetDirection, value: value });
    }

    public SetDirectionBuffer(value: ShapeDirectionEnum) {
        this.dispatch({ type: ItemPropertiesActionTypeEnum.SetDirectionBuffer, value: value });
    }    

}

export const ItemPropertiesContextDispatch = React.createContext<ItemPropertiesActions | undefined>(undefined);
export const ItemPropertiesContext = React.createContext<IItemPropertiesDataStates>(initialState);

export const ItemPropertiesContextProvider: React.FC<React.PropsWithChildren> = (props: React.PropsWithChildren) => {

    const [state, dispatch] = React.useReducer(ItemPropertiesReducer, initialState);

    const formatMethods = useFormatHelper();

    const actions = React.useMemo<ItemPropertiesActions>(() => {
        return new ItemPropertiesActions(dispatch, formatMethods);
    }, [dispatch, formatMethods]);

    return <ItemPropertiesContext.Provider value={state}>
        <ItemPropertiesContextDispatch.Provider value={actions}>
            {props.children}
        </ItemPropertiesContextDispatch.Provider>
    </ItemPropertiesContext.Provider>
}

export default ItemPropertiesContext;
