import React, { useCallback, useMemo, useState } from "react";
import { Stack, TextField, TableContainer, Table, TableHead, TableBody, TableRow, Box, Autocomplete } from "@mui/material";
import { StyledHeaderCell } from "./RowStyles";

import { useTranslations } from "@fenetech/translations";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import { ISimpleListItem, ISimpleListItemStringKey, IPartListItem, IPriceTableOptionGridRow } from "helpers/interfaces";
import useIsMobile from "helpers/hooks/useIsMobile";
import { PricingMethodEnum } from "helpers/enums";
import OptionsGridRow from "./OptionsGridRow";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";

interface IProps {
    tableID: string,
    parts: IPartListItem[],
    optionGroups: ISimpleListItemStringKey[],
    schedules: ISimpleListItem[],
    optionsGrid: IPriceTableOptionGridRow[],
    selectedPart: string,
    selectedOptionGroup: string,
    tableType: PricingMethodEnum,
    setSelectedPart: React.Dispatch<React.SetStateAction<string>>,
    setSelectedOptionGroup: React.Dispatch<React.SetStateAction<string>>,
    fillDownHandler: (index: number, scheduleID: number, discount: string, fixedPrice: boolean) => void,
}

const OptionsGrid = ({tableID, parts, optionGroups, schedules, optionsGrid, selectedPart, selectedOptionGroup, tableType,
                                setSelectedPart, setSelectedOptionGroup, fillDownHandler}: IProps) => {
    const tm = useTranslations();
    const cf = useCurrencyFormatter("");
    const lnf = useLocaleNumberFormatter({style: "decimal", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4});
    const isMobile = useIsMobile();
    const appInfo = useApplicationInfo();

    const [usesMultiplier, setUsesMultiplier] = useState<boolean>(false);

    const handlePartChange = useCallback((data: IPartListItem) => {
        setSelectedPart(data.partKey.masterPartNo + "~" + data.partKey.partNoSuffix);
        setSelectedOptionGroup("~");
    }, [setSelectedPart, setSelectedOptionGroup]);

    const handleOptionGroupChange = useCallback((data: ISimpleListItemStringKey) => {
        setSelectedOptionGroup(data.key);
    }, [setSelectedOptionGroup]);

    const partNo = useMemo(() => {
        if (selectedPart === "~") {
            return "";
        } else {
            return selectedPart.split("~")[0];
        }
    }, [selectedPart]);

    const partNoSuffix = useMemo(() => {
        if (selectedPart === "~") {
            return "";
        } else {
            return selectedPart.split("~")[1];
        }
    }, [selectedPart]);

    const priceColumnText = useMemo(() => {
        switch (tableType) {
            case PricingMethodEnum.Discount:
                if (appInfo.parameters.pricingShowDiscountMultiplierInsteadOfPercent) {
                    setUsesMultiplier(true);
                    return tm.Get("Discount Mult.");
                } else {
                    setUsesMultiplier(false);
                    return tm.Get("Discount %");
                }
            case PricingMethodEnum.Markup:
                if (appInfo.parameters.pricingShowMarkupMultiplierInsteadOfPercent) {
                    setUsesMultiplier(true);
                    return tm.Get("Markup Mult.");
                } else {
                    setUsesMultiplier(false);
                    return tm.Get("Markup %");
                }
            case PricingMethodEnum.Margin:
                setUsesMultiplier(false);
                return tm.Get("Gross Profit Margin");
        }
    }, [appInfo, tableType, tm]);

    const getKey = useCallback((option: IPriceTableOptionGridRow) => {
        return option.code + option.timestamp;
    }, []);

    const partList = useMemo(() => {
        return [{partKey: {masterPartNo: "", partNoSuffix: "", shortcutName: ""}, description: tm.Get("{All Parts}")}].concat(parts);
    }, [parts, tm]);

    const part = useMemo(() => {
        return partList.find((p) => p.partKey.masterPartNo + "~" + p.partKey.partNoSuffix === selectedPart) ?? partList[0];
    }, [partList, selectedPart]);

    const optionGroupList = useMemo(() => {
        return [{key: "~", description: tm.Get("{All Groups}")}].concat(optionGroups);
    }, [optionGroups, tm]);

    const optionGroup = useMemo(() => {
        return optionGroupList.find((p) => p.key === selectedOptionGroup) ?? optionGroupList[0];
    }, [optionGroupList, selectedOptionGroup]);

    return <>
        <Stack sx={{ width: "100%" }} direction="column" alignItems="flex-start" justifyItems="stretch" spacing={1}>
        
            <Autocomplete
                id="select-part"
                autoComplete
                autoHighlight
                autoSelect
                selectOnFocus
                disableClearable
                handleHomeEndKeys
                options={partList}
                sx={{width : isMobile ? 1 : 1 / 3}}
                size="small"
                getOptionLabel={(option : IPartListItem) => option.description}
                renderInput={(params) => <TextField
                    label={tm.Get("Part")}
                    {...params}
                />}
                value={part}
                onChange={(_, data) => handlePartChange(data)}
            />    
            <Autocomplete
                id="select-option-group"
                autoComplete
                autoHighlight
                autoSelect
                selectOnFocus
                disableClearable
                handleHomeEndKeys
                options={optionGroupList}
                sx={{width : isMobile ? 1 : 1 / 3}}
                size="small"
                getOptionLabel={(option : ISimpleListItemStringKey) => option.description}
                renderInput={(params) => <TextField
                    label={tm.Get("Option Group")}
                    {...params}
                />}
                value={optionGroup}
                onChange={(_, data) => handleOptionGroupChange(data)}
            />

            {optionsGrid.length > 0 &&
                <Box sx={{ display: "grid", width: "100%" }}>
                    <TableContainer>
                        <Table sx={{ minWidth: "100%", width: "max-content" }} size="small" padding="none">
                            <TableHead>
                                <TableRow>
                                    {selectedPart !== '~' && 
                                        <StyledHeaderCell style={{ width: "5%" }}>&nbsp;</StyledHeaderCell>
                                    }
                                    <StyledHeaderCell style={{ width: "15%" }}>{tm.Get("Code")}</StyledHeaderCell>
                                    {!isMobile && 
                                        <StyledHeaderCell style={{ width: "30%" }}>{tm.Get("Description")}</StyledHeaderCell>
                                    }
                                    <StyledHeaderCell style={{ width: "30%" }}>{tm.Get("Schedule")}</StyledHeaderCell>
                                    <StyledHeaderCell style={{ width: "15%", textAlign: "right" }}>{priceColumnText}</StyledHeaderCell>
                                    <StyledHeaderCell style={{ width: "5%" }}>&nbsp;</StyledHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {optionsGrid.map((option, i) => (
                                    <OptionsGridRow key={getKey(option)}
                                        tableID={tableID}
                                        schedules={schedules}
                                        option={option}
                                        index={i}
                                        usesMultiplier={usesMultiplier}
                                        tableType={tableType}
                                        partNo={partNo}
                                        partNoSuffix={partNoSuffix}
                                        fillDownHandler={fillDownHandler}
                                        cf={cf}
                                        lnf={lnf}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </Stack>  
    </>
}

export default OptionsGrid;