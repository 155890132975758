import MaintenanceAPI, { IPagedData } from "components/Maintenance/MaintenanceAPI";
import { ClassificationTypeEnum } from "helpers/enums";
import { IMaintenanceQuote, IUploadedOrder } from "helpers/interfaces";
import { IQuoteClassification } from "models/IQuote";
import React from "react";

export type MaintenanceData = (IPagedData<IMaintenanceQuote> | IPagedData<IUploadedOrder>);
type MaintenanceDataMap = Map<number, MaintenanceData>;

export enum MaintenanceActionEnum {
    AddOrUpdateData
}

export type MaintenanceAction =
    { type: MaintenanceActionEnum.AddOrUpdateData, value: [id: number, data: MaintenanceData] };

const dataReducer: React.Reducer<MaintenanceDataMap, MaintenanceAction> = (existingState: MaintenanceDataMap, action) => {

    const [classificationID, data] = action.value;

    switch (action.type) {
        case MaintenanceActionEnum.AddOrUpdateData:
            return new Map<ClassificationTypeEnum, MaintenanceData>([...existingState, [classificationID, data]]);
    }

};

export function useMaintenanceData(classification: IQuoteClassification | null, maxRows: number): [data: MaintenanceData | undefined, dispatch: React.Dispatch<MaintenanceAction>] {


    const [maintenanceData, dispatch] = React.useReducer(dataReducer, new Map<number, MaintenanceData>());

    const classificationID = classification?.classificationID;

    React.useEffect(() => {

        let controller: AbortController | null = null;

        if (classification && classificationID) {
            if (!maintenanceData.has(classificationID)) {

                controller = new AbortController();
                const signal = controller.signal;

                let query: Promise<MaintenanceData>;

                if (classification.classificationType === ClassificationTypeEnum.Uploaded)
                    query = MaintenanceAPI.QueryUploadedQuotes(maxRows, 0, "", "", signal);
                else
                    query = MaintenanceAPI.QueryQuotesByClassification(classification.classificationType, classification.classificationID, maxRows, 0, "", "", signal);

                query.then((pd) => {
                    dispatch({ type: MaintenanceActionEnum.AddOrUpdateData, value: [classificationID, pd] });
                }).catch(reason => {
                    if (reason)
                        console.log(reason);
                });

            }
        }

        return () => {
            if (controller) {
                controller.abort();
            }
        }

    }, [classification, classificationID, maintenanceData, maxRows]);

    if (!classificationID) return [undefined, dispatch];

    return [maintenanceData.get(classificationID), dispatch];

}
