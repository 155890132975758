import APIHelper from "helpers/APIHelper";
import { IOrder, IOrderLineItem, IOrderTotals, IOrderTaxDetail, IOrderPermissions, IOrderPolicy, IBaseContent, IContainer, IContainerDetail, IProdStatusSummary, ISchedule, IProdStatusUnitHistory, IProdStatusUnitRejectHistory, IUnacknowledgedOrder } from "helpers/interfaces";
import WebResponse from "helpers/WebResponse";

export default class OrdersAPI {
    
    public static async GetOrder(oKey: number): Promise<IOrder> {
        const url: string = `api/Orders/${oKey}`;
        const response = await APIHelper.GetAsync<IOrder>(url);
        return response.Result;
    }

    public static async GetOrderAndPermissions(oKey: number): Promise<{ order: IOrder, permissions: IOrderPermissions }> {
        const url: string = `api/Orders/${oKey}/OrderAndPermissions`;
        const response = await APIHelper.GetAsync<{ order: IOrder, permissions: IOrderPermissions }>(url);
        return response.Result;
    }

    public static async GetOrderNumber(oKey: number): Promise<string> {
        const url: string = `api/Orders/${oKey}/OrderNumber`;
        const response = await APIHelper.GetAsync<string>(url);
        return response.Result;
    }

    public static async GetOrderAccessAllowed(oKey: number): Promise<boolean> {
        const url: string = `api/Orders/${oKey}/Allowed`;
        const response = await APIHelper.GetAsync<boolean>(url);
        return response.Result;
    }

    public static async GetLineItemOptionsString(oKey: number, odKey: number, toolTip: boolean): Promise<string> {
        const url: string = `api/Orders/${oKey}/LineItems/${odKey}/OptionsString`;
        const params = {
            toolTip: toolTip,
        };
        const response = await APIHelper.GetAsync<string>(url, params);
        return response.Result;
    }

    public static async GetLineItemExceptions(oKey: number, odKey: number): Promise<string[]> {
        const url: string = `api/Orders/${oKey}/LineItems/${odKey}/Exceptions`;
        const response = await APIHelper.GetAsync<string[]>(url);
        return response.Result;
    }

    public static async GetLineItems(oKey: number): Promise<IOrderLineItem[]> {
        const url: string = `api/Orders/${oKey}/LineItems`;
        const response = await APIHelper.GetAsync<IOrderLineItem[]>(url);
        return response.Result;
    }

    public static async GetOrderTotals(oKey: number): Promise<IOrderTotals> {
        const url: string = `api/Orders/${oKey}/Totals`;
        const response = await APIHelper.GetAsync<IOrderTotals>(url);
        return response.Result;
    }

    public static async GetTaxDetails(oKey: number): Promise<IOrderTaxDetail[]> {
        const url: string = `api/Orders/${oKey}/TaxDetails`;
        const response = await APIHelper.GetAsync<IOrderTaxDetail[]>(url);
        return response.Result;
    }

    public static async GetOrderPolicies(oKey: number): Promise<IOrderPolicy[]> {
        const url: string = `api/Orders/${oKey}/Policies`;
        const response = await APIHelper.GetAsync<IOrderPolicy[]>(url);
        return response.Result;
    }

    public static async ApproveOrderPolicies(oKey: number): Promise<IBaseContent> {
        const url: string = `api/Orders/${oKey}/Policies/Approve`;
        const response = await APIHelper.PostAsync<IBaseContent>(url);
        return response.Result;
    }

    public static async GetOrderAcknowledgement(oKey: number): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Orders/OrderAcknowledgement`;
        const params = {
            oKey: oKey,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, "application/pdf");
    }

    public static async GetCustomReport(oKey: number, reportID: number): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Orders/Custom`;
        const params = {
            oKey: oKey,
            reportID: reportID,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, "application/pdf");
    }

    public static async GetOrderIsOnContainer(oKey: number): Promise<boolean> {
        const url: string = `api/Orders/${oKey}/IsOnContainer`;
        const response = await APIHelper.GetAsync<boolean>(url);
        return response.Result;
    }

    public static async GetOrderHasBeenReleased(oKey: number): Promise<boolean> {
        const url: string = `api/Orders/${oKey}/HasBeenReleased`;
        const response = await APIHelper.GetAsync<boolean>(url);
        return response.Result;
    }

    public static async GetContainers(oKey: number) {
        const url: string = `api/Orders/${oKey}/Containers`;
        const response = await APIHelper.GetAsync<IContainer[]>(url);
        return response.Result;
    }

    public static async GetContainerDetail(oKey: number, containerKey: number) {
        const url: string = `api/Orders/${oKey}/Containers/${containerKey}`;
        const response = await APIHelper.GetAsync<IContainerDetail[]>(url);
        return response.Result;
    }

    public static async GetProdStatusSummary(oKey: number) {
        const url: string = `api/Orders/${oKey}/ProdStatus`;
        const response = await APIHelper.GetAsync<IProdStatusSummary[]>(url);
        return response.Result;
    }

    public static async GetSchedule(oKey: number, schedID: number): Promise<ISchedule> {
        const url: string = `api/Orders/${oKey}/Schedules/${schedID}`;
        const response = await APIHelper.GetAsync<ISchedule>(url);
        return response.Result;
    }

    public static async GetProdStatusUnitHistory(oKey: number, schedID: number, unitID: number): Promise<IProdStatusUnitHistory[]> {
        const url: string = `api/Orders/${oKey}/ProdStatus/Unit/${schedID}/${unitID}/History`;
        const response = await APIHelper.GetAsync<IProdStatusUnitHistory[]>(url);
        return response.Result;
    }
    public static async GetProdStatusUnitRejectHistory(oKey: number, schedID: number, unitID: number) : Promise<IProdStatusUnitRejectHistory[]> {
        const url: string = `api/Orders/${oKey}/ProdStatus/Unit/${schedID}/${unitID}/RejectHistory`;
        const response = await APIHelper.GetAsync<IProdStatusUnitRejectHistory[]>(url);
        return response.Result;
    }

    public static async GetOnlineAckOKeyAsync(token: string) {
        const url: string = `api/Orders/OnlineAck/${token}/oKey`;
        const response = await APIHelper.GetAsync<number>(url);
        return response.Result;        
    }

    public static async GetOnlineAckOtherUnacknowledgedOrdersAsync(token: string) {
        const url: string = `api/Orders/OnlineAck/${token}/OtherUnacknowledgedOrders`;
        const response = await APIHelper.GetAsync<IUnacknowledgedOrder[]>(url);
        return response.Result;        
    }

    public static async Acknowledge(oKey: number, onlineAckToken: string) : Promise<IBaseContent> {
        const url: string = `api/Orders/${oKey}/Acknowledge`;
        const params = {
            token: onlineAckToken,
        }
        const response = await APIHelper.PutAsync<IBaseContent>(url, params);
        return response.Result;  
    }


}