import { useContext } from "react";

import { OrderActions, OrderContextDispatch } from "components/Orders/OrderContext";

export function useOrderActions(): OrderActions {

    const actions = useContext(OrderContextDispatch);
    return actions!;

};

export default useOrderActions;
