import { CircularProgress, Container, useTheme } from "@mui/material";
import * as React from "react";

interface IWaitingProps extends React.PropsWithChildren<any> {
    waiting: boolean;
    imageSize: number;
    minSpacing: boolean;
}


const Waiting: React.FC<IWaitingProps> = (props: IWaitingProps) => {

    const theme = useTheme();

     return <Container sx={{ display: "flex", position: "relative", height: "100%" }} disableGutters={props.minSpacing} >
        {props.children}
        {props && props.waiting &&
            <Container disableGutters sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                alignItems: "center",
                justifyContent: "center",
                background: theme.palette.background.default,
                opacity: ".5"

            }} >
                <CircularProgress sx={{ width: props.imageSize, height: props.imageSize }} />
            </Container>

        }
    </Container>;

}

export default Waiting;
