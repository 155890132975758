import { useState } from "react";

import { Alert, Menu, MenuItem, Snackbar, SnackbarCloseReason, useTheme } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import useWait from "helpers/context/Page/useWait";

import { IQuote } from "helpers/interfaces";
import QuotesAPI from "components/Quotes/QuotesAPI";
import useFileDownload from "helpers/hooks/useFileDownload";
import ReportSelectionDialog from "components/Reports/ReportSelectionDialog";
import useReports, { FormatType, IReportSelection } from "helpers/context/SelectionValues/useReports";
import WebResponse from "helpers/WebResponse";

type DownloadType =
    "Word" |
    "Excel" |
    "CSV" |
    "XML";

interface IDownloadMenuItem {
    text: string,
    downloadType: DownloadType
}

interface IProps {
    quote: IQuote,
    isMenuOpen: boolean,
    closeMenu: () => void,
    anchorRef: HTMLButtonElement | null,
}

function DownloadMenu({ quote, isMenuOpen, closeMenu, anchorRef }: IProps) {

    const tm = useTranslations();
    const theme = useTheme();
    const wait = useWait();
    const { reportSelections, getReportDownloadAPI } = useReports("web", "quote");

    const [isReportSelectionVisible, setIsReportSelectionVisible] = useState<boolean>(false);
    const [selectedReportFormat, setSelectedReportFormat] = useState<FormatType | undefined>(undefined);
    const [alertText, setAlertText] = useState<string | undefined>(undefined);

    const downloadMenuItems: IDownloadMenuItem[] = [
        { text: tm.Get("Word"), downloadType: "Word" },
        { text: tm.Get("Excel"), downloadType: "Excel" },
        { text: tm.Get("CSV"), downloadType: "CSV" },
        { text: tm.Get("XML"), downloadType: "XML" },
    ]

    const getFileDownloadAPI = (quote: IQuote, downloadType: DownloadType): Promise<WebResponse<Blob>> => {

        switch (downloadType) {
            case "CSV":
                return QuotesAPI.GetQuoteCSVExport(quote.oKey);
            case "XML":
                return QuotesAPI.GetQuoteXMLExport(quote.oKey);
            default:
                throw new Error(`File download type ${downloadType} not implemented.`);
        }
    };

    const handleDownloadError = () => {
        wait.Show(false);
        setAlertText(tm.Get("An unknown error has occurred."));
    };

    const fileDownload = useFileDownload({
        preDownload: () => wait.Show(true),
        postDownload: () => wait.Show(false),
        onError: () => handleDownloadError(),
    });

    const downloadClick = (downloadType: DownloadType) => {

        if (quote.oKey) {
            switch (downloadType) {
                case "Word":
                case "Excel":
                    setSelectedReportFormat(downloadType);
                    setIsReportSelectionVisible(true);
                    break;
                default:
                    fileDownload.download({ apiCall: () => getFileDownloadAPI(quote, downloadType) });
            }
        }

        closeMenu();
    }

    const onReportSelected = (selectedReport: IReportSelection): void => {
        setIsReportSelectionVisible(false);
        if (quote.oKey && selectedReportFormat) {
            fileDownload.download({ apiCall: () => getReportDownloadAPI(quote.oKey, selectedReport.reportID, selectedReportFormat) });
        }
    }

    const onAlertClose = (event: React.SyntheticEvent<Element, Event>) => {
        setAlertText('');
    }

    const onAlertCloseSnackbar = (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway')
            return;

        setAlertText('');
    }

    return <>

        <Menu
            open={isMenuOpen}
            anchorEl={anchorRef}
            onClose={() => closeMenu()}
            PaperProps={{
                elevation: 0,
                sx: {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,                    
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
            {downloadMenuItems.map((mi) =>
                <MenuItem component="menu" key={mi.downloadType} onClick={() => downloadClick(mi.downloadType)}>
                    {mi.text}
                </MenuItem>
            )}
        </Menu>

        <ReportSelectionDialog
            title={tm.Get("Download")}
            dialogVisible={isReportSelectionVisible}
            reports={reportSelections}
            onSubmit={onReportSelected}
            onCancel={() => setIsReportSelectionVisible(false)}
        />

        <Snackbar open={alertText ? true : false} autoHideDuration={5000} onClose={onAlertCloseSnackbar} >
            <Alert onClose={onAlertClose} severity='error' variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alertText}</Alert>
        </Snackbar>

        {/* Hidden link to host file downloads */}
        <a hidden ref={fileDownload.ref} />

    </>;
};

export default DownloadMenu;

