import { TableRow, TableCell, styled, alpha } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

import ResultColumn from "../Columns/ResultColumn";
import { IGlobalItem } from "models/GlobalOptions/IGlobalItem";
import { IGlobalOptions } from "components/GlobalOptions/GlobalOptionsContext";

interface IProps {
    globalOptions: IGlobalOptions,
    columns: ResultColumn[],
    globalItem: IGlobalItem,
    previousItem?: IGlobalItem,
}

const ResultRow: React.FC<IProps> = ({ globalOptions, columns, globalItem, previousItem }: IProps) => {

    const StyledCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(even)': {
          backgroundColor: alpha(theme.palette.secondary.light, 0.1),
        },
      }));

    return <>
        <StyledTableRow>
            {columns.map(lic => (
                <StyledCell key={lic.columnName} align={lic.align}>
                    {lic.CellContent(globalOptions, globalItem, previousItem)}
                </StyledCell>
            ))
            }
        </StyledTableRow>
    </>;
}


export default ResultRow;