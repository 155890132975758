import React from "react";
import { useCallback, useEffect, useState } from "react";

import useWait from "helpers/context/Page/useWait";
import GlobalOptionsAPI from "./GlobalOptionsAPI";
import { IGlobalOptionsResults, IGlobalOptionsSelections, IQuote } from "helpers/interfaces";
import { ChangeButtonStateEnum } from "models/GlobalOptions/IGlobalOptionsSelections";
import { GlobalChangeTypeEnum, IGlobalItem } from "models/GlobalOptions/IGlobalItem";
import useQuoteActions from "components/Quotes/useQuoteActions";
import { useNavigate } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";

const defaultSelections: IGlobalOptionsSelections = {
    questionSelections: [],
    oldOptionSelections: [],
    oldOptionValueSelections: [],
    newOptionSelections: [],
    newOptionValueSelections: [],
    selectedQuestion: "",
    selectedOldOption: "",
    selectedOldOptionValue: "",
    selectedNewOption: "",
    selectedNewOptionValue: "",
    changeButtonState: ChangeButtonStateEnum.AddOption,
    isOldValueVisible: false,
    isNewValueVisible: false,
    isValid: false,
    ignoredLineItems: [],
}

export interface IGlobalOptions {
    isLoading: boolean,
    selections: IGlobalOptionsSelections,
    savableItems: IGlobalItem[],
    unsavableItems: IGlobalItem[],
    canSave: boolean,
    onQuestionChange: (question: string) => void;
    onOldOptionChange: (optionCode: string) => void;
    onOldOptionValueChange: (optionValue: string) => void;
    onNewOptionChange: (optionCode: string) => void;
    onNewOptionValueChange: (optionValue: string) => void;
    onNewOptionValueTextChange: (optionValue: string) => void;
    newOptionValueText: string,
    onCommitSelections: () => void;
    onGlobalItemSelected: (globalItem: IGlobalItem, selected: boolean) => void,
    isGlobalItemSelected: (globalItem: IGlobalItem) => boolean,
    isGlobalItemEditEnabled: (globalItem: IGlobalItem) => boolean,
    globalItemToEdit?: IGlobalItem,
    setGlobalItemToEdit: (globalItem: IGlobalItem | undefined) => void,
    onCommitEditValue: (newValue: string) => void,
    onSave: (oKey: number) => void,
}

const initialState: IGlobalOptions = {
    isLoading: false,
    selections: defaultSelections,
    savableItems: [],
    unsavableItems: [],
    canSave: false,
    onQuestionChange: () => { },
    onOldOptionChange: () => { },
    onOldOptionValueChange: () => { },
    onNewOptionChange: () => { },
    onNewOptionValueChange: () => { },
    onNewOptionValueTextChange: () => { },
    newOptionValueText: "",
    onCommitSelections: () => { },
    onGlobalItemSelected: () => { },
    isGlobalItemSelected: () => false,
    isGlobalItemEditEnabled: () => false,
    globalItemToEdit: undefined,
    setGlobalItemToEdit: () => { },
    onCommitEditValue: () => { },
    onSave: () => { },
}

const GlobalOptionsContext = React.createContext<IGlobalOptions>(initialState);

interface IProps {
    quote: IQuote,
}

export const GlobalOptionsContextProvider: React.FC<React.PropsWithChildren<IProps>> = (props: React.PropsWithChildren<IProps>) => {

    const [selections, setSelections] = useState<IGlobalOptionsSelections>(defaultSelections);
    const [savableItems, setSavableItems] = useState<IGlobalItem[]>([]);
    const [unsavableItems, setUnsavableItems] = useState<IGlobalItem[]>([]);
    const [canSave, setCanSave] = useState<boolean>(false);
    const [globalItemToEdit, setGlobalItemToEdit] = useState<IGlobalItem | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newOptionValueText, setNewOptionValueText] = useState<string>("");
    const wait = useWait();
    const { quote } = props;
    const { ignoredLineItems } = selections;
    const quoteActions = useQuoteActions();
    const navigate = useNavigate();

    useEffect(() => {
        if (quote.oKey) {
            setIsLoading(true);
            GlobalOptionsAPI.InitAsync(quote.oKey).then(() => {
                GlobalOptionsAPI.GetSelectionsAsync().then((s) => {
                    setSelections(s);
                    setIsLoading(false);
                });
            });
        }
    }, [quote])

    useEffect(() => {
        wait.Show(isLoading);
    }, [isLoading, wait]);

    useEffect(() => {
        if (selections) {
            setNewOptionValueText(selections.selectedNewOptionValue);
        }
    }, [selections]);

    const setPayload = (pl: IGlobalOptionsResults) => {
        setSelections(pl.selections);
        setSavableItems(pl.savableItems);
        setUnsavableItems(pl.unsavableItems);
        setCanSave(pl.canSave);
    }

    const onQuestionChange = useCallback((question: string) => {
        setIsLoading(true);
        GlobalOptionsAPI.PostSelectQuestion(question).then((s) => {
            setSelections(s);
            setIsLoading(false);
        })

    }, []);

    const onOldOptionChange = useCallback((optionCode: string) => {
        setIsLoading(true);
        GlobalOptionsAPI.PostSelectOldOption(optionCode).then((s) => {
            setSelections(s);
            setIsLoading(false);
        })

    }, []);

    const onOldOptionValueChange = useCallback((optionValue: string) => {
        setIsLoading(true);
        GlobalOptionsAPI.PostSelectOldOptionValue(optionValue).then((s) => {
            setSelections(s);
            setIsLoading(false);
        })

    }, []);

    const onNewOptionChange = useCallback((optionCode: string) => {
        setIsLoading(true);
        GlobalOptionsAPI.PostSelectNewOption(optionCode).then((s) => {
            setSelections(s);
            setIsLoading(false);
        })

    }, []);

    const onNewOptionValueChange = useCallback((optionValue: string) => {
        setIsLoading(true);
        GlobalOptionsAPI.PostSelectNewOptionValue(optionValue).then((s) => {
            setSelections(s);
            setIsLoading(false);
        })

    }, []);

    const onNewOptionValueTextChange = useCallback((optionValue: string) => {
        setNewOptionValueText(optionValue);
    }, []);

    const onCommitSelections = useCallback(() => {

        if ((newOptionValueText !== selections.selectedNewOptionValue) && selections.newOptionValueSelections?.length <= 1) {
            //a new option value (text based) is pending. submit that first before committing all selections
            setIsLoading(true);
            GlobalOptionsAPI.PostSelectNewOptionValue(newOptionValueText).then(() => {
                GlobalOptionsAPI.PostCommitSelections().then((pl) => {
                    setPayload(pl);
                    setIsLoading(false);
                })
            });
        }
        else {
            setIsLoading(true);
            GlobalOptionsAPI.PostCommitSelections().then((pl) => {
                setPayload(pl);
                setIsLoading(false);
            });
        }

    }, [newOptionValueText, selections]);

    const onGlobalItemSelected = useCallback((globalItem: IGlobalItem, selected: boolean) => {
        setIsLoading(true);
        GlobalOptionsAPI.PostIgnoreLineItem(globalItem.lineItem, !selected).then((pl) => {
            setPayload(pl)
            setIsLoading(false);
        })
    }, []);


    const onCommitEditValue = useCallback((newValue: string) => {
        if (globalItemToEdit) {
            setIsLoading(true);
            GlobalOptionsAPI.PostChangeOptionValue(globalItemToEdit.odKey, globalItemToEdit.changeInfo.sequence, globalItemToEdit.changeInfo.newCode, newValue).then((pl) => {
                setPayload(pl);
                setIsLoading(false);
                setGlobalItemToEdit(undefined);
            })
        }
    }, [globalItemToEdit]);

    const onSave = useCallback(async (oKey: number) => {
        if (canSave) {
            setIsLoading(true);
            await GlobalOptionsAPI.PostSave(oKey);
            await quoteActions?.LoadQuoteAsync(oKey);
            navigate(QuoteNavigation.QuoteEntryURL(oKey));
            setIsLoading(false);
        }
    }, [canSave, quoteActions, navigate]);

    const isGlobalItemSelected = useCallback((globalItem: IGlobalItem) => {
        const item = ignoredLineItems?.find((si) => si === globalItem.lineItem);
        return item ? false : true;
    }, [ignoredLineItems]);

    const isGlobalItemEditEnabled = useCallback((globalItem: IGlobalItem) => {
        return globalItem.userInput && (globalItem.changeType === GlobalChangeTypeEnum.Added || globalItem.changeType === GlobalChangeTypeEnum.Changed);
    }, []);

    const state: IGlobalOptions = {
        isLoading,
        selections,
        savableItems,
        unsavableItems,
        canSave,
        onQuestionChange,
        onOldOptionChange,
        onOldOptionValueChange,
        onNewOptionChange,
        onNewOptionValueChange,
        onNewOptionValueTextChange,
        newOptionValueText,
        onCommitSelections,
        onGlobalItemSelected,
        isGlobalItemSelected,
        isGlobalItemEditEnabled,
        globalItemToEdit,
        setGlobalItemToEdit,
        onCommitEditValue,
        onSave,
    };

    return <>
        <GlobalOptionsContext.Provider value={state}>
            {props.children}
        </GlobalOptionsContext.Provider>
    </>;
}

export default GlobalOptionsContext;


