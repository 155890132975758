import WebResponse, { ResponseTypeEnum } from "./WebResponse";

export type ApiCallback = ((result: any) => void) | null;
export type LoadingCallback = (() => void) | null;
export type ErrorCallback = ((errorMessage: string, redirectToErrorPage: boolean) => void) | null;

export default class APIHelper {

    public static GetBaseURL(): string { return this.BASE_URL; }

    public static SetBaseURL(url: string): void {
        this.BASE_URL = url;

        if (this.BASE_URL[this.BASE_URL.length - 1] !== "/") {
            this.BASE_URL = this.BASE_URL + "/";
        }
    }


    private static BASE_URL: string = "http://localhost:5000";

    private static preRequestCallback: LoadingCallback;
    private static postRequestCallback: ApiCallback;
    private static handleErrorCallback: ErrorCallback;

    public static registerLayoutCallback(preRequestCallback: LoadingCallback, postRequestCallback: ApiCallback, handleErrorCallback: ErrorCallback): void {
        this.preRequestCallback = preRequestCallback;
        this.postRequestCallback = postRequestCallback;
        this.handleErrorCallback = handleErrorCallback;
    }

    public static async GetAsync<T>(url: string, params?: any, body?: any, redirectOnError: boolean = true, contentType: string = "application/json"): Promise<WebResponse<T>> {
        return await this.FetchAndBuildWebResponseAsync("GET", url, params, body, redirectOnError, contentType);
    }

    public static async CopyAsync<T>(url: string, params?: any, body?: any, redirectOnError: boolean = true): Promise<WebResponse<T>> {
        return await this.FetchAndBuildWebResponseAsync("COPY", url, params, body, redirectOnError);
    }

    public static async DeleteAsync<T>(url: string, params?: any, body?: any, redirectOnError: boolean = true): Promise<WebResponse<T>> {
        return await this.FetchAndBuildWebResponseAsync("DELETE", url, params, body, redirectOnError);
    }

    public static async PostAsync<T>(url: string, params?: any, body?: any, redirectOnError: boolean = true): Promise<WebResponse<T>> {
        return await this.FetchAndBuildWebResponseAsync("POST", url, params, body, redirectOnError);
    }

    public static async PutAsync<T>(url: string, params?: any, body?: any, redirectOnError: boolean = true): Promise<WebResponse<T>> {
        return await this.FetchAndBuildWebResponseAsync("PUT", url, params, body, redirectOnError);
    }



    //#region Private

    public static PrependBaseURL(url: string): string {

        if (url[0] === "/")
            url = url.slice(1);

        return `${this.BASE_URL}${url}`;
    }



    private static async FetchAndBuildWebResponseAsync<T>(httpVerb: string, urlString: string, params: any, body: any, redirectOnError: boolean, contentType: string = "application/json"): Promise<WebResponse<T>> {
        if (this.preRequestCallback) { this.preRequestCallback(); }
        let res: Response;
        try {
            res = await this.FetchAsync(httpVerb, urlString, params, body, contentType);
            const wr: WebResponse<T> = await this.BuildWebResponseAsync<T>(res);
            if (this.postRequestCallback) { this.postRequestCallback(wr); }
            if (this.handleErrorCallback && !wr.IsOk()) {
                this.handleErrorCallback(this.FormatErrorMessage<T>(wr, params, httpVerb, urlString), redirectOnError);
            }
            return wr;
        }
        catch (e) {
            if (this.handleErrorCallback) {
                this.handleErrorCallback(this.FormatErrorMessage<T>(null, params, httpVerb, urlString), redirectOnError);
            }
            return new WebResponse<T>(ResponseTypeEnum.Unknown);
        }
    }

    private static FormatErrorMessage<T>(wr: WebResponse<T> | null, params: any, httpVerb: string, urlString: string) {
        const message = wr?.Message ? `Message: ${wr.Message}` : "";
        const paramString = params ? `Params: ${JSON.stringify(params)}` : "";
        const responseType =  wr ? ResponseTypeEnum[wr.GetResponseType()] : "none";
        const errorMessage = `Fetch failed to ${httpVerb} at ${urlString} with response [${responseType}] ${message} ${paramString}`;
        return errorMessage;
    }

    private static async FetchAsync(httpVerb: string, urlString: string, params?: any, body?: any, contentType: string = "application/json"): Promise<Response> {

        let url: URL;
        url = new URL(this.PrependBaseURL(urlString));

        if (params) {
            url.search = new URLSearchParams(params).toString();
        }

        let headers: any = {
            "Accept": contentType,
            "Content-Type": contentType,
        }

        const fetchParams: RequestInit = {
            headers,
            method: httpVerb,
            mode: "cors",
            credentials: "include",
            body: JSON.stringify(body),
        };
        return await fetch(url.toString(), fetchParams);
    }

    private static async BuildWebResponseAsync<T>(res: Response): Promise<WebResponse<T>> {
        let wr: WebResponse<T>;

        if (res.status === 404) {
            //If the token expires, webenezer will start returning 404.
            wr = new WebResponse<T>(ResponseTypeEnum.UnAuthorized);
            wr.AuthRequired = true;
            const jsonText: string = await res.text();
            if (jsonText.length > 0) {
                wr.Message = JSON.parse(jsonText);
            }
        } else if (res.status === 403) {
            wr = new WebResponse<T>(ResponseTypeEnum.Forbidden);
            wr.AuthRequired = true;
            const jsonText: string = await res.text();
            if (jsonText.length > 0) {
                wr.Message = jsonText;
            }
        } else if (res.status === 401) {
            wr = new WebResponse<T>(ResponseTypeEnum.UnAuthorized);
            wr.AuthRequired = true;
        } else if (res.status === 400) {
            wr = new WebResponse<T>(ResponseTypeEnum.BadRequest);
            const jsonText: string = await res.text();
            if (jsonText.length > 0) {
                wr.Message = JSON.parse(jsonText);
            }
        } else if (res.status === 302) {
            wr = new WebResponse<T>(ResponseTypeEnum.Found);
            const location = res.headers.get("Location");
            if (location) {
                wr.Location = location;
            }
        } else if (res.status === 301) {
            wr = new WebResponse<T>(ResponseTypeEnum.Redirect);
        } else if (res.status === 200) {
            wr = new WebResponse<T>(ResponseTypeEnum.Ok);
            const contentType = res.headers.get("Content-Type")
            if (contentType && !contentType.includes("application/json")) {
                wr.Result = await res.blob() as unknown as T;
                wr.FileName = APIHelper.GetFileNameFromResponse(res);
            }
            else {
                const jsonText: string = await res.text();
                if (jsonText.length > 0) {
                    wr.Result = JSON.parse(jsonText);
                }
            }
            wr.AuthRequired = false;
        } else if (res.status === 204) {
            // success, but no content
            wr = new WebResponse<T>(ResponseTypeEnum.Ok);
        } else if (res.status === 500) {
            wr = new WebResponse<T>(ResponseTypeEnum.ServerError);
        } else if (res.status === 503) {
            wr = new WebResponse<T>(ResponseTypeEnum.ServiceUnavailable);
            wr.AuthRequired = true;
        } else {
            // FUTURE; handle other statuses in a cool way if we find a need
            wr = new WebResponse<T>(ResponseTypeEnum.Unknown);
            wr.Message = res.statusText;
        }
        return wr;
    }

    private static GetFileNameFromResponse(res: Response): string {
        const fileName = res.headers.get('content-disposition');
        return fileName?.split(';')
            ?.find(n => n.includes('filename='))
            ?.replace('filename=', '')?.trim() ?? "";
    }

    //#endregion

}
