import * as React from "react";

import { ICode, ISelectItem } from "../interfaces";
import TiledOptionValue from "./TiledOptionValue";
import WizardHelper from "../WizardHelper";
import { Grid } from "@mui/material";
import OptionGroupBox from "./OptionGroupBox";

interface IValueListProps {
    code: ICode;
    locked: boolean;
    readOnlyMode: boolean;
}

const TiledOptionValueList: React.FC<IValueListProps> = (props: IValueListProps) => {

    const c: ICode = props.code;

    if (c.selected && WizardHelper.CodeHasValueList(c)) {
        return <OptionGroupBox label={c.description} id="TILED_OVL" >
            <Grid item container minHeight="24px" ml={.5} pt={.5} pb={.5} direction="column" spacing={1} >

                <Grid item container spacing={1} >
                    {c.selectItems.map((si: ISelectItem) =>
                        <TiledOptionValue
                            key={"TOV" + c.sequence + "_" + c.code + '_' + si.description}
                            locked={props.locked}
                            code={c}
                            selectItem={si}
                            readOnlyMode={props.readOnlyMode}
                        />
                    )}
                </Grid>
            </Grid>
        </OptionGroupBox>
    }
    else {
        return null;
    }
}

export default TiledOptionValueList;