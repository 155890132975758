import { DispatchActionsBase } from "components/Common/DispatchActionsBase";
import React from "react";

export interface IUserData {
    isDealerCreatingAdminContractor: boolean,
}

export enum UserActionTypeEnum {
    SetIsDealerCreatingAdminContractor,
}

export type UserActionType =
    { type: UserActionTypeEnum.SetIsDealerCreatingAdminContractor, value: boolean };

const UserReducer: React.Reducer<IUserData, UserActionType> = (data: IUserData, action) => {

    switch (action.type) {
        case UserActionTypeEnum.SetIsDealerCreatingAdminContractor:
            return { ...data, isDealerCreatingAdminContractor: action.value };
    }
};

const initialState: IUserData = {
    isDealerCreatingAdminContractor: false
};

export class UserActions extends DispatchActionsBase<UserActionType> {

    public SetIsDealerCreatingAdminContractor(value: boolean) {
        this.dispatch({ type: UserActionTypeEnum.SetIsDealerCreatingAdminContractor, value: value });
    }

}

export const UserSetupContextDispatch = React.createContext<UserActions | undefined>(undefined);
export const UserSetupContext = React.createContext<IUserData>(initialState);

export const UserSetupContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(UserReducer, initialState);

    const actions = React.useMemo<UserActions>(() => {
        return new UserActions(dispatch);
    }, [dispatch]);

    return <UserSetupContext.Provider value={state}>
        <UserSetupContextDispatch.Provider value={actions}>
            {props.children}
        </UserSetupContextDispatch.Provider>
    </UserSetupContext.Provider>
}

export default UserSetupContext;
