import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Stack, Grid, IconButton } from "@mui/material";
import { Payments, Person, Edit } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";
import { useTranslations } from "@fenetech/translations";
import useEmployee from "helpers/context/SelectionValues/useEmployee";
import useQuoteData from "components/Quotes/useQuoteData";
import QuoteNavigation from "components/Quotes/QuoteNavigation";

function SalesCard() {
    const tm = useTranslations();

    const { quote, permissions, lock } = useQuoteData();
    const salesperson = useEmployee(quote?.salespersonID);
    const navigate = useNavigate();

    const canEditSales = useMemo(() => {
        if (permissions && lock) {
            return permissions.editQuote && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    return <>
        <Paper sx={{ display: "flex", width: "100%" }}>
            <Stack display="flex" width="100%" m={1}>
                <Grid container direction="row">
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="h6">
                            {tm.Get("Sales")}
                        </Typography>
                    </Grid>
                    <Grid item display="flex" justifyContent="right">
                        {canEditSales &&
                            <IconButton onClick={() => navigate(QuoteNavigation.QuoteEditURL(quote?.oKey ?? 0, "sales"))}>
                                <Edit />
                            </IconButton>
                        }
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Salesperson")} text={salesperson?.fullName ?? ""} isLoading={salesperson === undefined} />
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Payments color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Terms")} text={quote?.billing.terms} />
                    </Grid>
                </Grid>

            </Stack>
        </Paper>

    </>

}


export default SalesCard;