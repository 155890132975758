import React from "react";
import { Route, Routes } from "react-router-dom";
import SSOLanding from "./SSOLanding";
import PasswordLogin from "./PasswordLogin";
import Header from "../Layout/TopHeader";
import Footer from "../Layout/Footer";

interface IProps {
}

const LoginMain: React.FC<IProps> = (props: IProps) => {

    return <>
        <Header />
        <div className="article">
            <Routes>
                {/* <Route path="auth/sso" element={<SSOLanding setToken={props.setToken} />} /> */}
                <Route path="login/csr" element={<PasswordLogin csrMode={true} />} />
                <Route path="*" element={<PasswordLogin csrMode={false} />} /> {/* Default route */}
            </Routes>
        </div>
        <div className="footer">
            <Footer />
        </div>
    </>;

};

export default LoginMain;
