import React from "react";
import { Button, Stack } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import { ThemeColorEnum } from "helpers/enums";

interface IProps {
    okText?: string,
    cancelText?: string,
    defaultToYes?: boolean,
    okDisabled?: boolean,
    okButtonType?: "button" | "submit" | "reset",
    okForm?: string,
    okCommand?: () => void,
    cancelCommand?: () => void
}

const OKCancelControl: React.FC<IProps> = ({ okText, cancelText, defaultToYes, okDisabled, okButtonType, okForm, okCommand, cancelCommand }) => {
    const tm = useTranslations();

    return <Stack direction="row" spacing={1} sx={{marginRight: "16px"}}>
        <Button variant="contained" color={ThemeColorEnum.Secondary} onClick={okCommand} autoFocus={defaultToYes} type={okButtonType} form={okForm} disabled={okDisabled}>{okText ?? tm.Get("OK")}</Button>
        <Button variant="contained" color={ThemeColorEnum.Primary} onClick={cancelCommand} autoFocus={!defaultToYes}>{cancelText ?? tm.Get("Cancel")}</Button>
    </Stack>
}

export default OKCancelControl;