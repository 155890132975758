
import React from "react";
import { IDashboardQuote } from "helpers/interfaces";
import DashboardAPI from "../DashboardAPI";
import DashboardContext, { ActionTypeEnum, DashboardContextDispatch } from "../DashboardContext";


export default function useDashboardCompanyQuoteData(): IDashboardQuote[]|undefined {

    const dashboardData = React.useContext(DashboardContext);
    const dispatch = React.useContext(DashboardContextDispatch);
    const QuoteData = dashboardData.companyQuotes;

    const isExpired = (dashboardData.companyQuotesExpTime < Date.now()  );

    React.useEffect(() => {

        if (dispatch) {
            if (!QuoteData || isExpired) {
                DashboardAPI.QueryCompanyQuotes().then(o => {
                    dispatch({ type: ActionTypeEnum.SetCompanyQuotes, value: o });
                });
            }
        }

    }, [dispatch, QuoteData, isExpired]);

    return QuoteData;

};