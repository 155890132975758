import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Stack, Grid, Typography } from "@mui/material";
import APIHelper from "helpers/APIHelper";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import * as React from "react";

import { ICode } from "../interfaces";
import useWizardInteractions from "../useWizardInteractions";
import WizardHelper from "../WizardHelper";


interface IOWCodeImageProps {
    code: ICode;
    closePopup: (() => void) | undefined;
}

const OptionCodeImage: React.FC<IOWCodeImageProps> = (props: IOWCodeImageProps) => {

    const appInfo = useApplicationInfo();
    const wizardInteractions = useWizardInteractions();

    const handleCodeImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        let c: ICode = props.code;
        if (!c.selected) {
            wizardInteractions.SelectCodeAsync(c.sequence, c.code).then(() => {
                if (props.closePopup)
                    props.closePopup();
            });
        }
        else {
            wizardInteractions.RemoveCodeAsync(c.sequence, c.code).then(() => {
                if (props.closePopup)
                    props.closePopup();
            });
        }

        wizardInteractions.SetFocusedCode(null);
    }


    const c: ICode = props.code;

    const classes: string = "ow-tiled-code oimg-clickable";

    const backgroundStyle = c.selected ? {
        backgroundColor: "primary.light",
        borderRadius: 2,
        border: "solid",
        borderColor: "transparent",
        color: "primary.contrastText"
    } : {}

    const imageSource: string = APIHelper.GetBaseURL() + `api/images/OptionImage?optionCode=${c.code}&thumbnail=true`;

    return <Grid flexDirection="column" display="inline-flex" p={.5} m={.5} textAlign="center" 
        key={c.sequence + "_" + c.code} className={classes} onClick={handleCodeImageClick} width="150px"

        sx={{
            cursor: "pointer",
            ...backgroundStyle   
        }}
    >
        <div>
            {c.imageExists ?
                <input type="image"
                    width="82"
                    height="82"
                    src={imageSource}
                />
                :
                <Stack sx={{ height: "88px", display: "flex", lineHeight: "normal" }} justifyContent="center" alignItems="center" >
                    <InsertPhotoIcon fontSize="large" />
                </Stack>

            }
        </div>
        <Typography>
            {WizardHelper.GetOptionCodeDescription(c, appInfo.parameters.hideOptionCodes)}
        </Typography>
    </Grid >;

}

export default OptionCodeImage;
