import { Typography } from "@mui/material"

import SelectMfgShippingAddress from "components/Quotes/SelectFields/SelectMfgShippingAddress";

import { useTranslations } from "@fenetech/translations";
import { useFormContext } from "react-hook-form";
import { IQuotePermissions } from "helpers/interfaces";
import BoxWithHeader from "components/Common/BoxWithHeader";

interface IProps {
    mfgCustomerID: string,
    mfgSiteID: string,
    permissions?: IQuotePermissions,
    [x: string]: any  // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function MfgShipTo({ mfgCustomerID, mfgSiteID, permissions, ...rest }: IProps) {

    const tm = useTranslations();
    const { watch } = useFormContext();
    const watchDropShip = watch("dropShip");

    return <>
        <BoxWithHeader
            caption={tm.Get("Mfg Shipping Address")}>
            {watchDropShip &&
                <Typography>
                    {tm.Get("This order will be drop shipped.")}
                </Typography>
            }
            {!watchDropShip &&
                <SelectMfgShippingAddress mfgCustomerID={mfgCustomerID} mfgSiteID={mfgSiteID} defaultMfgShipToGUID=""
                    permissions={permissions} />
            }
        </BoxWithHeader>
    </>
}

export default MfgShipTo;