import React, { useCallback, useEffect, useMemo, useState } from "react";

import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useMeasurementTypes from "helpers/context/SelectionValues/useMeaurementTypes";
import { usePartDefaultsRepo } from "helpers/context/Parts/usePartDefaults";
import { useTranslations } from "@fenetech/translations";
import useQuoteData from "components/Quotes/useQuoteData";
import useWait from "helpers/context/Page/useWait";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { IActionButton } from "helpers/context/Page/PageContext";
import { OpeningShapeEnum, ShapeDirectionEnum, SizingModeEnum, ThemeColorEnum } from "helpers/enums";
import { IItemPropertiesData } from "components/OptionsWizard/ItemProperties/ItemPropertiesContext";
import { IPartKey } from "helpers/interfaces";
import ProductNavigator from "./ProductNavigator";
import WizardAPI from "components/OptionsWizard/WizardAPI";
import useQuoteActions from "components/Quotes/useQuoteActions";
import { useNavigate } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import useItemPropertiesActions from "components/OptionsWizard/ItemProperties/useItemPropertiesActions";
import { usePartCallSizesRepo } from "helpers/context/Parts/usePartCallSizes";


const ProductNavigatorWrapper: React.FC<any> = () => {

    const { quote, productNavigatorState } = useQuoteData();
    const oKey = quote?.oKey;
    const measurementTypes = useMeasurementTypes();
    const wait = useWait();
    const actionButtons = useActionButtons();
    const quoteActions = useQuoteActions();

    const navigate = useNavigate();

    const [callSize, setCallSize] = useState<string>("");
    const [finalNode, setFinalNode] = useState<string | undefined>(undefined);

    const itemPropertiesActions = useItemPropertiesActions();

    const callSizeRepo = usePartCallSizesRepo();
    const partDefaultsRepo = usePartDefaultsRepo();

    const tm = useTranslations();
    useWindowTitle(tm.Get("Product Navigator"));

    const quoteMeasurementType = useMemo(() => {
        if (quote && measurementTypes) {
            return measurementTypes.find((m) => m.setID === quote.engineeringUnitSetID);
        }
    }, [quote, measurementTypes]);

    const onCancel = useCallback(() => {
        navigate(QuoteNavigation.QuoteEntryURL(quote?.oKey ?? 0));
    }, [navigate, quote]);

    const onFinalNodeChange = useCallback((nodeID) => {
        setFinalNode(nodeID);
    }, []);

    const onCallSizeChange = useCallback((callSize) => {
        setCallSize(callSize);
    }, []);

    const onPartSelected = useCallback((partKey: IPartKey | null) => {
        if (partKey && quoteMeasurementType && quote) {
            const partDefaultsPromise = partDefaultsRepo(partKey.masterPartNo, partKey.partNoSuffix, partKey.shortcutName);
            const partCallSizesPromise = callSizeRepo(partKey.masterPartNo, partKey.partNoSuffix);

            Promise.all([partDefaultsPromise, partCallSizesPromise]).then(([partDefaults, partCallSizes]) => {

                var callSizeObject = partCallSizes.callSizes.find(e => e.callSize === callSize);
                const newLineItemProps: IItemPropertiesData = {
                    callSize: callSize,
                    comment: partDefaults?.comment ?? "",
                    height: (callSize === "" ? partDefaults?.height : callSizeObject?.height) ?? 0,
                    qty: partDefaults?.quantity ?? 1,
                    thickness: partDefaults?.thickness ?? 0,
                    width: (callSize === "" ? partDefaults?.width : callSizeObject?.width) ?? 0,
                    direction: ShapeDirectionEnum.Left,
                    legHeight: 0,
                    radius: 0,
                    shape: OpeningShapeEnum.Standard,
                    sizing: SizingModeEnum.Fixed
                }
                itemPropertiesActions.SetState(newLineItemProps, quoteMeasurementType.setID);

                const submitParams = {
                    OKey: quote?.oKey,
                    PartNo: partKey.masterPartNo,
                    PartNoSuffix: partKey.partNoSuffix,
                    ShortcutName: partKey.shortcutName,
                    Quantity: newLineItemProps.qty,
                    Width: newLineItemProps.width,
                    Height: newLineItemProps.height,
                    Thickness: newLineItemProps.thickness,
                    CallSize: newLineItemProps.callSize,
                    Comment: newLineItemProps.comment,
                };

                wait.Show(true);

                WizardAPI.InitNewLineItemAsync(submitParams).then(() => {
                    quoteActions?.SetProductNavigatorState({ cameFromProductNavigator: true, finalNodeID: finalNode ?? "", callSize: callSize, isUsingProductFilter: false, productFilterHasPartsToAdd: false, productFilterPartsToAdd: [] });
                    navigate(QuoteNavigation.OptionsWizardURL(oKey ?? 0, 0));
                }).finally(() => wait.Show(false));

            });
        }
    }, [quoteMeasurementType, quote, partDefaultsRepo, callSizeRepo, callSize, itemPropertiesActions, wait, quoteActions, finalNode, navigate, oKey]);

    const onProductFilterPartsSelected = useCallback(() => {
        const partKeys = productNavigatorState.productFilterPartsToAdd;

        if (partKeys.length > 0 && quoteMeasurementType && quote) {

            wait.Show(true);

            const lineItemsArgs: any[] = [];
            const partDefaultsPromises: Promise<any>[] = [];

            partKeys.forEach((partKey) => {
                partDefaultsPromises.push(partDefaultsRepo(partKey.partNo, partKey.partNoSuffix, ''));
            });

            Promise.all(partDefaultsPromises).then((partDefaults) => {

                partKeys.forEach((partKey, index) => {
             
                    const newLineItemProps: IItemPropertiesData = {
                        callSize: callSize,
                        comment: partDefaults[index]?.comment ?? "",
                        height: (callSize === "" ? partDefaults[index]?.height : undefined) ?? 0,
                        qty: partKey.qty,
                        thickness: partDefaults[index]?.thickness ?? 0,
                        width: (callSize === "" ? partDefaults[index]?.width : undefined) ?? 0,
                        direction: ShapeDirectionEnum.Left,
                        legHeight: 0,
                        radius: 0,
                        shape: OpeningShapeEnum.Standard,
                        sizing: SizingModeEnum.Fixed
                    }
                    
                    itemPropertiesActions.SetState(newLineItemProps, quoteMeasurementType.setID);

                    const submitParams = {
                        OKey: quote?.oKey,
                        PartNo: partKey.partNo,
                        PartNoSuffix: partKey.partNoSuffix,
                        ShortcutName: '',
                        Quantity: newLineItemProps.qty,
                        Width: newLineItemProps.width,
                        Height: newLineItemProps.height,
                        Thickness: newLineItemProps.thickness,
                        CallSize: newLineItemProps.callSize,
                        Comment: newLineItemProps.comment,
                    };

                    lineItemsArgs.push(submitParams);
                });
            }).then(() => {
                WizardAPI.InitNewLineItemsAsync(lineItemsArgs).then((lineItems) => {
                    quoteActions?.SetProductNavigatorState({ cameFromProductNavigator: false, finalNodeID: productNavigatorState.finalNodeID, callSize: productNavigatorState.callSize, isUsingProductFilter: false, productFilterHasPartsToAdd: false, productFilterPartsToAdd: [] });
                    quoteActions?.LoadQuoteAsync(oKey!);
                    navigate(QuoteNavigation.QuoteEntryURL(oKey!));
                }).finally(() => wait.Show(false));
            });
        }
    }, [productNavigatorState, quoteMeasurementType, quote, partDefaultsRepo, callSize, itemPropertiesActions, wait, quoteActions, navigate, oKey]);

    useEffect(() => {
        const cancelButton: IActionButton = {
            text: tm.Get("Cancel"),
            color: ThemeColorEnum.Primary,
            onClick: onCancel
        };

        const nextButton: IActionButton = {
            text: tm.Get("Add"),
            disabled: !productNavigatorState.productFilterHasPartsToAdd,
            color: ThemeColorEnum.Secondary,
            onClick: onProductFilterPartsSelected,
        };

        if (productNavigatorState.isUsingProductFilter) {
            actionButtons.Set(0, nextButton);
            actionButtons.Set(1, cancelButton);
        }
        else {
            actionButtons.Set(0, cancelButton);
        }

    }, [oKey, actionButtons, tm, onCancel, productNavigatorState.isUsingProductFilter, productNavigatorState.productFilterHasPartsToAdd, onPartSelected, onProductFilterPartsSelected]);

    if (!oKey) {
        return null;
    }

    return <>
        <ProductNavigator
            oKey={oKey}
            initialFinalNodeID={productNavigatorState.finalNodeID}
            initialCallSize={productNavigatorState.callSize}
            partList={null}
            onCancel={onCancel}
            onPartSelected={onPartSelected}
            onFinalNodeChange={onFinalNodeChange}
            onCallSizeChange={onCallSizeChange}
        />
    </>
}

export default ProductNavigatorWrapper;