import { UserPermissionTypes } from "helpers/enums";
import { IQuotePermissions } from "helpers/interfaces";
import ApiUser from "models/ApiUser";

export const AddQuotePermissions : (user: ApiUser) => IQuotePermissions = (user) => {
    return {
        editQuote: true,
        copyQuote: true,
        deleteQuote: true,
        parentCustomer: true,
        parentSite: true,
        webCustomer: true,
        measurementType: true,
        classification: true,
        quoteContact: true,
        quoteComment: true,
        notes: true,
        poNumber: true,
        customerRef: true,
        salesperson: user.HasPermission(UserPermissionTypes.ViewQuotesCompany),
        terms: true,
        pricingValue: user.HasPermission(UserPermissionTypes.ModifyQuotePricing),
        pricingValueVisible: user.HasPermission(UserPermissionTypes.ViewPricing) && user.HasPermission(UserPermissionTypes.ViewQuoteMarkupMarginDiscount),
        markdown: true,
        requestedDate: true,
        requiredDate: true,
        billingAddress: true,
        shippingAddress: true,
        shipVia: true,
        dropShip: true,
        shippingComment: true,
        mfgShipTo: true,
        viewCost: false,
        viewPrice: false,
        editPrice: false,
        editTax: false,
        imageAccessKey: 0,
    }
};
    
    