
import { useCallback, useMemo } from "react";
import { Paper, Button, Box } from "@mui/material";

import useOnlineAckToken from "helpers/hooks/useOnlineAckToken";
import useOrderData from "components/Orders/useOrderData";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import OrdersAPI from "components/Orders/OrdersAPI";
import useWait from "helpers/context/Page/useWait";
import useOrderActions from "components/Orders/useOrderActions";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { useTranslations } from "@fenetech/translations";
import { DateIsEmpty } from "helpers/objects";

function AcknowledgeOrder() {
    const onlineAckToken = useOnlineAckToken();
    const user = useUserInfo();
    const { order } = useOrderData();
    const orderActions = useOrderActions();
    const wait = useWait();
    const messageBox = useMessageBox();
    const tm = useTranslations();

    const acknowledgeOrderClicked = useCallback(() => {
        if (order?.oKey && onlineAckToken) {
            wait.Show(true);
            OrdersAPI.Acknowledge(order.oKey, onlineAckToken).then((e) => {
                if (e.message !== "") {
                    wait.Show(false);
                    messageBox.Show({message: tm.Get(e)});
                }
                else
                {
                    orderActions.LoadOrderAsync(order.oKey).then(() => {
                        wait.Show(false);
                    });
                }
            });
        }
    }, [onlineAckToken, order, wait, orderActions, messageBox, tm]);

    const buttonVisible = useMemo(() => {
        return user.HasRole(RoleEnum.OnlineAck) && order && DateIsEmpty(order.acknowledgedDate);
    }, [order, user]);

    return <>
        {buttonVisible &&
            <Paper sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <Box display="flex" flexDirection="row" m={1} >
                    <Button sx={{ alignSelf: "center" }} variant="contained" color="secondary" onClick={acknowledgeOrderClicked}>
                        {tm.Get("Acknowledge")}
                    </Button>
                </Box>
            </Paper>
        }
    </>

}

export default AcknowledgeOrder;