import * as React from "react";
import { ILineItemInfo, IWebDesigner, IOpeningDesignerOpeningSubLineItemInfo } from "../interfaces";
import { Grid } from "@mui/material";
import DesignerItemSizeLabel from "components/OptionsWizard/MainWizard/DesignerItemSizeLabel";
import { IODSLIProperties } from "components/OptionsWizard/ItemProperties/ODSLIProperties";
import ODSLIPropertiesDialog from "components/OptionsWizard/ItemProperties/ODSLIPropertiesDialog";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import useWizardInteractions from "../useWizardInteractions";
import { IItemPropertiesData } from "../ItemProperties/ItemPropertiesContext";
import useItemPropertiesActions from "../ItemProperties/useItemPropertiesActions";
import { OpeningShapeEnum, ShapeDirectionEnum, SizingModeEnum } from "helpers/enums";
import useIsMobile from "helpers/hooks/useIsMobile";
import DesignerItemProperties from "./DesignerItemProperties";

interface IProps {
}

const shouldShowDesignerEdit = (webDesigner: IWebDesigner | null): boolean => {
    if (!webDesigner)
        //Not a web designer, will show the standard Edit
        return false;
    else if (webDesigner.supportsSLI) {
        //This is BBW or OD, look at the main vs subline item settings
        return ((webDesigner.currentSection === 0 && webDesigner.canChangeMLISize) ||
            (webDesigner.currentSection !== 0 && webDesigner.canChangeSLISize))
    }
    else {
        //This is Showers, always show Edit (although the size will be disabled)
        return true;
    }
}

const DesignerItemSize: React.FC<IProps> = (props: IProps) => {

    const wizardState = useWizardState();
    const format = useFormatHelper();
    const wizardInteractions = useWizardInteractions();
    const itemPropertiesActions = useItemPropertiesActions();
    const isMobile = useIsMobile();

    let showSizeEdit: boolean = true;
    let showSize: boolean = true;

    const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);

    const itemProperties = React.useMemo<IItemPropertiesData>(() => {

        const webDesigner = wizardState.webDesigner;
        const selectedSLI = webDesigner?.sectionList?.find(sli => sli.sli === webDesigner?.currentSection) ?? null;
        const designerProperties = selectedSLI?.designerProperties as IOpeningDesignerOpeningSubLineItemInfo;

        const data: IItemPropertiesData = {
            qty: wizardState.itemInfo?.qty ?? 1,
            callSize: wizardState.itemInfo?.callSize ?? null,
            width: wizardState.itemInfo?.width ?? 0,
            height: wizardState.itemInfo?.height ?? 0,
            thickness: wizardState.itemInfo?.thickness ?? 0,
            comment: wizardState.itemInfo?.comment ?? "",
            direction: designerProperties?.direction ?? ShapeDirectionEnum.Left,
            legHeight: designerProperties?.legHeight ?? 0,
            radius: designerProperties?.radius ?? 0,
            shape: designerProperties?.shape ?? OpeningShapeEnum.Standard,
            sizing: designerProperties?.sizing ?? SizingModeEnum.Fixed
        };
        return data;
    }, [wizardState.itemInfo, wizardState.webDesigner]);

    const handleEditClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (wizardState.itemInfo) {
            itemPropertiesActions.SetState(itemProperties, wizardState.itemInfo.unitSet);
        }
        setDialogVisible(true)
    }, [itemProperties, itemPropertiesActions, wizardState.itemInfo]);

    const handleODSLIPropertiesSubmit = React.useCallback(async (oldState: IODSLIProperties, newState: IODSLIProperties) => {

        const responseMessage = await wizardInteractions.ChangeODSLIPropertiesAsync(newState);

        if (responseMessage) {
            return responseMessage;
        } else {
            setDialogVisible(false);
            return null;
        }

    }, [wizardInteractions]);

    if (!wizardState.itemInfo || !format) {
        return null;
    } else {

        if (wizardState.webDesigner) {
            const wd: IWebDesigner = wizardState.webDesigner;
            showSize = true;
            showSizeEdit = shouldShowDesignerEdit(wd);
        }

        let ii: ILineItemInfo = wizardState.itemInfo;

        return <>
            <Grid container direction="column" alignItems="stretch" spacing={1}  >
                <Grid item>
                    <DesignerItemSizeLabel
                        ii={ii}
                        showSize={showSize}
                        showSizeEdit={showSizeEdit}
                        handleEditClick={handleEditClick}
                        format={format}
                    />
                </Grid>

                {isMobile &&
                    <DesignerItemProperties includeGridItem />
                }

            </Grid >

            <ODSLIPropertiesDialog
                dialogVisible={dialogVisible}
                itemInfo={wizardState.itemInfo}
                webDesigner={wizardState.webDesigner}
                onCancel={() => setDialogVisible(false)}
                onSubmit={handleODSLIPropertiesSubmit}
            />

        </>;
    }

}

export default DesignerItemSize;
