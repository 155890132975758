import * as React from "react";

import OptionImagePicker from "./OptionImagePicker";

import { IQuestion, ICode } from "../interfaces";
import WizardHelper from "../WizardHelper";
import { Flag, Lock } from "@mui/icons-material";
import { Stack } from "@mui/material";

interface IOWQuestionProps {
    question: IQuestion;
    showImageSelection: boolean;
    readOnlyMode: boolean;
}

const QuestionHeader: React.FC<IOWQuestionProps> = (props: IOWQuestionProps) => {

    const q: IQuestion = props.question;

    const actionRequired: boolean = WizardHelper.IsQuestionOrStandaloneRequired(q);
    const imagesExist: boolean = q.codes.some((c: ICode) => c.imageExists);

    return <Stack direction="row" alignItems="center" >

        {actionRequired &&
            <Flag fontSize="small" color="warning" />
        }
        {q.locked &&
            <Lock fontSize="small" color="primary" />
        }
        {imagesExist && !q.locked && !props.readOnlyMode && props.showImageSelection &&
            <OptionImagePicker question={q} />
        }

    </Stack>;

}

export default QuestionHeader;