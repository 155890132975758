import { IPermission, IUserInfoFull, IUserUpdate } from "helpers/interfaces";
import { FormProvider, useForm } from "react-hook-form";
import React from 'react';
import { FindAllNestedProps } from "helpers/objects";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { useTranslations } from "@fenetech/translations";
import { LoginAuthenticationTypeEnum, LoginTypeEnum, RoleEnum, SecurityLevelEnum, ThemeColorEnum } from "helpers/enums";
import UserGeneral from "./Form/UserGeneral";
import PermissionSelect from "./Form/PermissionSelect";
import { Alert, AlertColor, Container, Snackbar, Stack } from "@mui/material";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { IActionButton } from "helpers/context/Page/PageContext";
import useUserInfo from "helpers/context/User/useUserInfo";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import ChangePassword from "components/User/ChangePassword";
import UserAPI from "components/User/UserAPI";
import { BlankUser } from "./Form/BlankUser";
import { useNavigate, useParams } from "react-router-dom";
import useUserData from "./useUserSetupData";
import useWait from "helpers/context/Page/useWait";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";

interface IProps {
    isEditMode: boolean
}

enum ModeEnum {
    viewingUser = 0,
    editingUser = 1,
    password = 2
}

const UserInfo: React.FC<IProps> = (props: IProps) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<[text: string, alertType: AlertColor] | null>(null);
    const [mode, setMode] = React.useState<ModeEnum>(props.isEditMode ? ModeEnum.editingUser : ModeEnum.viewingUser);
    const [userInfo, setUserInfo] = React.useState<IUserInfoFull>(BlankUser);

    const userInfoWithFormFields = React.useCallback((userInfo: IUserInfoFull) => {
        return {
            ...userInfo.user,
            password: "",
            confirmPassword: "",
            permissions: userInfo.user.securityLevel === SecurityLevelEnum.Administrator ?
                //If administrator, DB returns all zeroes but we want to show every permission enabled
                userInfo.permissions.map(permission => { return { ...permission, enabled: true } }) :
                userInfo.permissions,
            siteIDs: userInfo.assignedSites.map(mfgSite => mfgSite.siteID),
            isAllSites: userInfo.loginType === LoginTypeEnum.AllSites
        }
    }, []);
    const formMethods = useForm({ mode: "onChange", defaultValues: userInfoWithFormFields(userInfo) });

    const { handleSubmit, watch, reset } = formMethods;
    const tm = useTranslations();
    const messageBox = useMessageBox();
    const actionButtons = useActionButtons();
    const wait = useWait();
    const user = useUserInfo();
    const userData = useUserData();
    const navigate = useNavigate();
    const navBlockerActions = useNavigationBlockerActions();

    const securityLevel = watch("securityLevel");
    const loginAuthenticationType = watch("authenticationType");

    useNavigationBlocker((mode === ModeEnum.editingUser) && formMethods.formState.isDirty);

    const { employeeID } = useParams();
    if (!employeeID) navigate(-1);

    React.useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    const getUserInfoFull = async (employeeID: string) => {
        const result = await UserAPI.GetUserInfoFull(employeeID!)
        try {
            if (result) {
                setUserInfo(result);
                reset(userInfoWithFormFields(result));
            } else {
                setAlert([tm.Get("An unknown error has occurred."), "error"]);
            }
        } finally {
            setIsLoading(false);
        };
    }

    useEffectOnLoad(() => {
        getUserInfoFull(employeeID!)
    })

    React.useEffect(() => {
        actionButtons.SetBackButton(0, "setup/users");
        if (mode === ModeEnum.viewingUser) {
            const editButton: IActionButton = {
                text: tm.Get("Edit"),
                color: ThemeColorEnum.Secondary,
                disabled: (isLoading || isPosting),
                onClick: (() => setMode(ModeEnum.editingUser))
            };
            const passwordButton: IActionButton = {
                text: tm.Get("Change Password"),
                color: ThemeColorEnum.Secondary,
                disabled: (isLoading || isPosting || userInfo?.user.authenticationType === LoginAuthenticationTypeEnum.SingleSignOn),
                onClick: (() => setMode(ModeEnum.password))
            }
            actionButtons.Set(1, editButton);
            actionButtons.Set(2, passwordButton);
        } else {
            const saveButton: IActionButton = {
                text: tm.Get("Save"),
                color: ThemeColorEnum.Secondary,
                disabled: (isLoading || isPosting),
                type: "submit",
                form: "edit-user",
                onClick: () => navBlockerActions.Unblock(),
            };
            const cancelButton: IActionButton = {
                text: tm.Get("Cancel"),
                color: ThemeColorEnum.Primary,
                disabled: (isLoading || isPosting),
                onClick: () => {
                    reset(userInfoWithFormFields(userInfo));
                    setMode(ModeEnum.viewingUser);
                    navBlockerActions.Unblock();
                }
            };
            actionButtons.Set(1, saveButton);
            actionButtons.Set(2, cancelButton);
        }
    }, [userInfo, mode, isLoading, isPosting, actionButtons, tm, reset, userInfoWithFormFields, navBlockerActions]);

    const getPermissionValue = (permissions: IPermission[]) => permissions.reduce<number>((sum, permission) => { return permission.enabled ? sum + permission.id : sum }, 0)

    const onSubmit = async (formData: any, e: any) => {
        setIsPosting(true);

        if (formData["confirmPassword"] !== "") {
            const result = await UserAPI.UpdateUserPassword(userInfo.user.employeeID, formData["confirmPassword"])
            try {
                if (result.message) {
                    const translated = tm.Get(result);
                    setAlert([translated, "error"]);
                } else {
                    setAlert([tm.Get(userInfo.user.employeeID === user.employeeID ? "Your password has been updated." : "Changes have been saved."), "success"]);
                    setMode(ModeEnum.viewingUser);
                }
            } finally {
                setIsPosting(false);
            }
        } else {
            const userUpdate: IUserUpdate = {
                employeeID: formData["employeeID"],
                firstName: formData["firstName"].trim(),
                lastName: formData["lastName"].trim(),
                emailAddress: formData["emailAddress"].trim(),
                password: formData["password"],
                inactive: formData["inactive"],
                authenticationType: formData["authenticationType"],
                securityLevel: formData["securityLevel"],
                permissions: getPermissionValue(formData["permissions"]),
                siteIDs: formData["isAllSites"] ? [] : formData["siteIDs"] //If all sites, database expects empty list
            }

            try {
                const result = await UserAPI.UpdateUser(userUpdate);
                if (result?.message) {
                    const translated = tm.Get(result);
                    setAlert([translated, "error"]);
                } else {
                    getUserInfoFull(employeeID!)
                    setAlert([tm.Get("Changes have been saved."), "success"]);
                    setMode(ModeEnum.viewingUser);
                }
            } finally {
                setIsPosting(false);
            }
        }
    }

    const onError = (errors: { [x: string]: any }, e: any) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");
        console.log(formattedMessage);

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert(null);
    }

    return <>
        <Snackbar open={alert !== null} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert ? alert[1] : "info"} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert ? alert[0] : ""}</Alert>
        </Snackbar>
        <Container sx={{ marginTop: 1 }}>
            <FormProvider {...formMethods}>
                <form id="edit-user" onSubmit={handleSubmit(onSubmit, onError)}>
                    {mode === ModeEnum.password ?
                        <ChangePassword userName={userInfo.user.userName} />
                        :
                        <Stack direction="column" gap={1}>
                            <UserGeneral
                                windowTitle={mode === ModeEnum.editingUser ? tm.GetWithParams("Edit {0}", userInfo.user.userName) : userInfo.user.userName}
                                mfgSites={[...userInfo.availableSites ?? [], ...userInfo.assignedSites]}
                                mfgSitesVisible={!user.HasRole(RoleEnum.Contractor) && !userData.isDealerCreatingAdminContractor}
                                isEditMode={mode === ModeEnum.editingUser}
                                isAllSites={userInfo.loginType === LoginTypeEnum.AllSites}
                                allSitesVisible={userInfo.canSelectAllSites}
                                securityLevelDisabled={userData.isDealerCreatingAdminContractor || userInfo.user.mfgCreatedEmployee}
                                loginAuthenticationVisible={!user.HasRole(RoleEnum.Contractor) && !userData.isDealerCreatingAdminContractor && !userInfo.user.mfgCreatedEmployee}
                                userNameRequired={false}
                                passwordRequired={userInfo.user.authenticationType === LoginAuthenticationTypeEnum.SingleSignOn &&
                                    loginAuthenticationType === LoginAuthenticationTypeEnum.Standard}
                                firstNameDisabled={loginAuthenticationType === LoginAuthenticationTypeEnum.SingleSignOn}
                                lastNameDisabled={loginAuthenticationType === LoginAuthenticationTypeEnum.SingleSignOn}
                                emailDisabled={loginAuthenticationType === LoginAuthenticationTypeEnum.SingleSignOn}
                                inactiveDisabled={userInfo.user.mfgCreatedEmployee && (user.HasRole(RoleEnum.Contractor) || !userData.isDealerCreatingAdminContractor)}
                                customerInactiveVisible={userData.isDealerCreatingAdminContractor}
                                mfgInactiveVisible={user.HasRole(RoleEnum.Dealer)}
                                mfgCreatedVisible={!userData.isDealerCreatingAdminContractor}
                                mfgCreatedText={user.HasRole(RoleEnum.Contractor) ? tm.Get("Company Created") : tm.Get("Mfg Created")}
                            />

                            <PermissionSelect
                                readOnly={mode === ModeEnum.viewingUser}
                                disabled={securityLevel === SecurityLevelEnum.Administrator || userInfo.user.mfgCreatedEmployee}
                            />
                        </Stack>
                    }
                </form>
            </FormProvider>
        </Container>
    </>;
}

export default UserInfo;