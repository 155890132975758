import React from "react";
import { IAdvertisement, IDashboardQuote, IDashboardOrder, IDashboardPickup, IDashboardRMA } from "../../helpers/interfaces";


export interface IDashboardData {
    orders?: IDashboardOrder[],
    pickups?: IDashboardPickup[],
    rmas?: IDashboardRMA[],
    ads?: IAdvertisement[],
    companyQuotes?: IDashboardQuote[],
    customerQuotes?: IDashboardQuote[],
    submittedQuotes?: IDashboardQuote[],
    orderDataExpTime: number,
    pickupDataExpTime: number,
    rmaDataExpTime: number,
    companyQuotesExpTime: number,
    customerQuotesExpTime: number,
    submittedQuotesExpTime: number,
    adsExpTime: number
}


export enum ActionTypeEnum {
    SetOrders,
    SetPickups,
    SetRMAs,
    SetCompanyQuotes,
    SetCustomerQuotes,
    SetSubmittedQuotes,
    SetAds,
}

export type DashboardAction =
    { type: ActionTypeEnum.SetOrders, value: IDashboardOrder[] }
    | { type: ActionTypeEnum.SetPickups, value: IDashboardPickup[] }
    | { type: ActionTypeEnum.SetRMAs, value: IDashboardRMA[] }
    | { type: ActionTypeEnum.SetCompanyQuotes, value: IDashboardQuote[] }
    | { type: ActionTypeEnum.SetCustomerQuotes, value: IDashboardQuote[] }
    | { type: ActionTypeEnum.SetSubmittedQuotes, value: IDashboardQuote[] }
    | { type: ActionTypeEnum.SetAds, value: IAdvertisement[] }
    ;

const dashboardReducer: React.Reducer<IDashboardData, DashboardAction> = (data: IDashboardData, action) => {

    //order will stick around for 1 minute.  Ads will refresh after 60
    const newExpDate = new Date();
    let minutes: number = 1;

    if (action.type === ActionTypeEnum.SetAds)
        minutes = 60;

    newExpDate.setMinutes(newExpDate.getMinutes() + minutes);

    switch (action.type) {
        case ActionTypeEnum.SetOrders:
            return { ...data, orders: action.value, orderDataExpTime: newExpDate.getTime() };
        case ActionTypeEnum.SetPickups:
            return { ...data, pickups: action.value, pickupDataExpTime: newExpDate.getTime() };
        case ActionTypeEnum.SetRMAs:
            return { ...data, rmas: action.value, rmaDataExpTime: newExpDate.getTime() };
        case ActionTypeEnum.SetCompanyQuotes:
            return { ...data, companyQuotes: action.value, companyQuotesExpTime: newExpDate.getTime() };
        case ActionTypeEnum.SetCustomerQuotes:
            return { ...data, customerQuotes: action.value, customerQuotesExpTime: newExpDate.getTime() };
        case ActionTypeEnum.SetSubmittedQuotes:
            return { ...data, submittedQuotes: action.value, submittedQuotesExpTime: newExpDate.getTime() };
        case ActionTypeEnum.SetAds:
            return { ...data, ads: action.value, adsExpTime: newExpDate.getTime() };
    }
};


const initialState: IDashboardData = {
    orderDataExpTime: 0,
    pickupDataExpTime: 0,
    rmaDataExpTime: 0,
    customerQuotesExpTime: 0,
    companyQuotesExpTime: 0,
    submittedQuotesExpTime: 0,
    adsExpTime:0,
};

export const DashboardContextDispatch = React.createContext<React.Dispatch<DashboardAction> | undefined>(undefined);
const DashboardContext = React.createContext<IDashboardData>(initialState);



export const DashboardContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(dashboardReducer, initialState);

    return <DashboardContext.Provider value={state}>
        <DashboardContextDispatch.Provider value={dispatch}>
            {props.children}
        </DashboardContextDispatch.Provider>
    </DashboardContext.Provider>
}



export default DashboardContext;
