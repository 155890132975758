import * as React from "react";

import OptionPreview from "./OptionPreview";
import OptionSummary from "./OptionSummary";
import ItemValidation from "./ItemValidation";
import PricingSummary from "./PricingSummary";
import { ICode, IQuestion, IPartPreferences, IValidationInfo, ILineItemInfo, IWebDesigner, ILineItemPriceInfo, ISequencedWizardInfo, ISectionRegion, IDisplayViewSettings } from "../interfaces";
import { useTranslations, ITranslationManager } from "@fenetech/translations";
import Format from "helpers/fv.format";
import { Container, Stack, Tab, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PricingLabel from "components/OptionsWizard/MainWizard/PricingLabel";
import useOptionHash from "components/OptionsWizard/GenericComponents/useOptionHash";
import { WizardModeEnum } from "components/OptionsWizard/WizardContext";
import useQuoteData from "components/Quotes/useQuoteData";
import AutoScaleToWidthDiv from "../GenericComponents/AutoScaleToWidthDiv";
import ObjectViewSelector from "../ObjectViewer/ObjectViewSelector";
import WizardHelper from "../WizardHelper";
import DesignerItemProperties from "./DesignerItemProperties";

export interface IInfoProps {
    options: Array<ICode | IQuestion> | null;
    partPreferences: IPartPreferences | null;
    validations: IValidationInfo | null;
    itemInfo: ILineItemInfo | null;
    webDesigner: IWebDesigner | null;
    displayViewSettings: IDisplayViewSettings | null;
    focusedCode: ICode | null;
    prices: ILineItemPriceInfo | null;
    sequencedWizardInfo: ISequencedWizardInfo | null;
    clickRegionHandler?: (clickableReg: ISectionRegion, tm: ITranslationManager) => void;
    mode: WizardModeEnum;
    format: Format;
}

const ItemInfoContainer: React.FC<IInfoProps> = (props: IInfoProps) => {

    const tm = useTranslations();
    const { quote } = useQuoteData();
    const [currentTab, setCurrentTab] = React.useState<string>("PREVIEW");
    const [hasObjectViewerImage, setHasObjectViewerImage] = React.useState<boolean>(false);

    const optionHash = useOptionHash(props);

    const currentSLI = React.useMemo(() => {
        if (props.sequencedWizardInfo) {
            return props.sequencedWizardInfo.selectedItems[0];
        } else if (props.webDesigner) {
            return props.webDesigner.currentSection;
        }
        return 0;
    }, [props.sequencedWizardInfo, props.webDesigner]);



    const shouldShowValidationControl = React.useMemo(() => {
        if (props.mode === WizardModeEnum.Standard || props.mode === WizardModeEnum.SequencedWizard)
            return true;
        else
            return false;
    }, [props.mode]);

    const shouldShowPricingControl = React.useMemo(() => {
        if (props.prices && (props.mode === WizardModeEnum.Standard || props.mode === WizardModeEnum.SequencedWizard))
            return true;
        else
            return false;
    }, [props.mode, props.prices]);

    const shouldShowOptionSummaryControl = React.useMemo(() => {
        if (!props.sequencedWizardInfo && props.mode === WizardModeEnum.Standard)
            return true;
        else
            return false;
    }, [props.sequencedWizardInfo, props.mode]);

    const shouldShowObjectViewSelector = React.useMemo(() => {
        if (props.sequencedWizardInfo) {
            return false;
        }
        else if (props.focusedCode?.imageExists && WizardHelper.getMaximizeOptionImage()) {
            return false;
        }
        return hasObjectViewerImage;
    }, [props.sequencedWizardInfo, props.focusedCode?.imageExists, hasObjectViewerImage]);

    return <Container disableGutters>
        <Stack spacing={1} px={1}>

            <TabContext value={currentTab}>

                <TabList centered onChange={(e, newValue) => setCurrentTab(newValue)} variant="fullWidth">

                    <Tab label={tm.Get("Preview")} value="PREVIEW" />

                    {shouldShowOptionSummaryControl &&
                        <Tab label={tm.Get("Options")} value="OPTIONS" />
                    }

                    {shouldShowPricingControl &&
                        <Tab
                            label={<PricingLabel prices={props.prices} currencyCulture={quote?.currencyCulture ?? ""} />}
                            value="PRICING"
                        />
                    }

                </TabList>

                <TabPanel value="PREVIEW" sx={{ padding: 0, marginTop: 1 }}>

                    <Stack spacing={1} direction="column" alignItems="stretch">
                        <AutoScaleToWidthDiv>
                            <OptionPreview
                                optionHash={optionHash}
                                partPreferences={props.partPreferences}
                                itemInfo={props.itemInfo}
                                webDesigner={props.webDesigner}
                                focusedCode={props.focusedCode}
                                sequencedWizardInfo={props.sequencedWizardInfo}
                                clickRegionHandler={props.clickRegionHandler}
                                mode={props.mode}
                                tm={tm}
                                format={props.format}
                                renderAsDiv={false}
                                setHasObjectViewerImage={setHasObjectViewerImage}
                            />
                        </AutoScaleToWidthDiv>

                        {shouldShowObjectViewSelector &&
                            <ObjectViewSelector />
                        }

                        {!props.sequencedWizardInfo && props.mode === WizardModeEnum.Standard && props.itemInfo &&
                            <DesignerItemProperties />
                        }

                    </Stack>

                </TabPanel>

                {shouldShowOptionSummaryControl && props.options &&
                    <TabPanel value="OPTIONS" sx={{ padding: 0, marginTop: 1 }}>
                        <OptionSummary options={props.options} tm={tm} />
                    </TabPanel>
                }

                {shouldShowPricingControl && props.prices &&
                    <TabPanel value="PRICING" sx={{ padding: 0, marginTop: 1 }}>
                        <PricingSummary prices={props.prices} subLineItem={currentSLI} tm={tm} />
                    </TabPanel>
                }

            </TabContext>

            {shouldShowValidationControl && props.validations &&
                <Paper elevation={4} sx={{ position: "sticky", bottom: "0px" }}>
                    <ItemValidation validations={props.validations} />
                </Paper>
            }
        </Stack>

    </Container>;
}

export default ItemInfoContainer;
