import React from 'react';
import { Box, BoxProps, Paper, Typography } from '@mui/material';

interface IProps {
    caption: string
}

type BoxWithHeaderProps = BoxProps & IProps;

const BoxWithHeader: React.FC<React.PropsWithChildren<BoxWithHeaderProps>> = ({ caption, children, ...rest }: BoxWithHeaderProps) => {

    return <>
        <Paper>
            <Box {...rest}>
                <Box sx={{ bgcolor: "secondary.main" }} p={1}>
                    <Typography color="secondary.contrastText">
                        {caption}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" p={1} gap={1}>
                    {children}
                </Box>
            </Box>
        </Paper>
    </>;
}

export default BoxWithHeader;