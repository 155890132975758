import * as React from "react";
import { useMemo } from "react";

import OWExpander from "./OWExpander";
import OWQuestions from "./OWQuestions";
import OWTabs from "./OWTabs";

import { AltWizActionTypeEnum, GroupDisplayTypeEnum } from "../enum";
import { ILineItemInfo, IUserPreferences } from "../interfaces";
import { IQuestionGroup } from "../models";
import { Stack } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import OWTabsMobile from "components/OptionsWizard/OptionsWizard/OWTabsMobile";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import OptionGroupBox from "./OptionGroupBox";
import OWExpanderAccordian from "./OWExpanderAccordian";
import { useTranslations } from "@fenetech/translations";
import ItemPropertiesSelector from "../ItemProperties/ItemPropertiesSelector";


interface IOptionsWizardProps {
    groups: Array<IQuestionGroup>;
    userPreferences: IUserPreferences;
    wizardKey: number;
    readOnlyMode: boolean;
    itemInfo: ILineItemInfo | null;
}

const OptionsWizard: React.FC<IOptionsWizardProps> = (props: IOptionsWizardProps) => {

    const wizardState = useWizardState();

    const isMobile = useIsMobile();
    const tm = useTranslations();
    const userPreferences = wizardState.userPreferences;

    const canShowItemPropertiesInWizard = useMemo(() => {
        if (wizardState.sequencedWizardInfo)
        {
            //in sequenced wizard, only show size inside the wizard if we are editing the main line item options
            //otherwise sizing is a separate step
            return wizardState.sequencedWizardInfo.currentAction === AltWizActionTypeEnum.MainLineItemOptions;
        }
        else {
            return true;
        }
    }, [wizardState.sequencedWizardInfo]);

    const renderQuestionContainer = useMemo(() => {

        if (props.userPreferences.groupDisplayType === GroupDisplayTypeEnum.Tabs && props.groups && props.groups.length > 0 && props.groups[props.groups.length - 1].groupName !== "") {
            //Having the key based on the wizardKey ensures that the component will be recreated (instead of given new props) when the wiz key changes.
            // this will cause the tabs to reset to their default position on part /sub line item change.

            if (isMobile)
                return [<OWTabsMobile
                    preferences={props.userPreferences}
                    key={"TABS" + props.wizardKey}
                    questionGroups={props.groups}
                    readOnlyMode={props.readOnlyMode}
                    itemInfo={props.itemInfo}
                    showItemProperties={canShowItemPropertiesInWizard}
                />];
            else
                return [<OWTabs
                    preferences={props.userPreferences}
                    key={"TABS" + props.wizardKey}
                    questionGroups={props.groups}
                    readOnlyMode={props.readOnlyMode}
                    itemInfo={props.itemInfo}
                    showItemProperties={canShowItemPropertiesInWizard}
                />];
        } else {

            let questions: JSX.Element[];

            if (props.userPreferences.groupDisplayType === GroupDisplayTypeEnum.Expander && props.groups.length > 0 && props.groups[props.groups.length - 1].groupName !== "") {
                questions = props.groups.map((qg: IQuestionGroup, index) =>
                    <OWExpander
                        preferences={props.userPreferences}
                        key={`GRP_${index}_${qg.groupName}`}
                        questionGroup={qg}
                        readOnlyMode={props.readOnlyMode}
                    />);

            } else {
                questions = props.groups.map((qg: IQuestionGroup, index) =>
                    <OWQuestions
                        preferences={props.userPreferences}
                        key={`QSTS_${index}_${qg.groupName}`}
                        questionGroup={qg}
                        readOnlyMode={props.readOnlyMode}
                    />);
            }
            if (canShowItemPropertiesInWizard && props.itemInfo) {
                if (userPreferences.groupDisplayType === GroupDisplayTypeEnum.Expander) {
                    return [<OWExpanderAccordian key="ACRD_SIZE"
                        defaultExpanded
                        headerText={tm.Get("ITEM")} >
                        <ItemPropertiesSelector />
                    </OWExpanderAccordian>, ...questions];
                }
                else {
                    return [<OptionGroupBox key="ACRD_SIZE" id="ACRD_SIZE" label="">
                        <ItemPropertiesSelector />
                    </OptionGroupBox>, ...questions];
                }
            }
            else {
                return questions;
            }

        }
    }, [props.userPreferences, props.groups, props.wizardKey, props.readOnlyMode, props.itemInfo, isMobile, userPreferences.groupDisplayType, tm, canShowItemPropertiesInWizard]);

    return <Stack spacing={1}>
        {renderQuestionContainer}
    </Stack>;
}

export default OptionsWizard;



