import { useEffect } from 'react';
import useActionButtons from './useActionButtons';

/*
    Use this hook to set the default "Create Quote" action button that is on most pages
*/

export default function useDefaultActionButtons() {

    const actionButtons = useActionButtons();

    useEffect( () => {
        actionButtons.SetCreateQuoteButton(0);
    }, [actionButtons])
}