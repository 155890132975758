import { Paper, Typography, Stack, Grid } from "@mui/material";
import { SpeakerNotes, Person } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";
import { useTranslations } from "@fenetech/translations";
import useOrderData from "components/Orders/useOrderData";

interface IProps {
    orderTypeText: string;
}

function OrderInfoCard({ orderTypeText } : IProps) {
    const tm = useTranslations();
    const { order } = useOrderData();

    return <>
        <Paper sx={{display: "flex", width: "100%"}}>
            <Stack display="flex" width="100%" m={1}>
                <Grid container direction="row">
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="h6">
                            {orderTypeText}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Contact")} text={order?.orderContact} />
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <SpeakerNotes color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Comment")} text={order?.comments} />
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person sx={{ opacity: 0 }} />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("PO Number")} text={order?.poNumber} />
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person sx={{ opacity: 0 }} />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Customer Ref")} text={order?.customerRef} />
                    </Grid>
                </Grid>

            </Stack>
        </Paper>

    </>

}

export default OrderInfoCard;