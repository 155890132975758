
import React from "react";
import { IDashboardPickup } from "helpers/interfaces";
import DashboardAPI from "../DashboardAPI";
import DashboardContext, { ActionTypeEnum, DashboardContextDispatch } from "../DashboardContext";


export function useDashboardPickupData(): IDashboardPickup[]|undefined {

    const dashboardData = React.useContext(DashboardContext);
    const dispatch = React.useContext(DashboardContextDispatch);
    const pickupData = dashboardData.pickups;

    const isExpired = (dashboardData.pickupDataExpTime < Date.now()  );

    React.useEffect(() => {

        if (dispatch) {
            if (!pickupData || isExpired) {
                DashboardAPI.QueryOpenPickups().then(o => {
                    dispatch({ type: ActionTypeEnum.SetPickups, value: o });
                });
            }
        }

    }, [dispatch, pickupData, isExpired]);

    return pickupData;

};