import { useMemo } from "react";
import { Typography } from "@mui/material";

import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";

import { ILineItem } from "models/IQuote";
import useQuoteData from "components/Quotes/useQuoteData";
import useOrderData from "components/Orders/useOrderData";

interface IProps {
    lineItem: ILineItem,
    variant: any,
}

const LineItemTotal: React.FC<IProps> = ({ lineItem, variant }: IProps) => {

    const { quote } = useQuoteData();
    const { order } = useOrderData();

    const currencyFormatter = useCurrencyFormatter(quote?.currencyCulture ?? order?.currencyCulture);

    const getTotalPrice = (lineItem: ILineItem): number => {
        return lineItem.unitPrice * lineItem.quantity;
    };

    const isPriceBold = useMemo(() => {
        return lineItem.priceOverride;
    }, [lineItem]);

    return <>
        <Typography variant={variant} fontWeight={isPriceBold ? "bold" : ""} >
            {currencyFormatter.Format(getTotalPrice(lineItem))}
        </Typography>
    </>;

};

export default LineItemTotal;