import { FeneVisionThemeActions, FeneVisionThemeContext, FeneVisionThemeDispatchContext, IThemeContext } from "./FeneVisionThemeContext";
import React from "react";

export default function useFeneVisionTheme(): [IThemeContext, FeneVisionThemeActions | undefined] {

  const themeInfos = React.useContext<IThemeContext>(FeneVisionThemeContext);
  const dispatch = React.useContext(FeneVisionThemeDispatchContext);

  return [themeInfos, dispatch];

};

