import React, { Dispatch, SetStateAction } from 'react';
 
const useStateWithLocalStorage = (localStorageKey: string) : [string, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );
 
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value, localStorageKey]);
 
  return [value, setValue];
};

export default useStateWithLocalStorage;