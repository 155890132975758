export enum TransLogHeaderTypeEnum {
    Unknown,
    Quote,
    LineItem,
    LineItemOption
}

export interface ITransLogHeader {
    type: TransLogHeaderTypeEnum;
    date: Date;
    user: string;
    quoteNumber: string;
    lineItem: string;
    question: string;
    code: string;
    odKey: number;
    rowNumber: number;
    tableName: string;
}

export interface ITransLogDiff {
    columnName: string;
    oldValue: string;
    newValue: string;
}
