import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";
import LineItemExceptionIcon from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemExceptionIcon";

import { ILineItem, IOrderLineItem } from "helpers/interfaces";

export default class LineItemExceptionIconColumn extends LineItemColumn {
    onClick: (lineItem: ILineItem) => void;

    public constructor(columnName: string, headerText: string, width: string, onClick: (lineItem: ILineItem) => void) {
        super(columnName, headerText, width);
        this.align = "center";
        this.onClick = onClick;
    }

    public CellContent(props: IOrderLevelProps, lineItem: IOrderLineItem): JSX.Element {

        return <LineItemExceptionIcon
            lineItem={lineItem}
            validationExceptionsOnly={true}
            onClick={this.onClick}
        />

    }

    public FooterContent(props: IOrderLevelProps): JSX.Element {
        return <></>;
    }

}
