import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import { useFormContext, useController } from "react-hook-form";
import LabeledText from "./LabeledText";

interface IProps {
    fieldName : string,
    label: string,
    readOnly: boolean
}

type CheckBoxFormProps = CheckboxProps & IProps;

const CheckBoxForm = ({fieldName, label, readOnly, onChange, onBlur, ...rest} : CheckBoxFormProps) => {
    
    const { control } = useFormContext();
    const { field } = useController({
        name: fieldName,
        control: control
    });

    function handleOnChange(e : any) {
        if (!readOnly) {
            field.onChange(e);
            if (onChange) onChange(e, field.value);
        }
    }
    
    function handleOnBlur(e : any) {
        field.onBlur();
        if (onBlur) onBlur(e);
    }

    if (readOnly) {
        return <LabeledText
            label={label}
            text={""}
            isChecked={field.value}
        />
    }
    return <>
        <FormControlLabel
            control={
                <Checkbox
                    ref={field.ref}
                    inputRef={field.ref}
                    checked={field.value}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    {...rest}
                />}
            label={label}
        />
    </>
}

export default CheckBoxForm;