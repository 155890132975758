
import React from 'react';
import MfgCustomersAndSites from 'helpers/context/SelectionValues/MfgCustomersAndSites';
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useMfgCustomersAndSites(): MfgCustomersAndSites | undefined {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const mfgCustomersAndSites = selectionValues.mfgCustomersAndSites;

    const isExpired = false;

    React.useEffect(() => {
        if (!mfgCustomersAndSites || isExpired) {
            actions.LoadMfgCustomersAndSitesAsync();
        }

    }, [actions, mfgCustomersAndSites, isExpired]);

    return mfgCustomersAndSites;

};

export default useMfgCustomersAndSites;