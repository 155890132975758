import * as React from "react";

import QuestionComponentSelector from "./QuestionComponentSelector";

import WizardHelper from "../WizardHelper";
import { ICode, IQuestion, IUserPreferences } from "../interfaces";
import { Stack } from "@mui/material";


interface IProps { question: IQuestion, preferences: IUserPreferences, readOnlyMode: boolean};

const SelectedCodeNestedQuestions: React.FC<IProps> = (props: IProps) => {


    let q: IQuestion = props.question;

    let selectedCodes: Array<ICode> = WizardHelper.SelectedCodes(q);

    let nestedQuestions: Array<IQuestion> = new Array<IQuestion>();

    for (let c of selectedCodes) {
        if (c.nestedQuestions) {
            nestedQuestions = nestedQuestions.concat(c.nestedQuestions);
        }
    }

    if (nestedQuestions.length === 0)
        return null;

    return <Stack direction="column" spacing={1}>
        {
            nestedQuestions.map((q: IQuestion) => <QuestionComponentSelector preferences={props.preferences} question={q} key={q.sequence + "_" + q.question} readOnlyMode={props.readOnlyMode} />)
        }
    </Stack>
}

export default SelectedCodeNestedQuestions;

