import React from "react";
import usePageActions from "../Page/usePageActions";
/*
    Use this hook to set the page header in the action bar
*/

export default function useWindowTitle(title: string): void {

    const pageActions = usePageActions();

    React.useEffect(() => {
        pageActions.SetTitle(title);

        return () => {
            pageActions.SetTitle("");
        };
    }, [title, pageActions]);
}
