import { Paper, Typography, Stack, Grid, Link } from "@mui/material";
import { Room, Phone, LocalShipping, SpeakerNotes } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";

import { useTranslations } from "@fenetech/translations";
import useOrderData from "components/Orders/useOrderData";

import Format from "helpers/fv.format";

function ShippingCard() {
    const tm = useTranslations();
    const { order } = useOrderData();

    return <>
        <Paper sx={{display: "flex", width: "100%"}}>
            <Stack display="flex" width="100%" m={1}>
                <Grid container direction="row">
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="h6">
                            {tm.Get("Shipping")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container mt={1} direction="row">
                    <Grid item mr={1}>
                        <Room color="primary" />
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {order?.shipping?.name ?? ""}
                            <br />
                            {order?.shipping?.address?.address1 ?? ""}
                            <br />
                            {order?.shipping?.address?.address2 ?? ""}
                            <br />
                            {Format.FormatCityStateZip(order?.shipping?.address?.city ?? "", order?.shipping?.address?.state ?? "", order?.shipping?.address?.zipCode ?? "")}
                            <br />
                            {order?.shipping?.address?.country ?? ""}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Phone color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Phone")} text={
                            <Link href={`tel:${order?.shipping?.phone ?? ""}`} underline="always" tabIndex={(order?.shipping?.phone ?? "") === "" ? -1 : undefined}>
                                {order?.shipping?.phone ?? ""}
                            </Link>
                        } />
                    </Grid>
                </Grid>

                {order?.shipping?.fax &&
                    <Grid container mt={1} direction="row">
                        <Grid item mr={1} display="flex" alignItems="center">
                            <Phone opacity={0} />
                        </Grid>
                        <Grid item xs display="flex" alignItems="center">
                            <LabeledText label={tm.Get("Fax")} text={order.shipping.fax} />
                        </Grid>
                    </Grid>
                }

                <Grid container mt={1} direction="row">
                    {order?.shipping?.remoteDropShip &&
                        <>
                            <Grid item mr={1} display="flex" alignItems="center">
                                <LocalShipping color="primary" />
                            </Grid>
                            <Grid item xs display="flex" alignItems="center">
                                <Typography>{tm.Get("Drop Ship")}</Typography>
                            </Grid>
                        </>
                    }
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <SpeakerNotes color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Comment")} text={order?.shipping?.shippingComment} />
                    </Grid>
                </Grid>

            </Stack>
        </Paper>

    </>

}

export default ShippingCard;