import isNumeric from "fast-isnumeric";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Container, Box, Dialog, DialogTitle } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import useCurrencyFormatter, { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import useUserInfo from "helpers/context/User/useUserInfo";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useWait from "helpers/context/Page/useWait";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { IActionButton } from "helpers/context/Page/PageContext";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { RoleEnum, ThemeColorEnum, UserPermissionTypes } from "helpers/enums";
import useQuoteData from "components/Quotes/useQuoteData";
import WizardAPI, { ILineItemSimple } from "components/OptionsWizard/WizardAPI";
import LineItemPriceBreakdownAPI from "components/LineItemPriceBreakdown/LineItemPriceBreakdownAPI";
import { ILineItemPriceBreakdown, ILineItemPBOption, ILineItemPBDetails } from "helpers/interfaces";
import EditOptionPriceDialog from "components/LineItemPriceBreakdown/EditOptionPriceDialog"
import PBHeader from "components/LineItemPriceBreakdown/PBHeader"
import PBOptionsGrid from "components/LineItemPriceBreakdown/PBOptionsGrid"
import PBPricing from "components/LineItemPriceBreakdown/PBPricing"
import useQuoteActions from "components/Quotes/useQuoteActions";
import Format from "helpers/fv.format";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { useNavigate, useSearchParams } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import NotFound from "components/Layout/NotFound";

export interface ILineItemPriceBreakdownProps {
    qty: string;
    orderedQty: number;
    qtyOverride: boolean;
    item: number;
    lineItems: ILineItemPBDetails[];
    size: string;
    partPrice: string;
    partPriceOverride: boolean;
    currentQty: string;
    currentPartPrice: string;
    discountTable: string;
    optionsData: ILineItemPBOption[];
    itemWeight: string;
    itemCost: string;
    itemSqFtPrice: string;
    itemPrice: string;
    overallWeight: string;
    overallCost: string;
    overallSqFtPrice: string;
    overallPrice: string;
    currentOverallSqFtPrice: string;
    currentOverallPrice: string;
    editEnabled: boolean;
    viewCost: boolean;
    anyPriceOverridden: boolean;
    subLineQuantitiesCanChange: boolean;
    qtyErrorMessage: string;
    partPriceErrorMessage: string;
    overallSqFtPriceErrorMessage: string;
    overallPriceErrorMessage: string;
    setEditingOption: React.Dispatch<React.SetStateAction<number>>;
    setIsPosting: React.Dispatch<React.SetStateAction<boolean>>;
    setQtyErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    setPartPriceErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    setOverallSqFtPriceErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    setOverallPriceErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    handleItemChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleQtyChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handlePartPriceChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleOverallSqFtPriceChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleOverallPriceChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleRepriceClick: () => void;
    setData: React.Dispatch<React.SetStateAction<ILineItemPriceBreakdown | null>>;
    cf: ICurrencyFormatter;
};


function LineItemPriceBreakdown() {
    const user = useUserInfo();
    const appInfo = useApplicationInfo();
    const tm = useTranslations();
    const wait = useWait();
    const navigate = useNavigate();
    const messageBox = useMessageBox();
    const actionButtons = useActionButtons();
    const formatMethods = useFormatHelper();
    const [title, setTitle] = useState<string>("");
    const [data, setData] = useState<ILineItemPriceBreakdown | null>(null);
    const [lineItems, setLineItems] = useState<ILineItemPBDetails[]>([]);

    const [simpleLineItem, setSimpleLineItem] = React.useState<ILineItemSimple | null>(null)

    const { quote, permissions, lock } = useQuoteData();
    const cf = useCurrencyFormatter(quote?.currencyCulture);
    const quoteActions = useQuoteActions();
    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [viewCost, setViewCost] = useState<boolean>(false);
    const [isPosting, setIsPosting] = useState<boolean>(false);
    const [anyPriceOverridden, setAnyPriceOverridden] = useState<boolean>(false);
    const [subLineQuantitiesCanChange, setSubLineQuantitiesCanChange] = useState<boolean>(false);
    const [costingCurrency, setCostingCurrency] = useState<string>("");

    const oKey = quote?.oKey ?? 0;
    const [query] = useSearchParams();
    const odKeyString = query.get("odKey") ?? undefined;
    const odKey = odKeyString ? parseInt(odKeyString) : 0;

    // Header
    const [qty, setQty] = useState<string>("");
    const [qtyOverride, setQtyOverride] = useState<boolean>(false);
    const [item, setItem] = useState<number>(0);
    const [size, setSize] = useState<string>("");
    const [partPrice, setPartPrice] = useState<string>("");
    const [partPriceOverride, setPartPriceOverride] = useState<boolean>(false);
    const [qtyErrorMessage, setQtyErrorMessage] = useState<string>("");
    const [partPriceErrorMessage, setPartPriceErrorMessage] = useState<string>("");
    const [currentQty, setCurrentQty] = useState<string>("");
    const [currentPartPrice, setCurrentPartPrice] = useState<string>("");

    // Options
    const [discountTable, setDiscountTable] = useState<string>("");
    const [editingOption, setEditingOption] = useState<number>(-1);

    // Pricing
    const [itemWeight, setItemWeight] = useState<string>("");
    const [itemCost, setItemCost] = useState<string>("");
    const [itemSqFtPrice, setItemSqFtPrice] = useState<string>("0.00");
    const [itemPrice, setItemPrice] = useState<string>("0.00");
    const [overallWeight, setOverallWeight] = useState<string>("");
    const [overallCost, setOverallCost] = useState<string>("");
    const [overallSqFtPrice, setOverallSqFtPrice] = useState<string>("0.00");
    const [overallPrice, setOverallPrice] = useState<string>("0.00");
    const [overallSqFtPriceErrorMessage, setOverallSqFtPriceErrorMessage] = useState<string>("");
    const [overallPriceErrorMessage, setOverallPriceErrorMessage] = useState<string>("");
    const [currentOverallSqFtPrice, setCurrentOverallSqFtPrice] = useState<string>("");
    const [currentOverallPrice, setCurrentOverallPrice] = useState<string>("");

    const orderedQty = useMemo<number>(() => {
        if (data) {
            return data.lineItems[0].qty;
        } else {
            return 0;
        }
    }, [data]);

    const selectedLineItem = useMemo<ILineItemPBDetails | undefined>(() => {
        return data?.lineItems.find(l => l.subLineItem === item);
    }, [data, item]);

    const optionsData = useMemo<ILineItemPBOption[]>(() => {
        if (selectedLineItem) {
            return selectedLineItem.options;
        } else {
            return [];
        }
    }, [selectedLineItem]);

    useEffect(() => {
        if (oKey && odKey) {
            WizardAPI.GetLineItemInfoSimple(oKey, odKey, false).then((li) => {
                setSimpleLineItem(li);
            });
        }
    }, [oKey, odKey]);

    useEffect(() => {
        if (oKey && odKey && data === null) {
            LineItemPriceBreakdownAPI.PriceBreakdown(oKey, odKey, false).then((pd) => {
                setData(pd);
                if (pd) {
                    setCostingCurrency(pd.costingCurrencyCulture);
                }
            })
        }
    }, [oKey, odKey, data]);

    useWindowTitle(title);
    useEffect(() => {
        if (quote && simpleLineItem?.lineItem) {
            setTitle(`${tm.Get("Price Breakdown")} - #${quote.orderNumber ?? ""}-${simpleLineItem?.lineItem}`);
        }
    }, [quote, tm, simpleLineItem]);

    useEffect(() => {
        if (!permissions?.viewCost) {
            setViewCost(false);
        } else {
            setViewCost(true);
        }
    }, [user, quote, permissions]);

    useEffect(() => {
        if (lock && permissions) {
            if (!lock.isLockedByAnotherUser && permissions.editQuote && user.HasPermission(UserPermissionTypes.ModifyQuotePricing)) {
                setEditEnabled(true);
            } else {
                setEditEnabled(false);
            }
        }
    }, [lock, anyPriceOverridden, permissions, user]);

    useEffect(() => {
        if (oKey && odKey && (data === null || isPosting)) {
            wait.Show(true);
        } else {
            wait.Show(false);
        }
    }, [oKey, odKey, wait, data, isPosting])

    useEffect(() => {
        if (data && selectedLineItem && quote && costingCurrency !== "") {
            setQty(selectedLineItem.qty.toString());
            setQtyOverride(selectedLineItem.qtyOverride);
            setSize(formatMethods.FormatDimensions(selectedLineItem.width, selectedLineItem.height, selectedLineItem.thickness, quote.engineeringUnitSetID));
            setPartPrice(cf.Format(selectedLineItem.partPrice));
            setPartPriceOverride(selectedLineItem.priceOverride);
            setDiscountTable(selectedLineItem.discountTable);
            setLineItems(data.lineItems);
            setItemWeight(selectedLineItem.itemWeight === 0 ? "" : Format.FormatWeight(selectedLineItem.itemWeight));
            setItemCost(cf.FormatToCulture(selectedLineItem.itemCost, costingCurrency));
            setItemSqFtPrice(cf.Format(selectedLineItem.itemSqFtPrice));
            setItemPrice(cf.Format(selectedLineItem.itemPrice));
            setOverallWeight(data.topLevelDetails.overallWeight === 0 ? "" : Format.FormatWeight(data.topLevelDetails.overallWeight));
            setOverallCost(cf.FormatToCulture(data.topLevelDetails.overallCost, costingCurrency));
            setOverallSqFtPrice(cf.Format(data.topLevelDetails.overallSqFtPrice));
            setOverallPrice(cf.Format(data.topLevelDetails.overallPrice));
            setAnyPriceOverridden(data.topLevelDetails.anyPriceOverridden);
            setSubLineQuantitiesCanChange(data.topLevelDetails.subLineQuantitiesCanChange);
            setCurrentQty(selectedLineItem.qty.toString());
            setCurrentPartPrice(cf.Format(selectedLineItem.partPrice));
            setCurrentOverallSqFtPrice(cf.Format(data.topLevelDetails.overallSqFtPrice));
            setCurrentOverallPrice(cf.Format(data.topLevelDetails.overallPrice));
            setQtyErrorMessage("");
            setPartPriceErrorMessage("");
            setOverallSqFtPriceErrorMessage("");
            setOverallPriceErrorMessage("");
        }
    }, [data, selectedLineItem, cf, costingCurrency, formatMethods, quote])

    const handleQtyChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (editEnabled && item !== 0 && subLineQuantitiesCanChange) {
            setQty(e.target.value);
        }
    }

    const handlePartPriceChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (editEnabled) {
            setPartPrice(e.target.value);
        }
    }

    const handleOverallSqFtPriceChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (editEnabled) {
            setOverallSqFtPrice(e.target.value);
        }
    }

    const handleOverallPriceChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (editEnabled) {
            setOverallPrice(e.target.value);
        }
    }

    const handleItemChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            if (data && quote && costingCurrency !== "") {
                let i = parseInt(e.target.value);
                const subLineItem = data.lineItems.find(l => l.subLineItem === i);

                if (subLineItem)
                {
                    setQty(subLineItem.qty.toString());
                    setQtyOverride(subLineItem.qtyOverride);
                    setSize(formatMethods.FormatDimensions(subLineItem.width, subLineItem.height, subLineItem.thickness, quote.engineeringUnitSetID));
                    setPartPrice(cf.Format(subLineItem.partPrice));
                    setPartPriceOverride(subLineItem.priceOverride);
                    setDiscountTable(subLineItem.discountTable);
                    setItemWeight(subLineItem.itemWeight === 0 ? "" : Format.FormatWeight(subLineItem.itemWeight));
                    setItemCost(cf.FormatToCulture(subLineItem.itemCost, costingCurrency));
                    setItemSqFtPrice(cf.Format(subLineItem.itemSqFtPrice));
                    setItemPrice(cf.Format(subLineItem.itemPrice));
                    setCurrentQty(subLineItem.qty.toString());
                    setCurrentPartPrice(cf.Format(subLineItem.partPrice));
                    setItem(i);
                }
            }
        }
    };

    const optionPriceChangeSubmit = React.useCallback((value: ILineItemPriceBreakdown) => {
        setData(value);
        setEditingOption(-1);
    }, []);

    const optionPriceChangeCancel = () => {
        setEditingOption(-1);
    }

    const optionString = useCallback((option: ILineItemPBOption) => {
        let s = option.group
        if (appInfo.parameters.hideOptionCodes) {
            s += " - " + option.description;
        } else {
            s += "{" + option.code;
            if (option.value !== "") {
                s += "=[" + option.value + "]";
            }
            s += "}";
        }
        return s;
    }, [appInfo])

    const handleRepriceClick = useCallback(() => {
        if (editEnabled && oKey) {
            setIsPosting(true);
            LineItemPriceBreakdownAPI.Reprice().then(() => {
                setIsPosting(false);
                setData(null);
            });
        }
    }, [editEnabled, oKey])

    const handleSaveClick = useCallback(async () => {
        if (!isPosting) {
            if (qtyErrorMessage !== "") {
                messageBox.Show({ message: qtyErrorMessage, title: tm.Get("Price Breakdown") });
            } else if (partPriceErrorMessage !== "") {
                messageBox.Show({ message: partPriceErrorMessage, title: tm.Get("Price Breakdown") });
            } else if (overallSqFtPriceErrorMessage !== "") {
                messageBox.Show({ message: overallSqFtPriceErrorMessage, title: tm.Get("Price Breakdown") });
            } else if (overallPriceErrorMessage !== "") {
                messageBox.Show({ message: overallPriceErrorMessage, title: tm.Get("Price Breakdown") });
            } else {
                setIsPosting(true);
                await LineItemPriceBreakdownAPI.Save();
                await quoteActions?.LoadQuoteAsync(oKey);
                navigate(QuoteNavigation.QuoteEntryURL(oKey));
            }
        }
    }, [oKey, isPosting, tm, quoteActions, qtyErrorMessage, partPriceErrorMessage, overallSqFtPriceErrorMessage, overallPriceErrorMessage, messageBox, navigate])

    useEffect(() => {
        actionButtons.Clear();
        const submitButton: IActionButton = {
            text: editEnabled ? tm.Get("Save") : tm.Get("OK"),
            color: ThemeColorEnum.Secondary,
            disabled: isPosting,
            onClick: handleSaveClick
        };
        actionButtons.Set(0, submitButton);

        if (editEnabled) {
            const cancelButton: IActionButton = {
                text: tm.Get("Cancel"),
                color: ThemeColorEnum.Primary,
                disabled: isPosting,
                onClick: () => navigate(QuoteNavigation.QuoteEntryURL(oKey)),
            };
            actionButtons.Set(1, cancelButton);
        }

    }, [oKey, actionButtons, quoteActions, tm, editEnabled, isPosting, handleSaveClick, navigate]);

    const optionPriceDialog = useCallback(() => {

        if (editingOption !== -1 && editEnabled) {
            return <>
                <Dialog
                    open={editingOption !== -1}
                    onClose={optionPriceChangeCancel}
                >
                    <DialogTitle>{tm.Get("Edit Option Price")} - {optionString(optionsData[editingOption])}</DialogTitle>
                    <EditOptionPriceDialog
                        initialValue={cf.Format(optionsData[editingOption].price)}
                        item={item}
                        optionCode={optionsData[editingOption].code}
                        optionGroup={optionsData[editingOption].group}
                        setIsPosting={setIsPosting}
                        onCancel={optionPriceChangeCancel}
                        onSubmit={optionPriceChangeSubmit}
                    />
                </Dialog>
            </>;
        }

    }, [editingOption, editEnabled, optionsData, tm, cf, item, optionString, optionPriceChangeSubmit])

    if (!odKey) {
        return <NotFound />;
    }
    if (quote && (
        (user.parentCustomerID === quote?.customerID && !user.HasPermission(UserPermissionTypes.ViewCost)) ||
        (user.parentCustomerID !== quote?.customerID && !user.HasPermission(UserPermissionTypes.ViewPricing)))
    ) {
        // Insufficient Permissions
        navigate(QuoteNavigation.QuoteEntryURL(oKey));
        return null;
    }

    let props: ILineItemPriceBreakdownProps = {
        qty,
        orderedQty,
        qtyOverride,
        item,
        lineItems,
        size,
        partPrice,
        partPriceOverride,
        currentQty,
        currentPartPrice,
        discountTable: (user.HasRole(RoleEnum.Contractor) && user.parentCustomerID == quote?.customerID) ? "" : discountTable,
        optionsData,
        itemWeight,
        itemCost,
        itemSqFtPrice,
        itemPrice,
        overallWeight,
        overallCost,
        overallSqFtPrice,
        overallPrice,
        currentOverallSqFtPrice,
        currentOverallPrice,
        editEnabled,
        viewCost,
        anyPriceOverridden,
        subLineQuantitiesCanChange,
        qtyErrorMessage,
        partPriceErrorMessage,
        overallSqFtPriceErrorMessage,
        overallPriceErrorMessage,
        setEditingOption,
        setIsPosting,
        setQtyErrorMessage,
        setPartPriceErrorMessage,
        setOverallSqFtPriceErrorMessage,
        setOverallPriceErrorMessage,
        handleItemChange,
        handleQtyChange,
        handlePartPriceChange,
        handleOverallSqFtPriceChange,
        handleOverallPriceChange,
        handleRepriceClick,
        setData,
        cf
    }

    return <>
        {quote && lineItems.length > 0 &&
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" p={1} mt={1} gap={1}>
                    <PBHeader {...props} />
                    <PBOptionsGrid {...props} />
                    <PBPricing {...props} />
                </Box>
            </Container>
        }
        {optionPriceDialog()}
    </>
}

export default LineItemPriceBreakdown;