import { Checkbox } from "@mui/material";

import { IGlobalOptions } from "components/GlobalOptions/GlobalOptionsContext";
import { IGlobalItem } from "helpers/interfaces";
import ResultColumn from "components/GlobalOptions/Results/Columns/ResultColumn";

export default class SelectResultColumn extends ResultColumn {

    isSelected: (globalItem: IGlobalItem) => boolean;
    onSelect: (globalItem: IGlobalItem, selected: boolean) => void;

    public constructor(columnName: string, headerText: string, width: string, align: "inherit" | "left" | "center" | "right" | "justify" | undefined,
        isSelected: (globalItem: IGlobalItem) => boolean,
        onSelect: (globalItem: IGlobalItem, selected: boolean) => void) {

        super(columnName, headerText, width);
        this.align = align;
        this.isSelected = isSelected;
        this.onSelect = onSelect;
    }
    public CellContent(globalOptions: IGlobalOptions, globalItem: IGlobalItem, previousItem?: IGlobalItem): JSX.Element {

        if ((previousItem && globalItem.lineItem === previousItem.lineItem) || globalItem.subLineItem !== 0) {
            return <></>;
        }

        return <Checkbox
            checked={this.isSelected(globalItem)}
            onChange={(e) => this.onSelect(globalItem, e.target.checked)}
        />;
    }

}
