import LineItemColumn, { IQuoteLevelProps } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import LineItemUnitPrice from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemUnitPrice";
import QuoteTotalLabels from "components/Quotes/QuoteEntry/LineItems/Fields/QuoteTotalLabels";

import { ILineItem } from "helpers/interfaces";

export interface ILineItemUnitPriceEvents {
    onSurchargeClick: () => void;
    onTaxClick: () => void;
}

export default class LineItemUnitPriceColumn extends LineItemColumn {

    events: ILineItemUnitPriceEvents;
    showSqFtPrice: boolean;
    showTotalLabels: boolean;

    public constructor(columnName: string, headerText: string, width: string, showSqFtPrice: boolean, showTotalLabels: boolean, events: ILineItemUnitPriceEvents) {
        super(columnName, headerText, width);
        this.align = "right";
        this.events = events;
        this.showSqFtPrice = showSqFtPrice;
        this.showTotalLabels = showTotalLabels;
    }

    public CellContent(quoteProps: IQuoteLevelProps, lineItem: ILineItem): JSX.Element {

        return <LineItemUnitPrice
            lineItem={lineItem}
            variant="body2"
            showQuantity={false}
            showSqFtPrice={this.showSqFtPrice}
            isOrder={false}
        />;
    }

    public FooterContent({ lineItemGridProps }: IQuoteLevelProps): JSX.Element {
        if (this.showTotalLabels) {
            return <QuoteTotalLabels
                variant="body2"
                onSurchargeClick={this.events.onSurchargeClick}
                onTaxClick={this.events.onTaxClick}
            />;
        }
        return <></>;
    }

}

