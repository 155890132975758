import { IconButton, Tooltip } from "@mui/material";

import PrintReport from "components/Orders/OrderView/Header/PrintReport";
import { IOrder } from "helpers/interfaces";
import { useTranslations } from "@fenetech/translations";
import { Link } from "@mui/icons-material";

interface IProps {
    order?: IOrder,
    orderHasRelatedLinks: boolean,
    setRelatedLinksDialogOpen: (value: boolean) => void
}

function OrderHeaderActions({ order, orderHasRelatedLinks, setRelatedLinksDialogOpen }: IProps) {

    const tm = useTranslations();

    return <>
        {orderHasRelatedLinks &&
            <Tooltip title={tm.Get("Links")}>
                <IconButton aria-label="related links" sx={{ padding: 0 }} onClick={() => setRelatedLinksDialogOpen(true)}>
                    <Link color="primary" />
                </IconButton>
            </Tooltip>
        }
        {order && <>
            <PrintReport order={order} />
        </>}
    </>;
};

export default OrderHeaderActions;
