
import React from 'react';

import { IDiscountTableGrouping } from 'helpers/interfaces';
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useDiscountTables(parentCustomerID: string): IDiscountTableGrouping[] | undefined {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const discountTables = selectionValues.discountTables;

    React.useEffect(() => {

        actions.LoadDiscountTablesAsync(parentCustomerID);

    }, [actions, parentCustomerID]);

    return discountTables;

};

export default useDiscountTables;
