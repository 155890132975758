import { Box, Skeleton } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Constants from "helpers/constants";

interface IProps {
    imageSrc: string | null,
    isLoading: boolean
}

const CompanyLogoImage: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const imageIconSize: number = 50;

    if (props.isLoading) {
        return <Skeleton variant="rectangular" sx={{width: Constants.Dimensions.CompanyLogoWidth, height: Constants.Dimensions.CompanyLogoHeight}} />
    } else {
        return (props.imageSrc !== null ?
            <Box
                component="img"
                src={props.imageSrc}
                alt={tm.Get("Logo")}
                width={Constants.Dimensions.CompanyLogoWidth}
                height={Constants.Dimensions.CompanyLogoHeight}
            /> :
            <Box
                display="flex"
                width={Constants.Dimensions.CompanyLogoWidth}
                height={Constants.Dimensions.CompanyLogoHeight}
                alignItems="center"
                justifyContent="center"
            >
                <InsertPhotoIcon sx={{fontSize: imageIconSize}} />
            </Box>
        )
    }

    
}

export default CompanyLogoImage;