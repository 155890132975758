import { useEffect } from 'react';

import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useShipVias(mfgCustomerID: string) {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const shipVias = selectionValues.shipVias;

    const isExpired = false;

    const cacheKey = `${mfgCustomerID ?? ""}`;
    
    useEffect(() => {
        
        if (mfgCustomerID) {
            if (!shipVias.has(cacheKey) || isExpired)
            {
                actions.LoadShipViasAsync(mfgCustomerID, cacheKey);
            }
        }
        
    }, [actions, mfgCustomerID, shipVias, isExpired, cacheKey]);

    return shipVias.get(cacheKey);
}

export default useShipVias;

