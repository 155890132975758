import React from "react";
import { Divider, Drawer, Grid, IconButton, ListItem, ListItemText, List, AppBar, ListItemButton } from '@mui/material';
import useUserInfo from 'helpers/context/User/useUserInfo';
import { useTranslations } from "@fenetech/translations";
import { StateSetter } from "helpers/types";
import { Close, Logout, Settings, Palette } from "@mui/icons-material";
import UserAvatar from "./UserAvatar";
import useFeneVisionTheme from "helpers/context/Theme/useFeneVisionTheme";
import { useNavigate } from "react-router-dom";
import { RoleEnum } from "helpers/enums";


interface IProps {
    menuOpen: boolean;
    setMenuOpen: StateSetter<boolean>;
    signOut: () => void;
    navigateToProfile: () => void;
    navigateToPreferences: () => void;
    openThemeSelector: () => void;
    anchorElement: HTMLElement | null;
}

const UserMenuMobile: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const userInfo = useUserInfo();
    const navigate = useNavigate();
    const [fvThemeContext] = useFeneVisionTheme();

    const avatarWithUsername = () => {
        return <Grid container direction="row" alignItems="center" spacing={1} paddingBottom="6px">
            <Grid item>
                <UserAvatar />
            </Grid>
            <Grid item>
                {userInfo.DisplayUserName()}
            </Grid>
        </Grid>
    }

    return <>
        <Drawer transitionDuration={0}
            anchor="top"
            open={props.menuOpen}
            onClose={() => props.setMenuOpen(false)}
        >
            <AppBar sx={{ position: "sticky" }}>
                <Grid
                    container direction="row"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="flex-start"
                    className="header">

                    <Grid item className="logo">
                        <img src={`images/${fvThemeContext.selectedThemeInfo?.logoFileName}`} alt="Logo" style={{ cursor: "pointer" }} onClick={(() => navigate("/dashboard"))} />
                    </Grid>

                    <Grid item alignSelf="center" sx={{ width: 40, height: 40 }} >
                        <IconButton onClick={() => props.setMenuOpen(false)} >
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </AppBar>

            <List dense={true}>

                {userInfo.HasRole(RoleEnum.CSR) ?
                    <ListItem>
                        {avatarWithUsername()}
                    </ListItem>
                    :
                    <ListItemButton onClick={props.navigateToProfile}>
                        {avatarWithUsername()}
                    </ListItemButton>
                }
                <Divider />

                {fvThemeContext.themeInfos.length > 1 &&
                    <ListItem button alignItems="center" onClick={props.openThemeSelector}>
                        <ListItemText>{tm.Get("Theme")}</ListItemText>
                        <Palette />
                    </ListItem>
                }

                {!userInfo.HasRole(RoleEnum.CSR) &&
                    <ListItem button alignItems="center" onClick={props.navigateToPreferences}>
                        <ListItemText  >{tm.Get("Preferences")}</ListItemText>
                        <Settings />
                    </ListItem>
                }

                <ListItem button alignItems="center" onClick={props.signOut}>
                    <ListItemText>{tm.Get("Sign Out")}</ListItemText>
                    <Logout />
                </ListItem>
            </List>

        </Drawer>
    </>;
}

export default UserMenuMobile;