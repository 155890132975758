import jsSHA from "jssha";
import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Container, Stack, Box, alpha, useTheme } from "@mui/material";

import APIHelper from "helpers/APIHelper";
import { IShapeEditorInfo, IShapeDisplayInfo } from "helpers/interfaces";
import useIsMobile from "helpers/hooks/useIsMobile";
import Format, { ImperialFormatModeEnum } from "helpers/fv.format";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import useWait from "helpers/context/Page/useWait";
import ShapeAPI from "components/OptionsWizard/ShapeAPI";
import useWizardState, { useWizardStateActions } from "components/OptionsWizard/WebDesigner/useWizardState";
import ShapeDropdown from "./ShapeDropdown";
import ShapeEditorParams from "./ShapeEditorParams";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";

const ShapeEditor: React.FC<any> = () => {
    const isMobile = useIsMobile();
    const wizardState = useWizardState();
    const wizardActions = useWizardStateActions();
    const wait = useWait();
    const formatMethods = useFormatHelper();
    const theme = useTheme();
    const lnf = useLocaleNumberFormatter();

    const [loading, setLoading] = useState<boolean>(true);

    const [shapeEditor, setShapeEditor] = useState<IShapeEditorInfo | null>(null);
    const [displayShapeParamValues, setDisplayShapeParamValues] = useState<string[]>([]);

    const formatDimension = useCallback((value: number | null) => {
        return value === null ? "" : formatMethods.formatDimensionText(value, wizardState.itemInfo?.unitSet ?? 0, ImperialFormatModeEnum.SHOW_FRACTIONS, true);
    }, [formatMethods, wizardState]);

    useEffect(() => {
        if (shapeEditor === null) {
            wait.Show(true);
            setLoading(true);
            ShapeAPI.GetEditor().then((se) => {
                setShapeEditor(se);
                wizardActions?.SetShapeEditorCanSave(se.canSave);
                setDisplayShapeParamValues(se.shapeParameters.map((sp) => formatDimension(sp.value)));
                setLoading(false);
                wait.Show(false);
            });
        }
    }, [shapeEditor, wizardActions, wait, formatDimension]);

    const getHash = useCallback(() => {
        let jsonString: string = JSON.stringify({ shapeNum: shapeEditor?.selectedShapeNumber, ...shapeEditor?.shapeParameters });
        let objHash: any = new jsSHA("SHA-1", "TEXT");
        objHash.update(jsonString);
        return objHash.getHash("HEX") as string;
    }, [shapeEditor]);

    const getImageSource = useCallback(() => {
        let url = new URL(`${APIHelper.GetBaseURL()}api/Images/ShapeEditorImage`);

        let params = {
            imageHash: getHash()
        };

        url.search = new URLSearchParams(params as any).toString();
        return url.toString();
    }, [getHash]);

    const handleShapeChange = useCallback((event: any, data: IShapeDisplayInfo) => {
        if (!shapeEditor) return;
        let shapeNum = data.shapeNumber;
        if (shapeNum !== shapeEditor.selectedShapeNumber) {
            wait.Show(true);
            setLoading(true);
            ShapeAPI.EditorChangeShape(shapeNum).then((se) => {
                setShapeEditor({
                    availableShapes: shapeEditor.availableShapes,
                    selectedShapeNumber: shapeNum,
                    shapeParameters: se.shapeParameters,
                    canSave: se.canSave,
                    validationErrorText: se.validationErrorText
                });
                wizardActions?.SetShapeEditorCanSave(se.canSave);
                setDisplayShapeParamValues(se.shapeParameters.map((sp) => formatDimension(sp.value)));
                setLoading(false);
                wait.Show(false);
            });
        }
    }, [shapeEditor, wizardActions, wait, formatDimension]);

    const handleTextboxChange = useCallback((index: number, value: string) => {
        let values = displayShapeParamValues.slice();
        values[index] = value;
        setDisplayShapeParamValues(values);
    }, [displayShapeParamValues]);

    const validateNumber = useCallback((value: string) => {
        let decValue = lnf.Parse(value);
        if (!isNaN(decValue)) {
            if (decValue >= 0) {
                return decValue;
            }
        } else {
            decValue = Format.fracToDec(value) ?? -1;
            if (decValue >= 0) {
                return decValue;
            }
        }
        return null;
    }, [lnf]);

    const handleTextboxBlur = useCallback((index: number, value: string) => {
        if (!shapeEditor) return;
        let values = displayShapeParamValues.slice();
        let origValue = shapeEditor.shapeParameters[index].value;
        let convertedValue = validateNumber(value);
        if (convertedValue !== null) {
            if (convertedValue === shapeEditor.shapeParameters[index].value) {
                values[index] = formatDimension(convertedValue);
                setDisplayShapeParamValues(values);
                return;
            };
            wait.Show(true);
            ShapeAPI.EditorChangeParam({
                name: shapeEditor.shapeParameters[index].name,
                booleanValueType: false,
                value: convertedValue
            }).then((se) => {
                setShapeEditor({
                    availableShapes: shapeEditor.availableShapes,
                    selectedShapeNumber: shapeEditor.selectedShapeNumber,
                    shapeParameters: se.shapeParameters,
                    canSave: se.canSave,
                    validationErrorText: se.validationErrorText
                });
                wizardActions?.SetShapeEditorCanSave(se.canSave);
                setDisplayShapeParamValues(se.shapeParameters.map((sp) => formatDimension(sp.value)));
                wait.Show(false);
            });
        } else {
            values[index] = formatDimension(origValue);
            setDisplayShapeParamValues(values);
        }
    }, [shapeEditor, displayShapeParamValues, wizardActions, wait, validateNumber, formatDimension]);

    const handleCheckboxChange = useCallback((index: number, checked: boolean) => {
        if (!shapeEditor) return;
        let resultValue: number = checked ? 1 : 0;
        wait.Show(true);
        ShapeAPI.EditorChangeParam({
            name: shapeEditor.shapeParameters[index].name,
            booleanValueType: true,
            value: resultValue
        }).then((se) => {
            setShapeEditor({
                availableShapes: shapeEditor.availableShapes,
                selectedShapeNumber: shapeEditor.selectedShapeNumber,
                shapeParameters: se.shapeParameters,
                canSave: se.canSave,
                validationErrorText: se.validationErrorText
            });
            wizardActions?.SetShapeEditorCanSave(se.canSave);
            setDisplayShapeParamValues(se.shapeParameters.map((sp) => formatDimension(sp.value)));
            wait.Show(false);
        });
    }, [shapeEditor, wizardActions, wait, formatDimension]);

    return <>
        {shapeEditor &&
            <Container maxWidth="lg">
                <Box p={1} mt={1} gap={1}>
                    <Stack direction="column" justifyItems="stretch" spacing={2}>
                        <ShapeDropdown
                            shapeEditor={shapeEditor}
                            handleShapeChange={handleShapeChange}
                        />

                        <Stack direction={isMobile ? "column" : "row"}>
                            <ShapeEditorParams
                                shapeEditor={shapeEditor}
                                loading={loading}
                                displayShapeParamValues={displayShapeParamValues}
                                handleCheckboxChange={handleCheckboxChange}
                                handleTextboxChange={handleTextboxChange}
                                handleTextboxBlur={handleTextboxBlur}
                            />
                            <Box position="relative">
                                <img style={isMobile ? { width: "100%", maxWidth: "600px", maxHeight: "600px" } : { minWidth: "600px", minHeight: "600px" }} src={getImageSource()} alt="" />

                                {shapeEditor.validationErrorText !== "" &&
                                    <Box position="absolute" display="table" style={{ top: "0px", width: "100%", height: "100%" }}>
                                        <Typography textAlign="center" display="table-cell" color="error" style={{
                                            verticalAlign: "middle",
                                            fontWeight: "bold",
                                            backgroundColor: alpha(theme.palette.background.default, 0.6)
                                        }}>
                                            {shapeEditor.validationErrorText}
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Container>
        }
    </>

};

export default ShapeEditor;