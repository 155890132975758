import { useCallback, useMemo, useState } from "react";
import { IProductNavigatorNode, IProductNavigatorPartFilter, IProductNavigatorPartFilterPart } from "models/IProductNavigator"
import { useTranslations } from "@fenetech/translations";
import useWait from "helpers/context/Page/useWait";
import useIsMobile from "helpers/hooks/useIsMobile";
import ProductNavigatorAPI from "./ProductNavigatorAPI";
import { Autocomplete, Button, Grid, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { GridCallbackDetails, GridRenderCellParams, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import { ThemeColorEnum } from "helpers/enums";
import useQuoteActions from "components/Quotes/useQuoteActions";
import { Add, Delete } from "@mui/icons-material";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import useQuoteData from "components/Quotes/useQuoteData";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";

interface IProps {
    node: IProductNavigatorNode
}

function ProductNavigatorPartFilters({ node }: IProps) {
    const tm = useTranslations();
    const wait = useWait();
    const isMobile = useIsMobile();
    const theme = useTheme();
    const quoteActions = useQuoteActions();
    const quoteData = useQuoteData();
    const currencyFormatter = useCurrencyFormatter(quoteData.quote!.currencyCulture);
    const filterRowsPerPage = useRowsPerPage("ProductNavigatorPartFiltersFilterParts");
    const selectionsRowsPerPage = useRowsPerPage("ProductNavigatorPartFiltersSelections");

    const [partFilters, setPartFilters] = useState<IProductNavigatorPartFilter[]>([]);
    const [filterParts, setFilterParts] = useState<IProductNavigatorPartFilterPart[]>([]);
    const [partsToAddToQuote, setPartsToAddToQuote] = useState<IProductNavigatorPartFilterPart[]>([]);
    const [searchExecuted, setSearchExecuted] = useState<boolean>(false);

    const [noneString] = useState<string>("{None}");
    const [noneTranslated] = useState<string>(tm.Get(noneString));

    useEffectOnLoad(() => {
        if (node.filterID) {
            wait.Show(true)
            ProductNavigatorAPI.PartFilters(node.filterID).then((pf) => {
                setPartFilters(pf)
                wait.Show(false)
            })
        }
    });

    const getNextVisiblePartFilter = useCallback((sequence: number) => {
        return partFilters.sort(pf => pf.sequence).find(pf => pf.sequence > sequence && pf.visible);
    }, [partFilters]);

    const getSelectedFilterValues = useCallback((filters: IProductNavigatorPartFilter[], lastUpdatedFilter: IProductNavigatorPartFilter) =>{
        //The filter values we need are the ones that have a selectedValue and any hidden filter immediately following the last selected filter.
        let selectedValues: IProductNavigatorPartFilter[] = [];
        let lastFilterFound = false;

        filters.sort(pf => pf.sequence).forEach(pf => {
            if (!lastFilterFound){
                if(pf.sequence <= lastUpdatedFilter.sequence || (pf.sequence > lastUpdatedFilter.sequence && !pf.visible) ){
                    selectedValues.push({...pf, availableValues: []}); //Clear out the available values to limit amount of data being sent.
                }
                else{
                    lastFilterFound = true;
                }
            }
        });

        return selectedValues;
        
    }, []);

    const getFilterValueOptions = useCallback((filters: IProductNavigatorPartFilter[], lastUpdatedFilter: IProductNavigatorPartFilter) => {
        if (lastUpdatedFilter.selectedValue !== null) {
            wait.Show(true);

            const selectedValues: IProductNavigatorPartFilter[] = getSelectedFilterValues(filters, lastUpdatedFilter);

            ProductNavigatorAPI.PartFilterValues(filters[0].filterID, selectedValues).then((av) => {
                let filterToUpdate = getNextVisiblePartFilter(lastUpdatedFilter.sequence);
                if(filterToUpdate !== undefined){
                    setPartFilters(filters.map(filter => {
                        if (filter.sequence === filterToUpdate!.sequence) {
                            return { ...filter, availableValues: av };
                        } else {
                            return filter;
                        }
                    }));
                }
                wait.Show(false);
            })
        }
    }, [wait, getNextVisiblePartFilter, getSelectedFilterValues]);

    const handleFilterValueChange = useCallback((pf: IProductNavigatorPartFilter, filterValue: string|null) => {
        pf.selectedValue = filterValue;
        const updatedFilters: IProductNavigatorPartFilter[] = partFilters.map(filter => {
            if (filter.sequence === pf.sequence) {
                return { ...filter, selectedValue: filterValue };
            }
            else {
                if (filter.sequence >= pf.sequence) {
                    return { ...filter, selectedValue: null, availableValues: [] };
                }
                else {
                    return filter;
                }
            }
        });
        setPartFilters(updatedFilters!);
        if(filterValue !== null){
            getFilterValueOptions(updatedFilters, pf);
        }
    }, [partFilters, getFilterValueOptions]);

    const updatePartsToAddToQuoteQty = useCallback((partID: number, qty: number, validationErrorExists: boolean) => {
        setPartsToAddToQuote(partsToAddToQuote.map(p => {
            if (p.partID === partID) {
                return { ...p, qty: qty as number, validationErrorExists: validationErrorExists };
            }
            else {
                return p;
            }
        }));

        const filterPart = filterParts.find(p => p.partID === partID);
        if(filterPart?.availableQty !== null ){
            const updatedFilterParts = filterParts.map(p => {
                if (p.partID === partID) {
                    return { ...p, availableQty: Number(p.originalAvailableQty!) - Number(qty) }; //Set available qty to original qty minus qty added to quote.
                }
                return p;
            });
            setFilterParts(updatedFilterParts);
        } 

        quoteActions.UpdatePartFilterPartQty(partID, qty);
    }, [partsToAddToQuote, quoteActions, filterParts])

    const updateFilterPartQty = useCallback((partID: number, qty: number, validationErrorExists: boolean) => {
        setFilterParts(filterParts.map(p => {
            if (p.partID === partID) {
                return { ...p, qty: qty as number, validationErrorExists: validationErrorExists };
            }
            else {
                return p;
            }
        }))
    }, [filterParts]);

    const setAvailableQtys = useCallback((pfp: IProductNavigatorPartFilterPart[]) => {
        pfp.forEach(p => {
            const existingPartToAdd = partsToAddToQuote.find(fp => fp.partID === p.partID);
            if (existingPartToAdd) {
                p.availableQty = Number(p.availableQty!) - Number(existingPartToAdd.qty);
            }
        });
        setFilterParts(pfp);
    }, [partsToAddToQuote]);

    const getPartFilterParts = useCallback(() => {
        wait.Show(true);
        const selectedValues = partFilters.map(pf => { return {...pf, availableValues: []} });
        ProductNavigatorAPI.PartFilterParts(node.filterID!, selectedValues).then((pfp) => {
            if(pfp.length > 0 && pfp[0].availableQty !== null){
                setAvailableQtys(pfp);
            }
            else{
                setFilterParts(pfp);
            }
            wait.Show(false);
            setSearchExecuted(true);
        })
    }, [wait, partFilters, node.filterID, setAvailableQtys]);


    const addPartToQuote = useCallback((part: IProductNavigatorPartFilterPart) => {
        const existingPart = partsToAddToQuote.find(p => p.partID === part.partID);
        if (existingPart) {
            const updatedPartsToAddToQuote = partsToAddToQuote.map(p => {
                if (p.partID === part.partID) {
                    return { ...p, qty: Number(p.qty) + Number(part.qty) };
                }
                return p;
            });
            setPartsToAddToQuote(updatedPartsToAddToQuote);
        } else {
            setPartsToAddToQuote([...partsToAddToQuote, { ...part }]);
        }

        //If part is using availableQty column, need to subtract how many were added.
        if(part.availableQty !== null){
            const updatedFilterParts = filterParts.map(p => {
                if (p.partID === part.partID) {
                    return { ...p, availableQty: Number(p.availableQty!) - Number(part.qty) };
                }
                return p;
            });
            setFilterParts(updatedFilterParts);
        }

        quoteActions.AddPartFilterPart(part);

    }, [partsToAddToQuote, quoteActions, filterParts]);


    const removePartFromQuote = useCallback((part: IProductNavigatorPartFilterPart) => {
        setPartsToAddToQuote(partsToAddToQuote.filter(fp => fp.partID !== part.partID));

        //If part is using availableQty column, need to reset it back to original available qty.
        if(part.availableQty !== null){
            const updatedFilterParts = filterParts.map(p => {
                if (p.partID === part.partID) {
                    return { ...p, availableQty: p.originalAvailableQty };
                }
                return p;
            });
            setFilterParts(updatedFilterParts);
        }

        quoteActions.RemovePartFilterPart(part);
    }, [partsToAddToQuote, quoteActions, filterParts]);

    const getRowID: GridRowIdGetter = (row: GridRowModel) => {
        return row.partID;
    }

    const filterPartsColumnGenerator = useMemo(() => {
        const renderAddPartCell = (params: GridRenderCellParams) => {
            return <>
                {((params.row.availableQty !== null && params.row.availableQty > 0) || params.row.availableQty === null) &&
                <IconButton color={ThemeColorEnum.Primary} onClick={() => addPartToQuote(params.row)} disabled={params.row.validationErrorExists} >
                    <Add />
                </IconButton>
                }
            </>;
        };

        const filterPartsGenerator = new DataGridColumnGenerator(tm, filterParts, theme, isMobile, currencyFormatter);

        filterPartsGenerator.TryAddTextColumn("partNo", "Part No", { hideInMobile: false, flex: 5 });
        filterPartsGenerator.TryAddTextColumn("formattedDescription", "Description", { hideInMobile: false, flex: 10 });
        filterPartsGenerator.TryAddPriceColumn("price", "Price", quoteData.quote!.currencyCulture, { hideInMobile: false, flex: 2, hidden: !filterParts.some(p => p.price)});
        filterPartsGenerator.TryAddTextColumn("availableQty", "Available Qty", { hideInMobile: false, flex: 2, align:"right", hidden: !filterParts.some(p => p.availableQty !== null)});
        filterPartsGenerator.TryAddTextColumn("notes", "Notes", { hideInMobile: false, flex: 4, hidden: !filterParts.some(p => p.notes)});
        filterPartsGenerator.TryAddQtyColumn("qty", "Qty", { hideInMobile: false, flex:1 }, updateFilterPartQty);
        filterPartsGenerator.TryAddRenderCellColumn("partID", "Actions", renderAddPartCell, { hideInMobile: false, flex: 1 });


        return filterPartsGenerator;
    }, [filterParts, isMobile, tm, theme, updateFilterPartQty, addPartToQuote, currencyFormatter, quoteData.quote]);

    const quotePartsColumnGenerator = useMemo(() => {

        const renderRemovePartCell = (params: GridRenderCellParams) => {
            return <>
                <IconButton color={ThemeColorEnum.Primary} onClick={() => removePartFromQuote(params.row)}>
                    <Delete />
                </IconButton>
            </>;
        };

        const quotePartsGenerator = new DataGridColumnGenerator(tm, partsToAddToQuote, theme, isMobile, currencyFormatter);

        quotePartsGenerator.TryAddQtyColumn("qty", "Qty", { hideInMobile: false }, updatePartsToAddToQuoteQty);
        quotePartsGenerator.TryAddTextColumn("partNo", "Part No", { hideInMobile: false, flex: 2 });
        quotePartsGenerator.TryAddTextColumn("formattedDescription", "Description", { hideInMobile: false, flex: 6 });
        quotePartsGenerator.TryAddPriceColumn("price", "Price", quoteData.quote!.currencyCulture, { hideInMobile: false, flex: 2, hidden: !filterParts.some(p => p.price)});
        quotePartsGenerator.TryAddTextColumn("notes", "Notes", { hideInMobile: false, flex: 4, hidden: !filterParts.some(p => p.notes)});
        quotePartsGenerator.TryAddRenderCellColumn("partID", "Actions", renderRemovePartCell, { hideInMobile: false, flex: 1 });

        return quotePartsGenerator;
    }, [partsToAddToQuote, isMobile, tm, theme, updatePartsToAddToQuoteQty, removePartFromQuote, quoteData.quote, currencyFormatter, filterParts]);

    const onFilterPartsPageSizeChange = useCallback((pageSize: number, details: GridCallbackDetails) => 
    {
        filterRowsPerPage.setPageSize(pageSize)
    }, [filterRowsPerPage]);

    const onSelectionsPageSizeChange = useCallback((pageSize: number, details: GridCallbackDetails) => 
    {
        selectionsRowsPerPage.setPageSize(pageSize);
    }, [selectionsRowsPerPage]);
    

    return <>
        <Grid container spacing={2} style={{ marginTop: 5 }} >
            {partFilters?.map((pf) => {
                if (!pf.visible) return null;
                return <Grid item xs style={{ flex: 2 }} key={pf.description}>
                    <Autocomplete
                        fullWidth
                        autoComplete
                        autoSelect
                        autoHighlight
                        selectOnFocus
                        size="small"
                        options={pf.availableValues}
                        getOptionLabel={(option) => option === "" ? noneTranslated : option}
                        value={pf.selectedValue === "" ? noneTranslated : pf.selectedValue}
                        isOptionEqualToValue={(option, value) => option === value || (option === "" && value === noneTranslated) || (option === noneTranslated && value === "")}
                        disabled={pf.availableValues.length < 1}
                        onChange={(e, v) => handleFilterValueChange(pf, v)}
                        renderInput={(params) => <TextField {...params} label={pf.description} />}
                    />
                </Grid>
            })}
            {(partFilters?.length ?? 0) > 0 &&
                <Grid item xs>
                    <Button fullWidth style={{ flex: 1, marginTop: 3 }} variant="contained" color="secondary" disabled={partFilters.some(pf => pf.visible && pf.selectedValue === null)} onClick={() => getPartFilterParts()}>{tm.Get("Search")}</Button>
                </Grid>
            }
        </Grid>

        {searchExecuted &&
            <>
                <Typography sx={{ marginTop: 2 }} variant="h5">{tm.Get("Search Results")}</Typography>
                <CustomDataGridPro
                    rows={filterParts}
                    columns={filterPartsColumnGenerator.GetColumns()}
                    getRowId={getRowID}
                    onPageSizeChange={onFilterPartsPageSizeChange}
                    pageSize={filterRowsPerPage.pageSize}
                    rowsPerPageOptions={filterRowsPerPage.pageSizeOptions}
                />
            </>
        }

        {searchExecuted &&
            <>
                <Typography sx={{ marginTop: 2 }} variant="h5">{tm.Get("Selections")}</Typography>
                <CustomDataGridPro
                    rows={partsToAddToQuote}
                    columns={quotePartsColumnGenerator.GetColumns()}
                    getRowId={getRowID}
                    onPageSizeChange={onSelectionsPageSizeChange}
                    pageSize={selectionsRowsPerPage.pageSize}
                    rowsPerPageOptions={selectionsRowsPerPage.pageSizeOptions}
                />
            </>
        }

    </>
}


export default ProductNavigatorPartFilters;