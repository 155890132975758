import React, { useEffect } from "react";
import { createTheme, responsiveFontSizes, Theme, ThemeOptions } from "@mui/material";

import { IThemeInfo } from "helpers/interfaces";

export interface IThemeContext {

    themeInfos: IThemeInfo[],
    theme?: Theme,
    selectedThemeInfo?: IThemeInfo,
}

const defaultThemeOptions = {
    palette: {
        background: {
            paper: "#f2f2f2",
        },
        primary: {
            main: "#a11",
        },
        secondary: {
            main: "#3C3C3C"
        },
        tonalOffset: 0.3, //Used to auto-calc light/dark variants of main colors
    }
} as ThemeOptions;

const defaultThemeInfo: IThemeInfo = {
    themeName: "default",
    logoFileName: "header_logo.png",
    themeOptions: defaultThemeOptions,
};

const defaultThemeContext: IThemeContext = {
    themeInfos: [],
    theme: undefined,
    selectedThemeInfo: undefined,
};

/*Define Reducer and Actions*/
export type FeneVisionThemeAction =
    | { type: 'setThemeInfos', value: IThemeInfo[] }
    | { type: 'setSelectedThemeName', value: string }

const feneVisionThemeReducer: React.Reducer<IThemeContext, FeneVisionThemeAction> = (themeContext: IThemeContext, action) => {
    if (action.type === 'setThemeInfos') {
        return { ...themeContext, themeInfos: action.value };
    }
    else if (action.type === 'setSelectedThemeName') {
        let themeInfo = themeContext.themeInfos.find((ti) => ti.themeName === action.value);
        if (!themeInfo) {
            themeInfo = themeContext.themeInfos[0] ?? defaultThemeInfo;
        }
        const theme = responsiveFontSizes(createTheme(themeInfo.themeOptions))
        return { ...themeContext, selectedThemeInfo: themeInfo, theme: theme };
    }
    return themeContext;
}

export class FeneVisionThemeActions {

    #dispatcher: React.Dispatch<FeneVisionThemeAction>;

    constructor(dispatcher: React.Dispatch<FeneVisionThemeAction>) {
        this.#dispatcher = dispatcher;
    }

    public async LoadThemes() {
        
        try {
            //Themes come from the "theme.js" file in the unbuilt portion of the site (public).
            //It shows up as a global variable, defined in global.d.ts, accessed through the window object.
            //This lets users maniuplate the theme settings after the site has been built and deployed.
            const themeInfos = window.themes;
            const themePreference = localStorage.getItem("THEME");
            this.SetThemeInfos(themeInfos);
            this.SetSelectedTheme(themePreference ?? "");
        }
        catch {
            this.SetThemeInfos([defaultThemeInfo]);
            this.SetSelectedTheme(defaultThemeInfo.themeName);
        }
    }

    public SetThemeInfos(themeInfos: IThemeInfo[]) {
        this.#dispatcher({ type: "setThemeInfos", value: themeInfos });
    }

    public SetSelectedTheme(themeName: string) {
        this.#dispatcher({ type: "setSelectedThemeName", value: themeName });
    }

}

export const FeneVisionThemeDispatchContext = React.createContext<FeneVisionThemeActions | undefined>(undefined);
export const FeneVisionThemeContext = React.createContext<IThemeContext>(defaultThemeContext);

export const FeneVisionThemeContextProvider: React.FC<React.PropsWithChildren<any>> = (props) => {

    const [state, dispatch] = React.useReducer(feneVisionThemeReducer, defaultThemeContext);

    const actions = React.useMemo<FeneVisionThemeActions>(() => {
        return new FeneVisionThemeActions(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (actions) {
            actions.LoadThemes();
        }
    }, [actions]);

    return <FeneVisionThemeContext.Provider value={state}>
        <FeneVisionThemeDispatchContext.Provider value={actions}>
            {props.children}
        </FeneVisionThemeDispatchContext.Provider>
    </FeneVisionThemeContext.Provider>

}



