import React from "react";
import { ApplicationContext } from 'helpers/context/Application/ApplicationContext';

export const ApplicationContextValueAssurance: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    // Children of this component will not be rendered until the application context value is defined
    return <>
        <ApplicationContext.Consumer>
            {appInfo => appInfo && props.children}
        </ApplicationContext.Consumer>
    </>;
    
}

export default ApplicationContextValueAssurance;