import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { useFormContext, useController, Validate } from "react-hook-form";
import LabeledText from "./LabeledText";

interface IProps {
    fieldName: string,
    readOnly: boolean,
    label: string,
    requiredRule?: { value: boolean, message: string },
    validateValue?: Validate<any> | Record<string, Validate<any>>
    showPercentAdornment?: boolean,
}

type TextFieldFormProps = TextFieldProps & IProps;

const TextFieldForm = ({ fieldName, readOnly, label, requiredRule, validateValue, onChange, onBlur, showPercentAdornment, ...rest }: TextFieldFormProps) => {

    const { control, formState: { errors } } = useFormContext();
    const { field } = useController({
        name: fieldName,
        control: control,
        rules: {
            required: { value: requiredRule?.value ?? false, message: requiredRule?.message ?? "" },
            validate: validateValue
        },
    });

    function handleOnChange(e: any) {
        field.onChange(e);
        if (onChange) onChange(e);
    }

    function handleOnBlur(e: any) {
        field.onBlur();
        if (onBlur) onBlur(e);
    }

    const textFieldEndAdornment = React.useMemo(() => {
        if (showPercentAdornment) {
            return <InputAdornment position="end">%</InputAdornment>
        }
        return undefined;        
    }, [showPercentAdornment]);

    const labelFieldText = React.useMemo(() => {
        if (field.value && showPercentAdornment) {
            return `${field.value} %`;
        }
        return field.value ?? "";        
    }, [showPercentAdornment, field.value]);    

    if (readOnly) {
        return <LabeledText
            label={label}
            text={labelFieldText}
            showSkeletonWhenEmpty={true}
        />
    }

    return <>
        <TextField
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            ref={field.ref}
            inputRef={field.ref}
            value={field.value ?? ""}
            error={errors[fieldName]?.message ? true : false}
            helperText={errors[fieldName]?.message}
            label={label}
            InputProps={{ endAdornment: textFieldEndAdornment, ...rest.InputProps }}
            {...rest}
        />
    </>
}

export default TextFieldForm;