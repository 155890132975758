import { useEffect } from 'react';

import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useEmployee(employeeID: number | undefined) {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const employees = selectionValues.employees;

    const isExpired = false;

    useEffect(() => {

        if (employeeID !== undefined) {
            if (!employees.has(employeeID) || isExpired) {
                actions.LoadEmployeeAsync(employeeID);
            }
        }

    }, [actions, employeeID, employees, isExpired]);

    return employeeID === undefined ? undefined : employees.get(employeeID);

}

export default useEmployee;

