import { useRef, useEffect } from "react";
import { TextField } from "@mui/material";

import { useTranslations } from "@fenetech/translations";
import Constants from "helpers/constants";

interface IProps {
    newValue: string,
    setNewValue: (newValue: string) => void,
}

const TextOptionValueInput: React.FC<IProps> = ({ newValue, setNewValue }: IProps) => {

    const tm = useTranslations();

    const valueInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (valueInput?.current) {
            valueInput.current.focus();
            valueInput.current.select();
        }
    }, [valueInput]);

    return <>
        <TextField
            label={tm.Get("Value")}
            variant="outlined"
            size="small"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            inputProps={{maxLength: Constants.MaxLength.OptionValue}}                                    
            inputRef={valueInput}
        />
    </>;
};

export default TextOptionValueInput;