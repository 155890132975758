import { Button } from "@mui/material"

import APIHelper from "helpers/APIHelper";
import { ILineItem } from "models/IQuote";

interface IProps {
    oKey: number,
    lineItem: ILineItem;
    imageAccessKey: number,
    showMediumSize: boolean,
    isOrder: boolean,
    onClick: (imageSource: string) => void,
}

const LineItemImage: React.FC<IProps> = ({ oKey, lineItem, imageAccessKey, showMediumSize, isOrder, onClick }: IProps) => {

    const getImageSource = (mediumImage: boolean) => {

        if (oKey && lineItem.odKey && imageAccessKey && lineItem.thumbnailImageHash) {
            let url = new URL(`${APIHelper.GetBaseURL()}api/Images/Thumbnails/${oKey}/${lineItem.odKey}`);

            let params = {
                mediumSize: mediumImage,
                accessKey: imageAccessKey,
                imageHash: lineItem.thumbnailImageHash,
                isOrder: isOrder,
            };

            url.search = new URLSearchParams(params as any).toString();

            return url.toString();
        }

        return "";
    };

    const getImageElement = (mediumSize: boolean): JSX.Element => {
        const imageSource = getImageSource(mediumSize);
        if (imageSource) {
            return <img width="100%" height="100%" src={imageSource} />
        }

        return <></>;
    };

    // Prevent any of the links in this section from acting as "submit"
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    const getImageOrButtonElement = () => {
        if (lineItem.thumbnailImageExists) {
            if (lineItem.mediumColorImageExists) {
                if (showMediumSize) {
                    return getImageElement(true)
                }
                else {
                    return <>
                        <Button onClick={() => onClick(getImageSource(true))}>
                            {getImageElement(false)}
                        </Button>
                    </>
                }

            }
            else {
                return getImageElement(false);
            }
        }
    }

    return <>
        <div style={{ minWidth: "64px", minHeight: "60px" }} onClick={preventDefault}>
            {getImageOrButtonElement()}
        </div>
    </>


}

export default LineItemImage;