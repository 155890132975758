import { IOrderLineItem } from "helpers/interfaces";
import LineItemPart from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemPart";
import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";

export default class LineItemPartColumn extends LineItemColumn {

    public constructor(columnName: string, headerText: string, width: string) {
        super(columnName, headerText, width);
        this.align="left";
    }

    public CellContent(props: IOrderLevelProps, lineItem: IOrderLineItem): JSX.Element {

        return <LineItemPart
            oKey={props.order.oKey}
            isReadOnly={true}
            lineItem={lineItem}
            includeItemNumber={false}
            variant="body2"
        />;
    }

    public FooterContent(props: IOrderLevelProps): JSX.Element {
        return <></>;
    }
}
