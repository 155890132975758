
import { ISearchParams, ISearchResult, SearchTypeEnum } from "./Search";
import APIHelper from "../../helpers/APIHelper";



export default class SearchAPI {

    public static async SubmitSearchAsync(params: ISearchParams): Promise<ISearchResult[]> {

        let query: string = `api/Search/${SearchTypeEnum[params.searchType]}`;

        const { searchType: SearchType, ...rest } = params;

        const response = await APIHelper.GetAsync<ISearchResult[]>(query, rest);

        return response.Result;



    }

}







