import React, { useMemo } from 'react';
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

import { useController, useFormContext } from 'react-hook-form';
import { useTranslations } from '@fenetech/translations';
import useShipVias from 'helpers/context/SelectionValues/useShipVias';

import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    parentCustomerID: string,
    permissions?: IQuotePermissions
    [x: string] : any  // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function SelectShipVia ({ parentCustomerID, permissions, ...rest }: IProps)
{

    const tm = useTranslations();
    const { control } = useFormContext();

    const shipViaList = useShipVias(parentCustomerID);

    const { field } = useController({
        name: "shipping.shipVia", 
        control,
        rules: { required: false },
      });

    const values = useMemo(() => {
        return shipViaList?.map(({description}) => description) ?? [];
    }, [shipViaList]);

    return (
        <Autocomplete 
            id="shipVia"
            autoComplete
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={values} 
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
                <TextField 
                    variant="outlined" 
                    {...params} 
                    label={tm.Get("Ship Via")}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                            {(shipViaList === undefined) && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
                )}
            onChange={(_, data) => {field.onChange(data)}}
            value={(field.value === "") ? null : field.value}
            ref={field.ref}
            disabled={!permissions?.shipVia ?? true}
            {...rest}
        /> 
    );

}

export default SelectShipVia;