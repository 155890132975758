import useOrderData from "components/Orders/useOrderData";
import { useTranslations } from "@fenetech/translations";

import { OrderTypesEnum } from "helpers/enums";
import { GetEnumAsListOfKeyValuePairs } from "helpers/objects";

export function useOrderTypeText() : string {

    const { order } = useOrderData();
    const tm = useTranslations();
    const orderTypeNames = GetEnumAsListOfKeyValuePairs(Object.values(OrderTypesEnum));

    return tm.Get(orderTypeNames.find(otn => otn.value === order?.orderType)?.key ?? "Order");
}

export default useOrderTypeText;