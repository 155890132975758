import React, { } from "react";

import QuotesAPI from "components/Quotes/QuotesAPI";
import { IQuote } from "helpers/interfaces";
import { DispatchActionsBase } from "components/Common/DispatchActionsBase";


export interface IAddQuoteData {
    quoteDefaults?: IQuote,
}

export enum AddQuoteActionTypeEnum {
    LoadQuoteDefaults,
}

export type AddQuoteActionType =
    { type: AddQuoteActionTypeEnum.LoadQuoteDefaults, value: IQuote };

const addQuoteReducer: React.Reducer<IAddQuoteData, AddQuoteActionType> = (data: IAddQuoteData, action) => {

    switch (action.type) {
        case AddQuoteActionTypeEnum.LoadQuoteDefaults:
            return { ...data, quoteDefaults: action.value };
    }
};

const initialState: IAddQuoteData = {
    quoteDefaults: undefined
};

export class AddQuoteActions extends DispatchActionsBase<AddQuoteActionType> {

    public async LoadQuoteDefaultsAsync(webCustomerGUID: string): Promise<void> {
        const response = await QuotesAPI.GetQuoteDefaults(webCustomerGUID);
        const quoteDefaults = response.Result;

        if (response.IsOk()) {
            this.dispatch({ type: AddQuoteActionTypeEnum.LoadQuoteDefaults, value: quoteDefaults });
        }
    }

    public async PostQuoteHeaderAsync(quoteHeader: IQuote): Promise<number> {
        const response = await QuotesAPI.PostQuoteHeader(quoteHeader);
        const newOKey = response.Result;
        return newOKey;
    }

}

export const AddQuoteContextDispatch = React.createContext<AddQuoteActions | undefined>(undefined);
export const AddQuoteContext = React.createContext<IAddQuoteData>(initialState);

export const AddQuoteContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(addQuoteReducer, initialState);

    const actions = React.useMemo<AddQuoteActions>(() => {
        return new AddQuoteActions(dispatch);
    }, [dispatch]);

    return <AddQuoteContext.Provider value={state}>
        <AddQuoteContextDispatch.Provider value={actions}>
            {props.children}
        </AddQuoteContextDispatch.Provider>
    </AddQuoteContext.Provider>
}

export default AddQuoteContext;
