import { useContext } from "react";

import { QuoteActions, QuoteContextDispatch } from "components/Quotes/QuoteContext";

export function useQuoteActions(): QuoteActions{

    const actions = useContext(QuoteContextDispatch)!;
    return actions;

};

export default useQuoteActions;
