import * as React from "react";

import QuestionHeader from "./QuestionHeader";
import NonTiledSingleOption from "./NonTiledSingleOption";

import { IQuestion, ICode } from "../interfaces";
import { Grid } from "@mui/material";
import OptionGroupBox from "components/OptionsWizard/OptionsWizard/OptionGroupBox";


interface IQuestionProps {
    question: IQuestion,
    readOnlyMode: boolean,
}


const NonTiledMultiSelectQuestion: React.FC<IQuestionProps> = (props: IQuestionProps) => {

    const q: IQuestion = props.question;
    const c: ICode[] = q.codes;
    const selectedQuestions: boolean = c.some((c: ICode) => c.selected)

    if (q.locked && !selectedQuestions) {
        //The question is locked with nothing selected, hide the question
        return null
    }
    else {

        return <>

            <OptionGroupBox
                id="NTMSQ_GROUP"
                label={q.description}
                disabled={q.locked || props.readOnlyMode}
                startAdornment={<QuestionHeader question={q} showImageSelection={true} readOnlyMode={props.readOnlyMode} />}
            >
                <Grid container direction="column" item xs>
                    {q.codes.filter((c: ICode) => c.visible).map((c: ICode) =>
                        <Grid item xs key={c.sequence + "_" + c.code}>
                            <NonTiledSingleOption code={c} locked={q.locked} isWithinMultiSelect={true} readOnlyMode={props.readOnlyMode} />
                        </Grid>
                    )}
                </Grid>

            </OptionGroupBox>
        </>

    }
}

export default NonTiledMultiSelectQuestion;

