import * as React from "react";

import { IQuestion, IUserPreferences } from "../interfaces";

import NonTiledSingleSelectQuestion from "./NonTiledSingleSelectQuestion";
import NonTiledSingleOptionQuestion from "./NonTiledSingleOptionQuestion";
import NonTiledMultiSelectQuestion from "./NonTiledMultiSelectQuestion";
import SelectedCodeNestedQuestions from "./SelectedCodeNestedQuestions"
import TiledQuestion from "./TiledQuestion";

import { Stack } from "@mui/material";

interface IOWQuestionProps {
    question: IQuestion;
    preferences: IUserPreferences;
    readOnlyMode: boolean;
}


const QuestionComponentSelector: React.FC<IOWQuestionProps> = (props: IOWQuestionProps) => {


    let q: IQuestion = props.question;

    let questionComponent: React.ReactNode = null;

    //Trying to mimic what WPF option template selector is doing
    if (q.visible) {

        if (props.preferences.showTiled && q.supportsTiled) {
            questionComponent = <TiledQuestion question={q} readOnlyMode={props.readOnlyMode} />
        } else {
            if (q.multiSelect) {
                //Multi-Select
                questionComponent = <NonTiledMultiSelectQuestion question={q} readOnlyMode={props.readOnlyMode} />;
            } else if (q.question.length === 0 || q.codes.length === 1) {
                //Single option
                questionComponent = <NonTiledSingleOptionQuestion question={q} readOnlyMode={props.readOnlyMode} />;
            } else if (q.codes.length > 1) {
                //Single Select
                questionComponent = <NonTiledSingleSelectQuestion question={q} readOnlyMode={props.readOnlyMode} />;
            }
        }
    }


    //We take this branch even if not visible because a hidden question could have visible nested questions. Yes, that is a thing.
    return <Stack direction="column" spacing={1} id="question_container">
        {questionComponent}

        {q.visible &&
            <SelectedCodeNestedQuestions question={q} preferences={props.preferences} readOnlyMode={props.readOnlyMode} />
        }

        {q.nestedQuestions && q.nestedQuestions.map((q: IQuestion) =>
            <QuestionComponentSelector key={q.sequence + "_" + q.question} preferences={props.preferences} question={q} readOnlyMode={props.readOnlyMode} />
        )}

    </Stack>;
}

export default QuestionComponentSelector;