
import React from "react";
import { IDashboardQuote } from "helpers/interfaces";
import DashboardAPI from "../DashboardAPI";
import DashboardContext, { ActionTypeEnum, DashboardContextDispatch } from "../DashboardContext";


export default function useDashboardsubmittedQuoteData(): IDashboardQuote[]|undefined {

    const dashboardData = React.useContext(DashboardContext);
    const dispatch = React.useContext(DashboardContextDispatch);
    const QuoteData = dashboardData.submittedQuotes;

    const isExpired = (dashboardData.submittedQuotesExpTime < Date.now()  );

    React.useEffect(() => {

        if (dispatch) {
            if (!QuoteData || isExpired) {
                DashboardAPI.QuerySubmittedQuotes().then(o => {
                    dispatch({ type: ActionTypeEnum.SetSubmittedQuotes, value: o });
                });
            }
        }

    }, [dispatch, QuoteData, isExpired]);

    return QuoteData;

};