
import { Box, LinearProgress } from "@mui/material";
import React from 'react';

interface IProps {

}

const LoadingLinearProgress: React.FC<IProps> = (props: IProps) => {

    return <>
        <Box sx={{ width: "100%" }}>
            <LinearProgress />
        </Box>
    </>;
}

export default LoadingLinearProgress;