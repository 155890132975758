import { Grid, Stack } from "@mui/material";

import QuestionSelection from "./QuestionSelection";
import OldOptionSelection from "./OldOptionSelection";
import OldOptionValueSelection from "./OldOptionValueSelection";
import NewOptionSelection from "./NewOptionSelection";
import NewOptionValueSelection from "./NewOptionValueSelection";
import CommitSelections from "./CommitSelections";

const Selections: React.FC<any> = () => {

    return <>
        <Grid container gap={1}>
            <Grid item xs={12} sm={3}>
                <QuestionSelection />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Stack direction="column" gap={1}>
                    <OldOptionSelection />
                    <OldOptionValueSelection />
                </Stack>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Stack direction="column" gap={1}>
                    <NewOptionSelection />
                    <NewOptionValueSelection />
                </Stack>
            </Grid>
            <Grid item xs sm>
                <CommitSelections />
            </Grid>
        </Grid>
    </>;
};


export default Selections;
