import { Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface IState {
    hasError:boolean
}

interface IProps extends PropsWithChildren<any> {

}

class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props:IProps){
        super(props);
        this.state = {hasError:false};
    }

    static getDerivedStateFromError(){

        return {hasError:true};
    }

    componentDidCatch(error:any){
        console.log(error);
    }

    render() {
        if ( this.state.hasError){
            return <Typography variant="h1">Unknown error occured.</Typography>
        }

        return this.props.children;

    }

}

export default ErrorBoundary;