import * as React from "react";
import { } from "react-dom";

import { OptionSequenceTypeEnum } from "../enum";
import { IQuestionGroup } from "../models";
import { IQuestion, ICode, IUserPreferences } from "../interfaces";
import NonTiledStandAloneCode from "./NonTiledStandAloneCode";
import QuestionComponentSelector from "./QuestionComponentSelector";
import { Stack } from "@mui/material";

interface IOWQuestionsProps {
    questionGroup: IQuestionGroup;
    preferences: IUserPreferences;
    readOnlyMode: boolean;
}



const OWQuestions: React.FC<IOWQuestionsProps> = (props: IOWQuestionsProps) => {

    let q: IQuestionGroup = props.questionGroup;

    return <Stack direction="column" spacing={1} id="ow-questions-stack" >
        {q.questions.map((c: IQuestion | ICode) => {
            if (c.sequenceType === OptionSequenceTypeEnum.Question)
                return <QuestionComponentSelector preferences={props.preferences} key={c.sequence + "_" + c.question} question={c as IQuestion} readOnlyMode={props.readOnlyMode} />;
            else
                return <NonTiledStandAloneCode preferences={props.preferences} key={c.sequence + "_" + (c as ICode).code} code={c as ICode} readOnlyMode={props.readOnlyMode} />;
        })}
    </Stack>;

}

export default OWQuestions;