import React from "react";
import { IAdvertisement } from "../../../helpers/interfaces";
import DashboardAPI from "../DashboardAPI";
import DashboardContext, { ActionTypeEnum, DashboardContextDispatch } from "../DashboardContext";


export function useAdvertisements(): IAdvertisement[]|undefined {

    const dashboardData = React.useContext(DashboardContext);
    const dispatch = React.useContext(DashboardContextDispatch);

    const adData = dashboardData.ads;

    const isExpired = (dashboardData.adsExpTime < Date.now()  );

    React.useEffect(() => {

        if (dispatch) {
            if (!adData || isExpired) {
                DashboardAPI.QueryAdvertisements().then(o => {
                    dispatch({ type: ActionTypeEnum.SetAds, value: o });
                });
            }
        }

    }, [dispatch, adData, isExpired]);

    return adData;

};