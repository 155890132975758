import React, { PropsWithChildren } from 'react';

interface IProps {
}

// https://stackoverflow.com/questions/5445491/height-equal-to-dynamic-width-css-fluid-layout
// height=0 with a bottom padding of 100% forces the height to match the width, which will be resized as the screen size changes since it has a width of 1 ( 100% of container)

const AutoScaleToWidthDiv: React.FC<PropsWithChildren<IProps>> = (props: PropsWithChildren<IProps>) => {

    return <div style={{
        display: "block",
        width: "100%",
        height: 0,
        paddingBottom: "100%",
        position: "relative",
    }}>
        <div style={{ position: "absolute", top: "0px", bottom: "0px", left: "0px", right: "0px" }}>
            {props.children}
        </div>
    </div>
}

export default AutoScaleToWidthDiv;