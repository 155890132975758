import { useMemo } from "react"
import { Paper, Box, Typography, Grid } from "@mui/material";
import { Business } from "@mui/icons-material";

import useOrderData from "components/Orders/useOrderData";

function MfgCustomerCard() {
    const { order } = useOrderData();

    const formatMfgCustomerName = useMemo((): string => {
        let formattedName = order?.customerName ?? "";

        if (order?.siteName && order?.siteName !== order?.customerName) {
            formattedName += `[${order.siteName}]`;
        }

        return formattedName;
    }, [order]);

    return <>
        <Paper sx={{ display: "flex" }}>
            <Box sx={{ width: 300, margin: 1 }} display="flex" flexDirection="row" >
                <Grid container direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Business color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="subtitle1">
                            {formatMfgCustomerName}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    </>

}


export default MfgCustomerCard;