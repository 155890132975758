import APIHelper from "helpers/APIHelper";
import { IOrderHistory, IPurcharseHistoryCustomer, IPurcharseHistoryOption, IPurcharseHistoryOrder, IPurcharseHistoryPart } from "helpers/interfaces";
import { DateTime } from "luxon";

export default class HistoryAPI {

    public static async QueryOrderHistory(startDate: DateTime, endDate: DateTime): Promise<IOrderHistory> {
        const url: string = 'api/orders/history';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate()
        };
        const response = await APIHelper.GetAsync<IOrderHistory>(url, params);
        return response.Result;
    }

    public static async QueryPurchaseHistoryCustomers(startDate: DateTime, endDate: DateTime): Promise<IPurcharseHistoryCustomer[]> {
        const url: string = 'api/purchase/history/customers';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate()
        };
        const response = await APIHelper.GetAsync<IPurcharseHistoryCustomer[]>(url, params);
        return response.Result;
    }

    public static async QueryPurchaseHistoryOrders(startDate: DateTime, endDate: DateTime): Promise<IPurcharseHistoryOrder[]> {
        const url: string = 'api/purchase/history/orders';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate()
        };
        const response = await APIHelper.GetAsync<IPurcharseHistoryOrder[]>(url, params);
        return response.Result;
    }

    public static async QueryPurchaseHistoryParts(startDate: DateTime, endDate: DateTime): Promise<IPurcharseHistoryPart[]> {
        const url: string = 'api/purchase/history/parts';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate()
        };
        const response = await APIHelper.GetAsync<IPurcharseHistoryPart[]>(url, params);
        return response.Result;
    }

    public static async QueryPurchaseHistoryOptionParts(startDate: DateTime, endDate: DateTime, partNo: string = "", partNoSuffix: string = "", optionCode: string = ""): Promise<IPurcharseHistoryPart[]> {
        const url: string = 'api/purchase/history/options';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate(),
            partNo,
            partNoSuffix,
            optionCode
        };
        const response = await APIHelper.GetAsync<IPurcharseHistoryPart[]>(url, params);
        return response.Result;
    }

    public static async QueryPurchaseHistoryOptions(startDate: DateTime, endDate: DateTime, partNo: string = "", partNoSuffix: string = ""): Promise<IPurcharseHistoryOption[]> {
        const url: string = 'api/purchase/history/options';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate(),
            partNo,
            partNoSuffix
        };
        const response = await APIHelper.GetAsync<IPurcharseHistoryOption[]>(url, params);
        return response.Result;
    }

    public static async QueryPurchaseHistoryCustomerParts(startDate: DateTime, endDate: DateTime, customerID: string, siteID: string): Promise<IPurcharseHistoryPart[]> {
        const url: string = 'api/purchase/history/customerparts';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate(),
            customerID,
            siteID
        };
        const response = await APIHelper.GetAsync<IPurcharseHistoryPart[]>(url, params);
        return response.Result;
    }

    public static async QueryPurchaseHistoryCustomerOptions(startDate: DateTime, endDate: DateTime, customerID: string, siteID: string, partNo: string = "", partNoSuffix: string = ""): Promise<IPurcharseHistoryOption[]> {
        const url: string = 'api/purchase/history/customeroptions';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate(),
            customerID,
            siteID,
            partNo,
            partNoSuffix
        };
        const response = await APIHelper.GetAsync<IPurcharseHistoryOption[]>(url, params);
        return response.Result;
    }

    public static async QueryPurchaseHistoryCustomerOrders(startDate: DateTime, endDate: DateTime, customerID: string, siteID: string): Promise<IPurcharseHistoryOrder[]> {
        const url: string = 'api/purchase/history/customerorders';
        const params = {
            startDate: startDate.toISODate(),
            endDate: endDate.toISODate(),
            customerID,
            siteID
        };
        const response = await APIHelper.GetAsync<IPurcharseHistoryOrder[]>(url, params);
        return response.Result;
    }
};





