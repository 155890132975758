import React from "react";
import { useTranslations } from "@fenetech/translations";
import { GridActionsCellItem, GridColumnHeaderParams, GridEnrichedColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { Edit } from "@mui/icons-material";

type EditActionHandler = (siteID: string) => void;

interface IEditCommandProps {
    params: GridRowParams;
    editActionHandler: EditActionHandler;
}

export const CompanyCommandsColumn = (editHandler: EditActionHandler | null) => {
    
    const minWidth = 92;

    const EditCommand: React.FC<IEditCommandProps> = ({ params, editActionHandler }: IEditCommandProps) => {
        const tm = useTranslations();
        const siteID: string = params.row.siteID as string;
    
        const handlerShowDialog = React.useCallback(() => {
            editActionHandler(siteID);
        }, [siteID, editActionHandler]);
    
        return <>
            <GridActionsCellItem
                icon={<Edit />}
                color="primary"
                label={tm.GetWithParams("Edit {0}", tm.Get("Company"))}
                onClick={handlerShowDialog}
            />
        </>
    };

    const col = {
        field: "Commands",
        type: "actions",
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                Commands
            </div>
        },
        minWidth: minWidth,
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {

            const actions: JSX.Element[] = [];

            if (editHandler) actions.push(<EditCommand params={params} editActionHandler={editHandler} />);
            return actions;
        },
    } as GridEnrichedColDef;

    return col;
}
