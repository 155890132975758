import { ILoadingFormState } from "helpers/hooks/useLoadingForm";
import { PropsWithChildren } from "react"



const LoadingForm: React.FC<PropsWithChildren<ILoadingFormState>> = (props: PropsWithChildren<ILoadingFormState>) => {

    return <form onSubmit={props.internalSubmitHandler}>
        <fieldset disabled={props.submitting}>
            {props.children}
        </fieldset>
    </form>
}

export default LoadingForm;
