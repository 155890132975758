import { useEffect, useState, useCallback } from "react";
import { TableRow, TableCell, Tooltip, Skeleton, styled, alpha } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

import useQuoteData from "components/Quotes/useQuoteData";
import LineItemColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";

import { ILineItem } from "helpers/interfaces";
import Constants from "helpers/constants";
import useQuoteActions from "components/Quotes/useQuoteActions";

interface IProps {
    columns: LineItemColumn[];
    lineItem: ILineItem,
}

const LineItemComment: React.FC<IProps> = ({ columns, lineItem }: IProps) => {

    const { quote } = useQuoteData();
    const quoteActions = useQuoteActions();
    const [optionsString, setOptionsString] = useState<string | undefined>(undefined);
    const [optionsToolTip, setOptionsToolTip] = useState<string | undefined>(undefined);

    const StyledOptionsCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
        backgroundColor: alpha(theme.palette.secondary.light, 0.1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),        
        },
    }));        

    const loadOptionStringAndTooltipAsync = useCallback(async (oKey: number, odKey: number) => {
        const optionString = await quoteActions.GetLineItemOptionsStringAsync(oKey, odKey, false);
        setOptionsString(optionString);

        const toolTip = await quoteActions.GetLineItemOptionsStringAsync(oKey, odKey, true);
        setOptionsToolTip(toolTip);
    }, [quoteActions]);

    useEffect(() => {
        if (quote?.oKey && lineItem?.odKey)
        {
            loadOptionStringAndTooltipAsync(quote.oKey, lineItem.odKey);
        }
    }, [quote, lineItem, loadOptionStringAndTooltipAsync]);

    return <>
        <TableRow>
            <Tooltip title={optionsToolTip ?? ""}>
                <StyledOptionsCell colSpan={columns.length - 1}>
                    {(optionsString === undefined || optionsToolTip === undefined) ? 
                        <Skeleton variant="text" width="100%" /> 
                    : 
                        optionsString ? optionsString : Constants.Strings.ZeroWidthSpace 
                    }
                </StyledOptionsCell>
            </Tooltip>
        </TableRow>
    </>;
}


export default LineItemComment;