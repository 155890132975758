
import APIHelper from "../../helpers/APIHelper";
import { IWebCustomerSummary, IWebCustomerReport, IBaseContent } from "../../helpers/interfaces";

export default class CustomerAPI {

    public static async GetWebCustomerSummaryList(mfgCustomerID: string): Promise<IWebCustomerSummary[]> {
        const url: string = 'api/WebCustomers/SummaryList';
        const params = {
            mfgCustomerID: mfgCustomerID
        }
        const response = await APIHelper.GetAsync<IWebCustomerSummary[]>(url, params);
        return response.Result;
    }

    public static async GetWebContractorCustomerSummaryList(parentCustomerID: string): Promise<IWebCustomerSummary[]> {
        const url: string = 'api/WebCustomers/Contractors/SummaryList';
        const params = {
            parentCustomerID: parentCustomerID
        }
        const response = await APIHelper.GetAsync<IWebCustomerSummary[]>(url, params);
        return response.Result;
    }

    public static async GetWebCustomerPricingInfo(customerID: string): Promise<IWebCustomerReport> {
        const url: string = 'api/WebCustomers/CustomerPricingInfo';
        const params = {
            customerID: customerID,
        }
        const response = await APIHelper.GetAsync<IWebCustomerReport>(url, params);
        return response.Result;
    }

    public static async AddNewWebCustomerWithDefaults(customerName: string, mfgSiteID: string | undefined): Promise<string> {
        const url: string = 'api/WebCustomers/';
        const params = {
            customerName: customerName,
            mfgSiteID: mfgSiteID ?? "",
        };

        const response = await APIHelper.PostAsync<string>(url, params);
        return response.Result;
    }

    public static async AddNewWebContractorCustomerWithDefaults(customerName: string): Promise<string> {
        const url: string = 'api/WebCustomers/Contractors';
        const params = {
            customerName: customerName,
        }
        const response = await APIHelper.PostAsync<string>(url, params);
        return response.Result;
    }

    public static async UpdateWebCustomer(customerID: string, customer: IWebCustomerReport): Promise<IBaseContent> {
        const url: string = `api/WebCustomers/`;
        const params = {
            customerID: customerID,
        }        
        const body = customer;
        const response = await APIHelper.PutAsync<IBaseContent>(url, params, body);
        return response.Result;
    }

    public static async UpdateWebContractorCustomer(customerID: string, customer: IWebCustomerReport): Promise<IBaseContent> {
        const url: string = `api/WebCustomers/Contractors`;
        const params = {
            customerID: customerID,
        };
        const body = customer;
        const response = await APIHelper.PutAsync<IBaseContent>(url, params, body);
        return response.Result;
    }

    public static async DeleteWebCustomer(customerID: string): Promise<IBaseContent> {
        const url: string = 'api/WebCustomers/';
        const params = {
            customerID: customerID,
        }
        const response = await APIHelper.DeleteAsync<IBaseContent>(url, params, undefined, false);
        return response.Result;
    }

    public static async DeleteWebContractorCustomer(customerID: string, parentCustomerID: string): Promise<IBaseContent> {
        const url: string = 'api/WebCustomers/Contractors';
        const params = {
            customerID: customerID,
            parentCustomerID: parentCustomerID
        }
        const response = await APIHelper.DeleteAsync<IBaseContent>(url, params, undefined, false);
        return response.Result;
    }
}