import APIHelper from "helpers/APIHelper";
import { IPartDefaults } from "models/Parts/IPartDefaults";
import { IPartCallSizePresets } from "helpers/interfaces";

export default class PartsAPI {


    public static async GetPartDefaults(partNo:string, partNoSuffix:string, shortcutName:string): Promise<IPartDefaults> {
        const response = await APIHelper.GetAsync<IPartDefaults>("api/parts/defaults", {partNo, partNoSuffix, shortcutName});
        return response.Result;
    }

    public static async GetPartCallSizes(partNo:string, partNoSuffix:string): Promise<IPartCallSizePresets> {
        const response = await APIHelper.GetAsync<IPartCallSizePresets>("api/parts/callsizes", {partNo, partNoSuffix});
        return response.Result;
    }

}