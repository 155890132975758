import ResultColumn from "components/GlobalOptions/Results/Columns/ResultColumn";
import { IGlobalOptions } from "components/GlobalOptions/GlobalOptionsContext";
import ExceptionIcon from "components/GlobalOptions/Results/Fields/ExceptionIcon";

import { IGlobalItem } from "helpers/interfaces";

export default class ExceptionIconColumn extends ResultColumn {
    onClick: (globalItem: IGlobalItem) => void;

    public constructor(columnName: string, headerText: string, width: string, onClick: (globalItem: IGlobalItem) => void) {
        super(columnName, headerText, width);
        this.align = "center";
        this.onClick = onClick;
    }

    public CellContent(globalOptions: IGlobalOptions, globalItem: IGlobalItem, previousItem?: IGlobalItem): JSX.Element {

        return <ExceptionIcon
            globalItem={globalItem}
            onClick={this.onClick}
        />

    }

}
