import APIHelper from "helpers/APIHelper";
import { IPartKey, IProductNavigator } from "helpers/interfaces";
import { IProductNavigatorPartFilter, IProductNavigatorPartFilterPart } from "models/IProductNavigator";

export default class ProductNavigatorAPI {
    
    public static async ProductNavigator(oKey: number, getCallSizes: boolean, callSize: string, partList: IPartKey[] | null): Promise<IProductNavigator> {
        const url: string = `api/ProductNavigator/${oKey}`;
        const params = {
            getCallSizes,
            callSize,
            partList
        }
        const response = await APIHelper.PostAsync<IProductNavigator>(url, undefined, params);
        return response.Result;
    }

    public static async PartFilters(filterID:number): Promise<IProductNavigatorPartFilter[]>
    {
        const url: string = `api/ProductNavigator/PartFilter/${filterID}`;

        const response = await APIHelper.GetAsync<IProductNavigatorPartFilter[]>(url);
        return response.Result;
    }

    public static async PartFilterValues(filterID: number, selectedValues: IProductNavigatorPartFilter[]): Promise<string[]>
    {       
        const url: string = `api/ProductNavigator/PartFilter/${filterID}/PartFilterValues`;

        const response = await APIHelper.PostAsync<string[]>(url, undefined, selectedValues);
        return response.Result;
    }

    public static async PartFilterParts(filterID: number, selectedValues: IProductNavigatorPartFilter[]): Promise<IProductNavigatorPartFilterPart[]>
    {
        const url: string = `api/ProductNavigator/PartFilter/${filterID}/PartFilterParts`;

        const response = await APIHelper.PostAsync<IProductNavigatorPartFilterPart[]>(url, undefined, selectedValues);
        return response.Result;
    }
}