import { DateTime } from "luxon";
import Format from "./fv.format";

// Drills into nested props recursively and returns all property values that match a given name
export function FindAllNestedProps(root : any, propName: string) : any[] {

    const propValues : any[] = [];

    if(root && root.hasOwnProperty(propName)) {
        propValues.push(root[propName]);
    } 
    else if (typeof root === 'object') {
        for (const key of Object.keys(root))
        {
            if (root[key])
            {
                const nestedPropValues = FindAllNestedProps(root[key], propName);
                propValues.push(...nestedPropValues);
            }
        }
    }
    return propValues;
}

// Pass Object.values(EnumType) to use this
export function GetEnumAsListOfKeyValuePairs(enumValues: any[]) {
    let result: {key: string, value: number}[] = [];
    enumValues.forEach((key, index) => {
        if (isNaN(Number(key))) {
            //Regex here converts pascal to individual words. ex: MyEnumValue -> My Enum Value
            result.push({ key: (key.replace(/([A-Z])/g, ' $1') as string).trimStart(), value: Number(enumValues[index + (enumValues.length / 2)]) })
        }
    });
    return result;
}

// Pass Object.values(EnumType) to use this
export function GetMaxValueOfEnum(enumValues: any[]) {
    const numericValues = enumValues.filter(k => !isNaN(Number(k))).map(k => Number(k));
    return Math.max(...numericValues);
}

// https://stackoverflow.com/questions/2140627/how-to-do-case-insensitive-string-comparison see answer from Samuel Neff
export function CaseInsensitiveCompare(a: string, b: string) {
    return typeof a === 'string' && typeof b === 'string' ? 
        a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0 :
        a === b;
}

export function DateIsEmpty(date: Date | string | undefined) {
    if (date === undefined) {
        return true;
    }
    const dt = Format.ConvertDateToLuxonDateTime(date);
    return DateTimeIsEmpty(dt);
}

export function DateTimeIsEmpty(dt: DateTime | undefined) {
    if (dt === undefined) {
        return true;
    }
    const dtNull = DateTime.fromJSDate(new Date("1900-01-01T00:00:00"));
    return (dt.startOf("day").toMillis() === dtNull.startOf("day").toMillis());
}

type localeIncludesParams = {string: string, searchString: string, position?: number, locales?: string | string[] | undefined, options?: any};
// https://github.com/idmadj/locale-includes regular includes does not account for locale strings i.e. "ḃḉdÉ" vs. "bCde"
export const localeIncludes = ({string, searchString, position = 0, locales = undefined, options = {}}: localeIncludesParams): boolean => {
    if (string === undefined || 
        string === null || 
        searchString === undefined ||
        searchString === null
    ) {
        throw new Error('Programming Error: localeIncludes requires at least 2 parameters');
    }
    
    const stringLength = string.length;
    const searchStringLength = searchString.length;
    const lengthDiff = stringLength - searchStringLength;

    for (let i = position; i <= lengthDiff; i++) {
        if (string.substring(i, i + searchStringLength).localeCompare(searchString, locales, options) === 0) {
            return true;
        }
    }
    return false;
};

// Returns true if any string property on an object includes the search phrase
// excludeFields skips searching anything specified ex: searchObjectStringProperties('test', myObj, ['_id'])
export function searchObjectStringProperties(search: string, obj: any, excludeFields?: string[]) {
    return Object.keys(obj).some(key => {
        if (typeof obj[key] === "string" && !excludeFields?.find(field => field === key)) {
            return localeIncludes({string: obj[key], searchString: search, options: {sensitivity: 'accent'}} );
        }
        return false;
    })
}

// Drills into nested props given a path string and returns the value. ex: "item.property.nested"
export const ResolvePath = (path: string, obj: any) : any => path
    .split(".")
    .reduce((prev, curr) => prev ? prev[curr] : null, obj);

