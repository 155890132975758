import { TimeoutAction, TimeoutActionEnum } from "helpers/context/Authentication/AuthenticationContext";
import API from 'components/Login/LoginAPI';
import { AuthorizationTypeEnum } from "helpers/enums";
import LogoutAPI from "components/Login/LogoutAPI";
import { ResponseTypeEnum } from "helpers/WebResponse";

export default class AuthHandler {

    #serverTimeoutMS: number = 30 * 60 * 1000; //default to 30 minutes
    #clientTimeoutMS: number = 29 * 60 * 1000; //default to 29 minutes
    #dispatch: React.Dispatch<TimeoutAction>;
    #currentTimer: NodeJS.Timeout | null = null;
    #currentServerEndTime: number | undefined;

    constructor(dispatch: React.Dispatch<TimeoutAction>) {
        this.#dispatch = dispatch;
    }

    private ResetState = () => {
        this.#dispatch({ action: TimeoutActionEnum.Reset });
    }

    private SetSignedIn = () => {
        this.#dispatch({ action: TimeoutActionEnum.SetSignin, value: true });
    }

    private SetSignedInWithOnlineAckToken = () => {
        this.#dispatch({ action: TimeoutActionEnum.SetSigninWithOnlineAckToken, value: true });
    }    

    private ClearTimeout = () => {
        if (this.#currentTimer) {
            clearTimeout(this.#currentTimer);
            this.#currentTimer = null;
        }
    }

    public GeServerTimeRemaining = () => {
        if (this.#currentServerEndTime) {
            return this.#currentServerEndTime - Date.now();
        }
        return 0;
    }

    public ChangeTimeout = (minutes: number) => {

        const timerExists: boolean = !!this.#currentTimer;

        this.ClearTimeout();
        this.#serverTimeoutMS = Math.floor(minutes * 60 * 1000);
        this.#clientTimeoutMS = Math.floor(minutes * 60 * 1000) - (10 * 1000); //reduce the configured timeout by 10 seconds to have the client timeout before the site does.

        if (timerExists)
            this.StartTimer();
    }


    public StartTimer = () => {

        this.ClearTimeout();

        this.#currentServerEndTime = Date.now() + this.#serverTimeoutMS;
        this.#currentTimer = setTimeout(() => {
            this.#dispatch({ action: TimeoutActionEnum.TimedOut })
            this.ClearTimeout();
        }, this.#clientTimeoutMS)
    }

    public ResetTimer = () => {

        // The server side cookie will only reset it's expiration if its more than halfway through the expiration window
        // https://docs.microsoft.com/en-us/dotnet/api/microsoft.aspnetcore.authentication.cookies.cookieauthenticationoptions.slidingexpiration?view=aspnetcore-6.0
        if (this.GeServerTimeRemaining() < (this.#serverTimeoutMS / 2)) {
            this.StartTimer();
        }
    }

    public SignInAsync: (userName: string, password: string, loginType: AuthorizationTypeEnum) => Promise<boolean> = async (userName, password, loginType) => {

        const t = await API.SignInAsync(userName, password, loginType);

        if (t.BadRequest || t.AuthRequired) {
            this.ClearTimeout();
            return false;
        } else {
            this.SetSignedIn();
            this.StartTimer();
            return true;
        }
    }

    public SignInWithRemoteIDAsync: (remoteID: string, userName: string) => Promise<boolean> = async (remoteID, userName) => {
        const t = await API.SignInWithRemoteIDAsync(userName, remoteID);

        if (t.BadRequest || t.AuthRequired) {
            this.ClearTimeout();
            return false;
        } else {
            this.SetSignedIn();
            this.StartTimer();
            return true;
        }
    }

    public SignInWithOnlineAckTokenAsync: (token: string) => Promise<boolean> = async (token) => {
        const t = await API.SignInWithOnlineAckTokenAsync(token);

        if (t.BadRequest || t.AuthRequired) {
            this.ClearTimeout();
            return false;
        } else {
            this.SetSignedInWithOnlineAckToken();
            this.StartTimer();
            return true;
        }        
    }

    public SignOutAsync: () => Promise<void> = async () => {

        //Reset the state before calling logout, otherwise the request handlers will handle an unauthorized resonse and call into this again (infinitely)
        this.ClearTimeout();
        this.ResetState();

        let response = await LogoutAPI.LogoutAsync();

        if (response.GetResponseType() === ResponseTypeEnum.Ok) {
            const redirectUrl = response.Result;
            if (redirectUrl) {
                window.location.assign(redirectUrl);
            }
        }

    }

    public SetUserAsAuthenticated() {
        this.SetSignedIn();
        this.StartTimer();
    }

};


