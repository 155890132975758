import React from "react";

import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import QuotesAPI from "components/Quotes/QuotesAPI";
import { IQuote, IQuotePermissions, ILockedObject, IQuoteTotals, IApplicationInfo, ILineItem, ITaxDetail, ILineItemQuantityChange, ISurchargeOverrideInfo, ITaxOverrideInfo, ITransLogHeader, IPartKey } from "helpers/interfaces";

import { DispatchActionsBase } from "components/Common/DispatchActionsBase";
import { IProductNavigatorPartFilterPart } from "models/IProductNavigator";

export interface IQuoteData {
    quote?: IQuote,
    permissions?: IQuotePermissions,
    lock?: ILockedObject,
    lineItems: ILineItem[],
    totals?: IQuoteTotals,
    productNavigatorState: IProductNavigatorState,
    taxDetails: ITaxDetail[],
    defaultCategoryID?: number,
    defaultPart?: IPartKey,
    isLoading: boolean,
}

export interface IProductNavigatorState {
    cameFromProductNavigator: boolean,
    finalNodeID: string,
    callSize: string,
    isUsingProductFilter: boolean,
    productFilterHasPartsToAdd: boolean,
    productFilterPartsToAdd: IProductNavigatorPartFilterPart[],
}

export enum QuoteActionTypeEnum {
    LoadQuoteHeader,
    LoadLineItems,
    LoadQuoteTotals,
    LoadAddPartDefaults,
    Clear,
    SetProductNavigatorState,
    SetTaxDetails,
    SetIsLoading,
    SetIsUsingProductFilter,
    AddPartFilterPart,
    RemovePartFilterPart,
    UpdatePartFilterPartQty,
}

export type QuoteActionType =
    { type: QuoteActionTypeEnum.LoadQuoteHeader, value: { quote: IQuote, permissions: IQuotePermissions, lock: ILockedObject } }
    | { type: QuoteActionTypeEnum.LoadLineItems, value: ILineItem[] }
    | { type: QuoteActionTypeEnum.LoadQuoteTotals, value: IQuoteTotals }
    | { type: QuoteActionTypeEnum.LoadAddPartDefaults, value: { defaultCategoryID?: number, defaultPart?: IPartKey } }
    | { type: QuoteActionTypeEnum.Clear }
    | { type: QuoteActionTypeEnum.SetProductNavigatorState, value: IProductNavigatorState }
    | { type: QuoteActionTypeEnum.SetTaxDetails, value: ITaxDetail[] }
    | { type: QuoteActionTypeEnum.SetIsLoading, value: boolean }
    | { type: QuoteActionTypeEnum.SetIsUsingProductFilter, value: boolean }
    | { type: QuoteActionTypeEnum.AddPartFilterPart, value: IProductNavigatorPartFilterPart }
    | { type: QuoteActionTypeEnum.RemovePartFilterPart, value: IProductNavigatorPartFilterPart }
    | { type: QuoteActionTypeEnum.UpdatePartFilterPartQty, partID: number, qty: number}
    ;

const quoteReducer: React.Reducer<IQuoteData, QuoteActionType> = (data: IQuoteData, action) => {

    switch (action.type) {
        case QuoteActionTypeEnum.LoadQuoteHeader:
            return { ...data, ...action.value };
        case QuoteActionTypeEnum.LoadLineItems:
            return { ...data, lineItems: action.value };
        case QuoteActionTypeEnum.LoadQuoteTotals:
            return { ...data, totals: action.value };
        case QuoteActionTypeEnum.LoadAddPartDefaults:
            return { ...data, defaultCategoryID: action.value.defaultCategoryID, defaultPart: action.value.defaultPart };
        case QuoteActionTypeEnum.Clear:
            return { ...initialState };
        case QuoteActionTypeEnum.SetProductNavigatorState:
            return { ...data, productNavigatorState: action.value };
        case QuoteActionTypeEnum.SetTaxDetails:
            return { ...data, taxDetails: action.value }
        case QuoteActionTypeEnum.SetIsLoading:
            return { ...data, isLoading: action.value }
        case QuoteActionTypeEnum.SetIsUsingProductFilter:
            return { ...data, productNavigatorState: { ...data.productNavigatorState, isUsingProductFilter: action.value } }
        case QuoteActionTypeEnum.AddPartFilterPart: {
            let updatedParts: IProductNavigatorPartFilterPart[] = [];
            const existingPart = data.productNavigatorState.productFilterPartsToAdd.find(p => p.partID === action.value.partID);
            if (existingPart) {
                updatedParts = data.productNavigatorState.productFilterPartsToAdd.map(p => {
                    if (p.partID === action.value.partID) {
                        return {...p, qty: Number(p.qty) + Number(action.value.qty)};
                    }
                    return p;
                })
            }
            else{
                updatedParts = [...data.productNavigatorState.productFilterPartsToAdd, action.value];
            }
            return { ...data, productNavigatorState: { ...data.productNavigatorState, productFilterPartsToAdd: updatedParts, productFilterHasPartsToAdd:updatedParts.length > 0 } }
        }
        case QuoteActionTypeEnum.RemovePartFilterPart: {
            const newParts = data.productNavigatorState.productFilterPartsToAdd.filter(p => p.partID !== action.value.partID)
            return { ...data, productNavigatorState: { ...data.productNavigatorState, productFilterPartsToAdd: newParts, productFilterHasPartsToAdd: newParts.length > 0 } }
        }
        case QuoteActionTypeEnum.UpdatePartFilterPartQty: {
            const updatedParts = data.productNavigatorState.productFilterPartsToAdd.map(p => {
                if (p.partID === action.partID) {
                    return { ...p, qty: action.qty };
                }
                return p;
            });
            return { ...data, productNavigatorState: { ...data.productNavigatorState, productFilterPartsToAdd: updatedParts } }
        }
    }
};

const initialProductNavigatorState: IProductNavigatorState = {
    cameFromProductNavigator: false,
    callSize: "",
    finalNodeID: "",
    isUsingProductFilter: false,
    productFilterHasPartsToAdd: false,
    productFilterPartsToAdd: []
}

const initialState: IQuoteData = {
    lineItems: [],
    productNavigatorState: initialProductNavigatorState,
    taxDetails: [],
    isLoading: false,
};

export class QuoteActions extends DispatchActionsBase<QuoteActionType> {

    #appInfo: IApplicationInfo;

    constructor(dispatcher: React.Dispatch<QuoteActionType>, appInfo: IApplicationInfo) {
        super(dispatcher);
        this.#appInfo = appInfo;
    }

    public async LoadQuoteAsync(oKey: number, acquireLock?: boolean): Promise<boolean> {
        if (oKey) {
            this.SetIsLoading(true);
            const quotePromise = QuotesAPI.GetQuoteAndPermissions(oKey);
            const lockPromise = QuotesAPI.GetQuoteLock(oKey, this.#appInfo, acquireLock ?? false);
            const lineItemsPromise = QuotesAPI.GetLineItems(oKey)
            const totalsPromise = QuotesAPI.GetQuoteTotals(oKey);
            const [quoteResponse, lockResponse, lineItemsResponse, totalsResponse] = await Promise.all([quotePromise, lockPromise, lineItemsPromise, totalsPromise]);

            const quoteResults = quoteResponse.Result;
            const lockResults = lockResponse.Result;
            const quoteTotals = totalsResponse.Result;
            const lineItems = lineItemsResponse.Result;
            this.LoadAddPartDefaultsAsync(oKey);

            if (quoteResponse.IsOk() && lockResponse.IsOk() && lineItemsResponse.IsOk() && totalsResponse.IsOk()) {
                this.dispatch({ type: QuoteActionTypeEnum.LoadQuoteHeader, value: { quote: quoteResults.quote, permissions: quoteResults.permissions, lock: lockResults } });
                this.dispatch({ type: QuoteActionTypeEnum.LoadLineItems, value: lineItems });
                this.dispatch({ type: QuoteActionTypeEnum.LoadQuoteTotals, value: quoteTotals });
                this.SetIsLoading(false);
                return true;
            }
        }
        return false;
    }

    public async LoadAddPartDefaultsAsync(oKey: number): Promise<void> {
        let categoryPromise = QuotesAPI.GetLastCategoryIDOnQuote(oKey);
        let partPromise = QuotesAPI.GetLastPartKeyOnQuote(oKey);

        Promise.all([categoryPromise, partPromise]).then(([categoryResponse, partResponse]) => {

            if (categoryResponse.IsOk() && partResponse.IsOk()) {
                this.dispatch({ type: QuoteActionTypeEnum.LoadAddPartDefaults, value: { defaultCategoryID: categoryResponse.Result ?? undefined, defaultPart: partResponse.Result ?? undefined } });
            }
            else {
                this.dispatch({ type: QuoteActionTypeEnum.LoadAddPartDefaults, value: { defaultCategoryID: undefined, defaultPart: undefined } });
            }
        })
    }

    public SetIsLoading(isLoading: boolean) {
        this.dispatch({ type: QuoteActionTypeEnum.SetIsLoading, value: isLoading });
    }

    public SetProductNavigatorState(productNavigatorState: IProductNavigatorState) {
        this.dispatch({ type: QuoteActionTypeEnum.SetProductNavigatorState, value: productNavigatorState });
    }

    public SetIsUsingProductFilter(isUsingProductFilter: boolean) {
        this.dispatch({ type: QuoteActionTypeEnum.SetIsUsingProductFilter, value: isUsingProductFilter });
    }

    public AddPartFilterPart(part: IProductNavigatorPartFilterPart) {
        this.dispatch({ type: QuoteActionTypeEnum.AddPartFilterPart, value: part });
    }

    public RemovePartFilterPart(part: IProductNavigatorPartFilterPart) {    
        this.dispatch({ type: QuoteActionTypeEnum.RemovePartFilterPart, value: part });
    }

    public UpdatePartFilterPartQty(partID: number, qty: number) {
        this.dispatch({ type: QuoteActionTypeEnum.UpdatePartFilterPartQty, partID: partID, qty: qty });
    }

    public async LoadTaxDetailsAsync(oKey: number) {
        const response = await QuotesAPI.GetTaxDetails(oKey);
        const taxDetails = response.Result;
        if (response.IsOk()) {
            this.dispatch({ type: QuoteActionTypeEnum.SetTaxDetails, value: taxDetails });
        }

    }

    public async CopyQuoteAsync(oKey: number) {
        const response = await QuotesAPI.CopyQuote(oKey);
        const newOKey = response.Result;
        return newOKey;
    }

    public async DeleteQuoteAsync(oKey: number): Promise<boolean> {
        const result = await QuotesAPI.DeleteQuote(oKey);
        return result.IsOk();
    }

    public async GetLineItemExceptionsAsync(oKey: number, odKey: number) {
        const response = await QuotesAPI.GetLineItemExceptions(oKey, odKey);
        const exceptions = response.Result;
        return exceptions;
    }

    public async GetLineItemOptionsStringAsync(oKey: number, odKey: number, toolTip: boolean): Promise<string> {
        const response = await QuotesAPI.GetLineItemOptionsString(oKey, odKey, toolTip);
        const valueString = response.Result;
        if (valueString === undefined) {
            return "";
        }
        else {
            return valueString;
        }
    }

    public async UpdateLineItemQuantitiesAsync(oKey: number, lineItemQuantityChanges: ILineItemQuantityChange[]) {
        await QuotesAPI.UpdateLineItemQuantities(oKey, lineItemQuantityChanges);
    }

    public async CopyLineItemAsync(oKey: number, odKey: number): Promise<void> {
        await QuotesAPI.CopyLineItem(oKey, odKey);
    }

    public async MoveLineItemAsync(oKey: number, odKey: number, increment: boolean): Promise<void> {
        await QuotesAPI.MoveLineItem(oKey, odKey, increment);
    }

    public async DeleteLineItemAsync(oKey: number, odKey: number): Promise<void> {
        await QuotesAPI.DeleteLineItem(oKey, odKey);

    }

    public async OverrideSurchargesAsync(oKey: number, surchargeOverrideInfos: ISurchargeOverrideInfo[]) {
        await QuotesAPI.OverrideSurcharges(oKey, surchargeOverrideInfos);
    }

    public async OverrideTaxesAsync(oKey: number, taxOverrideInfos: ITaxOverrideInfo[]) {
        await QuotesAPI.OverrideTaxes(oKey, taxOverrideInfos);

    }

    public async RenewQuoteAsync(oKey: number, preservePrice: boolean) {
        await QuotesAPI.RenewQuote(oKey, preservePrice);
    }

    public async IsRenewQuoteAllowedAsync(oKey: number): Promise<boolean> {
        const response = await QuotesAPI.IsRenewQuoteAllowed(oKey);
        return response.Result;
    }

    public async GetQuoteHistoryAsync(oKey: number): Promise<ITransLogHeader[]> {
        const response = await QuotesAPI.GetQuoteHistory(oKey);
        return response.Result;
    }

    public async GetQuoteHistoryDetailAsync(oKey: number, odKey: number, question: string, code: string, tableName: string, rowNumber: number) {
        const response = await QuotesAPI.GetQuoteHistoryDetail(oKey, odKey, question, code, tableName, rowNumber);
        return response.Result;
    }

    public async LogRecentQuoteAsync(oKey: number) {
        const response = await QuotesAPI.LogRecentQuote(oKey);
        return response.Result;
    }

    public Clear() {
        this.dispatch({ type: QuoteActionTypeEnum.Clear });
    }


}

export const QuoteContextDispatch = React.createContext<QuoteActions | null>(null);
export const QuoteContext = React.createContext<IQuoteData>(initialState);

export const QuoteContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(quoteReducer, initialState);
    const appInfo = useApplicationInfo();

    const actions = React.useMemo<QuoteActions>(() => {
        return new QuoteActions(dispatch, appInfo);
    }, [dispatch, appInfo]);

    return <QuoteContext.Provider value={state}>
        <QuoteContextDispatch.Provider value={actions}>
            {props.children}
        </QuoteContextDispatch.Provider>
    </QuoteContext.Provider>
}

export default QuoteContext;
