import APIHelper from "helpers/APIHelper";
import * as React from "react";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Box, Stack } from "@mui/material";

import { ICode, ISelectItem } from "../interfaces";
import useWizardInteractions from "../useWizardInteractions";

interface IValueProps {
    code: ICode;
    selectItem: ISelectItem;
    locked: boolean;
    readOnlyMode: boolean;
}

const TiledOptionValue: React.FC<IValueProps> = (props: IValueProps) => {

    const wizardInteractions = useWizardInteractions();

    const handleValueImageClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();

        const c: ICode = props.code;
        if (!props.locked && !props.readOnlyMode) {
            const si: ISelectItem = props.selectItem;
            wizardInteractions.SelectCodeValueAsync(c.sequence, c.code, si.value);
        }
    }


    const c: ICode = props.code;
    const si: ISelectItem = props.selectItem;
    const siSelected = (si.value === c.value);

    const imageSource = APIHelper.GetBaseURL() + `api/images/OptionImage?optionCode=${c.code}&thumbnail=true&selectedItemValue=${si.value}`;

    const backgroundStyle = siSelected ? {
        backgroundColor: "primary.light",
        borderRadius: 2,
        border: "solid",
        borderColor: "transparent",
        color: "primary.contrastText"
    } : {}

    return <Box flexDirection="column" alignItems="center" display="inline-flex" p={.5} m={.5} textAlign="center"
        key={c.sequence + "_" + c.code + "_" + si.value}
        onClick={handleValueImageClick}
        width={(theme) => theme.spacing(18)}
        minHeight={(theme) => theme.spacing(18)}
        sx={{
            cursor: (props.locked || props.readOnlyMode) ? "default" : "pointer",
            ...backgroundStyle
        }}

    >
        <div>
            {si.imageExists ?
                <input type="image"
                    width="82"
                    height="82"
                    src={imageSource}
                    disabled={props.locked || props.readOnlyMode}
                />
                :
                <Stack sx={{ height: "88px", display: "flex", lineHeight: "normal" }} justifyContent="center" alignItems="center" >
                    <InsertPhotoIcon fontSize="large" />
                </Stack>
            }
        </div>
        <div onClick={handleValueImageClick} style={{ wordWrap: "break-word", cursor: "pointer" }} >
            {si.description}
        </div>
    </Box>;

}

export default TiledOptionValue;
