import { useMemo } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useFormContext, useController } from "react-hook-form";
import { useTranslations } from '@fenetech/translations';
import useQuoteClassifications from 'helpers/context/SelectionValues/useQuoteClassifications';

import { ClassificationTypeEnum, OrderStatusEnum } from 'helpers/enums';
import { IQuoteClassification } from 'models/IQuote'
import { IQuotePermissions } from "helpers/interfaces";
import Constants from "helpers/constants";

interface IProps {
    isUploaded : boolean,
    permissions? : IQuotePermissions,
    originalClassificationID : number|null,
    [x: string] : any  // this should always be the last prop, to support any other props that will be passed along down the component chain   
}

function SelectQuoteStatus ({ isUploaded, permissions, originalClassificationID, ...rest }: IProps)
{

    const quoteClassifications = useQuoteClassifications();

    const tm = useTranslations();
    const name = "classificationID";
    const { control, setValue } = useFormContext();
    const { field, formState: { errors } } = useController({
        name: name,
        control: control,
        rules: { required: {value: true, message: tm.Get("Required")} },
    });

    const handleChange = (event: SelectChangeEvent<number | string>) => {
        field.onChange(event);
        if (event.target.value)
        {
            if (!isUploaded && quoteClassifications?.GetQuoteClassificationsBeforeUpload)
            {
                const classification = quoteClassifications.GetQuoteClassification(Number(event.target.value));
                if (classification)
                {
                    if (classification.classificationType === ClassificationTypeEnum.OnHoldBeforeUpload)
                    {
                        setValue("status", OrderStatusEnum.OnHold);
                    }
                    else if (classification.classificationType === ClassificationTypeEnum.AvailableBeforeUpload)
                    {
                        setValue("status", OrderStatusEnum.Available);
                    }
                }
            }
        }
    }

    const getQuoteClassifications = useMemo(() : IQuoteClassification[]|undefined => {
        let list = isUploaded ? quoteClassifications?.GetQuoteClassificationsAfterUpload() : quoteClassifications?.GetQuoteClassificationsBeforeUpload();

        if (isUploaded && list)
        {
            //The "Uploaded" status may or may not be a classification entry in the db (most likely not)
            //And the classification doesn't change or get deleted on upload, so most quotes will have classifications that no longer match an entry in the list
            //So if the current classification exists in the list, it must be valid, but we'll add a fake classificationID of 0 which will mean "no classification"
            //If the current classification doesn't exist in the list, it's not valid, but we'll use that classificationID and call it "Uploaded"
            let uploadedListItem = list.find(i => i.description === "Uploaded");
            if (!uploadedListItem)
            {
                let currentListItem = list.find(i => originalClassificationID && i.classificationID === originalClassificationID);
                if (!currentListItem && originalClassificationID)
                {
                    uploadedListItem = {classificationID: originalClassificationID, description: "Uploaded", classificationType: ClassificationTypeEnum.AfterUpload};
                }
                else
                {
                    uploadedListItem = {classificationID: 0, description: "Uploaded", classificationType: ClassificationTypeEnum.AfterUpload};
                }
                list = [uploadedListItem, ...list];
            }
        }

        return list;

    }, [originalClassificationID, isUploaded, quoteClassifications]);

    return <>
        {(getQuoteClassifications && getQuoteClassifications.length > 1) &&
            <FormControl 
                variant="outlined"
                error={errors[name] ? true : false}
                {...rest}
            >
                <InputLabel>{tm.Get("Status")}</InputLabel>
                <Select
                    id="status"
                    onChange={handleChange}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                    ref={field.ref}
                    value={field.value ?? 0}
                    label={tm.Get("Status")}
                    disabled={!permissions?.classification ?? true}
                    >
                    {getQuoteClassifications?.map((mt) =>
                        <MenuItem
                            key={mt.classificationID}
                            value={mt.classificationID}
                        >
                            {Constants.Strings.Classifications.includes(mt.description) ? tm.Get(mt.description) : mt.description}
                        </MenuItem>
                    )}
                </Select>
                <FormHelperText>{errors[name]?.message}</FormHelperText>
            </FormControl>    
        }
    
    </>

}

export default SelectQuoteStatus;