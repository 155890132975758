import React, { useCallback, useEffect, useMemo } from "react";
import { Container, Box, Typography, Card } from "@mui/material";

import Selections from "components/GlobalOptions/Selections/Selections";
import ResultsGrid, { ResultsModeEnum } from "components/GlobalOptions/Results/ResultsGrid";
import EditOptionValueDialog from "components/GlobalOptions/Results/EditOptionValueDialog/EditOptionValueDialog";

import { useTranslations } from "@fenetech/translations";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useGlobalOptions from "components/GlobalOptions/useGlobalOptions";
import useQuoteActions from "components/Quotes/useQuoteActions";
import { ThemeColorEnum } from "helpers/enums";
import { IActionButton } from "helpers/context/Page/PageContext";
import { IQuote } from "helpers/interfaces";

import { useNavigate } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";

interface IProps {
    quote: IQuote,
}

const GlobalOptionsWizard: React.FC<any> = ({ quote }: IProps) => {

    const actionButtons = useActionButtons();
    const tm = useTranslations();
    const { isLoading, globalItemToEdit, setGlobalItemToEdit, onCommitEditValue, canSave, onSave } = useGlobalOptions();
    const quoteActions = useQuoteActions();
    const navigate = useNavigate();

    const handleSaveClick = useCallback(() => {
        if (canSave) {
            onSave(quote.oKey);
        }
    }, [canSave, quote.oKey, onSave]);

    useEffect(() => {
        actionButtons.Clear();

        if (quote) {
            const submitButton: IActionButton = {
                text: tm.Get("Save"),
                color: ThemeColorEnum.Secondary,
                disabled: isLoading || !canSave,
                onClick: handleSaveClick
            };
            actionButtons.Set(0, submitButton);

            const cancelButton: IActionButton = {
                text: tm.Get("Cancel"),
                color: ThemeColorEnum.Primary,
                disabled: isLoading,
                onClick: () => navigate(QuoteNavigation.QuoteEntryURL(quote?.oKey ?? 0)),
            };
            actionButtons.Set(1, cancelButton);
        }

    }, [quote, actionButtons, quoteActions, tm, isLoading, canSave, handleSaveClick, navigate]);

    const isItemEditDialogOpen = useMemo(() => {
        return globalItemToEdit ? true : false
    }, [globalItemToEdit]);

    return <>
        <Container maxWidth="xl">
            <Box display="flex" flexDirection="column" p={1} gap={1} mt={1}>
                <Selections />
                <Card>
                    <Typography>
                        {tm.Get("These items will be saved")}
                    </Typography>
                    <ResultsGrid mode={ResultsModeEnum.Savable} />
                </Card>
                <Card>
                    <Typography>
                        {tm.Get("These issues must be resolved before the items will be saved")}
                    </Typography>
                    <ResultsGrid mode={ResultsModeEnum.Unsavable} />
                </Card>
            </Box>
        </Container>
        {isItemEditDialogOpen &&
            <EditOptionValueDialog
                isOpen={isItemEditDialogOpen}
                onClose={() => setGlobalItemToEdit(undefined)}
                globalItem={globalItemToEdit}
                onCommit={onCommitEditValue}
            />
        }
    </>;
};

export default GlobalOptionsWizard;
