import * as React from "react";
import { Theme, alpha } from "@mui/material"
import { ISectionRegion, ICoordinate } from "../interfaces";
import { MapAreaShapeTypeEnum } from "../enum";
import { MouseEventHandler, TouchEventHandler } from "react";
import ScalableImage from "./ScalableImage";


interface IProps {
    src: string | undefined;
    clickableRegions: ISectionRegion[];
    clickRegionHandler?: (clickableReg: ISectionRegion) => void;
    selected: number[] | null;
    theme: Theme;
    renderAsDiv: boolean;
    imageStyle?: React.CSSProperties;
}

interface IState {
    ratio: number;
    offsetX: number;
    offsetY: number;
    hoveredRegion: ISectionRegion | null;
}

interface IRect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export default class ClickableImage extends React.Component<IProps, IState> {

    private imgRef: React.RefObject<HTMLImageElement>;
    private divRef: React.RefObject<HTMLDivElement>;
    private cvRef: React.RefObject<HTMLCanvasElement>;
    private ctx: CanvasRenderingContext2D | null = null;
    private resizeObserver: ResizeObserver | null = null;

    static readonly WEB_DESIGNER_IMAGE_SIZE: number = 750;  // If you ever change this search all the code for other constants with the same name
    static readonly DEFAULT_IMG_SIZE: number = ClickableImage.WEB_DESIGNER_IMAGE_SIZE;

    constructor(props: IProps) {
        super(props);
        this.imgRef = React.createRef<HTMLImageElement>();
        this.divRef = React.createRef<HTMLDivElement>();
        this.cvRef = React.createRef<HTMLCanvasElement>();

        this.state = { ratio: 1, offsetX: 0, offsetY: 0, hoveredRegion: null };
        this.resizeObserver = new ResizeObserver(() => {
            this.handleCanvasResized();
        });
    }

    public componentDidMount() {
        this.initCanvas();
        if (this.divRef.current) {
            this.resizeObserver?.observe(this.divRef.current);
        }
    }

    public componentWillUnmount() {
        if (this.divRef.current) {
            this.resizeObserver?.unobserve(this.divRef.current);
        }
    }

    public componentDidUpdate() {
        //handling resize here corrects the ratio when the image updated while it was excluded from the dom
        //a better fix might be to avoid updating the image when its in this state (and wait until the user clicks the image tab)
        this.handleCanvasResized();
        this.drawCanvas(this.ctx, this.state.hoveredRegion, this.state.ratio, this.state.offsetX, this.state.offsetY);
    }

    render(): React.ReactNode {

        return <div ref={this.divRef} style={{ display: "flex", height: "100%", flexGrow: 1 }} >

            <ScalableImage
                src={this.props.src}
                renderAsDiv={this.props.renderAsDiv}
                ref={this.imgRef}
                onLoad={this.onImageLoad}
                imageStyle={this.props.imageStyle}
            />

            <canvas ref={this.cvRef}
                style={{ position: "absolute", top: "0px", left: "0px", pointerEvents: "auto" }}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}
                onMouseDown={this.onMouseDown}
                onTouchStart={this.onTouchStart}
            />

        </div>
    }

    private onMouseMove: MouseEventHandler<HTMLCanvasElement> = (e: React.MouseEvent<HTMLCanvasElement>) => {

        if (this.cvRef.current && this.ctx && this.props.clickRegionHandler) {
            let hoveredRegion: ISectionRegion | null = this.getHoveredRegion(e.clientX, e.clientY);
            this.setState({ hoveredRegion });
            if (hoveredRegion !== null) {
                this.cvRef.current.style.cursor = "pointer";
            }
            else {
                this.cvRef.current.style.cursor = "default";
            }
        }
    };

    private onMouseLeave: MouseEventHandler<HTMLCanvasElement> = () => {
        if (this.props.clickRegionHandler) {
            if (this.cvRef.current) {
                this.cvRef.current.style.cursor = "default";
            }
            this.setState({ hoveredRegion: null });
        }
    };

    private onMouseDown: MouseEventHandler<HTMLCanvasElement> = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (e.target === this.cvRef.current) {
            e.preventDefault();
        }
        const clickedRegion = this.getHoveredRegion(e.clientX, e.clientY);
        if (clickedRegion !== null && this.props.clickRegionHandler) {
            this.props.clickRegionHandler(clickedRegion);
        }
    };

    private onTouchStart: TouchEventHandler<HTMLCanvasElement> = (e: React.TouchEvent<HTMLCanvasElement>) => {
        if (e.target === this.cvRef.current) {
            e.preventDefault();
        }
        const touch = e.touches[0];
        const touchedRegion = this.getHoveredRegion(touch.clientX, touch.clientY);

        if (touchedRegion !== null && this.props.clickRegionHandler) {
            this.props.clickRegionHandler(touchedRegion);
        }
    };

    private getHoveredRegion(clientX: number, clientY: number) {
        let hoveredRegion: ISectionRegion | null = null;
        if (this.ctx && this.cvRef.current) {
            const boundingRect = this.cvRef.current.getBoundingClientRect();
            const x = clientX - boundingRect.left;
            const y = clientY - boundingRect.top;
            for (const r of this.props.clickableRegions) {
                const path = ClickableImage.getRegionAsPath(r, this.state.ratio, this.state.offsetX, this.state.offsetY);
                if (this.ctx.isPointInPath(path, x, y)) {
                    hoveredRegion = r;
                    break;
                }
            }
        }
        return hoveredRegion;
    }

    private drawCanvas(ctx: CanvasRenderingContext2D | null, hoveredRect: ISectionRegion | null, ratio: number, offsetX: number, offsetY: number) {

        if (!this.cvRef.current || !ctx)
            return;

        const width: number = this.cvRef.current.width;
        const height: number = this.cvRef.current.height;
        ctx.clearRect(0, 0, width, height);

        if (this.props.selected && this.props.selected.length > 0) {
            if (!(this.props.selected.length === 1 && this.props.selected[0] === 0)) {

                let selectedRegions: ISectionRegion[] = [];
                if (this.props.selected !== null) {
                    const selected = this.props.selected;
                    selectedRegions = this.props.clickableRegions.filter(cr => selected.indexOf(cr.id) > -1);
                }

                ClickableImage.shadeNonSelectedRegions(ctx, selectedRegions, ratio, offsetX, offsetY, width, height, this.props.theme);
                ClickableImage.drawSelectedRegions(ctx, selectedRegions, ratio, offsetX, offsetY);
            }
        }

        // only show hovered recs if we are in a mode where we can click.
        if (this.props.clickRegionHandler !== undefined) {
            ClickableImage.drawHoveredRegion(ctx, hoveredRect, ratio, offsetX, offsetY);
        }

    }

    private static shadeNonSelectedRegions(ctx: CanvasRenderingContext2D, regions: ISectionRegion[], ratio: number, offsetX: number, offsetY: number, width: number, height: number, theme: Theme) {

        ctx.save(); //save context so we can restore after we are done with our clipping region

        // create path containing the selected sections
        const clip = new Path2D();
        for (const r of regions) {
            clip.addPath(this.getRegionAsPath(r, ratio, offsetX, offsetY));
        }

        // add rect to canvas perimeter to get evenodd clipping to invert, so we can draw everything except selected regions
        clip.rect(0, 0, width, height);
        ctx.clip(clip, "evenodd");

        //white out non-selected area
        ctx.fillStyle = alpha(theme.palette.background.default, 0.5);
        ctx.fillRect(0, 0, width, height);

        //restore so we can draw outside of clip region
        ctx.restore();
    }

    private static drawSelectedRegions(ctx: CanvasRenderingContext2D, regions: ISectionRegion[], ratio: number, offsetX: number, offsetY: number) {
        for (const r of regions) {
            if (r) {
                ctx.lineWidth = 7;
                ctx.strokeStyle = "rgba(0,0,255, 0.85)";
                ClickableImage.strokeScaledRegion(ctx, r, ratio, offsetX, offsetY);
            }
        }
    }

    private static drawHoveredRegion(ctx: CanvasRenderingContext2D, r: ISectionRegion | null, ratio: number, offsetX: number, offsetY: number) {
        if (r) {
            ctx.fillStyle = 'rgba(0, 0, 255, 0.3)';
            ctx.strokeStyle = 'pink';
            ctx.lineWidth = 4;
            this.fillScaledRegion(ctx, r, ratio, offsetX, offsetY);
        }
    }

    private static getRegionAsPath(r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        switch (r.shape) {
            case MapAreaShapeTypeEnum.Rect:
                return this.getRectRegionAsPath(r, ratio, offsetX, offsetY);
            case MapAreaShapeTypeEnum.Poly:
                return this.getPolyRegionAsPath(r, ratio, offsetX, offsetY);
            case MapAreaShapeTypeEnum.Circle:
                return this.getCircleRegionAsPath(r, ratio, offsetX, offsetY);
        }
    }

    private static getRectRegionAsPath(r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        const rect: IRect = this.getScaledRect(r, ratio, offsetX, offsetY);
        const path = new Path2D();
        path.rect(rect.x, rect.y, rect.width, rect.height);
        return path;
    }

    private static getPolyRegionAsPath(r: ISectionRegion, ratio: number, offsetX: number, offsetY) {
        // need at least at least 3 points to draw polygon 
        const path = new Path2D();
        if (r.coords.length > 2) {
            const scaledPoints: ICoordinate[] = r.coords.map(c => ({ x: (c.x * ratio) + offsetX, y: (c.y * ratio) + offsetY } as ICoordinate));
            path.moveTo(scaledPoints[0].x, scaledPoints[0].y);
            for (let i = 1; i < scaledPoints.length; i++) {
                path.lineTo(scaledPoints[i].x, scaledPoints[i].y);
            }
            path.closePath();
        }
        return path;
    }

    private static getCircleRegionAsPath(r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        const path = new Path2D();
        const xCenter: number = r.coords[0].x * ratio;
        const yCenter: number = r.coords[1].y * ratio;
        const rScaled: number = r.radius * ratio;
        path.arc(xCenter, yCenter, rScaled, 0, 2 * Math.PI, false);
        return path;
    }

    private static fillScaledRegion(ctx: CanvasRenderingContext2D, r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        ctx.beginPath();
        ctx.fill(this.getRegionAsPath(r, ratio, offsetX, offsetY));
    }

    private static strokeScaledRegion(ctx: CanvasRenderingContext2D, r: ISectionRegion, ratio: number, offsetX: number, offsetY: number) {
        ctx.beginPath();
        ctx.stroke(this.getRegionAsPath(r, ratio, offsetX, offsetY));
    }

    private static getScaledRect(r: ISectionRegion, ratio: number, offsetX: number, offsetY: number): IRect {
        const width: number = (r.coords[1].x - r.coords[0].x) * ratio;
        const height: number = (r.coords[1].y - r.coords[0].y) * ratio;
        const x: number = (r.coords[0].x * ratio) + offsetX;
        const y: number = (r.coords[0].y * ratio) + offsetY;
        return { x, y, width, height };
    }

    private initCanvas() {

        if (!this.cvRef.current || !this.imgRef.current || !this.divRef.current)
            return;

        this.cvRef.current.width = this.imgRef.current.clientWidth;
        this.cvRef.current.height = this.imgRef.current.clientHeight;
        this.divRef.current.style.width = this.imgRef.current.clientWidth + ' px';
        this.divRef.current.style.height = this.imgRef.current.clientHeight + ' px';

        const canvas: HTMLCanvasElement = this.cvRef.current;

        this.ctx = canvas.getContext('2d');
        if (!this.ctx)
            throw Error("Could not get canvas drawing context.");

        this.ctx.fillStyle = this.props.theme.palette.background.default;
        this.ctx.strokeStyle = 'pink';
        this.ctx.lineWidth = 4;

        if (this.props.renderAsDiv) {
            const ratio = this.getRatioFromImage(this.imgRef.current);
            const offsetX = this.getOffsetXFromImage(ratio, this.imgRef.current);
            const offsetY = this.getOffsetYFromImage(ratio, this.imgRef.current);
            this.setState({ ratio, offsetX, offsetY });
        }

    }

    onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        let img: HTMLImageElement = e.target as HTMLImageElement;
        const ratio = this.getRatioFromImage(img);
        const offsetX = this.getOffsetXFromImage(ratio, img);
        const offsetY = this.getOffsetYFromImage(ratio, img);
        this.setState({ ratio, offsetX, offsetY });
        this.initCanvas();
        this.drawCanvas(this.ctx, this.state.hoveredRegion, ratio, offsetX, offsetY);
    }

    private getRatioFromImage(img: HTMLImageElement) {
        if (this.props.renderAsDiv) {
            const smallestDimension = Math.min(img.clientWidth, img.clientHeight);
            const ratio: number = this.calculateRatio(smallestDimension);
            return ratio;
        }
        else {
            const ratio: number = this.calculateRatio(img.width);
            return ratio;
        }
    }

    private getOffsetXFromImage(ratio: number, img: HTMLImageElement) {
        if (this.props.renderAsDiv) {
            const offsetX: number = (img.clientWidth - (ClickableImage.DEFAULT_IMG_SIZE * ratio)) / 2;
            return offsetX;
        }
        else {
            return 0;
        }
    }

    private getOffsetYFromImage(ratio: number, img: HTMLImageElement) {
        if (this.props.renderAsDiv) {
            const offsetY: number = (img.clientHeight - (ClickableImage.DEFAULT_IMG_SIZE * ratio)) / 2;
            return offsetY;
        }
        else {
            return 0;
        }
    }

    private handleCanvasResized = () => {
        if (!this.imgRef.current)
            return;

        const newRatio: number = this.getRatioFromImage(this.imgRef.current);
        const newOffsetX: number = this.getOffsetXFromImage(newRatio, this.imgRef.current);
        const newOffsetY: number = this.getOffsetYFromImage(newRatio, this.imgRef.current);

        if (newRatio !== this.state.ratio || newOffsetX !== this.state.offsetX || newOffsetY !== this.state.offsetY) {
            this.setState({ ratio: newRatio, offsetX: newOffsetX, offsetY: newOffsetY });
            this.initCanvas(); //Handle resize of canvas to match new image size
            this.drawCanvas(this.ctx, this.state.hoveredRegion, newRatio, newOffsetX, newOffsetY);
        }
    }

    private calculateRatio(imgWidth: number): number {
        return imgWidth / ClickableImage.DEFAULT_IMG_SIZE;
    }

}
