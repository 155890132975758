import { ICompanyInfo } from "models/ICompany";

export const BlankCompany: ICompanyInfo = {
    name: "",
    addressInfo: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
        faxNumber: "",
        emailAddress: ""
    },
    url: "",
    customerGUID: "",
    parentPONumber: 0,
    defaultParentPONumber: "",
    parentCustomerRef: 0,
    defaultParentCustomerRef: "",
    defaultClassification: 0,
    defaultClassificationText: ""
}