import React from "react";

import CompanyQuotesGrid from "./CompanyQuotesGrid";
import CustomerQuotesGrid from "./CustomerQuotesGrid";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import SelectQuoteType from "./SelectQuoteType";
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslations } from "@fenetech/translations";

export enum QuoteDataTypeEnum {
  CompanyQuotes,
  CustomerQuotes,
  SubmittedQuotes,
}

interface IProps {

}


const DashboardQuotes: React.FC<IProps> = (_: IProps) => {

  const user = useUserInfo();
  const contractor = user.HasRole(RoleEnum.Contractor);
  const tm = useTranslations();
  const { dashboardQuoteType } = useSelectionValuesData();

  return <>
    <Box mt={1} mb={1}>
      {contractor ?
        (
          <Typography variant="h6" >
            {tm.Get("Customer Quotes")}
          </Typography>
        ) :
        (
          <>
            <Grid container item xs={3} alignItems="stretch" direction="column"  >
              <SelectQuoteType />
            </Grid>
            <Grid item xs />
          </>
        )
      }

    </Box>

    {dashboardQuoteType === QuoteDataTypeEnum.CompanyQuotes && <CompanyQuotesGrid />}
    {dashboardQuoteType === QuoteDataTypeEnum.CustomerQuotes && <CustomerQuotesGrid />}

  </>;


};


export default DashboardQuotes;
