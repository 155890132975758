import * as React from "react";

import NonTiledOptionValue from "./NonTiledOptionValue";
import NonTiledLockedOption from "./NonTiledLockedOption";
import { ICode } from "../interfaces";
import { Container, FormControlLabel, Grid, GridSize } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import useWizardInteractions from "../useWizardInteractions";
import WizardHelper from "../WizardHelper";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useIsMobile from "helpers/hooks/useIsMobile";

interface ICodeProps {
    code: ICode;
    locked: boolean;
    isWithinMultiSelect: boolean;
    readOnlyMode: boolean;
}


const NonTiledSingleOption: React.FC<ICodeProps> = (props: ICodeProps) => {

    const appInfo = useApplicationInfo();
    const wizardInteractions = useWizardInteractions();
    const isMobile = useIsMobile();

    const handleCodeClick = (event: React.MouseEvent<any>) => {
        event.preventDefault();

        if (!props.readOnlyMode && !props.locked) {
            let c: ICode = props.code;

            if (c.selected)
                wizardInteractions.RemoveCodeAsync(c.sequence, c.code);
            else
                wizardInteractions.SelectCodeAsync(c.sequence, c.code);

            wizardInteractions.SetFocusedCode(null);
        }
    }

    const handleCodeFocus = () => {
        wizardInteractions.SetFocusedCode(props.code);
    }

    const handleCodeBlur = () => {
        wizardInteractions.SetFocusedCode(null);
    }

    const c: ICode = props.code;

    const showValue = (c.selected && (c.userInput || c.config));

    const renderOptionValue = () => {
        /*Using a key here, will force the component to be recreated when value is updated from the API due to RunExpressions.  This is required to refresh its internal input state variable */
        return <NonTiledOptionValue
            code={c}
            withinTiled={false}
            key={c.value}
            locked={props.locked && !c.substitutePartEnableWizard}
            readOnlyMode={props.readOnlyMode}
        />
    }

    const renderQuestion = () => {
        return <FormControlLabel
            onFocus={handleCodeFocus} onBlur={handleCodeBlur}
            control={
                <Checkbox
                    sx={(theme) => {
                        return {
                            padding: theme.spacing(.375),
                            marginLeft: theme.spacing(.625)
                        };
                    }}
                    size="small"
                    checked={c.selected}
                    disabled={props.readOnlyMode || props.locked}
                />
            }
            label={WizardHelper.GetOptionCodeDescription(c, appInfo.parameters.hideOptionCodes)}
            onClick={handleCodeClick}
        />
    }


    const renderEnabledCell = () => {
        let questionXsValue: boolean | GridSize | undefined;
        let valueXsValue: boolean | GridSize | undefined;
        let valueMbValue: string | number;

        if (showValue) {
            questionXsValue = 6;
            valueXsValue = 6;
        } else {
            questionXsValue = 12;
        }

        valueMbValue = !isMobile && props.isWithinMultiSelect ? 1 : 0;

        return <Grid container direction={(isMobile && showValue) ? "column" : "row"} spacing={1}>
            <Grid item xs={questionXsValue}>
                {renderQuestion()}
            </Grid>

            {(showValue) &&
                <Grid item xs={valueXsValue} mb={valueMbValue}>
                    {renderOptionValue()}
                </Grid>
            }
        </Grid>;
    }

    if (props.locked && !c.substitutePartEnableWizard) {
        if (c.selected) {
            return <Container disableGutters className="ow-q-body"><NonTiledLockedOption code={c} /></Container>
        } else {
            //This code has been locked with without being selected, hide code
            return null
        }
    }
    else {
        return renderEnabledCell();
    }

}

export default NonTiledSingleOption;
