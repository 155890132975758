import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { IOptionValueSelection } from "models/GlobalOptions/IGlobalOptionsSelections";
import { useTranslations } from "@fenetech/translations";
import useGlobalOptions from "../useGlobalOptions";

const OldOptionValueSelection: React.FC<any> = () => {

    const tm = useTranslations();
    const { selections, onOldOptionValueChange } = useGlobalOptions();

    const selectedOldOptionValue = useMemo(() => {
        let selection = selections.oldOptionValueSelections?.find((q) => q.value === selections.selectedOldOptionValue);

        if (!selection) {
            selection = { description: "", value: "" };
        }
        return selection;

    }, [selections]);

    const isValueVisible = useMemo(() => {
        return selections.isOldValueVisible
    }, [selections]);

    if (!isValueVisible) {
        return null;
    }

    return <>
        <Autocomplete
            autoComplete
            disableClearable
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={selections.oldOptionValueSelections}
            getOptionLabel={(s: IOptionValueSelection) => s.description}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={tm.Get("Old Value")}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />
            )}
            onChange={(_, value) => onOldOptionValueChange(value.value)}
            value={selectedOldOptionValue}
        />
    </>;
};

export default OldOptionValueSelection;
