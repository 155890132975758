import usePartDefaults, { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import { useMemo } from "react";
import DesignerItemSize from "../MainWizard/DesignerItemSize";
import ItemProperties from "./ItemProperties";
import ODOpeningProperties from "./ODOpeningProperties";
import useWizardState from "../WebDesigner/useWizardState";
import usePartCallSizes from "helpers/context/Parts/usePartCallSizes";
import { AltWizActionTypeEnum } from "../enum";

interface IProps {
}

const ItemPropertiesSelector: React.FC<IProps> = (props: IProps) => {

    const wizardState = useWizardState();

    const partDefaults = usePartDefaults(wizardState.itemInfo?.partNo, wizardState.itemInfo?.partNoSuffix, wizardState.itemInfo?.shortcutName);
    const partCallSizes = usePartCallSizes(wizardState.itemInfo?.partNo, wizardState.itemInfo?.partNoSuffix);

    const partDefaultsClass = useMemo(() => {
        return new PartDefaults(partDefaults)
    }, [partDefaults]);

    const sizeEditableInOptionStructure = useMemo(() => {

        if (wizardState.sequencedWizardInfo) {
            return wizardState.sequencedWizardInfo.currentAction === AltWizActionTypeEnum.MainLineItemOptions;
        }
        if (wizardState.webDesigner) {
            return wizardState.webDesigner.currentSection === 0 && wizardState.webDesigner.canChangeMLISize === true;
        }
        else {
            return true;
        }

    }, [wizardState.sequencedWizardInfo, wizardState.webDesigner]);

    const showODOpeningItemProperties = useMemo(() => {
        if (wizardState.webDesigner) {
            return wizardState.webDesigner.currentSection === 0 && wizardState.webDesigner.canChangeSLISize === true;
        }
        else {
            return false;
        }
    }, [wizardState.webDesigner]);

    const showDesignerSubLineItemSize = useMemo(() => {
        if (wizardState.webDesigner) {
            return wizardState.webDesigner.currentSection !== 0;
        }
        else {
            return false;
        }
    }, [wizardState.webDesigner]);


    if (wizardState?.itemInfo && partDefaults && partCallSizes) {
        if (sizeEditableInOptionStructure) {
            if (showODOpeningItemProperties) {
                return <ODOpeningProperties
                    key="GRPS_SIZE"
                    isExistingItem={true}
                    unitSetID={wizardState.itemInfo.unitSet}
                    partDefaults={partDefaultsClass}
                    partCallSizes={partCallSizes}
                    autoSubmit={true} />

            }
            else {
                return <ItemProperties
                    key="GRPS_SIZE"
                    isSubLineItem={(wizardState.webDesigner && wizardState.webDesigner.currentSection !== 0) ?? false}
                    isExistingItem={true}
                    unitSetID={wizardState.itemInfo.unitSet}
                    partDefaults={partDefaultsClass}
                    partCallSizes={partCallSizes}
                    autoSubmit={true}
                    sizeEditable={true} />
            }
        }
        else {
            if (showDesignerSubLineItemSize) {
                return <DesignerItemSize
                    key="GRPS_SIZE"
                />
            }
            else {
                return <ItemProperties
                    key="GRPS_SIZE"
                    isSubLineItem={(wizardState.webDesigner && wizardState.webDesigner.currentSection !== 0) ?? false}
                    isExistingItem={true}
                    unitSetID={wizardState.itemInfo.unitSet}
                    partDefaults={partDefaultsClass}
                    partCallSizes={partCallSizes}
                    autoSubmit={true}
                    sizeEditable={false} />
            }
        }
    }

    return <></>;
};

export default ItemPropertiesSelector;