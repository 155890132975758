import { Grid, Typography, useTheme } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import React from "react";
import { useTranslations } from "@fenetech/translations";
import { ISearchResult, SearchTypeEnum } from "./Search";

import CustomDataGridPro from "components/Common/CustomDataGridPro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useIsMobile from "helpers/hooks/useIsMobile";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

interface IProps {
    results: ISearchResult[];
    searchType: SearchTypeEnum;
}

const SearchGrid: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const rowsPerPage = useRowsPerPage("SearchGrid");

    if (props.results.length === 0)
        return <>
            <Grid container>
                <Grid item textAlign="center">
                    <Typography variant="h4">{tm.Get("No results were found.")}</Typography>
                </Grid>
            </Grid>
        </>
    else {

        const generator = new DataGridColumnGenerator(tm, props.results, theme, isMobile);
        const uri = props.searchType === SearchTypeEnum.Quote ? "/quotes" : "/orders";
        generator.TryAddDocumentNumberColumn('key', 'documentNumber', SearchTypeEnum[props.searchType], uri, {hideInMobile: false, hideable: false})
        generator.TryAddDateColumn("orderDate", "Date",{hideInMobile:false});
        generator.TryAddTextColumn("customerName", "Customer",{hideInMobile:false});
        generator.TryAddTextColumn("poNumber", "PO Number",{hideInMobile:false});
        generator.TryAddTextColumn("customerRef", "Customer Ref", {hideInMobile:false});


        const getRowId: GridRowIdGetter = (row: GridRowModel) => {
            return row.key;
        }

        const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

        return <CustomDataGridPro
            getRowId={getRowId}
            rows={props.results}
            columns={generator.GetColumns()}
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
        />;
    }
}


export default SearchGrid;