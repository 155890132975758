import React, { useState } from 'react';
import { Grid, Stack, TextField } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';

import { IShapeDisplayInfo } from "models/IShapeDisplayInfo";
import { useTranslations } from "@fenetech/translations";
import useIsMobile from "helpers/hooks/useIsMobile";

interface IProps {
    displayShapeLibrary: IShapeDisplayInfo[],
    page: number,
    resultsPerPage: number,
    totalResults: number,
    handleSearchBlur: (e: any) => void,
    handleStraightSidesBlur: (e: any) => void,
    handleCurvedSidesBlur: (e: any) => void,
    setPage: React.Dispatch<number>,
    handleChangeRowsPerPage: (e: any) => void,
}

const ShapeFilters: React.FC<any> = (props: IProps) => {
    const isMobile = useIsMobile();
    const tm = useTranslations();

    const [displaySearchFilter, setDisplaySearchFilter] = useState<string>("");
    const [displayStraightSidesFilter, setDisplayStraightSidesFilter] = useState<string>("");
    const [displayCurvedSidesFilter, setDisplayCurvedSidesFilter] = useState<string>("");

    const handleStraightSidesFilterChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newFilter = parseInt(e.target.value);
        if (isNaN(newFilter)) {
            setDisplayStraightSidesFilter("");
        } else {
            setDisplayStraightSidesFilter(newFilter.toString());
        }
    };

    const handleCurvedSidesFilterChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newFilter = parseInt(e.target.value);
        if (isNaN(newFilter)) {
            setDisplayCurvedSidesFilter("");
        } else {
            setDisplayCurvedSidesFilter(newFilter.toString());
        }
    };

    return <Grid container direction="column" justifyItems="stretch">
        <Grid item>
            <Stack direction={isMobile ? "column" : "row"} justifyContent="center" gap={1}>
                <TextField size="small" style={{minWidth: 250, maxWidth: 250}} 
                    value={displaySearchFilter} label={tm.Get("Search")}
                    onChange={(e) => setDisplaySearchFilter(e.target.value)}
                    onBlur={props.handleSearchBlur}
                />
                <TextField size="small" style={{minWidth: 250, maxWidth: 250}} 
                    value={displayStraightSidesFilter} label={tm.Get("Straight Sides")}
                    onChange={handleStraightSidesFilterChange}
                    onBlur={props.handleStraightSidesBlur}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
                <TextField size="small" style={{minWidth: 250, maxWidth: 250}}
                    value={displayCurvedSidesFilter} label={tm.Get("Curved Sides")}
                    onChange={handleCurvedSidesFilterChange}
                    onBlur={props.handleCurvedSidesBlur}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
                <TablePagination 
                    count={props.totalResults}
                    page={props.page} 
                    onPageChange={(_, page) => props.setPage(page)}
                    rowsPerPage={props.resultsPerPage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 20, 50]}
                    labelRowsPerPage={tm.Get("Results per page:")}
                    showFirstButton
                    showLastButton
                />
            </Stack>
        </Grid>
    </Grid>
}

export default ShapeFilters