import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import useFeneVisionTheme from 'helpers/context/Theme/useFeneVisionTheme';
import { TitleContextProvider } from 'helpers/context/Title/TitleContext';
import { FeneVisionThemeContextProvider } from 'helpers/context/Theme/FeneVisionThemeContext';
import { TranslationContextProvider } from "@fenetech/translations";
import { HomeAddressContextProvider } from "./HomeAddress/HomeAddressContext";
import APIHelper from "helpers/APIHelper";

const NonAuthenticatingProviders: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {
    const apiRoot: string = APIHelper.GetBaseURL();
    const cleanApiRoot: string = apiRoot[apiRoot.length - 1] === '/' ? apiRoot.substring(0, apiRoot.length - 1) : apiRoot;

    return <FeneVisionThemeContextProvider>
        <TranslationContextProvider apiRoot={cleanApiRoot} applicationFlag={1}>
                <TitleContextProvider>
                    <HomeAddressContextProvider>
                        <InnerThemeProviders>
                            {props.children}
                        </InnerThemeProviders>
                    </HomeAddressContextProvider>
                </TitleContextProvider>
        </TranslationContextProvider>
    </FeneVisionThemeContextProvider >;
}

const InnerThemeProviders: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [themeContext] = useFeneVisionTheme();

    return <>
        {themeContext.theme &&
            <ThemeProvider theme={themeContext.theme}>
                {props.children}
            </ThemeProvider>
        }
    </>;
}


export default NonAuthenticatingProviders;
