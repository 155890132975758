import React from "react";
import { useTranslations } from "@fenetech/translations";
import { GridActionsCellItem, GridColumnHeaderParams, GridEnrichedColDef, GridRowParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Delete, Edit } from "@mui/icons-material";
import AlertDialog from "../../components/Common/AlertDialog";
import { Tooltip, useTheme, Link } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import { Link as RouterLink } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import useMessageBox from "helpers/context/Page/useMessageBox";
import { IWebCustomerSummary } from "models/IWebCustomer";
import CustomerInactiveIcon from "./CustomerInactiveIcon";

type EditActionHandler = (customerID: string) => void;
type DeleteActionHandler = (customerID: string) => Promise<void>;

interface IEditCommandProps {
    params: GridRowParams;
    editActionHandler: EditActionHandler;
}

interface IDeleteCommandProps {
    params: GridRowParams;
    deleteActionHandler: DeleteActionHandler;
}

export const CustomerNameColumn = (field: string, title: string, isMultiSite: boolean) => {

    const tm = useTranslations();
    const messageBox = useMessageBox();
    const isMobile = useIsMobile();
    const theme = useTheme();

    const customerTextColor = (customer: IWebCustomerSummary) => {
        if (customer.inactive) {
            return "error";
        }
        return undefined;
    }

    const col = {
        field: field,
        headerName: tm.Get(title),
        mindWidth: isMobile ? 90 : 130,
        flex: 2,
        hideable: false,
        renderCell: (params: GridRenderCellParams) => {
            if (!params || !params.value) return <></>;

            const customer = params.row as IWebCustomerSummary;
            const translatedToolTip = isMultiSite ? tm.GetWithParams("Default customer for site {0}", customer.parentSiteName) : tm.Get("Default Customer");
            const customerID: string = encodeURIComponent(customer.customerID) as string;
            const url = `view/${customerID}`;

            return <>
                <Link variant="body2" underline="hover" component={RouterLink} to={url} color={customerTextColor(customer)}>
                    {customer.name}
                </Link>
                {customer.default &&
                    <Tooltip title={translatedToolTip} sx={{ marginLeft: 1 }}>
                        <InfoIcon style={{ color: theme.palette.info.main }} fontSize="small" onClick={() => {
                            if (isMobile)
                                messageBox.Show({
                                    title: customer.name,
                                    message: translatedToolTip
                                });
                        }} />
                    </Tooltip>
                }
                {customer.inactive &&
                    <CustomerInactiveIcon
                        name={customer.name}
                    />
                }
            </>
        }
    }
    return col;
}

export const customerCommandsColumn = (editHandler: EditActionHandler | null, deleteHandler: DeleteActionHandler | null) => {

    const minWidth = 92;

    const EditCommand: React.FC<IEditCommandProps> = ({ params, editActionHandler }: IEditCommandProps) => {
        const tm = useTranslations();
        const customer = params.row as IWebCustomerSummary;
        const customerID: string = customer.customerID as string;

        const handlerShowDialog = React.useCallback(() => {
            editActionHandler(customerID);
        }, [customerID, editActionHandler]);

        return <>
            <GridActionsCellItem
                icon={<Edit />}
                color="primary"
                label={tm.Get("Edit Customer")}
                onClick={handlerShowDialog}
            />
        </>
    };

    const DeleteCommand: React.FC<IDeleteCommandProps> = ({ params, deleteActionHandler }: IDeleteCommandProps) => {

        const tm = useTranslations();
        const [open, setOpen] = React.useState<boolean>(false);
        const customer = params.row as IWebCustomerSummary;
        const customerID: string = customer.customerID as string;

        const handlerShowDialog = React.useCallback(() => {
            setOpen(true);
        }, [setOpen]);

        const handleDialogClose = React.useCallback(async (result: boolean) => {
            if (result) {
                await deleteActionHandler(customerID);
            }
            setOpen(false);
        }, [customerID, deleteActionHandler])

        return <>
            <GridActionsCellItem
                icon={<Delete />}
                color="primary"
                label={tm.Get("Delete Customer")}
                onClick={handlerShowDialog}
            />
            <AlertDialog open={open}
                handleClose={handleDialogClose}
                headerText={tm.Get("Delete")}
                message={tm.Get("Are you sure you want to delete this customer?")}
            />
        </>
    }

    const col = {
        field: "Commands",
        type: "actions",
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                Commands
            </div>
        },
        minWidth: minWidth,
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {

            const actions: JSX.Element[] = [];

            if (editHandler) actions.push(<EditCommand params={params} editActionHandler={editHandler} />);
            if (deleteHandler) actions.push(<DeleteCommand params={params} deleteActionHandler={deleteHandler} />);

            return actions;
        },
    } as GridEnrichedColDef;

    return col;
}
