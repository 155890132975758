import React, { useMemo, useCallback } from 'react';
import { Dialog } from "@mui/material";

import Taxes, { ITaxesInfo } from "components/Quotes/QuoteEntry/Taxes/Taxes";

import { useTranslations } from "@fenetech/translations";
import useIsMobile from "helpers/hooks/useIsMobile";

import { ITaxDetailProps } from "components/Quotes/QuoteEntry/Taxes/TaxDetail";
import { ITaxDetail } from "helpers/interfaces";

interface IProps {
    taxDetails: ITaxDetail[];
    dialogVisible: boolean;
    isReadOnly: boolean;
    onCancel: () => void;
    onSubmit: (originalState: ITaxesInfo, newState: ITaxesInfo) => void;
}

const TaxesDialog: React.FC<IProps> = ({ taxDetails, dialogVisible, isReadOnly, onCancel, onSubmit }: IProps) => {

    const tm = useTranslations();
    const isMobile = useIsMobile();

    const getDefaultTaxTitle = useCallback((index: number) => {
        return tm.Get("Tax") + (index === 0 ? "" : " " + index.toString())
    }, [tm]);

    const initialState: ITaxesInfo = useMemo(() => {

        const taxDetailProps: ITaxDetailProps[] = [];

        taxDetails.forEach((t) => {
            taxDetailProps.push({
                index: t.index,
                title: t.title ? t.title : getDefaultTaxTitle(t.index),
                percent: t.percent,
                percentChanged: false,
            });
        });

        return {
            details: taxDetailProps,
        };
    }, [taxDetails, getDefaultTaxTitle]);

    return <>
        <Dialog
            open={dialogVisible}
            onClose={onCancel}
            fullWidth={isMobile}
            maxWidth={isMobile ? "xs" : undefined}            
        >
            <Taxes
                initialState={initialState}
                isReadOnly={isReadOnly}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </Dialog>
    </>;

}

export default TaxesDialog;