import React from "react";
import { ApplicationContext } from "helpers/context/Application/ApplicationContext";

export interface ICurrencyFormatter {
    Format: (content: number) => string;
    FormatToCulture: (content: number, forcedCulture: string) => string;
    Parse: (content: string) => number;
    IsCurrencyFormat: (content: string) => boolean;
}

const NUMBER_WITH_DECIMAL_SEPARATOR = 1.1;
const NUMBER_WITH_GROUP_SEPARATOR = 1000;

function getDecimalSeparator(culture: string) {
    return Intl.NumberFormat(culture).formatToParts(NUMBER_WITH_DECIMAL_SEPARATOR).find(part => part.type === "decimal")?.value ?? ".";
}

function getGroupSeparator(culture: string) {
    return Intl.NumberFormat(culture).formatToParts(NUMBER_WITH_GROUP_SEPARATOR).find(part => part.type === "group")?.value ?? ",";
}

function getLocalDollarSymbol(culture: string) {
    // I didn't expect to need this, but it seems some cultures don't report USD as "$" with the narrowSymbol; they still use "US$"
    return Intl.NumberFormat(culture, {style: "currency", currency: "USD", currencyDisplay: "narrowSymbol"})
        .formatToParts(NUMBER_WITH_GROUP_SEPARATOR).find(part => part.type === "currency")?.value ?? "$";
}

function calculateWithRounding(valueToRound: number, minimumDenomination: number) {
    if (valueToRound < 0) {
        // JavaScript has midpoint rounding of "towards positive infinity," which is not the same as "away from zero" for negative values
        return Math.round(Math.abs(valueToRound / minimumDenomination)) * minimumDenomination * -1;
    } else {
        return Math.round(valueToRound / minimumDenomination) * minimumDenomination;
    }
}

// TODO: Same sort of treatment with non-currency values
export default function useCurrencyFormatter(culture: string | undefined = undefined): ICurrencyFormatter {

    let [cf, setCF] = React.useState<ICurrencyFormatter | null>(null);
    let appInfo = React.useContext(ApplicationContext)!;

    if (!cf) {

        let numberFormatter: Intl.NumberFormat | null = null;
        let minimumDenomination: number = 0.01;
        let symbol: string = "";

        let displayCulture = navigator.language;
        let decimalSeparator = getDecimalSeparator(displayCulture);
        let groupSeparator = getGroupSeparator(displayCulture);
        let localDollarSymbol = getLocalDollarSymbol(displayCulture);

        if (culture !== undefined) {
            let currencyInfo = appInfo.currencyCultures.find(c => c.cultureInfo === culture) ?? appInfo.currencyCultures[0];
            let decimalPoints: number = currencyInfo.decimalPlaces;
            minimumDenomination = currencyInfo.minimumCurrencyDenominationForCashRounding;
            symbol = currencyInfo.symbol;
            numberFormatter = Intl.NumberFormat(displayCulture, {
                minimumFractionDigits: decimalPoints, 
                maximumFractionDigits: decimalPoints,
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                currencySign: "accounting"
            });
        }
        
        cf = {
            Format: (content: number) => {
                if (!numberFormatter) return "Programming Error: no culture given upon initialization";
                return numberFormatter.format(calculateWithRounding(content, minimumDenomination)).replace(localDollarSymbol, symbol);
            },
            FormatToCulture: (content: number, forcedCulture: string) => {
                let forcedCurrencyInfo = appInfo.currencyCultures.find(c => c.cultureInfo === forcedCulture) ?? appInfo.currencyCultures[0];
                let forcedDecimalPoints: number = forcedCurrencyInfo.decimalPlaces;
                let forcedMinimumDenomination: number = forcedCurrencyInfo.minimumCurrencyDenominationForCashRounding;
                let forcedSymbol: string = forcedCurrencyInfo.symbol;

                return Intl.NumberFormat(displayCulture, {
                    minimumFractionDigits: forcedDecimalPoints, 
                    maximumFractionDigits: forcedDecimalPoints,
                    style: "currency",
                    currency: "USD",
                    currencyDisplay: "narrowSymbol",
                    currencySign: "accounting"
                }).format(calculateWithRounding(content, forcedMinimumDenomination)).replace(localDollarSymbol, forcedSymbol);
            },
            Parse: (content: string) => {
                // Replace surrounding parentheses with negative sign and remove unneeded symbols
                let newStr = content.replaceAll(groupSeparator, '').replace(decimalSeparator, '.').replace(symbol, '');
                newStr = newStr.replace(/\(\s*(.*)\s*\)/g, "-$1").trim();
                
                if (newStr.at(newStr.length - 1) === "-") {
                    newStr = "-" + newStr.substring(0, newStr.length - 1).trim();
                }

                // Number constructor converts "" to 0, but it should probably be NaN for our purposes
                if (newStr === "") return Number.NaN;

                // Yes, -0 exists in JavaScript. We don't want that here
                let retNum = Number(newStr);
                if (retNum === -0) retNum = 0;
                return retNum;
            },
            IsCurrencyFormat: (content: string) => {
                return content.indexOf(symbol) >= 0;
            }
        };
        setCF(cf);
    }

    return cf;
};