import { TableCell, styled, alpha } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

export const StyledCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
}));

export const StyledCommentCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: alpha(theme.palette.secondary.light, 0.1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
}));