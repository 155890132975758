export default class OrderNavigation {

    public static OrdersURL(oKey: number, onlineAckToken?: string) {
        if (onlineAckToken !== undefined) {
            return `/orders?OnlineAckToken=${onlineAckToken}`;
        }
        else {
            return `/orders?oKey=${oKey}`;
        }
    }

    public static LineItemPriceBreakdownURL(oKey: number, odKey: number, onlineAckToken?: string) {
        if (onlineAckToken !== undefined) {
            return `/orders/pricebreakdown?OnlineAckToken=${onlineAckToken}&odKey=${odKey}`;
        }
        else {
            return `/orders/pricebreakdown?oKey=${oKey}&odKey=${odKey}`;
        }

    }

}