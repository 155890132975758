import APIHelper from "helpers/APIHelper";
import { IShapeDisplayInfo } from "models/IShapeDisplayInfo";
import { IShapeEditorInfo, IShapeParam } from "models/IShapeEditorInfo";

export default class ShapeAPI {

    public static async GetLibrary(): Promise<IShapeDisplayInfo[]> {

        let url = "api/Shape/library";

        let wr = await APIHelper.GetAsync<IShapeDisplayInfo[]>(url);

        return wr.Result;

    }

    public static async InitEditor(question: string, code: string): Promise<void> {

        let url = "api/Shape/initEditor";
        let params: any = {
            question: question,
            code: code
        };

        let wr = await APIHelper.GetAsync<void>(url, params);

        return wr.Result;

    }

    public static async GetEditor(): Promise<IShapeEditorInfo> {

        let url = "api/Shape/editor";
        let wr = await APIHelper.GetAsync<IShapeEditorInfo>(url);
        return wr.Result;

    }

    public static async EditorChangeShape(shapeNum: number): Promise<IShapeEditorInfo> {

        let url = "api/Shape/editorChangeShape";
        let wr = await APIHelper.PostAsync<IShapeEditorInfo>(url, undefined, shapeNum);
        return wr.Result;

    }

    public static async EditorChangeParam(shapeParam: IShapeParam): Promise<IShapeEditorInfo> {

        let url = "api/Shape/editorChangeParam";
        let wr = await APIHelper.PostAsync<IShapeEditorInfo>(url, undefined, shapeParam);
        return wr.Result;

    }
}