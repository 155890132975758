import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import React from "react";
import API from "../../API";

export const HomeAddressContext = React.createContext<string>("");

/*Create provider*/
export const HomeAddressContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [homeAddress, setHomeAddress] = React.useState<string | null>(null);

    useEffectOnLoad(() => {
        API.GetHomeAddress().then(t => {
            setHomeAddress(t);
        }).catch(() => {
            setHomeAddress(null);
        });
    });

    return <HomeAddressContext.Provider value={homeAddress ?? ""}>
        {props.children}
    </HomeAddressContext.Provider>
}


