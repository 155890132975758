import { Menu, useTheme } from "@mui/material";
import useMenuItems from "helpers/hooks/useMenuItems";
import NavMenuItem from "./NavMenuItem";

interface IProps {
    menuOpen: boolean;
    setMenuOpen: (open: boolean) => void;
    handleNavClick: (uri: string) => void;
    anchorElement: HTMLElement | null;
}

const NavMenu = ({ menuOpen, anchorElement, setMenuOpen, handleNavClick }: IProps) => {

    const theme = useTheme();
    const menuItems = useMenuItems();

    return <Menu
        open={menuOpen}
        anchorEl={anchorElement}
        onClose={() => setMenuOpen(false)}
        PaperProps={{
            elevation: 0,
            sx: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                },
                '&:before': {
                    bgcolor: theme.palette.secondary.main,
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },

            },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    >

        {menuItems.map((i) =>
            <NavMenuItem component="menu" key={i.label + i.uri} record={i} handleNavClick={handleNavClick} theme={theme} />
        )}


    </Menu>

};


export default NavMenu;
