import React from "react"
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link, Checkbox, FormControlLabel, Dialog, DialogContent, DialogActions } from "@mui/material"
import { useTranslations } from "@fenetech/translations";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import OKCancelControl from "components/Common/OKCancelControl";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";

interface IProps {
    open: boolean;
    close: () => void;
    handleUpload: () => void;
}

const UploadMessageBox: React.FC<IProps> = ({ open, close, handleUpload }) => {
    const tm = useTranslations();
    const application = useApplicationInfo();

    const basePath = document.getElementsByTagName("base")[0].href;
    const privacyPolicyFile = "files/PrivacyPolicy.pdf";
    const termsOfServiceFile = "files/TermsOfService.pdf";

    const [checked, setChecked] = React.useState<boolean>(false);
    const [privacyPolicyExists, setPrivacyPolicyExists] = React.useState<boolean | null>(null);
    const [termsOfServiceExists, setTermsOfServiceExists] = React.useState<boolean | null>(null);

    const uploadMessage = React.useMemo(() => {
        if (application && application.parameters.customUploadMessage) {
            return application.parameters.customUploadMessage;
        }

        return tm.Get("You are about to upload the selected quotes as orders. You are responsible for the content of each order. Do you want to proceed?");
    }, [application, tm]);

    const handleCheckChanged = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
    }, []);

    const checkFileExists = React.useCallback((fileUrl: string, setter: React.Dispatch<React.SetStateAction<boolean | null>>) => {
        // This function throws a red 404 error in the console log when this fails, even though that's one of the expected outcomes.
        // Doesn't seem to be much we can do about that, but when it's expected it can be safely ignored.
        var xhr = new XMLHttpRequest();
        xhr.onerror = () => {
            setter(false);
        };
        xhr.onload = () => {
            setter(xhr.status === 200 && xhr.getResponseHeader("content-type") === "application/pdf");
        }
        xhr.open('HEAD', fileUrl);
        xhr.send();
    }, []);

    React.useEffect(() => {
        checkFileExists(basePath + privacyPolicyFile, setPrivacyPolicyExists);
        checkFileExists(basePath + termsOfServiceFile, setTermsOfServiceExists);
    }, [basePath, checkFileExists]);

    const isToSModeEnabled = React.useMemo(() => {
        if (privacyPolicyExists && termsOfServiceExists) {
            return true;
        } else {
            return false;
        }
    }, [privacyPolicyExists, termsOfServiceExists]);

    const tosLabel = React.useMemo(() => {
        var origLabel = tm.Get("I agree with the {0} and {1}.");
        var privPolString = tm.Get("Privacy Policy");
        var tosString = tm.Get("Terms of Service");
        var brokenLabel = origLabel.split(/(?:\{0\}|\{1\})/g);
        return <>
            <Typography display="inline">{brokenLabel[0] ?? ""}</Typography>
            <Link underline="hover" component={RouterLink} rel="noopener noreferrer" target="_blank" to={{ pathname: `/files/PrivacyPolicy.pdf` }}>
                {privPolString}
            </Link>
            <Typography display="inline">{brokenLabel[1] ?? ""}</Typography>
            <Link underline="hover" component={RouterLink} rel="noopener noreferrer" target="_blank" to={{ pathname: `/files/TermsOfService.pdf` }}>
                {tosString}
            </Link>
            <Typography display="inline">{brokenLabel[2] ?? ""}</Typography>
        </>
    }, [tm])

    return <>
        {privacyPolicyExists !== null && termsOfServiceExists !== null &&
            <Dialog fullWidth
                onClose={close}
                open={open}
            >
                <DialogTitleWithClose onClose={close}>
                    {tm.Get("Quote Maintenance")}
                </DialogTitleWithClose>
                <DialogContent>
                    <Typography whiteSpace="pre-wrap">
                        {uploadMessage}
                    </Typography>
                    {isToSModeEnabled &&
                        <>
                            <br />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleCheckChanged}
                                    />
                                }
                                label={tosLabel}
                            />
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <OKCancelControl okText={tm.Get("Yes")} cancelText={tm.Get("No")} okDisabled={isToSModeEnabled && !checked} okCommand={handleUpload} cancelCommand={close} />
                </DialogActions>
            </Dialog>
        }
    </>
}

export default UploadMessageBox;