import * as React from "react";

import NonTiledSplitOption from "./NonTiledSplitOption";

import WizardHelper, { HeaderValuePair } from "../WizardHelper";
import { ICode } from "../interfaces";
import { Grid, TextField } from "@mui/material";
import OptionGroupBox from "components/OptionsWizard/OptionsWizard/OptionGroupBox";
import { RecalcButton } from "./NonTiledOptionValue";
import useWizardInteractions from "../useWizardInteractions";

interface IProps {
    handleValueRefresh: (event: React.MouseEvent<HTMLInputElement>) => void;
    headerValuePairs: Array<HeaderValuePair>;
    optionValue: string;
    sequence: number;
    codeText: string;
    usesConfigurator: boolean;
    code: ICode;
    locked: boolean;
    readOnlyMode: boolean;
}


const NonTiledSplitOptions: React.FC<IProps> = (props: IProps) => {

    const valueSplits = props.headerValuePairs;

    const wizardInteractions = useWizardInteractions();

    const handleSplitOptionValueChange = (hvp: HeaderValuePair, newValue: string) => {

        hvp.value = newValue;

        let valueSplits: Array<HeaderValuePair> = props.headerValuePairs;
        let newInputString: string = WizardHelper.ValueCombine(valueSplits);
        wizardInteractions.SetFocusedCode(null);
        wizardInteractions.SelectCodeValueAsync(props.sequence, props.codeText, newInputString);
    }

    if (props.usesConfigurator && ((valueSplits?.length ?? 0) === 0)) {
        return <>
            <TextField
                label={props.code.description}
                fullWidth
                disabled={true}
                size="small"
                value={props.optionValue}
            />
        </>;
    }

    return <>
        <OptionGroupBox label={props.code.description}
            id="NTSO_GROUP"
            endAdornment={(!props.usesConfigurator && !props.code.locked &&
                <RecalcButton readOnlyMode={props.readOnlyMode} handleValueRefresh={props.handleValueRefresh} />
            )}
        >
            <Grid item container direction="column" alignItems="stretch" spacing={1} >
                <Grid item xs>
                    <TextField fullWidth disabled={true} size="small" value={props.optionValue} />
                </Grid>

                {!props.usesConfigurator && valueSplits.map((hvp: HeaderValuePair) =>
                    <NonTiledSplitOption
                        key={hvp.header}
                        hvp={hvp}
                        handleSplitOptionValueChange={handleSplitOptionValueChange}
                        locked={props.locked && !props.code.substitutePartEnableWizard}
                        readOnlyMode={props.readOnlyMode}
                    />
                )}
            </Grid>
        </OptionGroupBox>
    </>;
}

export default NonTiledSplitOptions;




