import { useContext } from "react";
import GlobalOptionsContext, { IGlobalOptions } from "./GlobalOptionsContext";

export function useGlobalOptions(): IGlobalOptions {

    const globalOptions = useContext(GlobalOptionsContext);
    return globalOptions;
};

export default useGlobalOptions;
