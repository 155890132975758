import React from "react";
import { useTranslations } from "@fenetech/translations";
import { Tooltip, useTheme } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import WarningIcon from "@mui/icons-material/Warning"
import useMessageBox from "helpers/context/Page/useMessageBox";

interface IProps {
    name: string;
}

const CustomerInactiveIcon: React.FC<IProps> = ({ name }: IProps) => {

    const tm = useTranslations();
    const isMobile = useIsMobile();
    const messageBox = useMessageBox();
    const theme = useTheme();

    return <>

        <Tooltip title={tm.Get("Inactive")} sx={{ marginLeft: 1 }}>
            <WarningIcon style={{ color: theme.palette.error.main }} fontSize="small" onClick={() => {
                if (isMobile)
                    messageBox.Show({
                        title: name,
                        message: tm.Get("Customer Inactive")
                    });
            }} />
        </Tooltip>

    </>;
}

export default CustomerInactiveIcon;
