import { CircularProgress, Tooltip } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";

import useDuplicatePOCheck from "components/Quotes/DuplicatePOCheck/useDuplicatePOCheck";

interface IProps 
{
    oKey?: number,
    customerGUID?: string,
    poNumberToCheck?: string
}

function DuplicatePOCheck({oKey, customerGUID, poNumberToCheck} : IProps) {

    const [quoteWithDuplicatePONumber, duplicatePOMessage] = useDuplicatePOCheck(oKey, customerGUID, poNumberToCheck);

    return <>
        {(poNumberToCheck && quoteWithDuplicatePONumber === undefined) &&
            <CircularProgress color="inherit" size={20} />
        }
        {quoteWithDuplicatePONumber && 
            <Tooltip title={duplicatePOMessage}>
                <WarningAmber />
            </Tooltip>
        }
    </>;
}

export default DuplicatePOCheck;