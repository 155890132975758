import React from "react";

export interface ILocaleNumberFormatter {
    Format: (content: number) => string;
    Parse: (content: string) => number;
}

const NUMBER_WITH_DECIMAL_SEPARATOR = 1.1;
const NUMBER_WITH_GROUP_SEPARATOR = 1000;
const DEFAULT_OPTIONS: Intl.NumberFormatOptions = {
    style: "decimal",
    useGrouping: false
}

function getDecimalSeparator(culture: string) {
    return Intl.NumberFormat(culture).formatToParts(NUMBER_WITH_DECIMAL_SEPARATOR).find(part => part.type === "decimal")?.value ?? ".";
}

function getGroupSeparator(culture: string) {
    return Intl.NumberFormat(culture).formatToParts(NUMBER_WITH_GROUP_SEPARATOR).find(part => part.type === "group")?.value ?? ",";
}

// Maybe could find a better way to combine this with useCurrencyFormatter. 
// But non-currency formatting doesn't have any DB dependencies, so may be better to keep this separate

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#parameters
export default function useLocaleNumberFormatter(options: Intl.NumberFormatOptions = DEFAULT_OPTIONS): ILocaleNumberFormatter {

    let [lnf, setLNF] = React.useState<ILocaleNumberFormatter | null>(null);

    if (!lnf) {

        let displayCulture = navigator.language;
        let decimalSeparator = getDecimalSeparator(displayCulture);
        let groupSeparator = getGroupSeparator(displayCulture);
        
        lnf = {
            Format: (content: number) => {
                return (content).toLocaleString(displayCulture, options);
            },
            Parse: (content: string) => {
                let newStr = content.replaceAll(groupSeparator, '').replace(decimalSeparator, '.').trim();

                // Number constructor converts "" to 0, but it should probably be NaN for our purposes
                if (newStr === "") return Number.NaN;

                // Yes, -0 exists in JavaScript. We don't want that here
                let retNum = Number(newStr);
                if (retNum === -0) retNum = +0;
                return retNum;
            }
        };
        setLNF(lnf);
    }

    return lnf;
};