import * as React from 'react';

import type { } from '@mui/x-data-grid-pro/themeAugmentation';

import {  useTheme } from '@mui/material';
import { useTranslations } from '@fenetech/translations';
import { useDashboardOrderData } from './useDashboardData';

import  { AddStatusColumnData } from "components/Common/DataGridColumnGenerator";
import { generateOrderColumns } from "./OrdersGridColumns";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from '@mui/x-data-grid-pro';
import useApplicationInfo from 'helpers/context/Application/useApplicationInfo';
import useIsMobile from 'helpers/hooks/useIsMobile';
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useUserInfo from "helpers/context/User/useUserInfo";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

const OrdersGrid: React.FC<any> = (props: any) => {

    const appInfo = useApplicationInfo();
    const tm = useTranslations();
    const cf = useCurrencyFormatter();
    const theme = useTheme();
    const rawData = useDashboardOrderData();
    const isMobile = useIsMobile();
    const user = useUserInfo();
    const rowsPerPage = useRowsPerPage("DashboardOrders");

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        return row.oKey;
    }

    const data = React.useMemo(() => {
        if (rawData) {
            return rawData.map((o) => AddStatusColumnData(o, "Received", theme, tm));
        } else {
            return null;
        }
    }, [rawData, theme, tm]);

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <CustomDataGridPro
            rows={data}
            columns={generateOrderColumns(tm, data, theme, appInfo, isMobile, cf, user)}
            getRowId={getRowId}
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
        />
    </>
};

export default OrdersGrid;