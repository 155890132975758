import { TableHead, TableRow, TableCell, styled } from "@mui/material"
import { tableCellClasses } from '@mui/material/TableCell';

import { ILineItemGridProps } from "components/Quotes/QuoteEntry/LineItems/LineItemGrid";
import LineItemColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";

interface IProps {
    lineItemGridProps: ILineItemGridProps;
    columns: LineItemColumn[];
}

const LineItemHeader: React.FC<IProps> = ({ lineItemGridProps, columns }: IProps) => {

    const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        },
    }));
    
    return <>
            <TableHead>
                <TableRow>
                    {columns.map(lic => (
                        <StyledHeaderCell key={lic.columnName} sx={{width: lic.width}} align={lic.align} >
                            {lic.headerText}
                        </StyledHeaderCell>
                    ))}
                </TableRow>
            </TableHead>

    </>;
}

export default LineItemHeader;