import { Grid, Typography, Button } from "@mui/material";
import { ILineItemInfo } from "components/OptionsWizard/interfaces";
import { ThemeColorEnum } from "helpers/enums";
import Format from "helpers/fv.format";
import { useTranslations } from "@fenetech/translations";
import React from 'react';
import OptionGroupBox from "../OptionsWizard/OptionGroupBox";

interface IProps {
    ii: ILineItemInfo;
    format: Format;
    showSize: boolean;
    showSizeEdit: boolean;
    handleEditClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void);
}

const DesignerItemSizeLabel: React.FC<IProps> = ({ ii, format, showSize, showSizeEdit, handleEditClick }: IProps) => {

    const tm = useTranslations();
    let itemSize: string = format.FormatDimensions(ii.width, ii.height, ii.thickness, ii.unitSet)

    return <>
        <OptionGroupBox id="DESIGNER_ITEMSIZE" label="">
            <Grid item container p={0} direction="row" alignItems="center" spacing={1}>
                {showSize && <>
                    <Grid item> <Typography>{tm.Get("Item Size:")}</Typography></Grid>
                    <Grid item><Typography>{itemSize}</Typography></Grid>
                </>
                }

                {showSizeEdit && handleEditClick && <>
                    <Grid container item xs justifyContent="flex-end" >
                        <Button variant="contained" onClick={handleEditClick} color={ThemeColorEnum.Secondary}>
                            {tm.Get("Edit")}
                        </Button>
                    </Grid>
                </>}
            </Grid>
        </OptionGroupBox>

    </>;
}

export default DesignerItemSizeLabel;