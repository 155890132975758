export interface IGlobalOptionsSelections {
    questionSelections: IQuestionSelection[],
    oldOptionSelections: IOptionSelection[],
    oldOptionValueSelections: IOptionValueSelection[],
    newOptionSelections: IOptionSelection[],
    newOptionValueSelections: IOptionValueSelection[],
    changeButtonState: ChangeButtonStateEnum,
    selectedQuestion: string,
    selectedOldOption: string,
    selectedOldOptionValue: string,
    selectedNewOption: string,
    selectedNewOptionValue: string,
    isOldValueVisible: boolean,
    isNewValueVisible: boolean,
    isValid: boolean,
    ignoredLineItems: number[],
}

export interface IQuestionSelection {
    description: string,
    value: string,
}

export interface IOptionSelection {
    description: string,
    value: string,
}

export interface IOptionValueSelection {
    description: string,
    value: string,
}

export enum ChangeButtonStateEnum {
    RemoveOption,
    AddOption,
    ChangeOption
}