import React, { useRef, useState, useEffect, useCallback } from 'react';
import { DialogActions, DialogContent, Grid, TextField } from "@mui/material";
import { useTranslations } from "@fenetech/translations";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useMessageBox from "helpers/context/Page/useMessageBox";
import LineItemPriceBreakdownAPI from "components/LineItemPriceBreakdown/LineItemPriceBreakdownAPI";
import { ILineItemPriceBreakdown } from "helpers/interfaces"
import Constants from "helpers/constants";
import OKCancelControl from "components/Common/OKCancelControl";
import ErrorAdornment from "components/Common/ErrorAdornment";
import useQuoteData from "components/Quotes/useQuoteData";

interface IProps {
    initialValue: string,
    item: number,
    optionCode: string,
    optionGroup: string,
    setIsPosting: React.Dispatch<React.SetStateAction<boolean>>,
    onCancel: () => void,
    onSubmit: (value: ILineItemPriceBreakdown) => void;
};

const EditOptionPriceDialog: React.FC<IProps> = ({ initialValue, item, optionCode, optionGroup, setIsPosting, onCancel, onSubmit }) => {
    const { quote } = useQuoteData();
    const tm = useTranslations();
    const cf = useCurrencyFormatter(quote?.currencyCulture);
    const messageBox = useMessageBox();
    const [value, setValue] = useState<string>(initialValue);
    const valueInput = useRef<HTMLInputElement>(null);
    const [valueErrorMessage, setValueErrorMessage] = useState<string>("");

    useEffect(() => {
        if (valueInput?.current) {
            valueInput.current.focus();
            valueInput.current.select();
        }
    }, [valueInput]);

    const handleValueChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(e.target.value);
    }

    const validateOptionPrice = useCallback((value: string) => {
        var numberPrice = cf.Parse(value);
        if (value === "") {
            return tm.Get("Price must be specified");
        } else if (isNaN(numberPrice)) {
            return tm.Get("Price must be a valid currency value.");
        } else if (numberPrice < Constants.Min.Price || numberPrice > Constants.Max.Price) {
            return tm.GetWithParams("Price must be between {0} and {1}.", Constants.Min.Price.toString(), Constants.Max.Price.toString());
        } else {
            return "";
        }
    }, [cf, tm])

    const handleSubmit = useCallback(() => {
        var errorMessage = validateOptionPrice(value);
        setValueErrorMessage(errorMessage);
        if (errorMessage === "") {
            setIsPosting(true);
            LineItemPriceBreakdownAPI.ChangeOptionPrice(item, optionCode, optionGroup, cf.Parse(value)).then((newBreakdown) => {
                if (newBreakdown) {
                    onSubmit(newBreakdown);
                }
                setIsPosting(false);
            });
        } else {
            messageBox.Show({ message: errorMessage, title: tm.Get("Price Breakdown") });
        }
    }, [onSubmit, validateOptionPrice, setIsPosting, item, optionCode, optionGroup, value, cf, tm, messageBox]);

    return <>
        <DialogContent>
            <Grid container direction="column" justifyItems="stretch" mt={1}>
                <Grid item>
                    <TextField inputRef={valueInput} label={tm.Get("Option Price")} value={value} onChange={handleValueChange} fullWidth
                        error={valueErrorMessage !== ""}
                        InputProps={{
                            endAdornment: valueErrorMessage !== "" ? <ErrorAdornment validationError={valueErrorMessage} /> : null,
                            inputProps: {
                                style: {
                                    textAlign: 'right',
                                },
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <OKCancelControl okCommand={handleSubmit} cancelCommand={onCancel} />
        </DialogActions>
    </>
}

export default EditOptionPriceDialog