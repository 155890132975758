import { InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from '@fenetech/translations';
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from 'helpers/constants';
import { PricingMethodEnum } from 'helpers/enums';
import { ResolvePath } from "helpers/objects";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";


interface IProps {
    pricingMethod: PricingMethodEnum,
    isPricingMethodPercentBased: boolean
    fieldName: string,
    readOnly: boolean,
    lnf: ILocaleNumberFormatter,
    [x: string]: any    // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function PricingFieldForm({ pricingMethod, fieldName, readOnly, lnf, label, isPricingMethodPercentBased, ...rest }: IProps) {
    const tm = useTranslations();
    const { setValue, watch, formState: { errors } } = useFormContext();

    //Database returns percents, check if conversion is needed
    useEffectOnLoad(() => {
        const initialValue = watch(fieldName);
        if (!isPricingMethodPercentBased) {
            setValue(fieldName, lnf.Format(convertPercentToMultiplier(initialValue)));
        } else {
            setValue(fieldName, lnf.Format(initialValue));
        }
    });

    const validateValue = (value: string): string | boolean => {
        if (value) {
            const floatValue = lnf.Parse(value.toString());

            if (isNaN(floatValue)) {
                const message = tm.Get("Must be a number.");
                return message;
            } else {
                // THOUGHT: maybe use something like https://github.com/s-yadav/react-number-format ? 
                let minValue: number = 0;
                let maxValue: number = 100;
                switch (pricingMethod) {
                    case PricingMethodEnum.Discount:
                        minValue = isPricingMethodPercentBased ? Constants.Min.DiscountPercent : Constants.Min.DiscountMultiplier;
                        maxValue = isPricingMethodPercentBased ? Constants.Max.DiscountPercent : Constants.Max.DiscountMultiplier;
                        break;
                    case PricingMethodEnum.Markup:
                        minValue = isPricingMethodPercentBased ? Constants.Min.MarkupPercent : Constants.Min.MarkupMultiplier;
                        maxValue = isPricingMethodPercentBased ? Constants.Max.MarkupPercent : Constants.Max.MarkupMultiplier;
                        break;
                    case PricingMethodEnum.Margin:
                        minValue = Constants.Min.MarginPercent;
                        maxValue = Constants.Max.MarginPercent;
                        break;
                    case PricingMethodEnum.Markdown:
                        minValue = Constants.Min.MarkdownPercent;
                        maxValue = Constants.Max.MarkdownPercent;
                        break;
                }
                if (floatValue > maxValue || floatValue < minValue) {
                    const message = tm.GetWithParams("{0} must be between {1} and {2}.", label ?? "value", minValue.toString(), maxValue.toString());
                    return message;
                }
            }
        }
        return true;
    }

    const convertPercentToMultiplier = (percent: number) => {
        if (pricingMethod === PricingMethodEnum.Markup) {
            return 1 + (percent / 100);
        } else if (pricingMethod === PricingMethodEnum.Discount || pricingMethod === PricingMethodEnum.Margin) {
            return 1 - (percent / 100);
        } else {
            throw new Error("Invalid pricing method");
        }
    }

    function handleOnBlur(e: any): void {
        if (e.target.value !== "") {
            let numericValue = lnf.Parse(e.target.value);
            setValue(fieldName, lnf.Format(numericValue));
        }
    }

    const getValidationError = (): any => ResolvePath(fieldName, errors);

    return <>
        <TextFieldForm
            variant="outlined"
            size="small"
            readOnly={readOnly}
            id={fieldName}
            fieldName={fieldName}
            validateValue={validateValue}
            InputProps={{
                endAdornment: isPricingMethodPercentBased && <InputAdornment position="end">%</InputAdornment>
            }}
            label={label}
            placeholder={label}
            onBlur={handleOnBlur}
            error={getValidationError() ? true : false}
            helperText={getValidationError()?.message}
            {...rest}
        />
    </>
}

export default PricingFieldForm;