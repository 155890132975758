import { Alert, AlertColor, Container, Snackbar, Stack } from "@mui/material";
import { IActionButton } from "helpers/context/Page/PageContext";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useWait from "helpers/context/Page/useWait";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useUserInfo from "helpers/context/User/useUserInfo";
import { CustomerRefOptionEnum, PONumberOptionEnum, RoleEnum, ThemeColorEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";
import { FindAllNestedProps } from "helpers/objects";
import { ICompanyInfo, ICompanyInfoWithLogo, ICompanyUpdate } from "models/ICompany";
import React from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CompanyAPI from "./CompanyAPI";
import { BlankCompany } from "./Form/BlankCompany";
import CompanyInformation from "./Form/CompanyInformation";
import CompanyLogo from "./Form/CompanyLogo";
import CompanySettings from "./Form/CompanySettings";

interface IProps {
    isEditMode: boolean
}

const CompanyInfo: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const user = useUserInfo();
    const navigate = useNavigate();
    const wait = useWait();
    const messageBox = useMessageBox();
    const actionButtons = useActionButtons();
    const formMethods = useForm({ mode: "onChange", defaultValues: BlankCompany});
    const { handleSubmit, reset } = formMethods;

    const { siteID } = useParams();
    if (!siteID) navigate(-1);

    useWindowTitle(tm.Get("Company"));

    const [company, setCompany] = React.useState<ICompanyInfo | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(props.isEditMode);
    const [alert, setAlert] = React.useState<[text: string, alertType: AlertColor] | null>(null);

    const [companyLogoBase64, setCompanyLogoBase64] = React.useState<string | null>(null);
    const [localLogoFileBase64, setLocalLogoFileBase64] = React.useState<string | null>(null);
    const [logoCleared, setLogoCleared] = React.useState<boolean>(false);

    //If multiSite, there will be a back button at index 0 so there is an offset
    const actionButtonOffset = user.isMultiSite ? 1 : 0;

    React.useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    React.useEffect(() => {
        if (user.isMultiSite) {
            actionButtons.SetBackButton(0, "/setup/company");
        }
        if (!isEditMode) {
            const editButton: IActionButton = {
                text: tm.Get("Edit"),
                color: ThemeColorEnum.Primary,
                disabled: (company === null || isLoading || isPosting),
                onClick: (() => setIsEditMode(true))
            };
            actionButtons.Set(0 + actionButtonOffset, editButton);
        } else {
            const saveButton : IActionButton = {
                text: tm.Get("Save"), 
                color: ThemeColorEnum.Secondary,
                disabled: (isLoading || isPosting) ? true : false,
                type: "submit",
                form: "edit-company"
            };
            const cancelButton : IActionButton = {
                text: tm.Get("Cancel"), 
                color: ThemeColorEnum.Primary,
                disabled: (isLoading || isPosting) ? true: false,
                onClick: () => {
                    setIsEditMode(false);
                    setLogoCleared(false);
                    actionButtons.Remove(1 + actionButtonOffset);
                    reset({...company});
                }
            };
            actionButtons.Set(0 + actionButtonOffset, saveButton);
            actionButtons.Set(1 + actionButtonOffset, cancelButton);
        }
    }, [company, isEditMode, isLoading, isPosting, actionButtons, user, actionButtonOffset, tm, reset]);

    const getCompanyInformation = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const result: ICompanyInfoWithLogo = user.HasRole(RoleEnum.Dealer) ?
                await CompanyAPI.GetCompanyInformation(user.mfgCustomerID, siteID!) :
                await CompanyAPI.GetContractorCompanyInformation(user.parentCustomerID);

            setCompany(result.companyInfo);
            reset({...result.companyInfo});
            if (result.companyLogoBase64 !== null) {
                setCompanyLogoBase64(result.companyLogoBase64);
            }
        } finally {
            setIsLoading(false);
        }
    }, [siteID, user, reset]);

    React.useEffect(() => {
        if (!company) {
            getCompanyInformation();
        }
    }, [company, getCompanyInformation]);

    const onSubmit = async (formData, e: any) => {

        const update: ICompanyUpdate = {
            name: formData["name"],
            addressInfo: formData["addressInfo"],
            url: formData["url"],
            customerGUID: formData["customerGUID"],
            PONumberUseMfgSetting: formData["parentPONumber"] === PONumberOptionEnum.Default,
            defaultParentPONumber: formData["parentPONumber"],
            customerRefUseMfgSetting: formData["parentCustomerRef"] === CustomerRefOptionEnum.Default,
            defaultParentCustomerRef: formData["parentCustomerRef"],
            defaultClassification: formData["defaultClassification"],
        }

        const clearLogo = logoCleared && companyLogoBase64 !== null;
        //Backend expects only base64 string, need to remove mime type: "data:image/png;base64,"
        const localLogoFileBase64WithoutType = localLogoFileBase64 ? localLogoFileBase64.split(',')[1] : null;
        
        try {
            setIsPosting(true);
            if (user.HasRole(RoleEnum.Dealer))
                await CompanyAPI.UpdateCompanyInformation(update, localLogoFileBase64WithoutType, clearLogo);
            else
                await CompanyAPI.UpdateContractorCompanyInformation(update, localLogoFileBase64WithoutType, clearLogo);
            
            await getCompanyInformation();
            setAlert([tm.Get("Changes have been saved."), "success"]);
            setIsEditMode(false);
            actionButtons.Remove(1 + actionButtonOffset);
        } finally {
            setIsPosting(false);
        }
    }

    const onError = (errors: { [x: string]: any }, e: any) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");
        console.log(formattedMessage);

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') 
            return;
        setAlert(null);
    }

    return <>
        <Snackbar open={alert !== null} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert ? alert[1] : "info"} variant='filled' sx={{width: '100%', fontWeight: 'bold'}}>{alert ? alert[0] : ""}</Alert>
        </Snackbar>
        <Container maxWidth="lg">
            <FormProvider {...formMethods}>
                <form id="edit-company" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Stack direction="column" gap={1} sx={{marginTop: 1}}>
                        <CompanyInformation
                            readOnly={!isEditMode}
                        />
                        <CompanySettings
                            readOnly={!isEditMode}
                        />
                        <CompanyLogo
                            readOnly={!isEditMode}
                            isLoading={isLoading}
                            companyLogoBase64={companyLogoBase64}
                            localLogoFileBase64={localLogoFileBase64}
                            setLocalLogoFileBase64={setLocalLogoFileBase64}
                            logoCleared={logoCleared}
                            setLogoCleared={setLogoCleared}
                        />
                    </Stack>
                </form>
            </FormProvider>
        </Container>
    </>;
}

export default CompanyInfo;