import WizardContext, { WizardActions, WizardDispatchContext } from "components/OptionsWizard/WizardContext";
import React from "react";

export function useWizardStateActions(): WizardActions {
    const actions = React.useContext(WizardDispatchContext);
    return actions!;
};

export default function useWizardState() {
    const wizardState = React.useContext(WizardContext);
    return wizardState;
}