import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { Room, Delete, Edit } from "@mui/icons-material";
import CustomCardHeader from "components/Common/CustomCardHeader";
import Format from "helpers/fv.format";
import { IWebCustomerSummary } from "models/IWebCustomer";
import React from 'react';
import { useTranslations } from "@fenetech/translations";
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from "@mui/icons-material/Warning"
import AlertDialog from "components/Common/AlertDialog";

interface IProps {
    customer: IWebCustomerSummary,
    isMfgSiteVisible: boolean,
    viewCustomerHandler: (customerID: string) => void,
    editCustomerHandler: (customerID: string) => void,
    deleteCustomerHandler: (customerID: string) => void
}

const CustomerCard: React.FC<IProps> = ({ customer, isMfgSiteVisible, viewCustomerHandler, editCustomerHandler, deleteCustomerHandler } : IProps) => {

    const tm = useTranslations();

    const [open, setOpen] = React.useState<boolean>(false);

    const handlerShowDialog = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleDialogClose = React.useCallback((result: boolean) => {
        if (result) {
            deleteCustomerHandler(customer.customerID);
        }
        setOpen(false);
    }, [customer.customerID, deleteCustomerHandler])

    const renderAddress = React.useMemo(() => {
        if (!customer.billAddress1 || customer.billAddress1 === "") return `{${tm.Get("Not Available")}}`
        return <>
            {customer.billAddress1}
            <br />
            {Format.FormatCityStateZip(customer.billCity ?? "", customer.billState ?? "", customer.billZipCode ?? "")}
        </>
    }, [customer.billAddress1, customer.billCity, customer.billState, customer.billZipCode, tm]);

    return <>
        <Card sx={{marginY: 2}}>
            <CustomCardHeader title={isMfgSiteVisible ? `${customer.name} [${customer.parentSiteName}]` : customer.name} onClick={() => viewCustomerHandler(customer.customerID)} />
            <CardContent>
                {customer.default &&
                    <Grid container mt={1}>
                        <Grid item mr={1}>
                            <InfoIcon color="primary"/>
                        </Grid>
                        <Grid item xs>
                            <Typography>
                                {isMfgSiteVisible ? tm.GetWithParams("Default customer for site {0}", customer.parentSiteName) : tm.Get("Default Customer")}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                {customer.inactive &&
                    <Grid container mt={1}>
                        <Grid item mr={1}>
                            <WarningIcon color="error"/>
                        </Grid>
                        <Grid item xs>
                            <Typography color="error">
                                {tm.Get("Customer Inactive")}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                <Grid container mt={1}>
                    <Grid item mr={1}>
                        <Room color="primary"/>
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {renderAddress}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button variant="contained" size="small" color="secondary" onClick={() => editCustomerHandler(customer.customerID)}>
                    {tm.Get("Edit")} <Edit fontSize="small" sx={{marginLeft: 1}} />
                </Button>
                <Button variant="contained" size="small" color="secondary" onClick={handlerShowDialog}>
                    {tm.Get("Delete")} <Delete fontSize="small" sx={{marginLeft: 1}} />
                </Button>
            </CardActions>
        </Card>
        <AlertDialog open={open}
            handleClose={handleDialogClose}
            headerText={tm.Get("Delete")}
            message={tm.Get("Are you sure you want to delete this customer?")}
        />
    </>
}

export default CustomerCard;