import { IApplicationInfo, IEngineeringUnitSets, IGSPs } from "helpers/interfaces";
import { ICurrencyCultureInfo } from "./ICurrencyCultureInfo";

export default class ApplicationInfo implements IApplicationInfo {

    public constructor(info: IApplicationInfo) {
        this.rmaUnlocked = info.rmaUnlocked;
        this.extendedContractorModeUnlocked = info.extendedContractorModeUnlocked;
        this.productNavigatorEnabled = info.productNavigatorEnabled;
        this.surchargesConfigured = info.surchargesConfigured;
        this.parameters = info.parameters;
        this.engineeringUnits = info.engineeringUnits;
        this.timeout = info.timeout;
        this.currencyCultures = info.currencyCultures;
    }

    timeout:number;
    engineeringUnits: IEngineeringUnitSets;
    rmaUnlocked: boolean;
    extendedContractorModeUnlocked: boolean;
    productNavigatorEnabled: boolean;
    surchargesConfigured: boolean;
    parameters: IGSPs;
    currencyCultures: ICurrencyCultureInfo[];
}
