import * as React from "react";

import QuestionComponentSelector from "./QuestionComponentSelector";
import NonTiledSingleOption from "./NonTiledSingleOption";

import { IQuestion, ICode, IUserPreferences } from "../interfaces";
import WizardHelper from "../WizardHelper";
import { Flag } from "@mui/icons-material";
import { Grid, Stack } from "@mui/material";
import OptionGroupBox from "./OptionGroupBox";

interface IOWCodeProps {
    code: ICode,
    preferences: IUserPreferences;
    readOnlyMode: boolean;
}


const NonTiledStandAloneCode: React.FC<IOWCodeProps> = (props: IOWCodeProps) => {

    const c: ICode = props.code;

    const handleAdorment = () => {
        return <>
            {actionRequired &&
                <Flag sx={{ width: "16px", height: "16px" }} color="warning" />
            }
            {c.locked &&
                <img src="./Images/lock.png" />
            }
        </>
    }

    if (!c.visible)
        return null;

    let actionRequired: boolean = WizardHelper.IsQuestionOrStandaloneRequired(c);

    if (c.locked && !c.selected) {
        //The code is locked with nothing selected, hide the code
        return null;
    }
    else {
        return <Stack direction="row" alignItems="center">
            <Grid item xs>
                <OptionGroupBox
                    label={c.description}
                    id="NTSOQ_GROUP"
                    disabled={c.locked}
                    startAdornment={handleAdorment()}>
                    <NonTiledSingleOption
                        code={c}
                        locked={c.locked}
                        isWithinMultiSelect={false}
                        readOnlyMode={false}
                    />
                    {c.selected && c.nestedQuestions && c.nestedQuestions.map((q: IQuestion) =>
                        <QuestionComponentSelector preferences={props.preferences} question={q} key={q.sequence + "_" + q.question} readOnlyMode={props.readOnlyMode} />
                    )}
                </OptionGroupBox>
            </Grid>
        </Stack>
    }
}

export default NonTiledStandAloneCode;

