import { Autocomplete, TextField, styled } from "@mui/material";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import { useTranslations } from "@fenetech/translations";
import { IWebCustomer, IWebCustomerWithParent } from "models/IWebCustomer";
import React from 'react';
import useUserSetupActions from "./useUserSetupActions";

interface IProps {
    customers: IWebCustomerWithParent[] | null,
    setSelectedCustomer: (customer: IWebCustomerWithParent) => void
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default
}));

const CustomerSelect: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const user = useUserInfo();
    const userActions = useUserSetupActions();

    const companyCustomer: IWebCustomerWithParent = {
        customerGUID: "-1",
        customerID: "-1",
        siteID: "-1",
        name: tm.Get("{Company}"),
        parentCustomerID: "",
        parentSiteID: "",
        parentSiteName: "",
        isDefault: false,
        isInactive: false
    }

    let dataSource: IWebCustomerWithParent[] = props.customers ? [companyCustomer, ...props.customers] : [];

    return <Autocomplete
        id="select-customer"
        autoComplete
        autoSelect
        autoHighlight
        selectOnFocus
        handleHomeEndKeys
        options={dataSource.sort((a, b) => a.parentSiteName.localeCompare(b.parentSiteName))}
        disabled={dataSource === null}
        isOptionEqualToValue={(option, value) => option.customerGUID === value.customerGUID}
        getOptionLabel={(option: IWebCustomerWithParent) => option ? option.name : ""}
        groupBy={(option) => option.parentSiteName}
        renderGroup={(params) => (
            <div key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                {params.children}
            </div>
        )}
        renderOption={(props, option) => (
            <li {...props} key={option.customerGUID}>
                {option ? option.name : ""}
            </li>
        )}
        renderInput={(params) => (
            <TextField
                variant="outlined"
                {...params}
                InputProps={{ ...params.InputProps }}
            />
        )}
        onChange={(_, data: IWebCustomerWithParent | null) => {
            if (data) {
                userActions.SetIsDealerCreatingAdminContractor(!user.HasRole(RoleEnum.Contractor) && data.customerGUID !== "-1");
                props.setSelectedCustomer(data);
            }
        }}
        defaultValue={dataSource ? companyCustomer : undefined}
    />
}

export default CustomerSelect;