import React, { Reducer } from "react";

type KeyMap<K, T> = Map<K, T>;

export type MapAction<K, T> =
    { type: "ADDUPDATE", value: [key: K, data: T, sessionKey: string] } |
    { type: "SET", value: KeyMap<K, T> };


const dataReducer = <K, T>(existingState: KeyMap<K, T>, action: MapAction<K, T>) => {

    switch (action.type) {
        case "ADDUPDATE":
            const [mapKey, mapValue, sessionKey] = action.value;
            const newState = new Map<K, T>([...existingState, [mapKey, mapValue]]);
            sessionStorage.setItem(sessionKey, JSON.stringify(Array.from(newState.entries())));
            return newState;
        case "SET":
            return action.value;
    }

};

//Initialize the data store with the value from session storage or a new map if it doesn't exist
const initialStateLoader = <K, T>(storageKey: string) => {

    const cachedMapJson = sessionStorage.getItem(storageKey);
    let initialMap: KeyMap<K, T>;
    if (cachedMapJson) {
        initialMap = new Map(JSON.parse(cachedMapJson));
    } else {
        initialMap = new Map<K, T>();
    }

    return initialMap;
}

function useClearSessionOnUnload(sessionKey: string) {
    React.useEffect(() => {
        const clearSessionStorage = function (this: Window, e: BeforeUnloadEvent): void {
            sessionStorage.setItem(sessionKey, "");
            console.log(`Session cleared: ${sessionKey}`);
        };
        window.addEventListener("beforeunload", clearSessionStorage);
        return () => {
            window.removeEventListener("beforeunload", clearSessionStorage);
        };
    }, [sessionKey]);
}

export function useBrowserBackedMapAsync<T>(sessionKey: string, apiFetch: (...params: any[]) => Promise<T>) {

    type K = string;
    type TypedKeyMap = Map<K, T>;
    type TypedAction = MapAction<K, T>;

    const [itemMap, mapDispatcher] = React.useReducer<Reducer<TypedKeyMap, TypedAction>, string>(dataReducer, sessionKey as any, initialStateLoader);

    const callBack = React.useCallback(async (...params: any[]) => {
        const recordKey: string = params.reduce((p, c, i) => p + String.fromCharCode(31) + c)
        if (!itemMap.has(recordKey)) {
            const value = await apiFetch(...params);
            mapDispatcher({ type: "ADDUPDATE", value: [recordKey, value, sessionKey] });
            return value;
        }
        else {
            return itemMap.get(recordKey) as T;
        }
    }, [apiFetch, itemMap, sessionKey]);

    useClearSessionOnUnload(sessionKey);

    return callBack;

};

export default function useBrowserBackedMap<T>(sessionKey: string, apiFetch: (...params: any[]) => Promise<T>, ...params: any[]): T | null {

    type K = string;
    type TypedKeyMap = Map<K, T>;
    type TypedAction = MapAction<K, T>;

    const [itemMap, mapDispatcher] = React.useReducer<Reducer<TypedKeyMap, TypedAction>, string>(dataReducer, sessionKey as any, initialStateLoader);

    const recordKey: string = params.reduce((p, c, i) => p + String.fromCharCode(31) + c)

    //Query for this item if we don't have it in the cache yet
    React.useEffect(() => {
        if (!itemMap.has(recordKey)) {
            apiFetch(...params).then(d => {
                mapDispatcher({ type: "ADDUPDATE", value: [recordKey, d, sessionKey] });
            });
        }
    }, [apiFetch, itemMap, params, sessionKey, recordKey]);

    useClearSessionOnUnload(sessionKey);

    //Return the item from the cache
    if (itemMap.has(recordKey)) {
        return itemMap.get(recordKey) as T;
    } else {
        return null;
    }

};

