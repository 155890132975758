import { useState, useEffect, useMemo, useCallback } from "react"

import { Container, Box } from "@mui/material"
import { useForm, FormProvider } from "react-hook-form";

import SelectWebCustomer from "components/Quotes/SelectFields/SelectWebCustomer"
import QuoteInfo from "components/Quotes/AddQuote/QuoteInfo"
import Sales from "components/Quotes/AddQuote/Sales"
import Billing from "components/Quotes/AddQuote/Billing"
import Shipping from "components/Quotes/AddQuote/Shipping"
import MfgShipTo from "components/Quotes/AddQuote/MfgShipTo"

import { useNavigate } from "react-router";
import { useTranslations } from "@fenetech/translations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useWait from "helpers/context/Page/useWait";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useUserInfo from "helpers/context/User/useUserInfo";
import useMfgSecondaryShipTos from "helpers/context/SelectionValues/useMfgSecondaryShipTos";
import useMessageBox from "helpers/context/Page/useMessageBox";

import { PricingMethodEnum, RoleEnum, ThemeColorEnum } from "helpers/enums";
import { IQuote } from "models/IQuote";
import { IActionButton } from "helpers/context/Page/PageContext";
import { BlankQuote } from "components/Quotes/AddQuote/BlankQuote";
import { AddQuotePermissions } from "components/Quotes/AddQuote/AddQuotePermissions";
import { FindAllNestedProps } from "helpers/objects";
import useAddQuoteData from "./useAddQuoteData";
import useAddQuoteActions from "./useAddQuoteActions";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";

function AddQuote() {

    const tm = useTranslations();
    const navigate = useNavigate();
    const user = useUserInfo();
    const navBlockerActions = useNavigationBlockerActions();

    const wait = useWait();

    useWindowTitle(tm.Get("Add Quote"))

    const actionButtons = useActionButtons();
    const messageBox = useMessageBox();

    const defaultExpanderState = {
        info: false,
        sales: false,
        billing: false,
        shipping: false,
    }

    //#region State variables

    const [isPosting, setIsPosting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [webCustomerGUID, setWebCustomerGUID] = useState<string>("");
    const [webCustomerID, setWebCustomerID] = useState<string>("");
    const [mfgCustomerID, setMfgCustomerID] = useState<string>("");
    const [mfgSiteID, setMfgSiteID] = useState<string>("");

    const { quoteDefaults } = useAddQuoteData();
    const addQuoteActions = useAddQuoteActions();

    const permissions = AddQuotePermissions(user);
    const formMethods = useForm({ mode: "onChange", defaultValues: BlankQuote });
    const { handleSubmit, reset, getValues } = formMethods;

    const [quoteToSubmit, setQuoteToSubmit] = useState<IQuote | undefined>(undefined);

    const [expandedState, setExpandedState] = useState<typeof defaultExpanderState>(defaultExpanderState);

    const mfgShippingAddresses = useMfgSecondaryShipTos(mfgCustomerID, mfgSiteID);

    const isContractorQuote = useMemo(() => {
        return user.HasRole(RoleEnum.Contractor);
    }, [user]);

    const parentCustomerID = useMemo(() => {
        return isContractorQuote ? user.parentCustomerID : mfgCustomerID;
    }, [isContractorQuote, user, mfgCustomerID]);

    const parentSiteID = useMemo(() => {
        return isContractorQuote ? user.parentCustomerID : mfgSiteID;
    }, [isContractorQuote, user, mfgSiteID]);

    const contractorCustomerID = useMemo(() => {
        return isContractorQuote ? user.parentCustomerID : "";
    }, [isContractorQuote, user]);

    //#endregion

    //#region functions

    useNavigationBlocker(formMethods.formState.isDirty);

    const onSubmit = (formData: IQuote, e: any) => {
        if (user.HasRole(RoleEnum.Contractor)) {
            formData.parentCustomerID = user.parentCustomerID;
            formData.parentSiteID = user.parentCustomerID;
        }
        else {
            formData.parentCustomerID = mfgCustomerID;
            formData.parentSiteID = mfgSiteID;
        }
        formData.customerID = webCustomerID;
        formData.enteredBy = user.employeeID;
        if (!formData.shipping.mfgShipToGUID) {
            formData.shipping.mfgShipToGUID = undefined;
        }
        setQuoteToSubmit(formData);
    };

    const onError = (errors: { [x: string]: any }, e: any) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }

    }

    const handleChange = (section: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
        //Note: to allow more than one expansion at a time: setExpandedState({...expandedState, [section]: isExpanded});
        setExpandedState({ info: false, sales: false, billing: false, shipping: false, [section]: isExpanded });
    };

    const onWebCustomerChange = useCallback((webCustomerID: string, webCustomerGUID: string) => {
        setWebCustomerID(webCustomerID);
        setWebCustomerGUID(webCustomerGUID);
    }, [setWebCustomerID, setWebCustomerGUID]);

    const onCancelClick = useCallback(() => {
        if (user.isAccessedExternal) {
            // attempt to close the window (tab), but this may only work if the window was opened by a script
            window.open('about:blank', '_self');
            window.close();                
        }
        else {
            navigate(-1);
        }
        navBlockerActions.Unblock();
    }, [user, navigate, navBlockerActions]);

    //#endregion

    //#region Fetch data effects

    useEffect(() => {
        if (webCustomerGUID) {
            setIsLoading(true);
            addQuoteActions.LoadQuoteDefaultsAsync(webCustomerGUID).then(() => {
                setIsLoading(false);
            });
        }
    }, [addQuoteActions, webCustomerGUID]);

    useEffect(() => {
        if (quoteToSubmit) {
            setIsPosting(true);
            addQuoteActions.PostQuoteHeaderAsync(quoteToSubmit)
                .then((oKey: number) => {
                    if (oKey !== undefined && oKey > 0) {
                        navigate(`/quotes?oKey=${oKey}`);
                    }
                })
                .finally(() => {
                    setIsPosting(false);
                });
        }

    }, [quoteToSubmit, addQuoteActions, navigate]);

    //#endregion

    //#region other effects

    useEffect(() => {

        if (quoteDefaults) {
            //reset the form with the new customer's default, but preserve some of the values
            const currentValues = getValues();
            reset({
                ...quoteDefaults,
                poNumber: currentValues.poNumber,
                customerRef: currentValues.customerRef,
                orderContact: currentValues.orderContact,
                orderComment: currentValues.orderComment,
                orderNotes: currentValues.orderNotes,
                shipping: {
                    ...quoteDefaults.shipping,
                    mfgShipToGUID: currentValues.shipping.mfgShipToGUID,
                    dropShip: currentValues.shipping.dropShip,
                },
            });
        }

    }, [quoteDefaults, getValues, reset]);

    useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    useEffect(() => {
        const addButton: IActionButton = {
            text: tm.Get("Add"),
            color: ThemeColorEnum.Secondary,
            disabled: (webCustomerGUID && !isLoading && !isPosting) ? false : true,
            type: "submit",
            form: "quoteForm",
            onClick: (() => navBlockerActions.Unblock())
        };
        actionButtons.Set(0, addButton);

        const cancelButton: IActionButton = {
            text: tm.Get("Cancel"),
            color: ThemeColorEnum.Primary,
            onClick: onCancelClick,
        };
        actionButtons.Set(1, cancelButton);

    }, [actionButtons, webCustomerGUID, isLoading, isPosting, navigate, tm, navBlockerActions, onCancelClick]);


    //#endregion

    return (
        <>
            <Container maxWidth="lg">
                <FormProvider {...formMethods}>
                    <form id="quoteForm" onSubmit={handleSubmit(onSubmit, onError)}>
                        <Box display="flex" flexDirection="column" gap={1} >
                            <SelectWebCustomer
                                isContractorQuote={isContractorQuote} contractorCustomerID={contractorCustomerID}
                                setWebCustomer={onWebCustomerChange} setMfgCustomerID={setMfgCustomerID} setMfgSiteID={setMfgSiteID}
                                permissions={permissions} />
                            {(webCustomerGUID) &&
                                <Box display="flex" flexDirection="column" gap={1} >
                                    <QuoteInfo
                                        expanded={expandedState.info} onExpandedChange={handleChange("info")}
                                        webCustomerGUID={webCustomerGUID} webCustomerID={webCustomerID}
                                        oKey={0} isUploaded={false}
                                        permissions={permissions}
                                        originalClassificationID={quoteDefaults?.classificationID ?? null} />
                                    <Sales
                                        expanded={expandedState.sales} onExpandedChange={handleChange("sales")}
                                        parentCustomerID={parentCustomerID}
                                        parentSiteID={parentSiteID}
                                        pricingMethod={quoteDefaults?.pricing.pricingMethod ?? PricingMethodEnum.Discount}
                                        permissions={permissions}
                                        markdownVisible={false} />
                                    <Billing
                                        expanded={expandedState.billing} onExpandedChange={handleChange("billing")}
                                        permissions={permissions} />
                                    <Shipping
                                        expanded={expandedState.shipping} onExpandedChange={handleChange("shipping")}
                                        parentCustomerID={parentCustomerID}
                                        permissions={permissions} />
                                    {(mfgShippingAddresses && mfgShippingAddresses.length > 0) &&
                                        <MfgShipTo
                                            mfgCustomerID={mfgCustomerID} mfgSiteID={mfgSiteID}
                                            permissions={permissions} />
                                    }
                                </Box>
                            }
                        </Box>
                    </form>
                </FormProvider>
            </Container>
        </>
    );
}

export default AddQuote;
