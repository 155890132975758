import { ILineItemGridProps } from "components/Quotes/QuoteEntry/LineItems/LineItemGrid";
import { IQuote, ILineItem, IQuotePermissions, ILockedObject } from "helpers/interfaces";

export interface IQuoteLevelProps {
    lineItemGridProps: ILineItemGridProps,
    quote: IQuote,
    permissions: IQuotePermissions,
    lock: ILockedObject,
}

export default abstract class LineItemColumn {

    columnName: string;
    headerText: string;
    width: string;
    rowSpan: number = 1;
    align?: "inherit" | "left" | "center" | "right" | "justify";

    public constructor(columnName: string, headerText: string, width: string) {
        this.columnName = columnName;
        this.headerText = headerText;
        this.width = width;
    }

    public abstract CellContent(quoteProps: IQuoteLevelProps, lineItem: ILineItem): JSX.Element

    public abstract FooterContent(quoteProps: IQuoteLevelProps): JSX.Element

}


