import * as React from "react";

import SubLineItemTabs from "./SubLineItemTabs";
import PartSelector from "./PartSelector";
import DesignerTools from "./DesignerTools";

import { WizardModeEnum } from "components/OptionsWizard/WizardContext";
import { IWebDesigner, ISubLineItemInfo, IValidationInfo, ILineItemInfo } from "../interfaces";
import { ITranslationManager } from "@fenetech/translations";
import { Box, Divider, Stack } from "@mui/material";
import useWizardInteractions from "../useWizardInteractions";

interface IProps {
    itemInfo: ILineItemInfo | null;
    webDesigner: IWebDesigner | null;
    validation: IValidationInfo | null;
}


const WebDesignerHeader: React.FC<IProps> = ({itemInfo, webDesigner, validation}: IProps) => {

    const wizardInteractions = useWizardInteractions();

    const currentSection = React.useMemo(() => {
        return webDesigner ? webDesigner.currentSection : 0;
    }, [webDesigner]);

    const handleSectionChange = (sli: number, tm: ITranslationManager) => {
        wizardInteractions.SelectDesignerItemAsync(sli);
    }

    if (!validation || !itemInfo || !webDesigner || !webDesigner.supportsSLI) { return null; }

    let currentInfo: ISubLineItemInfo | undefined = webDesigner.sectionList?.find((sli) => sli.sli === currentSection);

    if (!currentInfo)
        return null;

    return <Box id="sli-tabs" 
        sx={(theme) => {
            return {
                position: "sticky",
                top: "40px",
                zIndex: 20,
                background: theme.palette.background.default,
            }
        }}
    >

        <SubLineItemTabs
            sliList={webDesigner.sectionList}
            currentSLI={currentSection}
            handleSectionChange={handleSectionChange}
            requiredOptionSubLineItems={validation.requiredOptionSubLineItems}
            requiredPartSubLineItems={validation.requiredPartSubLineItems}
            blockingValidationSubLineItems={validation.blockingValidationSubLineItems}
        >
            {/*The tab guts are here*/}
            <Stack spacing={1} mt={1}>

                <PartSelector sliInfo={currentInfo} itemInfo={itemInfo} mode={WizardModeEnum.Standard} />

                <DesignerTools
                    itemInfo={currentInfo}
                />
                <Divider />
            </Stack>

        </SubLineItemTabs>     

    </Box>;

}

export default WebDesignerHeader;
