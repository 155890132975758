import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";

import { IGlobalOptions } from "components/GlobalOptions/GlobalOptionsContext";
import { IGlobalItem } from "models/GlobalOptions/IGlobalItem";
import { useTranslations } from "@fenetech/translations";
import { ThemeColorEnum } from "helpers/enums";

interface IProps {
    globalOptions: IGlobalOptions,
    globalItem: IGlobalItem,
}

const NewOptionValue: React.FC<IProps> = ({ globalOptions, globalItem }: IProps) => {

    const tm = useTranslations();
    const { setGlobalItemToEdit: setEditDialogVisible } = globalOptions;

    function getColor() {
        if (globalOptions.isGlobalItemSelected(globalItem)) {
            return "text.primary";
        }
        else {
            return "text.disabled";
        }
    }

    function getText() {
        let text = globalItem.changeInfo.newValueDescription;
        if (!text) {
            text = globalItem.changeInfo.newValue;
        }
        return text;
    }

    function showEdit() {
        return globalOptions.isGlobalItemEditEnabled(globalItem);
    }

    return <>
        <Grid container direction="row" display="flex">
            <Grid item xs>
                <Typography color={getColor()}>
                    {getText()}
                </Typography>

            </Grid>
            {showEdit() &&
                <Grid item xs="auto">
                    <Tooltip title={tm.Get("Edit")}>
                        <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => setEditDialogVisible(globalItem)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>

                </Grid>
            }
        </Grid>
    </>;

};

export default NewOptionValue;