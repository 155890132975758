import React from 'react';
import useNavigationBlockerActions from "./useNavigationBlockerActions";
/*
    Use this hook to set the page blocker based on dirty state
*/

export default function useNavigationBlocker(isDirty: boolean) {

    const navBlockerActions = useNavigationBlockerActions();

    React.useEffect(() => {
        if (isDirty) {
            navBlockerActions.Block();
        }
        else {
            navBlockerActions.Unblock();
        }

        return () => {
            navBlockerActions.Unblock();
        };
    }, [navBlockerActions, isDirty]);

    return () => { navBlockerActions.Unblock() }

}
