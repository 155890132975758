import { useMemo } from "react";
import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { ILineItem } from "models/IQuote";
import QuoteNavigation from "components/Quotes/QuoteNavigation";

interface IProps {
    oKey: number,
    lineItem: ILineItem,
    isReadOnly: boolean,
    includeItemNumber: boolean,
    variant: any,
}

const LineItemPart: React.FC<IProps> = ({ oKey, lineItem, isReadOnly, includeItemNumber, variant }: IProps) => {

    const lineItemText = useMemo(() => {

        if (includeItemNumber) {
            return `${lineItem.lineItemNumber}: ${lineItem.formattedPart}`;
        }
        else {
            return lineItem.formattedPart;
        }

    }, [includeItemNumber, lineItem]);

    return <>
        {isReadOnly ?
            <Typography variant={variant} textAlign="left" >
                {lineItemText}
            </Typography>
            :
            <Link component={RouterLink} to={QuoteNavigation.OptionsWizardURL(oKey, lineItem.odKey)} underline="hover"
                variant={variant} textAlign="left" fontWeight="bold" >
                {lineItemText}
            </Link>
        }
    </>;


};

export default LineItemPart;


