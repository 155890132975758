import { ILineItemGridProps } from "components/Orders/OrderView/LineItems/LineItemGrid";
import { IOrder, IOrderPermissions, IOrderLineItem } from "helpers/interfaces";

export interface IOrderLevelProps {
    lineItemGridProps: ILineItemGridProps,
    order: IOrder,
    permissions: IOrderPermissions,
}

export default abstract class LineItemColumn {

    columnName: string;
    headerText: string;
    width: string;
    rowSpan: number = 1;
    align?: "inherit" | "left" | "center" | "right" | "justify";

    public constructor(columnName: string, headerText: string, width: string) {
        this.columnName = columnName;
        this.headerText = headerText;
        this.width = width;
    }

    public abstract CellContent(orderProps: IOrderLevelProps, lineItem: IOrderLineItem): JSX.Element

    public abstract FooterContent(orderProps: IOrderLevelProps): JSX.Element

}


