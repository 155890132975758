import React from 'react';
import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material"
import { useFormContext } from "react-hook-form";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import { useTranslations } from "@fenetech/translations";
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";

interface IProps {
    readOnly: boolean
}

const CompanyInformation: React.FC<IProps> = (props: IProps) => {

    const { formState: { errors } } = useFormContext();
    const tm = useTranslations();

    const [isExpanded, setIsEpanded] = React.useState<boolean>(true);

    return <>
        <Accordion expanded={isExpanded} onChange={() => setIsEpanded((prev) => !prev)} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("Company Information")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="name"
                            readOnly={props.readOnly}
                            requiredRule={{ value: true, message: tm.Get("Must enter a Company Name.") }}
                            error={errors.name?.message ? true : false}
                            helperText={errors.name?.message}
                            variant="standard"
                            id="company.name"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.CompanyName }}
                            label={tm.Get("Name")}
                            placeholder={tm.Get("Name")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="url"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="company.url"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.WebURL }}
                            label={tm.Get("Web URL")}
                            placeholder={tm.Get("Web URL")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="addressInfo.address1"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="company.addressInfo.address1"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.Address1 }}
                            label={tm.Get("Address 1")}
                            placeholder={tm.Get("Address 1")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="addressInfo.address2"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="company.addressInfo.address2"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.Address2 }}
                            label={tm.Get("Address 2")}
                            placeholder={tm.Get("Address 2")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="addressInfo.city"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="company.addressInfo.city"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.City }}
                            label={tm.Get("City")}
                            placeholder={tm.Get("City")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextFieldForm
                            fieldName="addressInfo.state"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="company.addressInfo.state"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.State }}
                            label={tm.Get("State")}
                            placeholder={tm.Get("State")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextFieldForm
                            fieldName="addressInfo.zipCode"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="company.addressInfo.zipCode"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.ZipCode }}
                            label={tm.Get("Zip Code")}
                            placeholder={tm.Get("Zip Code")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="addressInfo.phoneNumber"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="company.addressInfo.phoneNumber"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.Phone }}
                            label={tm.Get("Phone")}
                            placeholder={tm.Get("Phone")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextFieldForm
                            fieldName="addressInfo.faxNumber"
                            readOnly={props.readOnly}
                            variant="standard"
                            id="company.addressInfo.faxNumber"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.Fax }}
                            label={tm.Get("Fax")}
                            placeholder={tm.Get("Fax")}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default CompanyInformation;