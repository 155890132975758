import { useEffect } from 'react';

import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

function useMfgCustomer(mfgCustomerID: string, mfgSiteID: string) {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const webCustomers = selectionValues.mfgCustomerNames;

    const isExpired = false;

    const cacheKey = `${mfgSiteID}~|~${mfgSiteID}`;
    
    useEffect(() => {
        
        if (mfgSiteID && mfgSiteID) {
            if (!webCustomers.has(cacheKey) || isExpired)
            {
                actions.LoadMfgCustomerAsync(mfgCustomerID, mfgSiteID, cacheKey);
            }
        }
        
    }, [actions, mfgCustomerID, mfgSiteID, webCustomers, isExpired, cacheKey]);

    return webCustomers.get(cacheKey);
}

export default useMfgCustomer;

