import { TablePagination, Typography } from "@mui/material";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import { useTranslations } from "@fenetech/translations";
import { IWebCustomerSummary } from "models/IWebCustomer";
import React from 'react';
import CustomerCard from "./CustomerCard";

interface IProps {
    filteredCustomerList: IWebCustomerSummary[] | null,
    isMfgSiteVisible: boolean,
    viewCustomerHandler: (customerID: string) => void,
    editCustomerHandler: (customerID: string) => void,
    deleteCustomerHandler: (customerID: string) => void
}

const CustomersGridMobile: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const rowsPerPage = useRowsPerPage("CustomerMobile");

    const [page, setPage] = React.useState<number>(0);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        rowsPerPage.setPageSize(parseInt(event.target.value));
        setPage(0);
    };

    const paginatedCustomerList = React.useMemo(() => {
        if (!props.filteredCustomerList)
            return null;

        let startIndex = page * rowsPerPage.pageSize;
        let endIndex = startIndex + rowsPerPage.pageSize;
        endIndex = endIndex > props.filteredCustomerList.length ? props.filteredCustomerList.length : endIndex;
        
        return props.filteredCustomerList.slice(startIndex, endIndex);
    }, [page, rowsPerPage, props.filteredCustomerList])

    if (!paginatedCustomerList || !props.filteredCustomerList) return <Typography>{tm.Get("No data available")}</Typography>;
        
    return <>
        <TablePagination
            component="div"
            count={props.filteredCustomerList.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage.pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {paginatedCustomerList.map((customer, index) => {
            return <CustomerCard
                key={index}
                customer={customer}
                isMfgSiteVisible={props.isMfgSiteVisible}
                viewCustomerHandler={props.viewCustomerHandler}
                editCustomerHandler={props.editCustomerHandler}
                deleteCustomerHandler={props.deleteCustomerHandler}
            />
        })}
    </>
}

export default CustomersGridMobile;