import { IWebCustomerPricingInfo  } from "models/IWebCustomer";

export const BlankCustomer: IWebCustomerPricingInfo  = {
    customer: {
        customerGUID: "",
        customerID: "",
        parentCustomerID: "",
        parentSiteID: "",
        name: "",
        notes: "",
        inactive: false,
        shippingInfo: { address1: "", address2: "", city: "", state: "", zipCode: "", phoneNumber: "", faxNumber: "", emailAddress: "" },
        billingInfo: { address1: "", address2: "", city: "", state: "", zipCode: "", phoneNumber: "", faxNumber: "", emailAddress: "" },
        taxPercent: 0,
        taxPercent1: 0,
        taxPercent2: 0,
        taxPercent3: 0,
        taxPercent4: 0,
        taxTitle: "",
        taxTitle1: "",
        taxTitle2: "",
        taxTitle3: "",
        taxTitle4: "",
        terms: "",
        shipVia: "",
        default: false,
        pricingMethod: 0,
        useBillAsShipAddress: false,
        salesPersonID: 0,
        engineeringUnitSetID: 0,
        clickOnceEnabledForNonTemplateOpenings: false,
        userDef1: "",
        userDef2: "",
        userDef3: "",
        userDef4: "",
        userDef5: "",
        userDef6: "",
        userDef7: "",
        userDef8: "",
        userDef9: "",
        userDef10: "",
        userDef11: "",
        userDef12: "",
        userDef13: "",
        userDef14: "",
        userDef15: "",
        userDef16: "",
        userDef17: "",
        userDef18: "",
        userDef19: "",
        userDef20: ""
    },
    pricing: [{ id: 0, category: "", discountTable: "", discountTableID: -1, discountPercent: 0.0, catSeq: 0 }]
}