import React, { useCallback, useState } from "react";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Link, Box, Typography, Tooltip } from "@mui/material";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import APIHelper from "helpers/APIHelper";
import { IProductNavigatorNode } from "helpers/interfaces";
import ProductNavigatorDescriptionMessageBox from "components/ProductNavigator/ProductNavigatorDescriptionMessageBox";
import { IPartKey } from "helpers/interfaces";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IProps {
    node: IProductNavigatorNode,
    setFinalNode: (node: IProductNavigatorNode | null) => void,
    onPartSelected: (partKey: IPartKey) => void,
}

function ProductNavigatorNodeDisplay({node, setFinalNode, onPartSelected}: IProps) {

    const [showingDescription, setShowingDescription] = useState<boolean>(false);

    const getImageSource = useCallback(() => {
        let url = new URL(`${APIHelper.GetBaseURL()}api/Images/ProductNavigatorImage`);

        let params = {
            nodeID: node.nodeID
        };

        url.search = new URLSearchParams(params as any).toString();
        return url.toString();
    }, [node]);

    const handleNodeClick = useCallback((node: IProductNavigatorNode) => {
        if (node.partGUID === "") {
            setFinalNode(node);
        } else {
            onPartSelected({
                masterPartNo: node.masterPartNo,
                partNoSuffix: node.masterPartNoSuffix,
                shortcutName: ""
            });
        }
    }, [setFinalNode, onPartSelected]);

    return <>
        <Box margin="5px" display="inline-block">
            <Link maxHeight="180px" maxWidth="180px" minHeight="180px" minWidth="180px" component="button" underline="hover" onClick={() => handleNodeClick(node)}>
                <Tooltip title={node.tooltip}>
                    {node.imageExists ? 
                        <img height="180px" width="auto" style={{ maxWidth: "180px" }} src={getImageSource()} alt=""/>
                    :
                        <InsertPhotoIcon fontSize="large" />
                    }
                </Tooltip>
            </Link>
                
            <Box display="flex" alignItems="center" justifyContent="center">
                <Link component="button" underline="hover" onClick={() => handleNodeClick(node)}>
                    <Typography display="flex">{node.title} {node.partGUID === "" && <ExpandMoreIcon fontSize="small"/>}</Typography>
                </Link>
                {node.description !== "" &&
                    <Tooltip title={node.description}>
                        <StickyNote2Icon fontSize="small" onClick={() => setShowingDescription(true)}/>
                    </Tooltip>
                }
                {node.description === "" && node.hyperLink !== "" &&
                    <Tooltip title={node.hyperLinkText === "" ? node.hyperLink : node.hyperLinkText}>
                        <Link display="flex" href={node.hyperLink} target="_blank" rel="noopener noreferrer">
                            <StickyNote2Icon fontSize="small"/>
                        </Link>
                    </Tooltip>
                }
            </Box>
        </Box>

        <ProductNavigatorDescriptionMessageBox open={showingDescription} node={node} close={() => setShowingDescription(false)}/>
    </>
}

export default ProductNavigatorNodeDisplay