import LineItemColumn, { IOrderLevelProps } from "components/Orders/OrderView/LineItems/Columns/LineItemColumn";
import LineItemSize from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemSize";
import { IOrderLineItem } from "helpers/interfaces";

export default class LineItemSizeColumn extends LineItemColumn {

    public constructor(columnName: string, headerText: string, width: string) {
        super(columnName, headerText, width);
        this.align = "left";
    }

    public CellContent(props: IOrderLevelProps, lineItem: IOrderLineItem): JSX.Element {

        return <LineItemSize
            lineItem={lineItem}
            engineeringUnitSetID={props.order.engineeringUnitSetID}
            variant="body2"
        />
    }

    public FooterContent(props: IOrderLevelProps): JSX.Element {
        return <></>;
    }
}
